import React, { useState, useEffect, useMemo } from "react";
import "./Detail.css";
import "./TypeSelect.css";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedColor } from "../../store/slices/colorSlice";
import CloseIcon from "@mui/icons-material/Close";
import ProductPurchase from "./ProductPurchase";
import { setSelectedVariant } from "../../features/productDesc/productDescSlice";
import { findPDPqty } from "../../data";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import {
  addToCartsAsync,
  addToPreorderCartsAsync,
  fetchAllCartsAsync,
  fetchAllPreorderCartsAsync,
  removeFromCartsAsync,
  selectAllCarts,
  selectAllPreorders,
} from "../../features/cartPage/cartPageSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { selectUser } from "../../features/auth/authSlice";
import { toggleOverlay } from "../../store/slices/headerSlice";
import { useNavigate } from "react-router";
import { calculateDealerPrice, calculateOfferPrice, colourNameToHex, formatWithCommasWithDecimal } from "../../utils/utils";

function TypeSelect({ selectProduct, qtys }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const [cartVal, setCartVal] = useState(0);
  const userDetail = useSelector(selectUser);
  const selectedVariant = useSelector(
    (state) => state.productDetail.selectedVariant
  );
  // console.log(selectProduct, qtys);
  // console.log(selectedVariant);
  const quantity = findPDPqty(selectedVariant?.SKU_ID, qtys && qtys) || 0;
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectColor, setSelectColor] = useState(null);
  // console.log(selectedSize, selectColor);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);
  const [isCartValueZero, setIsCartValueZero] = useState(false);
  const cart = useSelector(selectAllCarts);
  const preOrder = useSelector(selectAllPreorders);
  const [selectTab, setSelectTab] = useState("variant");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const variations = useMemo(
    () =>
      (selectProduct &&
        selectProduct[0]?.skuDetails &&
        selectProduct[0]?.skuDetails) ||
      [],
    [selectProduct]
  );

  useEffect(() => {
    if (userDetail?.UserID) {
      dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail?.UserID]);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    // console.log("useEffect type select")
    if (cartVal > findPDPqty(selectedVariant?.SKU_ID, qtys && qtys)) {
      setCartVal(findPDPqty(selectedVariant?.SKU_ID, qtys && qtys));
    }
  }, [selectedVariant]);

  const handleRequestMore = () => {
    setRequestSubmitted(true);

    setTimeout(() => {
      setRequestSubmitted(false);
    }, 2000); // Remove message after 3 seconds (3000 milliseconds)
  };
  const handleInputChange = (e) => {
    // Ensure the entered value is a number
    let inputValue = parseInt(e.target.value);
    if (selectProduct && selectProduct[0].Manufacturer === "ESI") {
      setCartVal(parseInt(inputValue && inputValue ) || 0);
    } else {
      const newQuantity = Math.min(parseInt(inputValue) || 0, quantity || 0);

      setCartVal(newQuantity);
    }
  };

  const handleInputBlur = () => {
    // If the user enters a quantity beyond the product quantity, adjust it
    if (selectProduct && selectProduct[0].Manufacturer === "ESI") {
      const newQuantity = cartVal || 0;
      const updateqty = parseInt(
        cartVal % parseInt(selectProduct && selectProduct[0]?.Bundle)
      );
      setCartVal(String(cartVal - updateqty));
    } else {
      const newQuantity = Math.min(cartVal, quantity || 0);
      if (cartVal < quantity) {
        const updateqty = parseInt(
          cartVal % parseInt(selectProduct && selectProduct[0]?.Bundle)
        );
        setCartVal(String(cartVal - updateqty));
      } else {
        const updateqty = parseInt(
          quantity % parseInt(selectProduct && selectProduct[0]?.Bundle)
        );
        setCartVal(String(quantity - updateqty));
      }
    }
  };


  function increaseCarts(cartVal) {
    // should increase multiple of cartVal * currentProduct[0].Bundle
    const bundleSize = parseInt(selectProduct && selectProduct[0]?.Bundle);
    if (selectProduct && selectProduct[0].Manufacturer === "ESI") {
      setCartVal(parseInt(cartVal) + parseInt(bundleSize));
    } else {
      const maxQty = findPDPqty(selectedVariant?.SKU_ID, qtys && qtys);
      if (cartVal + bundleSize <= maxQty) {
        setCartVal(cartVal + bundleSize);
      }
    }
  }
  function decreaseCarts() {
    // should decrease multiple of cartVal * currentProduct[0].Bundle
    const bundleSize = parseInt(selectProduct && selectProduct[0]?.Bundle);
    if (cartVal > bundleSize) {
      setCartVal(cartVal - bundleSize);
    } else if (cartVal > 1) {
      // If current quantity is not a multiple of bundleSize but > 1, decrease to nearest multiple
      setCartVal(Math.max(1, Math.floor(cartVal / bundleSize) * bundleSize));
    }
  }

  useEffect(() => {
    // console.log("useEffect type select")
    const sizes = [...new Set(variations.map((variant) => variant.size_name))];
    const colors = [
      ...new Set(variations.map((variant) => variant.colour_name)),
    ];
    // setSelectedSize(selectedVariant?.size_name || null);
    // setSelectColor(selectedVariant?.colour_name || null);
    dispatch(setSelectedVariant(variations[0]));
    setAvailableSizes(sizes);
    setAvailableColors(colors);
  }, [variations, selectedVariant]);

  useEffect(() => {
    if (variations && variations?.length === 1) {
      setSelectedSize(variations[0]?.size_name);
      setSelectColor(variations[0]?.colour_name);
    }
    else if(availableSizes.length === 1){
      setSelectedSize(availableSizes && availableSizes[0]);
    }
    else if(availableColors.length === 1){
      setSelectColor(availableColors && availableColors[0]);
    } else {
      setSelectedSize(null);
      setSelectColor(null);
    }
  }, [variations]);

  useEffect(() => {
    // console.log("useEffect type select-2")
    let filteredSizes = [];
    let filteredColors = [];

    if (!selectedSize && !selectColor) {
      filteredSizes = [
        ...new Set(variations.map((variant) => variant.size_name)),
      ];
      filteredColors = [
        ...new Set(variations.map((variant) => variant.colour_name)),
      ];
      dispatch(setSelectedVariant(variations[0]));
    } else {
      if (selectedSize && selectColor) {
        const selectedSku = variations.find(
          (variant) =>
            variant.size_name === selectedSize &&
            variant.colour_name === selectColor
        );

        if (selectedSku) {
          dispatch(setSelectedVariant(selectedSku));
        }
      } else if (selectedSize && !selectColor) {
        const selectedSku = variations.find(
          (variant) => variant.size_name === selectedSize
        );
        if (selectedSku) {
          dispatch(setSelectedVariant(selectedSku));
        }
      } else if (!selectedSize && selectColor) {
        const selectedSku = variations.find(
          (variant) => variant.colour_name === selectColor
        );
        if (selectedSku) {
          dispatch(setSelectedVariant(selectedSku));
        }
      }

      filteredSizes = [
        ...new Set(variations.map((variant) => variant.size_name)),
      ];
      filteredColors = [
        ...new Set(variations.map((variant) => variant.colour_name)),
      ];

      if (selectedSize) {
        filteredColors = variations
          .filter((variant) => variant.size_name === selectedSize)
          .map((variant) => variant.colour_name);
      }

      if (selectColor) {
        filteredSizes = variations
          .filter((variant) => variant.colour_name === selectColor)
          .map((variant) => variant.size_name);
      }
    }

    setAvailableSizes([...new Set(filteredSizes)]);
    setAvailableColors([...new Set(filteredColors)]);
  }, [selectedSize, selectColor, variations, dispatch, selectedVariant]);

  const handleRemoveSize = () => {
    setSelectedSize(null);
  };
  const handleRemoveColor = () => {
    dispatch(setSelectedColor(null));
    setSelectColor(null);
  };
  const handleSelectSize = (size) => {
    setSelectedSize(size);
  };
  const handleSelectColor = (color) => {
    setSelectColor(color);
  };



  const handleCurrentPrice = () => {
    const originalPrice =
      selectedVariant && selectedVariant?.Offer_price
        ? calculateOfferPrice(
            parseInt(selectedVariant?.MRP_price),
            parseInt(selectedVariant?.Offer_price),
            selectProduct && selectProduct[0] && selectProduct[0]?.GST
          )
        : calculateDealerPrice(
            parseInt(selectedVariant?.MRP_price),
            parseInt(selectedVariant?.NDP_Price),
            selectProduct && selectProduct[0] && selectProduct[0]?.GST
          );

    const discountedPrice = originalPrice;
    const finalPrice = discountedPrice * cartVal;

    return finalPrice;
  };

  // Function to check if a product is in the cart
  const isInCart = () => {
    return (
      Array.isArray(cart) &&
      cart?.some(
        (item) =>
          (item?.skuDetails?.SKU_ID) ===
          (selectedVariant?.SKU_ID)
      )
    );
  };

  // Function to check if a product is in the prebook
  const isInPreBook = () => {
    return (
      Array.isArray(preOrder) &&
      preOrder?.some(
        (item) => parseInt(item?.SKU_ID) === parseInt(selectedVariant?.SKU_ID)
      )
    );
  };

  useEffect(() => {
    if (cartVal > 0) {
      setIsCartValueZero(false);
    }
  }, [cartVal]);

  useEffect(() => {
    if (Object.keys(userDetail).length > 0) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, [userDetail]);

  // TO ADD PRODUCT INTO CART
  const addToCart = () => {
    if (
      availableSizes &&
      availableSizes[0] !== null &&
      availableColors &&
      availableColors[0] !== null
    ) {
      if (selectedSize && selectColor) {
        handleOpen();
        let cartData = {
          userID: userDetail?.UserID,
          SKU: selectedVariant?.SKU_ID,
          buyQty:
            cartVal === 0 ? selectProduct && selectProduct[0]?.Bundle : cartVal,
        };
        if (Object.keys(userDetail).length > 0 && cartVal >= 1) {
          dispatch(addToCartsAsync(cartData)).then(() => {
            dispatch(fetchAllCartsAsync(userDetail.UserID)).then(() => {
              handleClose();
            });
          });
        } else if (Object.keys(userDetail).length > 0 && cartVal === 0) {
          setIsCartValueZero(true);
          handleClose();
        } else {
          setSelectTab("price");
          setIsLoggedIn(true);
          handleClose();
        }
      } else {
        return;
      }
    } else if (
      availableSizes &&
      availableSizes[0] !== null &&
      availableColors[0] === null
    ) {
      if (selectedSize !== null) {
        handleOpen();
        let cartData = {
          userID: userDetail?.UserID,
          SKU: selectedVariant?.SKU_ID,
          buyQty:
            cartVal === 0 ? selectProduct && selectProduct[0]?.Bundle : cartVal,
        };
        if (Object.keys(userDetail).length > 0 && cartVal >= 1) {
          dispatch(addToCartsAsync(cartData)).then(() => {
            dispatch(fetchAllCartsAsync(userDetail.UserID)).then(() => {
              handleClose();
            });
          });
        } else if (Object.keys(userDetail).length > 0 && cartVal === 0) {
          setIsCartValueZero(true);
          handleClose();
        } else {
          setSelectTab("price");
          setIsLoggedIn(true);
          handleClose();
        }
      }
    } else if (
      availableSizes &&
      availableSizes[0] === null &&
      availableColors &&
      availableColors[0] !== null
    ) {
      if (selectColor) {
        handleOpen();
        let cartData = {
          userID: userDetail?.UserID,
          SKU: selectedVariant?.SKU_ID,
          buyQty:
            cartVal === 0 ? selectProduct && selectProduct[0]?.Bundle : cartVal,
        };
        if (Object.keys(userDetail).length > 0 && cartVal >= 1) {
          dispatch(addToCartsAsync(cartData)).then(() => {
            dispatch(fetchAllCartsAsync(userDetail.UserID)).then(() => {
              handleClose();
            });
          });
        } else if (Object.keys(userDetail).length > 0 && cartVal === 0) {
          setIsCartValueZero(true);
          handleClose();
        } else {
          setSelectTab("price");
          setIsLoggedIn(true);
          handleClose();
        }
      }
    } else {
      return;
    }
  };

  const buyNowHandle = () => {
    if (isInCart()) {
      navigate("/carts");
    } else {
      if (
        availableSizes &&
        availableSizes[0] !== null &&
        availableColors &&
        availableColors[0] !== null
      ) {
        if (selectedSize && selectColor) {
          handleOpen();
          let cartData = {
            userID: userDetail?.UserID,
            SKU: selectedVariant?.SKU_ID,
            buyQty:
              cartVal === 0
                ? selectProduct && selectProduct[0]?.Bundle
                : cartVal,
          };
          if (Object.keys(userDetail).length > 0 && cartVal >= 1) {
            dispatch(addToCartsAsync(cartData)).then(() => {
              dispatch(fetchAllCartsAsync(userDetail.UserID)).then(() => {
                handleClose();
                navigate("/carts");
              });
            });
          } else if (Object.keys(userDetail).length > 0 && cartVal === 0) {
            setIsCartValueZero(true);
            handleClose();
          } else {
            setSelectTab("price");
            setIsLoggedIn(true);
            handleClose();
          }
        } else {
          return;
        }
      } else if (
        availableSizes &&
        availableSizes[0] !== null &&
        availableColors[0] === null
      ) {
        if (selectedSize !== null) {
          handleOpen();
          let cartData = {
            userID: userDetail?.UserID,
            SKU: selectedVariant?.SKU_ID,
            buyQty:
              cartVal === 0
                ? selectProduct && selectProduct[0]?.Bundle
                : cartVal,
          };
          if (Object.keys(userDetail).length > 0 && cartVal >= 1) {
            dispatch(addToCartsAsync(cartData)).then(() => {
              dispatch(fetchAllCartsAsync(userDetail.UserID)).then(() => {
                handleClose();
                navigate("/carts");
              });
            });
          } else if (Object.keys(userDetail).length > 0 && cartVal === 0) {
            setIsCartValueZero(true);
            handleClose();
          } else {
            setSelectTab("price");
            setIsLoggedIn(true);
            handleClose();
          }
        }
      } else if (
        availableSizes &&
        availableSizes[0] === null &&
        availableColors &&
        availableColors[0] !== null
      ) {
        if (selectColor) {
          handleOpen();
          let cartData = {
            userID: userDetail?.UserID,
            SKU: selectedVariant?.SKU_ID,
            buyQty:
              cartVal === 0
                ? selectProduct && selectProduct[0]?.Bundle
                : cartVal,
          };
          if (Object.keys(userDetail).length > 0 && cartVal >= 1) {
            dispatch(addToCartsAsync(cartData)).then(() => {
              dispatch(fetchAllCartsAsync(userDetail.UserID)).then(() => {
                handleClose();
                navigate("/carts");
              });
            });
          } else if (Object.keys(userDetail).length > 0 && cartVal === 0) {
            setIsCartValueZero(true);
            handleClose();
          } else {
            setSelectTab("price");
            setIsLoggedIn(true);
            handleClose();
          }
        }
      } else {
        return;
      }
    }
  };

  const removeFromCart = () => {
    handleOpen();
    let cartData = {
      userID: userDetail?.UserID,
      SKU: selectedVariant?.SKU_ID,
    };
    dispatch(removeFromCartsAsync(cartData)).then(() => {
      dispatch(fetchAllCartsAsync(userDetail.UserID)).then(() => {
        handleClose();
      });
    });
  };

  const addToPreBook = () => {
    if (
      availableSizes &&
      availableSizes[0] !== null &&
      availableColors &&
      availableColors[0] !== null
    ) {
      if (selectedSize && selectColor) {
        handleOpen();
        let preOrderData = {
          UserID: userDetail?.UserID,
          SKU_ID: selectedVariant?.SKU_ID,
          quantity:
            cartVal === 0 ? selectProduct && selectProduct[0]?.Bundle : cartVal,
        };
        if (Object.keys(userDetail).length > 0 && cartVal >= 1) {
          dispatch(addToPreorderCartsAsync(preOrderData)).then(() => {
            dispatch(fetchAllPreorderCartsAsync(userDetail.UserID)).then(() => {
              handleClose();
            });
          });
        } else if (Object.keys(userDetail).length > 0 && cartVal === 0) {
          setIsCartValueZero(true);
          handleClose();
        } else {
          setSelectTab("price");
          setIsLoggedIn(true);
          handleClose();
        }
      } else {
        return;
      }
    } else if (
      availableSizes &&
      availableSizes[0] !== null &&
      availableColors[0] === null
    ) {
      if (selectedSize !== null) {
        handleOpen();
        let preOrderData = {
          UserID: userDetail?.UserID,
          SKU_ID: selectedVariant?.SKU_ID,
          quantity:
            cartVal === 0 ? selectProduct && selectProduct[0]?.Bundle : cartVal,
        };
        if (Object.keys(userDetail).length > 0 && cartVal >= 1) {
          dispatch(addToPreorderCartsAsync(preOrderData)).then(() => {
            dispatch(fetchAllPreorderCartsAsync(userDetail.UserID)).then(() => {
              handleClose();
            });
          });
        } else if (Object.keys(userDetail).length > 0 && cartVal === 0) {
          setIsCartValueZero(true);
          handleClose();
        } else {
          setSelectTab("price");
          setIsLoggedIn(true);
          handleClose();
        }
      }
    } else if (
      availableSizes &&
      availableSizes[0] === null &&
      availableColors &&
      availableColors[0] !== null
    ) {
      if (selectColor) {
        handleOpen();
        let preOrderData = {
          UserID: userDetail?.UserID,
          SKU_ID: selectedVariant?.SKU_ID,
          quantity:
            cartVal === 0 ? selectProduct && selectProduct[0]?.Bundle : cartVal,
        };
        if (Object.keys(userDetail).length > 0 && cartVal >= 1) {
          dispatch(addToPreorderCartsAsync(preOrderData)).then(() => {
            dispatch(fetchAllPreorderCartsAsync(userDetail.UserID)).then(() => {
              handleClose();
            });
          });
        } else if (Object.keys(userDetail).length > 0 && cartVal === 0) {
          setIsCartValueZero(true);
          handleClose();
        } else {
          setSelectTab("price");
          setIsLoggedIn(true);
          handleClose();
        }
      }
    } else {
      return;
    }
  };

  return (
    <div className="products-type-select-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open} // Show backdrop when either open or filtering state is true
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="products-select-tabs-container">
        <span
          className={`products-select-each-tabs ${
            selectTab === "variant" && "products-select-tabs"
          }`}
          onClick={() => setSelectTab("variant")}
        >
          Variant
        </span>
        <span
          className={`products-select-each-tabs ${
            selectTab === "price" && "products-select-tabs-price"
          }`}
          onClick={() => setSelectTab("price")}
        >
          Price Details
        </span>
      </div>
      <div className="products-selected-variant-container">
        {selectedSize && (
          <div className="products-selected-size-container">
            <span className="products-selected-size">
              {selectedSize.split(" ").slice(0, 2).join(" ")}
            </span>
            <CloseIcon onClick={handleRemoveSize} />
          </div>
        )}
        {selectColor && (
          <div className="products-selected-size-container">
            <span className="products-selected-size">
              {selectColor.split(" ").slice(0, 2).join(" ")}
            </span>
            <CloseIcon onClick={handleRemoveColor} />
          </div>
        )}
      </div>
      {selectTab === "variant" && (
        <>
          <div className="products-selected-container">
            <div className="products-variant-container">
              {availableSizes &&
                availableSizes[0] !== null &&
                availableSizes.length > 0 && (
                  <div className="products-size-container">
                    <span className="products-size-heading">Choose Size:</span>
                    <select
                      className="products-size-select-container"
                      name="size"
                      id="size"
                      onChange={(e) => handleSelectSize(e.target.value)}
                    >
                      <option value="">Size</option>
                      {availableSizes &&
                        availableSizes?.map((size) => (
                          <option
                            key={size}
                            value={size}
                            defaultValue={selectedSize === size}
                          >
                            {size}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
              {availableColors &&
                availableColors[0] !== null &&
                availableColors?.length > 0 && (
                  <div className="products-color-container">
                    <span className="products-color-heading">
                      Choose Color:
                    </span>

                    {availableColors && availableColors[0] !== undefined && (
                      <div className="products-color-list-container">
                        {availableColors.map((color, index) => (
                          <div
                            className="products-color-list-main-container"
                            key={index}
                          >
                            <div
                              key={color}
                              className={`desktop-select-variant-bottom-product-color-div ${
                                color === selectColor ? "active" : ""
                              }`}
                              style={{ marginRight: "0" }}
                              onClick={() => {
                                handleSelectColor(color);
                              }}
                            >
                              <button
                                className={
                                  color === selectColor ? "active" : ""
                                }
                                style={{ background: colourNameToHex(color) }}
                              />
                            </div>
                            <span className="color-name-text">
                              {color.split(" ").slice(0, 2).join(" ")}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
          <div className="products-carts-container">
            <div className="products-carts-instock-container">
              {cartVal ===
              findPDPqty(
                selectedVariant && selectedVariant?.SKU_ID,
                qtys && qtys
              ) ? (
                <div className="max-inventery-count-request">
                  <span className="max-inventery-count">
                    <div>
                      <sup>*</sup>only{" "}
                      {findPDPqty(
                        selectedVariant && selectedVariant?.SKU_ID,
                        qtys && qtys
                      )}{" "}
                      units in stocks!
                    </div>
                  </span>
                  <div
                    className={`request-submitted-success ${
                      requestSubmitted ? "open" : ""
                    }`}
                  >
                    <iframe
                      src="https://giphy.com/embed/QJ4Hm8oJgMJIqFAuVc"
                      width="50"
                      height="50"
                      title="done"
                      frameBorder="0"
                      className="giphy-embed"
                      allowFullScreen
                    ></iframe>
                    <span>Your request has been submitted successfully!</span>
                  </div>
                  <span
                    className="product-request-more"
                    onClick={handleRequestMore}
                  >
                    Request more
                  </span>
                </div>
              ) : (
                <div className="product-inStock">
                  <CheckBoxOutlinedIcon />
                  <span>In Stock</span>
                </div>
              )}
            </div>
            <div className="products-carts-quantity-price-container">
              <div className="product-carts-counter-div">
                <p>Quantity:</p>
                <div className="product-counter-invertory">
                  <div className="product-counter">
                    <KeyboardArrowDownIcon
                      style={{
                        background:
                          cartVal <= selectProduct && selectProduct[0]?.Bundle
                            ? "rgb(165, 157, 159)"
                            : "var(--color-blue)",
                        borderRadius: "50px",
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => decreaseCarts(cartVal)}
                    />
                    <input
                      id="cart-value-input"
                      type="text"
                      onKeyPress={(e) => {
                        if (!/[0-9\b]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      value={cartVal}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      style={{ border: isCartValueZero ? "1px solid red" : "" }}
                    />
                    <KeyboardArrowUpIcon
                      style={{
                        background:
                          cartVal ===
                          findPDPqty(selectedVariant?.SKU_ID, qtys && qtys) && selectProduct && selectProduct[0]?.Manufacturer !== "ESI"
                            ? "rgb(165, 157, 159)"
                            : "var(--color-blue)",
                        borderRadius: "50px",
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => increaseCarts(cartVal)}
                    />
                  </div>
                </div>
                {(cartVal <= 0 ||
                  (availableSizes &&
                    availableSizes[0] !== null &&
                    !selectedSize) ||
                  (availableColors &&
                    availableColors[0] !== null &&
                    !selectColor)) && (
                  <div className="pdp-show-message-for-variant-part">
                    <sub style={{ marginTop: "-6px" }}>★</sub>Please select{" "}
                    {availableSizes &&
                      availableSizes[0] !== null &&
                      !selectedSize && <span>&nbsp;size</span>}{" "}
                    {availableColors &&
                      availableColors[0] !== null &&
                      !selectColor && <span>, color</span>}{" "}
                    {cartVal <= 0 && <span>, quantity</span>}
                  </div>
                )}
              </div>
              <div className="products-totals-price">
                Total: ₹
                {Object.keys(userDetail).length > 0 ? (
                  formatWithCommasWithDecimal(handleCurrentPrice())
                ) : (
                  <span style={{ marginLeft: "0.5vw", fontWeight: "500" }}>
                    ---/---
                  </span>
                )}
              </div>
            </div>
            <div className="products-carts-btn-container">
              {selectProduct && selectProduct[0]?.Manufacturer === "ESI" ? (
                <div
                  className="desktop-prebook-btn-container"
                  style={{ height: "40px" }}
                >
                  {isInPreBook() ? (
                    <span className="desktop-prebook-remove-btn">
                      <AddTaskOutlinedIcon />
                      Pre-Booked
                    </span>
                  ) : (
                    <span
                      className="desktop-prebook-book-btn"
                      onClick={() => addToPreBook()}
                    >
                      Pre-Book - At no advance cost
                    </span>
                  )}
                </div>
              ) : (
                <>
                  <span
                    className="add-cart"
                    onClick={isInCart() ? removeFromCart : addToCart}
                  >
                    {isInCart() ? <span>Added</span> : <span>Add to Cart</span>}
                  </span>
                  <span className="buy-now-btn" onClick={buyNowHandle}>
                    <span>Buy Now</span>
                  </span>
                </>
              )}
            </div>
          </div>
        </>
      )}
      {selectTab === "price" && (
        <ProductPurchase
          currentProduct={selectProduct}
          qtys={qtys}
          cartVal={cartVal}
          setCartVal={setCartVal}
          availableSizes={availableSizes}
          availableColors={availableColors}
          selectedSize={selectedSize}
          selectColor={selectColor}
        />
      )}
    </div>
  );
}

export default TypeSelect;
