import React from "react";
import "./MobileNoProductPage.css";
import noProductLogo from "../../img/no-products-logo.png";

function MobileNoProductPage() {
  return (
    <div className="mobile-no-product-item-container">
        <img src={noProductLogo} alt="" />
        <h3>No products were found.</h3>
        <span className="mobile-sorry-mate-product">
          Sorry, Temporarily we couldn't find any products
        </span>
    </div>
  );
}

export default MobileNoProductPage;
