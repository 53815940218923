import React, { useState } from "react";
import "./UserLoginPage.css";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import AuthenticationPage from "../Authentication/AuthenticationPage";
import {
  toggleOverlay,
  selectIsOverlayVisible,
} from "../../store/slices/headerSlice";
import NearbyStore from "./NearbyStore";
function UserLoginPage({
  handleLogin,
  isOverlayVisible,
  setSearchTerm,
  toggleOverlay,
}) {
  const [activeTab, setActiveTab] = useState("cycleDealer");
  const [openStore, setOpenStore] = useState(null);
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLoginOverlay = () => {
    toggleOverlay();
  };
  const handleRegisterNow = () => {
    navigate("/sign-up");
  };

  const handleSubmitPincode = (e) => {
    e.preventDefault();
    const pincode = e.target.elements.pincode.value;
    // console.log(pincode);
    setOpenStore(pincode);
  };

  return (
    <div className="User-login-page-product-page-container">
      <div className="user-login-text-container">
        <p>You are not logged in Please login to make a purchase</p>
        <span>Are you a?</span>
      </div>
      <div className="user-login-tabs-container">
        <span
          className={activeTab === "cycleDealer" ? "active-tab" : ""}
          onClick={() => handleTabClick("cycleDealer")}
        >
          Cycle Dealer
        </span>
        <span
          className={activeTab === "customer" ? "active-tab" : ""}
          onClick={() => handleTabClick("customer")}
        >
          Customer
        </span>
      </div>
      {activeTab === "cycleDealer" && (
        <div className="dealership-page-detail">
          <button className="dealership-login-now" onClick={handleLoginOverlay}>
            Login
          </button>
          <span style={{ color: "#595757", margin: "3px 0" }}>- or -</span>
          <button
            className="dealership-register-now"
            onClick={handleLoginOverlay}
          >
            Register Now
          </button>
          <div className="register-user-cyclecircle">
            <p
              style={{
                width: "100%",
                textAlign: "center",
                color: "var(--color-blue)",
                fontSize: "1.05rem",
                paddingTop: "15px",
              }}
            >
              Register now on Cyclecircle.one
            </p>
            <p style={{ textAlign: "center", color: "gray" }}>
              25 minutes rapid onboarding make your first ever purchasing in
              Cyclecircle.one
            </p>
          </div>
        </div>
      )}
      {activeTab === "customer" && (
        <div className="customer-page-detail">
          <p style={{ color: "var(--color-blue)" }}>
            To find your nearest cyclecircle dealership please enter your
            pincode:
          </p>
          <form
            className="customer-search-pincode"
            onSubmit={handleSubmitPincode}
          >
            <input
               type="text"
               inputmode="numeric"
              name="pincode"
              pattern="[0-9]*"
              onKeyPress={(e) => {
                // Allow only numbers (0-9) and backspace (8)
                if (!/[0-9\b]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onInput={(e) => {
                // Limit input to 10 digits
                if (e.target.value.length > 6) {
                  e.target.value = e.target.value.slice(0, 6);
                }
              }}
              maxLength="6"
              max="999999"
              placeholder="Enter Pincode"
              required
            />
            <button type="submit" className="customer-check-btn">
              Check
            </button>
          </form>
          <p style={{ textAlign: "center" }}>
            Find the nearest Cyclecircle.one dealership to make your purchase
            directly.
          </p>
        </div>
      )}
      {isOverlayVisible && (
        <div className="overlay-container">
          <AuthenticationPage />
        </div>
      )}
      {openStore && openStore?.length === 6 && (
        <>
          <div className="nearby-overlay"></div>
          <div className="nearby-dealers-store-container-overlay">
            <NearbyStore openStore={openStore} setOpenStore={setOpenStore} />
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});
const mapDispatchToProps = {
  toggleOverlay,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserLoginPage);
