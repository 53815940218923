import React, { useEffect, useState } from "react";
import "./AdminDashboard.css";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import NoCartItem from "../Carts/NoCartItem";
import { IMAGE_BASE } from "../../config";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  calculateDealerPrice,
  calculateDiscountPercentage,
  calculateOfferPrice,
  formatWithCommasWithDecimal,
} from "../../utils/utils";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import {
  fetchAllPreOrderUserDetailsAsync,
  removeAllFromPreorderCartsAsync,
  selectAllPreordersUser,
} from "../../features/cartPage/cartPageSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";

function AdminPreOrder() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const userDetail = useSelector(selectUser);
  const userPreBook = useSelector(selectAllPreordersUser);
  // console.log(userPreBook);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(userPreBook);

  const [openOrder, setOpenOrder] = useState(null);
  const [fFilled, setFFilled] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail?.UserID) {
      handleOpen();
      dispatch(fetchAllPreOrderUserDetailsAsync(userDetail?.UserID)).then(
        () => {
          handleClose();
        }
      );
    }
  }, [dispatch, userDetail?.UserID]);

  useEffect(() => {
    // Filter users whenever the search query changes
    if (userPreBook) {
      setFilteredUsers(
        userPreBook.filter(
          (user) =>
            user?.FirstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user?.LastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user?.PhoneNumber.includes(searchQuery)
        )
      );
    }
  }, [searchQuery, userPreBook]);

  const wantToFulfilled = (id) => {
    // console.log(fFilled);
    handleOpen();
    dispatch(removeAllFromPreorderCartsAsync(fFilled)).then(() => {
      dispatch(fetchAllPreOrderUserDetailsAsync(userDetail?.UserID));
      setFFilled(null);
      handleClose();
    });
  };
  const handleFulfilled = (id) => {
    setFFilled(id);
  };

  const calculateTotalAmount = (user) => {
    let totalAmount = 0;

    if (user && user?.Preorders) {
      for (const preorder of user?.Preorders) {
        // Choose the price: offer price if available, otherwise NDP price
        const price = preorder?.Offer_price
          ? calculateOfferPrice(
              parseInt(preorder?.MRP_price),
              parseInt(preorder?.Offer_price),
              preorder?.GST
            )
          : calculateDealerPrice(
              parseInt(preorder?.MRP_price),
              parseInt(preorder?.NDP_price),
              preorder?.GST
            );

        // Calculate the amount for this preorder item
        const amount = price * preorder?.quantity;

        // Add to the total amount
        totalAmount += amount;
      }
    }

    return totalAmount;
  };

  const calculateOverallAmount = () => {
    let overallAmount = 0;

    if (userPreBook && userPreBook?.length > 0) {
      for (const user of userPreBook) {
        if (user && user?.Preorders) {
          for (const preorder of user?.Preorders) {
            // Choose the price: offer price if available, otherwise NDP price
            const price = preorder?.Offer_price
              ? calculateOfferPrice(
                  parseInt(preorder?.MRP_price),
                  parseInt(preorder?.Offer_price),
                  preorder?.GST
                )
              : calculateDealerPrice(
                  parseInt(preorder?.MRP_price),
                  parseInt(preorder?.NDP_price),
                  preorder?.GST
                );

            // Calculate the amount for this preorder item
            const amount = price * preorder?.quantity;

            // Add to the total amount
            overallAmount += amount;
          }
        }
      }
    }
    return overallAmount;
  };

  return (
    <div className="admin-dashboard-right-pre-order-1">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="admin-dashboard-right-top-heading">
        <span className="admin-dashboard-heading-name">Pre-Orders</span>
        <img
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail?.UserImage || ""
          }`}
          alt={userDetail?.UserImage || ""}
          className="admin-logo"
        />
      </div>
      <div className="admin-dashboard-main-container">
        <div className="admin-dashboard-middle-container">
          <div className="admin-dashboard-total-preorder-container">
            <span className="admin-dashboard-heading-total">
              Total Pre-Orders
            </span>
            <span className="admin-dashboard-total-count">
              {userPreBook && Array.isArray(userPreBook)
                ? userPreBook?.length
                : 0}
            </span>
            <WorkHistoryOutlinedIcon />
          </div>
          <div
            className="admin-dashboard-middle-search-container"
            style={{ position: "relative", top: "20px", right: "75px" }}
          >
            <SearchIcon />
            <input
              type="text"
              placeholder="Search by name or phone"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="admin-dashboard-search-input"
            />
          </div>
        </div>
        {filteredUsers && Array.isArray(filteredUsers) ? (
          <div className="admin-dashboard-main-user-preorder-container">
            <div className="admin-dashboard-main-user-preorder-header">
              <span className="admin-dashboard-main-user-preorder-heading">
                Recent Pre-Orders
              </span>
              <span className="admin-dashboard-main-user-preorder-overall">
                Overall Amount:
                <b style={{ fontSize: "16px" }}>
                  {" "}
                  ₹{formatWithCommasWithDecimal(calculateOverallAmount())}
                </b>
              </span>
            </div>
            <div className="admin-dashboard-main-user-item-head">
              <span className="admin-dashboard-serial-no">S.No</span>
              <span className="admin-dashboard-item-each-head">User Name</span>
              <span className="admin-dashboard-item-each-head">
                Phone Number
              </span>
              <span className="admin-dashboard-item-each-head">Email</span>
              <span className="admin-dashboard-item-each-head">Order</span>
              <span className="admin-dashboard-item-dot">-</span>
            </div>
            <div className="admin-dashboard-main-user-prebook-container">
              {filteredUsers &&
                Array.isArray(filteredUsers) &&
                filteredUsers?.map((user, index) => (
                  <div className="admin-dashboard-main-each-user" key={index}>
                    <div
                      className="admin-dashboard-main-each-user-top"
                      style={{
                        boxShadow:
                          user?.UserID === openOrder &&
                          "0 3px 3px 0 rgba(0, 0, 0, 0.1)",
                      }}
                      key={index}
                    >
                      <span className="admin-dashboard-user-sno">
                        {index + 1}
                      </span>
                      <span className="admin-user-each-attr">
                        {user?.FirstName}&nbsp;{user?.LastName}
                      </span>
                      <span className="admin-user-each-attr">
                        {user?.PhoneNumber}
                      </span>
                      <span className="admin-user-each-attr">
                        {user?.Email}
                      </span>
                      <span className="admin-user-each-attr">
                        Total Amount: ₹
                        {formatWithCommasWithDecimal(
                          calculateTotalAmount(user)
                        )}
                        {openOrder === user?.UserID ? (
                          <KeyboardArrowDownOutlinedIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => setOpenOrder(null)}
                          />
                        ) : (
                          <ChevronRightOutlinedIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => setOpenOrder(user?.UserID)}
                          />
                        )}
                      </span>
                      <span
                        className="admin-user-req-fulfilled"
                        onClick={() => handleFulfilled(user?.UserID)}
                      >
                        Fulfilled
                      </span>
                      {fFilled !== null && (
                        <div className="admin-req-fulfilled-overlay">
                          <div className="mobile-logout-overlay"></div>
                          <div className="logout-overlay-container">
                            <h3 style={{ margin: "0" }}>Fulfilled?</h3>
                            <span
                              className="want-logout"
                              style={{ textAlign: "center" }}
                            >
                              Are you sure,This package is fulfilled?
                            </span>
                            <div className="logout-btn-div">
                              <span
                                className="no-btn"
                                onClick={() => handleFulfilled(null)}
                              >
                                No
                              </span>
                              <span
                                className="yes-btn"
                                onClick={() => wantToFulfilled(user?.UserID)}
                              >
                                Yes
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {openOrder === user?.UserID && (
                      <div className="admin-user-bottom-products">
                        <div className="main-carts-products-div-container">
                          {user?.Preorders && user?.Preorders.length > 0 && (
                            <>
                              <div className="main-carts-products-details-align-key">
                                <span className="product-name-img">
                                  Product
                                </span>
                                <span className="product-carts-quantity">
                                  Qty
                                </span>
                                <span className="product-carts-price-per-unit">
                                  Price per unit
                                </span>
                                <span className="product-carts-price">
                                  {" "}
                                  Total Price <b>(Incl. GST)</b>
                                </span>
                              </div>
                              <div className="main-carts-products-contain-div">
                                {user?.Preorders?.map((data, index) => (
                                  <div
                                    className="main-carts-product-div-each-container"
                                    key={index}
                                  >
                                    <div className="main-carts-product-div-name-img">
                                      <div className="main-carts-product-img">
                                        <LazyLoadImage
                                          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                                            data?.image && data?.image
                                          }`}
                                          alt=""
                                          effect="blur"
                                          wrapperProps={{
                                            // If you need to, you can tweak the effect transition using the wrapper style.
                                            style: {
                                              transitionDelay: "1s",
                                            },
                                          }}
                                        />
                                      </div>
                                      <div className="main-carts-product-name-desc">
                                        <div className="main-carts-product-name-desc-only">
                                          {data && data?.manufacture_images ? (
                                            <LazyLoadImage
                                              className="main-carts-product-brand-img"
                                              src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                                                data && data?.manufacture_images
                                              }`}
                                              alt=""
                                              effect="blur"
                                              wrapperProps={{
                                                // If you need to, you can tweak the effect transition using the wrapper style.
                                                style: {
                                                  transitionDelay: "1s",
                                                },
                                              }}
                                            />
                                          ) : (
                                            <h3
                                              className="main-parts-product-name"
                                              style={{
                                                color: "var(--color-peach)",
                                                fontStyle: "italic",
                                              }}
                                            >
                                              {data?.manufacture_name &&
                                                data?.manufacture_name}
                                            </h3>
                                          )}
                                          <span className="main-parts-product-desc">
                                            {(data?.prod_name).slice(0, 65)}
                                          </span>
                                          <div className="main-cart-product-properties-main-container">
                                            {data?.size && (
                                              <div className="main-cart-product-size-container">
                                                <span className="main-cart-product-size">
                                                  {data?.size &&
                                                    (data?.size).slice(0, 10)}
                                                </span>
                                              </div>
                                            )}
                                            {data?.colour && (
                                              <div className="main-cart-product-size-container">
                                                <span className="main-cart-product-size">
                                                  {data?.colour &&
                                                    (data?.colour).slice(0, 15)}
                                                </span>
                                              </div>
                                            )}
                                            {data?.variant && (
                                              <div className="main-cart-product-size-container">
                                                <span className="main-cart-product-size">
                                                  {data?.variant &&
                                                    (data?.variant).slice(
                                                      0,
                                                      15
                                                    )}
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="main-carts-product-quantity-div">
                                      <span>{data?.quantity}</span>
                                    </div>
                                    <div className="main-carts-product-pricing-container">
                                      <div className="main-carts-product-ndp-offer-price">
                                        {data?.NDP_price && (
                                          <span
                                            className="main-carts-product-npd-price-container"
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            Dealer Price -{" "}
                                            <b
                                              style={{
                                                textDecoration:
                                                  data?.Offer_price !== null &&
                                                  "line-through",
                                                fontSize:
                                                  data?.Offer_price !== null &&
                                                  "10px",
                                              }}
                                            >
                                              {" "}
                                              ₹
                                              {formatWithCommasWithDecimal(
                                                calculateDealerPrice(
                                                  parseInt(data?.MRP_price),
                                                  parseInt(data?.NDP_price),
                                                  data?.GST
                                                )
                                              )}
                                            </b>
                                          </span>
                                        )}
                                        {data?.Offer_price !== null && (
                                          <span className="main-carts-product-offer-price-span">
                                            ₹
                                            {formatWithCommasWithDecimal(
                                              calculateOfferPrice(
                                                parseInt(data?.MRP_price),
                                                parseInt(data?.Offer_price),
                                                data?.GST
                                              )
                                            )}
                                          </span>
                                        )}{" "}
                                      </div>
                                      {data?.MRP_price && (
                                        <span className="main-carts-product-mrp-price-span">
                                          Product MRP - ₹
                                          {formatWithCommasWithDecimal(
                                            parseInt(data?.MRP_price)
                                          )}
                                        </span>
                                      )}
                                    </div>
                                    <div className="main-carts-product-price-div">
                                      <div className="main-carts-product-price-main-container">
                                        ₹
                                        {formatWithCommasWithDecimal(
                                          (data?.Offer_price
                                            ? calculateOfferPrice(
                                                parseInt(data?.MRP_price),
                                                parseInt(data?.Offer_price),
                                                data?.GST
                                              )
                                            : calculateDealerPrice(
                                                parseInt(data?.MRP_price),
                                                parseInt(data?.NDP_price),
                                                data?.GST
                                              )) * data?.quantity
                                        )}
                                      </div>
                                      {calculateDiscountPercentage(
                                        data?.MRP_price,
                                        data?.NDP_price
                                      ) > 0 && (
                                        <div className="main-carts-product-dealer-margin-span">
                                          <LocalOfferOutlinedIcon />{" "}
                                          <span
                                            style={{
                                              textDecoration:
                                                data?.Offer_price !== null &&
                                                "line-through",
                                              fontSize:
                                                data?.Offer_price !== null &&
                                                "10px",
                                              margin: "0 0.2vw",
                                            }}
                                          >
                                            {" "}
                                            {calculateDiscountPercentage(
                                              data?.MRP_price,
                                              calculateDealerPrice(
                                                parseInt(data?.MRP_price),
                                                parseInt(data?.NDP_price),
                                                data?.GST
                                              )
                                            )}
                                            %
                                          </span>
                                          {data?.Offer_price !== null && (
                                            <span className="main-carts-product-dealer-margin-offer-ribbon">
                                              {calculateDiscountPercentage(
                                                data?.MRP_price,
                                                calculateOfferPrice(
                                                  parseInt(data?.MRP_price),
                                                  parseInt(data?.Offer_price),
                                                  data?.GST
                                                )
                                              )}
                                              %
                                            </span>
                                          )}{" "}
                                          Dealer Margin
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <NoCartItem text={"Pre-Book"} />
        )}
      </div>
    </div>
  );
}

export default AdminPreOrder;
