import { API_BASE_URL } from "../../config";
export function fetchAllReviews(prod_ID) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}review/${prod_ID}`
    );
    const data = await response.json();
      // console.log("from API",data)
    resolve({ data });
  });
}
export function addReview(reviewData) {
  return new Promise(async (resolve, reject) => {
    try {
      // console.log(reviewData);
      const response = await fetch(
        `${API_BASE_URL}review`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any additional headers if needed
          },
          body: JSON.stringify(reviewData),
        }
      );

      if (!response.ok) {
        // Handle non-successful responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const newData = data.reviews;
      // console.log("from API",newData)
      resolve(newData);
    } catch (error) {
      reject(error);
    }
  });
}
export function removeReview(reviewData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}review`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any additional headers if needed
          },
          body: JSON.stringify(reviewData),
        }
      );

      if (!response.ok) {
        // Handle non-successful responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const newData = data.reviews;
      // console.log("from API",newData)
      resolve(newData);
    } catch (error) {
      reject(error);
    }
  });
}
export function likeOrDislikeReview(likeOrDislikeData) {
  const {prod_ID,reviewID,userID} = likeOrDislikeData;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}review_like/${prod_ID}/${reviewID}/${userID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        // Handle non-successful responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const newData = data.reviews;
      // console.log("from API",newData)
      resolve(newData);
    } catch (error) {
      reject(error);
    }
  });
}
