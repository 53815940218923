import React from "react";
import "./MobilePreOrder.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  fetchThroughBrandAsync,
  setSubCategories,
} from "../../features/listingPage/listingPageSlice";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";
import esiBanner from "../../icons/badges/esi-banner-mobile.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function MobilePreOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleFetchThroughBrands = (brand) => {
    dispatch(fetchThroughBrandAsync(brand)).then(() => {
      dispatch(setSubCategories([]));
      dispatch(setSelectedSubcategory(null));
      dispatch(
        setBreadcrumb({
          categoryID: null,
          categoryName: brand,
          subCategoryID: null,
          subCategoryName: "",
          productName: "",
          ProductID: null,
        })
      );
      navigate(`/listing-page/${brand}`);
    });
  };
  return (
    <div className="mobile-preorder-container">
      <LazyLoadImage
        src={esiBanner}
        alt=""
        className="mobile-preorder-banner"
        onClick={() => handleFetchThroughBrands("esi")}
        effect="blur"
        wrapperProps={{
          // If you need to, you can tweak the effect transition using the wrapper style.
          style: { transitionDelay: "1s" },
        }}
      />
    </div>
  );
}

export default MobilePreOrder;
