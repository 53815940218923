import React, { useState } from "react";
import Carts_Breadcrum from "../components/Carts/Carts_Breadcrum";
import CartsProduct from "../components/Carts/CartsProduct";
import LikeProduct from "../components/Wishlist/LikeProduct";
import Footer from "../components/HomePage/Footer";
import Header from "../components/HomePage/Header";
import styled from "styled-components";
import ScrollPromices from "../components/HomePage/ScrollPromices";
import { useMediaQuery } from "react-responsive";
import MobileScrollPromises from "../mobileComponent/MobileEndpoints/MobileScrollPromises";
import MobileHeader from "../mobileComponent/MobileEndpoints/MobileHeader";
import MobileCarts from "../mobileComponent/MobileCartPage/MobileCarts";
import MobileCartBreadcrum from "../mobileComponent/MobileCartPage/MobileCartBreadcrum";
import MobileFooter from "../mobileComponent/MobileEndpoints/MobileFooter";

function Carts() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 501px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  return (
    <>
      {isDesktopOrLaptop && (
        <>
          <HeaderDiv>
            <ScrollPromices />
            <Header />
          </HeaderDiv>
          <CartsProduct />
          <Footer />
        </>
      )}
      {isTabletOrMobile && (
        <>
          {/* <MobileHeaderDiv>
            <MobileScrollPromises />
            <MobileHeader />
          </MobileHeaderDiv> */}
          <MobileCarts />
        </>
      )}
    </>
  );
}

export default Carts;

const HeaderDiv = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const MobileHeaderDiv = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;
