import React,{useEffect,useRef} from "react";
import "./SharePopUp.css";
import whatsappIcon from "../../img/whatsapp.svg";
import mailIcon from "../../img/mail.svg";
import close from "../../img/close.svg";
import phLink from "../../img/ph-link-bold.svg";
import { Link } from "react-router-dom";

function SharePopUp({onClose}) {
  const infoRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (infoRef.current && !infoRef.current.contains(event.target)) {
        // Clicked outside the component, you can close it here
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleWhatsAppShare = () => {
    const currentURL = window.location.href;
    if (navigator.share) {
      navigator
        .share({
          title: "Title",
          text: "Text to share",
          url: currentURL,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      console.log("Web Share API not supported");
    }
    onClose();
  };

  const handleMailShare = () => {
    const currentURL = window.location.href;
    const subject = encodeURIComponent("Subject");
    const body = encodeURIComponent("Body " + currentURL);
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
    onClose();
  };
  

  const handleCopyLink = () => {
    const currentURL = window.location.href;
    navigator.clipboard.writeText(currentURL).then(
      () => {
        console.log("Link copied to clipboard");
      },
      (err) => {
        console.error("Failed to copy:", err);
      }
    );
    onClose();
  };


  return (
    <div ref={infoRef} className="rectangle">
      <div className="frame">
        <div className="social">
          <Link to="https://wa.me/9429693000" target="_blank" className="nimbus-whatsapp-wrapper">
            <img
              className="img"
              alt="Nimbus whatsapp"
              src={whatsappIcon}
            />
          </Link>
        </div>
        <div className="social">
          <div className="fluent-mail-wrapper">
            <img
              className="img"
              alt="Fluent mail"
              src={mailIcon}
              onClick={handleMailShare}
            />
          </div>
        </div>
        <div className="social">
          <div className="fluent-copy-wrapper">
            <img
              className="img"
              alt="Fluent mail"
              src={phLink}
              onClick={handleCopyLink}
            />
          </div>
        </div>
        <div className="social" style={{width:"25px"}}>
            <img
              className="img"
              alt="close icon"
              src={close}
              style={{width:"15px"}}
              onClick={onClose}
            />
        </div>
      </div>
    </div>
  );
}

export default SharePopUp;
