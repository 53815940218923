import React, { useState } from "react";
import "./MobileSideBar.css";
import cyclecircleLogo from "../../img/parts/Cycle-Circle-Logo-White-1.png";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { Slide } from "react-reveal";
import userOutline from "../../img/navigation/header-user-outline.png";
import {
  selectIsOverlayVisible,
  toggleOverlay,
} from "../../store/slices/headerSlice";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectUser } from "../../features/auth/authSlice";
import { IMAGE_BASE } from "../../config";
import LogoutOverlay from "../../components/EndPages/LogoutOverlay";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import WalletIcon from "@mui/icons-material/Wallet";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Link } from "react-router-dom";

function MobileSideBar({
  setOpenBurger,
  openBurger,
  isOverlayVisible,
  toggleOverlay,
}) {
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const [openLogout, setOpenLogout] = useState(false);
  const handleNavigate = (url) => {
    navigate(`/user/${url}`);
  };
  const handleOpenLogout = () => {
    setOpenLogout(!openLogout);
  };

  return (
    <Slide right>
      <div className="mobile-sidebar-container">
        {/* <div className="mobile-sidebar-logo-header">
          <img
            src={cyclecircleLogo}
            alt=""
            className="mobile-website-header-logo"
          />
          <CloseSharpIcon onClick={() => setOpenBurger(false)} />
        </div> */}
        {Object.keys(userDetail).length > 0 ? (
          <div className="mobile-sidebar-user-details-container">
            <div className="mobile-sidebar-remove-icon">
              <CloseIcon onClick={() => setOpenBurger(false)} />
            </div>
            <img
              src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
                userDetail?.UserImage || ""
              }`}
              alt=""
              className="mobile-sidebar-user-img"
            />
            <div className="mobile-sidebar-user-details-main">
              <span className="mobile-sidebar-user-name">
                {userDetail && userDetail?.FirstName}{" "}
                {userDetail && userDetail?.LastName}
              </span>
              <span className="mobile-sidebar-user-email">
                {userDetail && userDetail?.Email}
              </span>
              <span className="mobile-sidebar-user-email">
                {userDetail && userDetail?.PhoneNumber}
              </span>
              {/* {Object.keys(userDetail).length > 0 && (
                <span className="mobile-user-logout" onClick={handleOpenLogout}>
                  Logout
                </span>
              )} */}
              {openLogout && (
                <LogoutOverlay handleOpenLogout={handleOpenLogout} />
              )}
            </div>
          </div>
        ) : (
          <div className="mobile-sidebar-remove-not-login">
            <div className="mobile-sidebar-remove-icon">
              <CloseIcon onClick={() => setOpenBurger(false)} />
            </div>
            <div className="mobile-sidebar-login-container">
              <img
                src={userOutline}
                alt=""
                className="mobile-sidebar-login-img"
              />
              <span
                className="mobile-sidebar-login-span"
                onClick={() => {
                  toggleOverlay();
                  setOpenBurger(false);
                }}
              >
                Login or Signup
              </span>
            </div>
          </div>
        )}
        <div className="mobile-sidebar-main-content-container">
          <span className="mobile-sidebar-main-content-heading">
            My Account
          </span>
          <div className="mobile-sidebar-wishlist-container">
            <div
              className="mobile-sidebar-wishlist-left-container"
              onClick={() => {
                Object.keys(userDetail).length
                  ? navigate("/user/profile")
                  : toggleOverlay();
                setOpenBurger(false);
              }}
            >
              <AccountCircleOutlinedIcon id="mobile-sidebar-wishlist-left-icon" />
              <span className="mobile-sidebar-wishlist-name">My Profile</span>
            </div>
          </div>
          <div className="mobile-sidebar-wishlist-container">
            <div
              className="mobile-sidebar-wishlist-left-container"
              onClick={() => {
                Object.keys(userDetail).length
                  ? handleNavigate("preorders")
                  : toggleOverlay();
                setOpenBurger(false);
              }}
            >
              <WorkHistoryOutlinedIcon id="mobile-sidebar-wishlist-left-icon" />
              <span className="mobile-sidebar-wishlist-name">My Pre-Orders</span>
            </div>
          </div>
          <div className="mobile-sidebar-wishlist-container">
            <div
              className="mobile-sidebar-wishlist-left-container"
              onClick={() => {
                Object.keys(userDetail).length
                  ? handleNavigate("orders")
                  : toggleOverlay();
                setOpenBurger(false);
              }}
            >
              <ShoppingBagOutlinedIcon id="mobile-sidebar-wishlist-left-icon" />
              <span className="mobile-sidebar-wishlist-name">My Orders</span>
            </div>
          </div>
          <div
            className="mobile-sidebar-wishlist-container"
            onClick={() => {
              Object.keys(userDetail).length
                ? navigate("/wishlist")
                : toggleOverlay();
              setOpenBurger(false);
            }}
          >
            <div className="mobile-sidebar-wishlist-left-container">
              <BookmarkBorderOutlinedIcon id="mobile-sidebar-wishlist-left-icon" />
              <span className="mobile-sidebar-wishlist-name">Wishlist</span>
            </div>
          </div>
          <div
            className="mobile-sidebar-wishlist-container"
            onClick={() => {
              Object.keys(userDetail).length
                ? navigate("/user/wallet")
                : toggleOverlay();
              setOpenBurger(false);
            }}
          >
            <div className="mobile-sidebar-wishlist-left-container">
              <WalletIcon id="mobile-sidebar-wishlist-left-icon" />
              <span className="mobile-sidebar-wishlist-name">Wallet</span>
            </div>
          </div>
          <hr className="mobile-sidebar-wishlist-seperator" />
          <span className="mobile-sidebar-main-content-heading">Others</span>
          <Link
            to="https://wa.me/9429693000"
            target="_blank"
            className="mobile-sidebar-wishlist-container"
          >
            <div className="mobile-sidebar-wishlist-left-container">
              <WhatsAppIcon id="mobile-sidebar-wishlist-left-icon" />
              <span className="mobile-sidebar-wishlist-name">Chat Us</span>
            </div>
          </Link>
          <div className="mobile-sidebar-wishlist-container" onClick={()=>navigate("/privacypolicy")}>
            <div className="mobile-sidebar-wishlist-left-container">
              <PrivacyTipOutlinedIcon id="mobile-sidebar-wishlist-left-icon" />
              <span className="mobile-sidebar-wishlist-name">
                Privacy Policy
              </span>
            </div>
          </div>
          {Object.keys(userDetail).length && (
            <div
              className="mobile-sidebar-logout-container"
              onClick={handleOpenLogout}
            >
              <div className="mobile-sidebar-wishlist-logout-container">
                <LogoutOutlinedIcon id="mobile-sidebar-logout-left-icon" />
                <span className="mobile-sidebar-logout-name">Log Out</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </Slide>
  );
}

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});
const mapDispatchToProps = {
  toggleOverlay,
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileSideBar);
