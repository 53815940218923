import React, { useState } from 'react';
import "./MobileProductTabs.css"
import vector_black from "../../icons/Vector_black.svg";
import vector_white from "../../icons/Vector_white.svg";
import data_black from "../../icons/data_black.svg";
import data_white from "../../icons/data_white.svg";
import review_black from "../../icons/review_black.svg";
import review_white from "../../icons/review_white.svg";
import play_black from "../../icons/play_black.svg";
import insight_white from "../../icons/Insights-outline.svg";
import insight_black from "../../icons/Insights-filled.svg";
import play_white from "../../icons/play_white.svg";
import informationMaganta from "../../icons/information-maganta.png";
import informationBlack from "../../icons/information-black.png";
import MobileInsight from './ProductAllInfo/MobileInsight';
import MobileAboutBrands from './MobileAboutBrands';
import MobileReview from './ProductAllInfo/MobileReview';
import MobileTechData from './ProductAllInfo/MobileTechData';
import MobileprodDesc from './ProductAllInfo/MobileprodDesc';
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import KeyboardArrowUpSharpIcon from "@mui/icons-material/KeyboardArrowUpSharp";

function MobileProductTabs({productDesc}) {
  const [infoTitle, setInfoTitle] = useState("");
  return (
    <div className="mobile-product-all-info">
      <div className="mobile-product-description-tab-container">
        <div
          className="mobile-product-description-header-div"
          onClick={() =>
            "Compatibility" !== infoTitle
              ? setInfoTitle("Compatibility")
              : setInfoTitle("")
          }
        >
          <div className="mobile-product-description-heading">
            <img src={vector_black} alt="" />
            Product Description
          </div>
          {"Compatibility" !== infoTitle && <KeyboardArrowDownSharpIcon />}
          {"Compatibility" === infoTitle && <KeyboardArrowUpSharpIcon />}
        </div>
        <div className="mobile-product-description-details-container">
          {infoTitle === "Compatibility" && (
            <MobileprodDesc productDesc={productDesc} />
          )}
        </div>
      </div>
      <div className="mobile-product-insight-tab-container">
        <div
          className="mobile-product-insight-header-div"
          onClick={() =>
            "insight" !== infoTitle
              ? setInfoTitle("insight")
              : setInfoTitle("")
          }
        >
          <div className="mobile-product-insight-heading">
            <img src={insight_white} alt="" />
            Insights
          </div>
          {"insight" !== infoTitle && <KeyboardArrowDownSharpIcon />}
          {"insight" === infoTitle && <KeyboardArrowUpSharpIcon />}
        </div>
        <div className="mobile-product-insight-details-container">
          {infoTitle === "insight" && (
            <MobileInsight productDesc={productDesc} />
          )}
        </div>
      </div>
      <div className="mobile-technical-data-tab-container">
        <div
          className="mobile-technical-data-header-div"
          onClick={() =>
            "Technical Data" !== infoTitle
              ? setInfoTitle("Technical Data")
              : setInfoTitle("")
          }
        >
          <div className="mobile-technical-data-heading">
            <img src={data_black} alt="" />
            Technical Data
          </div>
          {"Technical Data" !== infoTitle && <KeyboardArrowDownSharpIcon />}
          {"Technical Data" === infoTitle && <KeyboardArrowUpSharpIcon />}
        </div>
        <div className="mobile-technical-data-details-container">
          {infoTitle === "Technical Data" && (
            <MobileTechData productDesc={productDesc} />
          )}
        </div>
      </div>
      <div className="mobile-review-tab-container">
        <div
          className="mobile-review-header-div"
          onClick={() =>
            "Reviews" !== infoTitle
              ? setInfoTitle("Reviews")
              : setInfoTitle("")
          }
        >
          <div className="mobile-review-heading">
            <img src={review_black} alt="" />
            Reviews
          </div>
          {"Reviews" !== infoTitle && <KeyboardArrowDownSharpIcon />}
          {"Reviews" === infoTitle && <KeyboardArrowUpSharpIcon />}
        </div>
        <div className="mobile-review-details-container">
          {infoTitle === "Reviews" && <MobileReview />}
        </div>
      </div>
      <div className="mobile-about-brand-tab-container">
        <div
          className="mobile-about-brand-header-div"
          onClick={() =>
            "About Brands" !== infoTitle
              ? setInfoTitle("About Brands")
              : setInfoTitle("")
          }
        >
          <div className="mobile-about-brand-heading">
            <img src={informationBlack} alt="" />
            About Brands
          </div>
          {"About Brands" !== infoTitle && <KeyboardArrowDownSharpIcon />}
          {"About Brands" === infoTitle && <KeyboardArrowUpSharpIcon />}
        </div>
        <div className="mobile-about-brand-details-container">
          {infoTitle === "About Brands" && (
            <MobileAboutBrands/>
          )}
        </div>
      </div>
    </div>
  )
}

export default MobileProductTabs