import React from "react";
import MyWishlist from "../components/Wishlist/MyWishlist";
import LikeProduct from "../components/Wishlist/LikeProduct";
import Header from "../components/HomePage/Header";
import Footer from "../components/HomePage/Footer";
import styled from "styled-components";
import ScrollPromices from "../components/HomePage/ScrollPromices";
import BottomPromises from "../components/HomePage/BottomPromises";
import PopularSearch from "../components/HomePage/PopularSearch";
import { useMediaQuery } from "react-responsive";
import MobileScrollPromises from "../mobileComponent/MobileEndpoints/MobileScrollPromises";
import MobileHeader from "../mobileComponent/MobileEndpoints/MobileHeader";
import MobileFooter from "../mobileComponent/MobileEndpoints/MobileFooter";
import MobileWishlist from "../mobileComponent/MobileWishlist/MobileWishlist";
import MobileBottomNavigation from "../mobileComponent/MobileEndpoints/MobileBottomNavigation";

function Wishlist() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 501px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  return (
    <div>
      {isDesktopOrLaptop && (
        <>
      <HeaderDiv>
        <ScrollPromices />
        <Header />
      </HeaderDiv>
      <MyWishlist />
      {/* <LikeProduct/> */}
      <BottomPromises />
      <PopularSearch />
      <Footer />
      </>
      )}
      {isTabletOrMobile && (
        <>
        <MobileHeaderDiv>
          {/* <MobileScrollPromises/> */}
          <MobileHeader/>
        </MobileHeaderDiv>
        <MobileBottomNavigation/>
        <MobileWishlist/>
        <MobileFooter/>
        </>
      )}
    </div>
  );
}

export default Wishlist;

const HeaderDiv = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const MobileHeaderDiv = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;