import React, { useState, useRef } from "react";
import "./MobileEditBasicProfileDetail.css";
import CropEasy from "../../components/Authentication/SignUp/CropEasy";
import { useDispatch, useSelector } from "react-redux";
import inspectUploadedImage from "../../img/gg_search.svg";
import deleteUploadedImage from "../../img/mdi-light_delete.svg";
import upload_logo from "../../img/upload_layer.svg";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fetchUserAsync,
  selectUser,
  updateProfileDetailAsync,
  updateProfileImageAsync,
} from "../../features/auth/authSlice";
import { IMAGE_BASE } from "../../config";

function MobileEditBasicProfileDetail({ handleEditBasicProfile }) {
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const [data, setData] = useState(0);
  const [inspect, setInspect] = useState(false);
  const inputRef = useRef(null);
  const [finalData, setFinalData] = useState({
    FirstName: (userDetail && userDetail?.FirstName) || null,
    LastName: (userDetail && userDetail?.LastName) || null,
    Email: (userDetail && userDetail?.Email) || null,
    PhoneNumber: (userDetail && userDetail?.PhoneNumber) || null,
    DOB: (userDetail && userDetail?.DOB) || null,
    Gender: (userDetail && userDetail?.Gender) || null,
    Password: (userDetail && userDetail?.Password) || null,
    USER_IMAGE: (userDetail && userDetail?.UserImage) || null,
    dealership_name: (userDetail && userDetail?.dealership_name) || null,
    address_line_1: (userDetail && userDetail?.address_line_1) || null,
    address_line_2: (userDetail && userDetail?.address_line_2) || null,
    pin_code: (userDetail && userDetail?.pin_code) || null,
    location: (userDetail && userDetail?.location) || null,
    district: (userDetail && userDetail?.district) || null,
    state_id: (userDetail && userDetail?.state_id) || null,
    GST: (userDetail && userDetail?.GST) || null,
    aadhar: (userDetail && userDetail?.aadhar) || null,
    pan_card: (userDetail && userDetail?.pan_card) || null,
    PAN_IMAGE: (userDetail && userDetail?.pan_card_img) || null,
    AADHAR_FRONT: (userDetail && userDetail?.aadhar_front) || null,
    AADHAR_BACK: (userDetail && userDetail?.aadhar_back) || null,
  });
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [updateFData, setUpdateFData] = useState({
    FirstName: (userDetail && userDetail?.FirstName) || null,
    LastName: (userDetail && userDetail?.LastName) || null,
    DOB: (userDetail && userDetail?.DOB) || null,
    Gender: (userDetail && userDetail?.Gender) || null,
  });
  const [updateImg, setUpdateImg] = useState(null);
  const [originalSelectedImage, setOriginalSelectedImage] = useState(
    userDetail &&
      `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
        userDetail && userDetail?.UserImage
      }`
  );
  const [selectedImage, setSelectedImage] = useState(
    userDetail &&
      `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
        userDetail && userDetail?.UserImage
      }`
  );
  const handleZoomChange = (value) => {
    setData(value);
  };
  const handleInspect = () => {
    setInspect(!inspect);
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setOriginalSelectedImage(null);
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      updateFinalData({ [e.target.name]: selectedFile });
      setUpdateImg(selectedFile);
      setSelectedImage(imageUrl);
      setOriginalSelectedImage(imageUrl);
    }

    // console.log("Selected File:", selectedFile);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the input is for 'Gender' and value is either 'Male' or 'Female'
    // if (name === "Gender" && (value === "Male" || value === "Female")) {
    //   // Randomly select an image from userMaleLogo or userFemaleLogo based on the selected gender
    //   const randomIndex = Math.floor(Math.random() * 10); // Generates a random index from 0 to 9
    //   const randomImage =
    //     value === "Male"
    //       ? randomMaleLogo[randomIndex]
    //       : randomFemaleLogo[randomIndex];

    //   const file = new File([randomImage], "randomImage.png", {
    //     type: "image/png",
    //   });
    //   console.log(file);

    //   // Update the final data with the selected image
    //   updateFinalData({ USER_IMAGE: file });

    //   // Create object URL for the randomImage file
    //   const imageUrl = URL.createObjectURL(file);
    //   setSelectedImage(imageUrl);
    //   console.log(selectedImage);
    //   setOriginalSelectedImage(imageUrl);
    // }

    // Update final data with the input value
    updateFinalData({ [name]: value });
  };
  const handleEnterKey = (e, nextInputId) => {
    if (e.key === "Enter") {
      const nextInput = document.getElementById(nextInputId);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };
  const updateFinalData = (dataToUpdate) => {
    setFinalData((prevData) => ({
      ...prevData,
      ...dataToUpdate,
    }));
  };

  const handleUpdateDetail = () => {
    handleOpen();
    const changedFields = {};
    for (const key in updateFData) {
      if (updateFData[key] !== finalData[key]) {
        changedFields[key] = finalData[key];
      }
    }
    // console.log(updateImg);
    // console.log(finalData.USER_IMAGE);

    if (Object.keys(changedFields).length === 0 && updateImg === null) {
      handleEditBasicProfile();
      return; // Exit early
    }

    // Dispatch the modifyAddressAsync action with the changedFields
    const updateData = {
      data: {
        // UserID: userDetail.UserID,
        ...changedFields,
      },
      userID: userDetail.UserID,
    };
    // console.log(updateImg);
    // console.log(finalData.USER_IMAGE);
    if (updateImg !== null) {
      let imageData = {
        userID: userDetail.UserID,
        files: updateImg,
      };
      if (Object.keys(updateFData).length > 0) {
        dispatch(updateProfileImageAsync(imageData))
          .then(() => {
            dispatch(updateProfileDetailAsync(updateData)).then(() => {
              dispatch(fetchUserAsync(userDetail?.UserID));
              handleEditBasicProfile();
              handleClose();
              Swal.fire({
                icon: "success",
                title: "Success!",
                text: "Profile modified successfully",
              });
            });
          })
          .catch((error) => {
            handleClose();
            // Handle the error if the address modification fails
            console.error("Error modifying address:", error);
            let errorMessage =
              "Failed to modify address. Please try again later.";

            if (error.response && error.response.status === 404) {
              errorMessage =
                "Address not found. Please refresh the page and try again.";
            }

            Swal.fire({
              icon: "error",
              title: "Error!",
              text: errorMessage,
            });
          });
      } else {
        dispatch(updateProfileImageAsync(imageData))
          .then(() => {
            dispatch(fetchUserAsync(userDetail?.UserID));
            handleEditBasicProfile();
            handleClose();
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Profile modified successfully",
            });
          })
          .catch((error) => {
            handleClose();
            // Handle the error if the address modification fails
            console.error("Error modifying address:", error);
            let errorMessage =
              "Failed to modify address. Please try again later.";

            if (error.response && error.response.status === 404) {
              errorMessage =
                "Address not found. Please refresh the page and try again.";
            }

            Swal.fire({
              icon: "error",
              title: "Error!",
              text: errorMessage,
            });
          });
      }
    } else {
      dispatch(updateProfileDetailAsync(updateData))
        .then(() => {
          // Address modified successfully, show success message
          dispatch(fetchUserAsync(userDetail?.UserID));
          handleEditBasicProfile();
          handleClose();
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Profile modified successfully",
          });
        })
        .catch((error) => {
          // Handle the error if the address modification fails
          handleClose();
          console.error("Error modifying address:", error);
          let errorMessage =
            "Failed to modify address. Please try again later.";

          if (error.response && error.response.status === 404) {
            errorMessage =
              "Address not found. Please refresh the page and try again.";
          }

          Swal.fire({
            icon: "error",
            title: "Error!",
            text: errorMessage,
          });
        });
    }
  };
  return (
    <div className="my-profile-edit-profile-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <span className="my-profile-edit-heading">My Information</span>
      <div className="my-profile-edit-profile-left-container">
        {inspect && (
          <div className="basic-details-overlay">
            <div className="basic-details-crop-easy-main-container">
              <CropEasy
                photoURL={originalSelectedImage}
                setPhotoURL={setSelectedImage}
                zoom={data}
                onZoomChange={handleZoomChange}
                setOpenCrop={setInspect}
              />
            </div>
          </div>
        )}
        <div className="my-profile-basic-detail-photo-upload-container">
          {selectedImage ? (
            <div className="my-profile-photo-upload-main">
              <img
                src={selectedImage}
                alt="selected"
                className="my-profile-uploaded-image-fix"
              />
              <div className="my-profile-uploaded-image-inspect-remove-container">
                <div className="my-profile-uploaded-image-inspect-div-container">
                  <img
                    src={inspectUploadedImage}
                    alt=""
                    onClick={handleInspect}
                  />
                  <span onClick={handleInspect}>Inspect</span>
                </div>
                <div className="my-profile-uploaded-image-remove-div-container">
                  <img
                    src={deleteUploadedImage}
                    alt=""
                    onClick={handleRemoveImage}
                  />
                  <span
                    onClick={() => {
                      handleRemoveImage();
                      setInspect(false);
                    }}
                  >
                    Remove
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="my-profile-basic-detail-photo-upload-bordered-container"
              style={{ cursor: "pointer" }}
              onClick={handleImageClick}
            >
              <img src={upload_logo} alt="upload_logo" />
              <label>
                <p>Upload your photo</p>
              </label>
              <span>Drag & Drop</span>
              <span className="upload-browse">Browse</span>
            </div>
          )}
          <input
            type="file"
            id="userImage"
            name="USER_IMAGE"
            ref={inputRef}
            style={{ display: "none" }}
            onChange={(e) => {
              handleImageUpload(e);
            }}
            required
          />
        </div>
      </div>
      <div className="my-profile-edit-profile-right-container">
        <div className="my-profile-basic-detail-new-detail-form-1">
          <fieldset className="input-fieldset">
            <legend className="input-legend">
              First name <b>*</b>
            </legend>
            <input
              className="input-type-write"
              type="text"
              name="FirstName"
              value={finalData.FirstName || ""}
              onKeyDown={(e) => handleEnterKey(e, "secondNameInput")}
              onChange={handleInputChange}
              required
            />
          </fieldset>
          <fieldset className="input-fieldset">
            <legend className="input-legend">
              Last name <b>*</b>
            </legend>
            <input
              className="input-type-write"
              type="text"
              name="LastName"
              value={finalData.LastName || ""}
              id="secondNameInput"
              onKeyDown={(e) => handleEnterKey(e, "emailInput")}
              onChange={handleInputChange}
              required
            />
          </fieldset>
          <fieldset className=" input-fieldset my-profile-phone-number-edit">
            <legend className="input-legend">
              Phone number <b style={{ color: "rgba(0,0,0,0.3)" }}>*</b>
            </legend>
            <input
              className="input-type-write"
              type="phone"
              name="PhoneNumber"
              value={finalData.PhoneNumber || ""}
              id="phoneInput"
              onKeyDown={(e) => handleEnterKey(e, "genderInput")}
              readOnly
            />
          </fieldset>
          <fieldset className=" input-fieldset my-profile-phone-number-edit">
            <legend className="input-legend">
              Email id <b style={{ color: "rgba(0,0,0,0.3)" }}>*</b>
            </legend>
            <input
              className="input-type-write"
              type="email"
              name="Email"
              value={finalData.Email || ""}
              id="emailInput"
              onKeyDown={(e) => handleEnterKey(e, "phoneInput")}
              readOnly
            />
          </fieldset>
          <fieldset className="input-fieldset">
            <legend className="input-legend">
              Date of birth <b>*</b>
            </legend>
            <input
              className="input-type-write"
              type="date"
              id="dobInput"
              value={finalData.DOB || ""}
              name="DOB"
              max={new Date().toISOString().split("T")[0]}
              onKeyDown={(e) => handleEnterKey(e, "passwordInput")}
              onChange={handleInputChange}
              required
            />
          </fieldset>
          <div className="my-profile-basic-detail-form-2-gender">
            <fieldset>
              <legend style={{ marginLeft: "-10px" }}>
                Gender <b>*</b>
              </legend>
              <input
                type="radio"
                name="Gender"
                id="genderInput"
                value="Male"
                checked={finalData.Gender === "Male"}
                onKeyDown={(e) => handleEnterKey(e, "dobInput")}
                onChange={handleInputChange}
              />
              <span>Male</span>
              <input
                type="radio"
                name="Gender"
                id="genderInput"
                value="Female"
                checked={finalData.Gender === "Female"}
                onKeyDown={(e) => handleEnterKey(e, "passwordInput")}
                onChange={handleInputChange}
              />
              <span>Female</span>
            </fieldset>
          </div>
          <div className="my-profile-basic-detail-cancel-and-save-changes-btn">
            <span
              className="my-profile-basic-detail-save-changes-btn"
              onClick={handleUpdateDetail}
            >
              Save Changes
            </span>
            <span
              className="my-profile-basic-detail-cancel-btn"
              onClick={handleEditBasicProfile}
            >
              Cancel
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileEditBasicProfileDetail;
