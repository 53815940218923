import React from "react";
import "./OurBeliefSystem.css";
import cycleBanner from "../../img/banners/our-belief-system.png";
import styled from "styled-components";
import ScrollPromices from "../HomePage/ScrollPromices";
import Header from "../HomePage/Header";
import BottomPromises from "../HomePage/BottomPromises";
import PopularSearch from "../HomePage/PopularSearch";
import Footer from "../HomePage/Footer";
import { useMediaQuery } from "react-responsive";
import MobileHeader from "../../mobileComponent/MobileEndpoints/MobileHeader";
import MobilePopularSearch from "../../mobileComponent/MobileEndpoints/MobilePopularSearch";
import MobileFooter from "../../mobileComponent/MobileEndpoints/MobileFooter";

const OurBeliefSystem = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 501px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  return (
    <div>
      {isDesktopOrLaptop && (
        <HeaderDiv>
          <ScrollPromices />
          <Header />
        </HeaderDiv>
      )}
      {isTabletOrMobile && (
        <MobileHeaderDiv>
          <MobileHeader />
        </MobileHeaderDiv>
      )}
      <div className="banner">
        <h1>Our Belief System</h1>
        <img src={cycleBanner} alt="Cycling Banner" />
      </div>
      <div className="content">
        <p className="content-p">
          CycleCircle is a vibrant community of cycling enthusiasts dedicated to
          transforming the cycling landscape in India. From our origins in the
          scenic hills of Shimla, we've seen firsthand the transformative power
          of cycling.
        </p>
        <p className="content-p">
          We're driven by a straightforward mission: to boost cycling's
          popularity in India while staying true to our core values of
          inclusivity, equality, and environmental responsibility. We cherish
          the natural world and place its preservation at the forefront of our
          efforts. Our community is a space where discrimination of any kind is
          unwelcome.
        </p>
        <p className="content-p">
          At CycleCircle, we celebrate diversity in every form. We champion
          equality across genders and social backgrounds, and we view cycling as
          a powerful force for unity. We are highly committed to sustainability,
          aiming to minimize our environmental impact and encourage eco-friendly
          living through our products and practices.
        </p>
        <p className="content-p">
          Our emphasis is on community and cooperation. We believe in the power
          of collective action to drive meaningful change. Together, we ride
          towards a brighter future where cycling bridges gaps and brings people
          together from all corners of society.
        </p>
        <p className="content-p">
          Embark on this exciting journey with us toward a more inclusive,
          equitable, and sustainable India.
        </p>
        <p className="content-p1">
          Let's make a difference, one pedal revolution at a time.
        </p>
      </div>
      {isDesktopOrLaptop && (
        <>
          <BottomPromises />
          <PopularSearch />
          <Footer />
        </>
      )}
      {isTabletOrMobile && (
        <>
          <MobilePopularSearch />
          <MobileFooter />
        </>
      )}
    </div>
  );
};

export default OurBeliefSystem;

const HeaderDiv = styled.div`
  width: 100%;
  height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;

const MobileHeaderDiv = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;
