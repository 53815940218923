import React from 'react';
import "./Parallex.css";
import { Link } from 'react-router-dom';

function Parallex() {
  return (
    <div className='parallex-container'>
        <div className="cycle-adventures-tour-container">
            <span className="cycle-adventures-tours-heading">Bicycle Adventures, Tours, Maps</span>
            <span className="cycle-adventures-tours-text">Cycling,adventures and cultural immersions across Himalaya,curated by experts</span>
            <Link to="https://tours.cyclecircle.one/" target='_blank' className="cycle-adventures-tour-btn">Explore Adventures</Link>
        </div>
    </div>
  )
}

export default Parallex