import React from "react";
import Breadcumb from "../components/ProductDescription/Breadcumb";
import Detail from "../components/ProductDescription/Detail";
import Header from "../components/HomePage/Header";
import Footer from "../components/HomePage/Footer";
import SimilarProduct from "../components/ProductDescription/SimilarProduct";
import AboutBrands from "../components/ProductDescription/AboutBrands";
import styled from "styled-components";
import ScrollPromices from "../components/HomePage/ScrollPromices";
import OurBrands from "../components/HomePage/OurBrands";
import Promotions from "../components/HomePage/Promotions/Promotions";
import BottomPromises from "../components/HomePage/BottomPromises";
import PopularSearch from "../components/HomePage/PopularSearch";
import { useMediaQuery } from "react-responsive";
import MobileScrollPromises from "../mobileComponent/MobileEndpoints/MobileScrollPromises";
import MobileHeader from "../mobileComponent/MobileEndpoints/MobileHeader";
import MobileFooter from "../mobileComponent/MobileEndpoints/MobileFooter";
import MobilePopularSearch from "../mobileComponent/MobileEndpoints/MobilePopularSearch";
import MobilePromotion from "../mobileComponent/MobileHomePage/MobilePromotion/MobilePromotion";
import MobileDetails from "../mobileComponent/MobileDescriptionPage/MobileDetails";

function ProductDescription() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 501px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  return (
    <div>
      {isDesktopOrLaptop && (
        <>
          <HeaderDiv>
            <ScrollPromices />
            <Header />
          </HeaderDiv>
          <RestPart>
            <Breadcumb />
            <Detail />
            {/* <AboutBrands /> */}
            {/* <SimilarProduct /> */}
            <Promotions />
            <OurBrands />
            <BottomPromises />
            <PopularSearch />
            <Footer />
          </RestPart>
        </>
      )}
      {isTabletOrMobile && (
        <>
          <MobileHeaderDiv>
            {/* <MobileScrollPromises/> */}
            <MobileHeader />
          </MobileHeaderDiv>
          <MobileDetails />
          <MobilePromotion />
          <MobilePopularSearch />
          <MobileFooter />
        </>
      )}
    </div>
  );
}

export default ProductDescription;

const HeaderDiv = styled.div`
  width: 100%;
  height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const NavigationDiv = styled.div`
  // background:white;
  // padding-top:10px;
  width: 100%;
  height: 65px;
  @media (max-width: 500px) {
    position: sticky;
    height: 8vh;
    top: 12vh;
    z-index: 99;
    background: var(--main-background-color);
    box-shadow: 0 0px 8px 4px rgba(0, 0, 0, 0.2);
    border-radius: ${({ height }) =>
      height > 200
        ? "0 0 20px 20px"
        : "0"}; /* Conditionally apply border-radius */
  }
`;

const RestPart = styled.div`
  position: relative;
  top: 30px;
  @media (max-width: 500px) {
    position: relative;
    top: 30px;
  }
`;

const MobileHeaderDiv = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;
