import React, { useEffect, useRef, useState } from "react";
import "./SortBy.css";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { useDispatch } from "react-redux";
import {
  setSortPrice,
  setSortRating,
} from "../../../store/slices/allFilterSlice";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { Bounce, Slide } from "react-reveal";
import upIcon from "../../../icons/sortby/sort-up.png";
import downIcon from "../../../icons/sortby/sort-below.png";
import upIconWhite from "../../../icons/sortby/sort-up-white.png";
import downIconWhite from "../../../icons/sortby/sort-down-white.png";

function SortBy({
  onSelect,
  closeSortBySelect,
  previouslySortPrice,
  previouslySortRating,
}) {
  const handleSortOptionClick = (option) => {
    onSelect(option);
  };
  const dispatch = useDispatch();

  // Create a ref to the component
  const sortBySelectRef = useRef(null);

  useEffect(() => {
    // Add event listener when component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const handleClickOutside = (event) => {
    if (
      sortBySelectRef.current &&
      !sortBySelectRef.current.contains(event.target)
    ) {
      // Click occurred outside of the BrandSelect component
      closeSortBySelect();
    }
  };

  // State to track whether the mobile sortby container is open
  const [isMobileSortByOpen, setIsMobileSortByOpen] = useState(false);

  const toggleMobileSortBy = () => {
    setIsMobileSortByOpen((prevIsMobileSortByOpen) => !prevIsMobileSortByOpen);
  };

  return (
    <>
      <div ref={sortBySelectRef} className="sortby-container">
        <div
          className={`sortby-price-sorting-container ${
            !previouslySortPrice && !previouslySortRating ? "selected" : ""
          }`}
          onClick={() => {
            dispatch(setSortPrice(""));
            dispatch(setSortRating(""));
            handleSortOptionClick("relevance");
          }}
        >
          Relevance
        </div>
        <div
          className={`sortby-price-sorting-container ${
            previouslySortPrice ? "selected" : ""
          }`}
        >
          <span>Price</span>
          <div className="price-sorting">
            <div
              className={`a-z-price-sorting ${
                previouslySortPrice === "desc" ? "selected" : ""
              }`}
              onClick={() => {
                dispatch(setSortPrice("desc"));
                dispatch(setSortRating(""));
                handleSortOptionClick("a-z-price");
              }}
            >
              <span>H L</span>
              <img
                src={previouslySortPrice === "desc" ? downIconWhite : downIcon}
                alt=""
                className="sortby-main-icon"
              />
            </div>
            <div
              className={`a-z-price-sorting ${
                previouslySortPrice === "asc" ? "selected" : ""
              }`}
              onClick={() => {
                dispatch(setSortPrice("asc"));
                dispatch(setSortRating(""));
                handleSortOptionClick("z-a-price");
              }}
            >
              <span>H L</span>
              <img
                src={previouslySortPrice === "asc" ? upIconWhite : upIcon}
                alt=""
                className="sortby-main-icon"
              />
            </div>
          </div>
        </div>
        <div
          className={`sortby-price-sorting-container ${
            previouslySortRating ? "selected" : ""
          }`}
        >
          <span>Rating</span>
          <div className="price-sorting">
            <div
              className={`a-z-rating-sorting ${
                previouslySortRating === "desc" ? "selected" : ""
              }`}
              onClick={() => {
                dispatch(setSortRating("desc"));
                dispatch(setSortPrice(""));
                handleSortOptionClick("a-z-rating");
              }}
            >
              <span>H L</span>
              <img
                src={previouslySortRating === "desc" ? downIconWhite : downIcon}
                alt=""
                className="sortby-main-icon"
              />
            </div>
            <div
              className={`a-z-rating-sorting ${
                previouslySortRating === "asc" ? "selected" : ""
              }`}
              onClick={() => {
                dispatch(setSortRating("asc"));
                dispatch(setSortPrice(""));
                handleSortOptionClick("z-a-rating");
              }}
            >
              <span>H L</span>
              <img
                src={previouslySortRating === "asc" ? upIconWhite : upIcon}
                alt=""
                className="sortby-main-icon"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="overlay-sortby"></div>
      {/* <Slide bottom> */}
      <div className="mobile-sortby-container">
        <div className="mobile-sortby-header-div">
          <span>Sort By</span>
          <CloseSharpIcon />
        </div>
        <div className="mobile-sortby-main-container">
          <span
            className={`mobile-sortby-relevance ${
              !previouslySortPrice && !previouslySortRating
                ? "mobile-selected"
                : ""
            }`}
            onClick={() => {
              dispatch(setSortPrice(""));
              dispatch(setSortRating(""));
              handleSortOptionClick("relevance");
            }}
          >
            Relevance
          </span>
          <div
            className={`mobile-sortby-price-sort-container ${
              previouslySortPrice ? "mobile-selected" : ""
            }`}
          >
            <span className="mobile-sortby-price-heading">Price</span>
            <div
              className={`mobile-sortby-price-sort-up-down-div ${
                previouslySortPrice ? "mobile-white-selected" : ""
              }`}
            >
              <NorthIcon
                className={`${
                  previouslySortPrice === "desc" ? "mobile-selected" : ""
                }`}
                onClick={() => {
                  dispatch(setSortPrice("desc"));
                  dispatch(setSortRating(""));
                  handleSortOptionClick("a-z-price");
                }}
              />
              <SouthIcon
                className={`${
                  previouslySortPrice === "asc" ? "mobile-selected" : ""
                }`}
                onClick={() => {
                  dispatch(setSortPrice("asc"));
                  dispatch(setSortRating(""));
                  handleSortOptionClick("z-a-price");
                }}
              />
            </div>
          </div>
          <div
            className={`mobile-sortby-rating-sort-container ${
              previouslySortRating ? "mobile-selected" : ""
            }`}
          >
            <span className="mobile-sortby-rating-heading">Rating</span>
            <div
              className={`mobile-sortby-rating-sort-up-down-div ${
                previouslySortRating ? "mobile-white-selected" : ""
              }`}
            >
              <NorthIcon
                className={`${
                  previouslySortRating === "desc" ? "mobile-selected" : ""
                }`}
                onClick={() => {
                  dispatch(setSortRating("desc"));
                  dispatch(setSortPrice(""));
                  handleSortOptionClick("a-z-rating");
                }}
              />
              <SouthIcon
                className={`${
                  previouslySortRating === "asc" ? "mobile-selected" : ""
                }`}
                onClick={() => {
                  dispatch(setSortRating("asc"));
                  dispatch(setSortPrice(""));
                  handleSortOptionClick("z-a-rating");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* </Slide> */}
    </>
  );
}

export default SortBy;
