import React, { useRef, useState } from 'react';
import "./MobileUploadDocument.css";
import upload_logo from "../../../img/upload_layer.svg";
import pdfLogo from "../../../icons/pdf.png";
import CloseIcon from "@mui/icons-material/Close";

function MobileUploadDocument({ updateFinalData, finalData }) {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateFinalData({ [name]: value });
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check if the selected file is a PDF
      if (file.type === "application/pdf") {
        setFileName(file.name);
        updateFinalData({ Document_IMAGE: file });
      } else {
        // If the selected file is not a PDF, alert the user
        // console.log("Please select a PDF file.");
        // Clear the file input value
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    }
  };
  

  const handleRemoveFile = () => {
    setFileName(null);
    updateFinalData({ Document_IMAGE: null });
    // Clear the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  return (
    <div className="mobile-uploads-document-container">
      <span className="mobile-uploads-documents-desc">
        We require any of these documents for verification purposes.
      </span>

      <div className="mobile-upload-documents-main-upload-container">
        <fieldset className="mobile-input-fieldset">
          <select
            className=" mobile-select-state-select"
            style={{ height: "6vh" }}
            id="business_document"
            name="business_document"
            onChange={handleInputChange}
            value={finalData?.business_document || ""}
            required
          >
            <option className="mobile-select-upload-option" value="">
              Business Document
            </option>
            <option className="mobile-select-upload-option" value="ROC Certificate">
              Registration Certificate from ROC(Registrar of Company for
              Jurisdiction)
            </option>
            <option className="mobile-select-upload-option" value="Tax Return">
              Sales Tax & Income Tax Return IMSME
            </option>
            <option className="mobile-select-upload-option" value="Shop License">
              Certificate/License issued under Shops & Establishment act and /
              or any other acts as applicable to the firm.
            </option>
          </select>
        </fieldset>
        <div className="mobile-upload-documents-upload-and-detail">
          <div className="mobile-upload-documents-img-text-div">
            <input
              type="file"
              className="mobile-upload-input"
              name="Document_IMAGE"
              id="Document_IMAGE"
              ref={fileInputRef}
              onChange={handleFileSelect}
              accept=".pdf"
            />
            <img src={upload_logo} alt="" className="mobile-upload-documents-img" />
            <span className="mobile-upload-documents-text">Upload Documents</span>
          </div>
          {fileName && (
            <div className="mobile-upload-document-name">
              <img className="mobile-upload-document-logo" src={pdfLogo} alt="" />
              <span className="mobile-upload-document-span">{fileName.slice(0, 16)}.pdf</span>
              <CloseIcon onClick={handleRemoveFile}/>
            </div>
          )}
          <div className="mobile-upload-documents-size-details">
            <ul className="mobile-upload-documents-ul">
              <li>Max File size: 10MB</li>
              <li>File Type .PDF</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileUploadDocument