import React from "react";
import "./MobileCtgProduct.css";
import { useSelector } from "react-redux";
import { selectAllCategoryProducts } from "../../features/listingPage/listingPageSlice";

function MobileCtgProduct({ categoryName }) {
  const pdts = useSelector(selectAllCategoryProducts);
  const products = pdts.Data;
  return (
    <div className="mobile-ctg-product-container">
      <span className="mobile-ctg-name">{categoryName}</span>
      <span className="mobile-total-product-available">
        Showing{" "}
        {products?.product?.length > 16 ? "16" : products?.product?.length} of{" "}
        {products?.product?.length} products
      </span>
    </div>
  );
}

export default MobileCtgProduct;
