import React, { useEffect, useMemo, useState } from "react";
import "./MobileNavigation.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectAllNavigations } from "../../features/HomePage/homeSlice";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";
import { IMAGE_BASE } from "../../config";

function MobileNavigation({ handleNavigations, showNavigation }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const verticalDetail = useSelector(selectAllNavigations);
  // console.log(verticalDetail);
  const [verticalColor, setVerticalColor] = useState(null);

  useEffect(() => {
    if (showNavigation === 1) {
      setVerticalColor("var(--color-blue)");
    } else if (showNavigation === 2) {
      setVerticalColor("var(--color-green)");
    } else if (showNavigation === 3) {
      setVerticalColor("var(--color-peach)");
    } else {
      setVerticalColor(null);
    }
  }, [showNavigation]);

  const handleBrandNavigate = (value) => {
    const lowercaseValue = value.toLowerCase();
    navigate(`/listing-page/${lowercaseValue}`);
    handleNavigations(null);
  };

  const handelCategoryNavigate = (catID, catName) => {
    navigate(`/listing-page/${catID}/${catName}`);
    dispatch(setSelectedSubcategory(null));
    // dispatch(fetchAllCategoryProductsAsync(catID));
    handleNavigations(null);
  };

  const memoizedCategories = useMemo(() => {
    return (
      verticalDetail?.categories &&
      verticalDetail?.categories.map((category, index) => (
        <div
          className="mobile-navigation-each-category-container"
          key={index}
          onClick={() =>
            handelCategoryNavigate(category?.CategoryID, category?.categoryName)
          }
        >
          <img
            src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_CATEGORY_IMAGE/${
              category?.image && category?.image
            }`}
            alt=""
            className="mobile-navigation-each-category-img"
          />
          <span className="mobile-navigation-each-category-name">
            {category.categoryName && category?.categoryName}
          </span>
        </div>
      ))
    );
  }, [verticalDetail, verticalColor]);

  const memoizedManufacturers = useMemo(() => {
    return (
      verticalDetail?.manufacturers &&
      verticalDetail?.manufacturers.map((brand, index) => (
        <div className="mobile-navigation-each-brands-container" key={index}>
          <img
            src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
              brand?.manufacture_images && brand?.manufacture_images
            }`}
            alt=""
            className="mobile-navigation-each-brands-logo"
            onClick={() =>
              handleBrandNavigate(
                brand?.manufacture_name && brand?.manufacture_name
              )
            }
          />
        </div>
      ))
    );
  }, [verticalDetail]);

  return (
    <div className="mobile-navigation-container">
      <div className="mobile-navigation-main-container">
        {memoizedCategories}
      </div>
      <div className="mobile-navigation-brands-container">
        {memoizedManufacturers}
      </div>
    </div>
  );
}

export default MobileNavigation;
