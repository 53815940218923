import React, { useEffect, useState } from "react";
import "./MobileDetails.css";
import MobileBreadcrum from "./MobileBreadcrum";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MobileImgSlider from "./MobileImgSlider";
import MobileBasicDetail from "./MobileBasicDetail";
import MobilePrice from "./MobilePrice";
import MobileAdvantage from "./MobileAdvantage";
import MobileVarientSelection from "./MobileVarientSelection";
import MobilePriceBreakDown from "./MobilePriceBreakDown";
import MobileDescPromises from "./MobileDescPromises";
import MobileProductTabs from "./MobileProductTabs";
import MobileAboutBrands from "./MobileAboutBrands";
import { useParams } from "react-router";
import {
  fetchDealerProductDetailsAsync,
  fetchProductDetailsAsync,
  selectProductDetails,
  setSelectedProd_ID,
  setSelectedVariant,
} from "../../features/productDesc/productDescSlice";
import { useDispatch, useSelector } from "react-redux";

function MobileDetails() {
  const { prod_ID } = useParams();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const selectedVariant = useSelector(
    (state) => state.productDetail.selectedVariant
  );

  const dispatch = useDispatch();
  const productSelect = useSelector(selectProductDetails);
  useEffect(() => {
    const fetchData = async () => {
      handleOpen();
      await dispatch(setSelectedProd_ID(prod_ID));
      if (prod_ID?.startsWith("9")) {
        await dispatch(fetchDealerProductDetailsAsync(prod_ID));
        handleClose();
      } else {
        await dispatch(fetchProductDetailsAsync(prod_ID));
        handleClose();
      }
    };
    fetchData();
    return () => clearTimeout(2000);
  }, [dispatch, prod_ID]);

  useEffect(() => {
    if (
      productSelect &&
      productSelect.products &&
      productSelect.products.length > 0 &&
      productSelect.products[0].skuDetails &&
      productSelect.products[0].skuDetails.length > 0
    ) {
      if (selectedVariant?.length === 0) {
        dispatch(setSelectedVariant(productSelect?.products[0]?.skuDetails[0]));
      }
    }
  }, [dispatch, productSelect]);

  // console.log(productSelect?.quantity && productSelect?.quantity);
  return (
    <div className="mobile-details-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mobile-details-breadcrum-container">
        <MobileBreadcrum />
      </div>
      <div className="mobile-details-img-slider-container">
        <MobileImgSlider selectProduct={productSelect.products} />
      </div>
      <div className="mobile-details-product-basic-detail-container">
        <MobileBasicDetail selectProduct={productSelect.products} />
      </div>
      <div className="mobile-details-product-price-container">
        <MobilePrice selectProduct={productSelect?.products} />
      </div>
      <div className="mobile-details-product-varient-selection-container">
        <MobileVarientSelection
          selectProduct={productSelect?.products}
          qtys={productSelect?.quantity && productSelect?.quantity}
        />
      </div>
      <div className="mobile-details-product-advantage-container">
        <MobileAdvantage selectProduct={productSelect?.products} />
      </div>
      <div className="mobile-details-product-price-break-down-container">
        <MobilePriceBreakDown
          currentProduct={productSelect?.products}
          qtys={productSelect?.quantity && productSelect?.quantity}
        />
      </div>
      <div className="mobile-details-promises-container">
        <MobileDescPromises />
      </div>
      <div className="mobile-details-product-explore-tab-container">
        <MobileProductTabs productDesc={productSelect?.products} />
      </div>
      {/* <div className="mobile-details-about-brands-container">
        <MobileAboutBrands />
      </div> */}
    </div>
  );
}

export default MobileDetails;
