import React from "react";
import "./MobileAdvantage.css";
import { IMAGE_BASE } from "../../config";

function MobileAdvantage({ selectProduct }) {
  const advantages =
    selectProduct && selectProduct[0] && selectProduct[0]?.Advantages;
  return (
    <div className="mobile-advantage-container">
      {advantages && Object.keys(advantages).length > 0 && (
        <div className="mobile-product-description-adv-container">
          <h3>Advantages</h3>

          <div className="mobile-product-description-adv-main-container">
            {advantages?.Advantage1 && (
              <div className="mobile-product-description-each-adv-div">
                <img
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_ADVANTAGE/${advantages?.Image1}`}
                  alt=""
                />
                <span>{advantages?.Advantage1}</span>
              </div>
            )}
            {advantages?.Advantage2 && (
              <div className="mobile-product-description-each-adv-div">
                <img
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_ADVANTAGE/${advantages?.Image2}`}
                  alt=""
                />
                <span>{advantages?.Advantage2}</span>
              </div>
            )}
            {advantages?.Advantage3 && (
              <div className="mobile-product-description-each-adv-div">
                <img
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_ADVANTAGE/${advantages?.Image3}`}
                  alt=""
                />
                <span>{advantages?.Advantage3}</span>
              </div>
            )}{" "}
            {advantages?.Advantage4 && (
              <div className="mobile-product-description-each-adv-div">
                <img
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_ADVANTAGE/${advantages?.Image4}`}
                  alt=""
                />
                <span>{advantages?.Advantage4}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default MobileAdvantage;
