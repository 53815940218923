import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAllReviews, addReview,removeReview,likeOrDislikeReview} from "./reviewAPI";

const initialState = {
  reviews: [],
  status: "idle", // Include the 'status' property in the initialState
};

export const fetchAllReviewsAsync = createAsyncThunk(
  "reviews/fetchAllReviews",
  async (prod_ID) => {
    const response = await fetchAllReviews(prod_ID);
    // console.log(response)
    return response.data.review;
  }
);

export const addReviewAsync = createAsyncThunk(
  "reviews/addReview",
  async (reviewData) => { // Pass wishlistData to the async function
    const response = await addReview(reviewData);
    // console.log(response);
    return response;
  }
);
export const removeReviewAsync = createAsyncThunk(
  "reviews/removeReview",
  async (reviewData) => { // Pass wishlistData to the async function
    const response = await removeReview(reviewData);
    // console.log(response);
    return response;
  }
);
export const likeOrDislikeReviewAsync = createAsyncThunk(
  "reviews/likeOrDislikeReview",
  async (likeOrDislikeData) => { // Pass wishlistData to the async function
    // console.log(likeOrDislikeData);
    const response = await likeOrDislikeReview(likeOrDislikeData);
    // console.log(response);
    return response;
  }
);

export const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllReviewsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllReviewsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.reviews = action.payload;
      })
      .addCase(addReviewAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addReviewAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.reviews = action.payload;
      })
      .addCase(removeReviewAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeReviewAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload);
        state.reviews = action.payload;
      })
      .addCase(likeOrDislikeReviewAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(likeOrDislikeReviewAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload);
        // state.reviews = action.payload;
      });
  },
});

export const selectAllReviews = (state) => state.review.reviews;

export default reviewSlice.reducer;
