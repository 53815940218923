import React from "react";
import "./NoCartItem.css";
import noCartLogo from "../../img/no-cart-item.png";
import { useNavigate } from "react-router";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function NoCartItem({ text }) {
  const navigate = useNavigate();
  const handleAddToCart = () => {
    navigate("/");
  };
  return (
    <div className="no-cart-item-container">
      <div className="no-cart-item-div">
        <LazyLoadImage
          src={noCartLogo}
          alt=""
          effect="blur"
          wrapperProps={{
            // If you need to, you can tweak the effect transition using the wrapper style.
            style: { transitionDelay: "1s" },
          }}
        />
        <h3>No Items Found!</h3>
        <span className="sorry-mate">
          Sorry mate... no items found inside your {text}
        </span>
        <span className="add-items-to-cart-btn" onClick={handleAddToCart}>
          Add items To {text}
        </span>
      </div>
    </div>
  );
}

export default NoCartItem;
