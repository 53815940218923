import axios from "axios";
import { API_BASE_URL } from "../../config";
export async function fetchUser(token, userID) {
  function getCookie(name) {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${name}=`));

    return cookieValue ? cookieValue.split("=")[1] : null;
  }
  try {
    const tokens = getCookie("token"); // Get the token from cookies
    const response = await fetch(`${API_BASE_URL}/user/${userID}`, {
      method: "GET",
      headers: {
        Authorization: `${tokens}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error("Error fetching user data:", error);
    throw error;
  }
}
export async function sendEmail(emailData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${API_BASE_URL}email_forgot_password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
        body: JSON.stringify(emailData),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export async function verifyOTP(otpData) {
  try {
    const response = await fetch(`${API_BASE_URL}email_otp_verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any additional headers if needed
      },
      body: JSON.stringify(otpData),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error("Error fetching user data:", error);
    throw error;
  }
}
export async function setPassword(passwordData) {
  try {
    const response = await fetch(`${API_BASE_URL}verify_email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any additional headers if needed
      },
      body: JSON.stringify(passwordData),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error("Error fetching user data:", error);
    throw error;
  }
}

export async function signUp(signUPData) {
  try {
    const formData = new FormData();

    // Append form data fields
    Object.keys(signUPData).forEach((key) => {
      // Check if the key is one of the fields to be stringified
      if (["Aadhar_DATA", "PAN_DATA", "GST_DATA"].includes(key)) {
        // Stringify the value before appending to form data
        formData.append(key, JSON.stringify(signUPData[key]));
      } else {
        // Append other fields directly
        formData.append(key, signUPData[key]);
      }
    });

    const response = await axios.post(`${API_BASE_URL}registration`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    // const data = await response?.data;
    // console.log("Response", response);
    return response;
  } catch (error) {
    // console.error("Error fetching user data:", error);
    throw error;
  }
}

export async function updateProfileImage(imageData) {
  try {
    // console.log(imageData);
    const formData = new FormData();

    // Append form data fields
    Object.keys(imageData).forEach((key) => {
      formData.append(key, imageData[key]);
    });
    const response = await axios.post(
      `${API_BASE_URL}update_user_profile`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const data = await response.json();
    // console.log("data", data);
    return data;
  } catch (error) {
    // console.error("Error fetching user data:", error);
    throw error;
  }
}
export function updateProfileDetail(UpdateData) {
  // console.log(UpdateData);
  let modifyData = UpdateData.data;
  let userID = UpdateData.userID;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}update_user_details/${userID}`,
        {
          // const response = await fetch(`http://localhost:8000/api/v1/shipping_address/${shippingID}`, {

          method: "PATCH", // Use the PATCH method for modifying an address
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(modifyData), // Send the modified data in the request body
        }
      );
      if (!response.ok) {
        throw new Error("Failed to modify address");
      }
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      reject(error);
    }
  });
}

// LOGIN WITH PHONE NUMBER
