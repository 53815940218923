import React, { useState } from "react";
import "./AdminDashboard.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import cyclecircleLogo from "../../img/parts/Cycle-Circle-Logo-White-1.png";
import { useNavigate } from "react-router";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import AdminPreOrder from "./AdminPreOrder";
import AdminOrders from "./AdminOrders";
import AdminCarts from "./AdminCarts";
import AdminToggle from "./AdminToggle";
import AdminShipping from "./AdminShipping";

function AdminDashboard() {
  const navigate = useNavigate();
  const [nav, setNav] = useState("pre-orders");

  const renderContent = () => {
    switch (nav) {
      case "pre-orders":
        return <AdminPreOrder />;
      case "orders":
        return <AdminOrders />;
      case "carts":
        return <AdminCarts />;
        case "toggle":
          return <AdminToggle />;
          case "shipping":
          return <AdminShipping />;
      default:
        return <AdminPreOrder />;
    }
  };

  return (
    <div className="admin-dashboard-preorder-container">
      <div className="admin-dashboard-left-container">
        <img
          src={cyclecircleLogo}
          alt=""
          className="admin-dashboard-cc-logo"
          onClick={() => navigate("/")}
        />
        <span
          className="admin-dashboard-back-to-home"
          onClick={() => navigate("/")}
        >
          <KeyboardBackspaceIcon /> Back to home
        </span>
        <div className="admin-dashboard-navigator">
          <span className="admin-dashboard-heading">NAVIGATION</span>
          <div
            className="admin-dashboard-navigate"
            onClick={() => setNav("pre-orders")}
            style={{
              background: nav === "pre-orders" ? "rgb(228, 219, 219)" : "white",
            }}
          >
            <WorkHistoryOutlinedIcon />
            <span className="admin-dashboard-navigator-name">Pre-Orders</span>
          </div>
          <div
            className="admin-dashboard-navigate"
            onClick={() => setNav("orders")}
            style={{
              background: nav === "orders" ? "rgb(228, 219, 219)" : "white",
            }}
          >
            <Inventory2OutlinedIcon />
            <span className="admin-dashboard-navigator-name">Orders</span>
          </div>
          <div
            className="admin-dashboard-navigate"
            onClick={() => setNav("carts")}
            style={{
              background: nav === "carts" ? "rgb(228, 219, 219)" : "white",
            }}
          >
            <ShoppingCartOutlinedIcon />
            <span className="admin-dashboard-navigator-name">Carts</span>
          </div>
          <div
            className="admin-dashboard-navigate"
            onClick={() => setNav("toggle")}
            style={{
              background: nav === "toggle" ? "rgb(228, 219, 219)" : "white",
            }}
          >
            <ToggleOnOutlinedIcon />
            <span className="admin-dashboard-navigator-name">Switch to Admin</span>
          </div>
          <div
            className="admin-dashboard-navigate"
            onClick={() => setNav("shipping")}
            style={{
              background: nav === "shipping" ? "rgb(228, 219, 219)" : "white",
            }}
          >
            <LocalShippingOutlinedIcon />
            <span className="admin-dashboard-navigator-name">Free Shipping</span>
          </div>
        </div>
      </div>
      <div className="admin-dashboard-right-container">{renderContent()}</div>
    </div>
  );
}

export default AdminDashboard;
