import React, { useState } from "react";
import "./CartsProduct.css";
import PriceDetail from "./PriceDetail";
import DeliveryEstimates from "./DeliveryEstimates";
import CartsAllProduct from "./CartsAllProduct";
import ContactDetail from "./ContactDetail";
import DefaultContact from "./DefaultContact";
import PaymentMode from "./PaymentMode";
import NeedHelp from "./NeedHelp";
import Carts_Breadcrum from "./Carts_Breadcrum";
import Promotions from "../HomePage/Promotions/Promotions";
import WalletPayment from "./WalletPayment";
import { API_BASE_PAYMENT_URL } from "../../config";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";

function CartsProduct() {
  const [currentStep, setCurrentStep] = useState(1);
  const userDetail = useSelector(selectUser);
  const [showContactDetail, setShowContactDetail] = useState(true);
  const [showDefaultContact, setShowDefaultContact] = useState(false);
  const [confirmOverlay, setConfirmOverlay] = useState(false);
  const [paymentSessionId, setPaymentSessionId] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [generatePaymentUrl,setGeneratePaymentUrl] = useState(false);
  const handleConfirmOverlay = () => {
    setConfirmOverlay(true);
  };

  const handleCheckout = () => {
    setShowContactDetail(false);
    setShowDefaultContact(false);
  };

  const handleAddNewAddress = () => {
    setShowContactDetail(true);
    setShowDefaultContact(false);
  };

  const handleGeneratePaymentURL = async (price) => {
    try {
      const response = await fetch(
        `${API_BASE_PAYMENT_URL}${userDetail?.UserID}/${price}`
      );

      const data = await response.json();
      setPaymentSessionId(data?.Payment_Session_ID);
      setTransactionId(data?.LinkID);
      // console.log(data);
    } catch (error) {
      console.error("An error occurred during the API request:", error.message);
    }
  };
  return (
    <>
      <div className="cart-breadcrum-main-container">
        <Carts_Breadcrum
          confirmOverlay={confirmOverlay}
          setConfirmOverlay={setConfirmOverlay}
          handleConfirmOverlay={handleConfirmOverlay}
          onCheckout={handleCheckout}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleGeneratePaymentURL={handleGeneratePaymentURL}
          paymentSessionId={paymentSessionId}
          transactionId={transactionId}
          setGeneratePaymentUrl={setGeneratePaymentUrl}
        />
      </div>
      <div className="carts_product-align-container">
        {currentStep === 1 && (
          <div className="carts-container">
            {currentStep === 1 && <CartsAllProduct />}
          </div>
        )}
        {currentStep === 2 && (
          <div className="carts-container-step2">
            {currentStep === 2 && (
              <DefaultContact onAddNewAddress={handleAddNewAddress} />
            )}
          </div>
        )}
        {currentStep === 3 && (
          <div className="carts-container-step3">
            {currentStep === 3 && (
              <WalletPayment
                confirmOverlay={confirmOverlay}
                setConfirmOverlay={setConfirmOverlay}
                handleConfirmOverlay={handleConfirmOverlay}
                onCheckout={handleCheckout}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                handleGeneratePaymentURL={handleGeneratePaymentURL}
                paymentSessionId={paymentSessionId}
                transactionId={transactionId}
                generatePaymentUrl={generatePaymentUrl}
                setGeneratePaymentUrl={setGeneratePaymentUrl}
              />
            )}
          </div>
        )}
        {currentStep !== 3 && (
          <div className="cartsProduct-estimates-container">
            <div className="cartsProduct-checkout-container">
              <PriceDetail
                confirmOverlay={confirmOverlay}
                setConfirmOverlay={setConfirmOverlay}
                handleConfirmOverlay={handleConfirmOverlay}
                onCheckout={handleCheckout}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                handleGeneratePaymentURL={handleGeneratePaymentURL}
                paymentSessionId={paymentSessionId}
                transactionId={transactionId}
              />
            </div>
          </div>
        )}
      </div>
      {currentStep === 1 && <Promotions />}
      <NeedHelp />
    </>
  );
}

export default CartsProduct;
