import React from 'react';
import "./MobileRelatedSearch.css";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { fetchThroughSearchAsync, setSubCategories } from '../../features/listingPage/listingPageSlice';
import { setSelectedSubcategory } from '../../store/slices/subCategorySlice';
import { setBreadcrumb } from '../../store/slices/breadCrumbsSlice';

function MobileRelatedSearch() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchedItem = useParams();
  // console.log(searchedItem.text)

  const handleSearchNavigate = (value) => {
      dispatch(fetchThroughSearchAsync(value.trim()));
      dispatch(setSubCategories(null));
      dispatch(setSelectedSubcategory(null));
      dispatch(
        setBreadcrumb({
          categoryID: 0,
          categoryName: "",
          subCategoryID: 0,
          subCategoryName: "",
          ProductID: 0,
          productName: "",
        })
      );
      navigate(`/search/${value}`);

  };
  return (
    <div className='mobile-related-search-container'>
      <span className="mobile-related-search-heading">Related Searches</span>
      <div className="mobile-related-search-main-container">
        <span className={`mobile-each-related-search ${searchedItem?.text === "Tubeless Gravel Tires" ? 'active-mobile-search' : ''}`} onClick={()=>handleSearchNavigate("Tubeless Gravel Tires")}>Tubeless Gravel Tires</span>
        <span className={`mobile-each-related-search ${searchedItem?.text === "Frameset" ? 'active-mobile-search' : ''}`} onClick={()=>handleSearchNavigate("Frameset")}>Frameset</span>
        <span className={`mobile-each-related-search ${searchedItem?.text === "Valve" ? 'active-mobile-search' : ''}`} onClick={()=>handleSearchNavigate("Valve")}>Valve</span>
        <span className={`mobile-each-related-search ${searchedItem?.text === "Pump" ? 'active-mobile-search' : ''}`} onClick={()=>handleSearchNavigate("Pump")}>Pump</span>
        <span className={`mobile-each-related-search ${searchedItem?.text === "Sealant & Tubeless Kit" ? 'active-mobile-search' : ''}`} onClick={()=>handleSearchNavigate("Sealant & Tubeless Kit")}>Sealant & Tubeless Kit</span>
      </div>
    </div>
  )
}

export default MobileRelatedSearch