import React, { useEffect, useState } from "react";
import "./MobilePdts.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  addToWishlistsAsync,
  fetchAllWishlistsAsync,
  removeFromWishlistAsync,
  selectAllWishlists,
} from "../../features/wishlist/wishlistSlice";
import {
  fetchAllCartsAsync,
  selectAllCarts,
} from "../../features/cartPage/cartPageSlice";
import { selectUser } from "../../features/auth/authSlice";
import {
  selectIsOverlayVisible,
  toggleOverlay,
} from "../../store/slices/headerSlice";
import HoverSlider from "../../components/ListingPage/ListingProduct/HoverSlider";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import StarIcon from "@mui/icons-material/Star";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import cartOutlineLogo from "../../img/navigation/cart-bookmark-outline.png";
import cartFilledLogo from "../../img/navigation/cart-bookmark-filled.png";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { IMAGE_BASE } from "../../config";
import {
  selectAllCategoryProducts,
  setBrands,
  setMaterials,
  setSelectedCategoryID,
} from "../../features/listingPage/listingPageSlice";
import { selectAllFilter } from "../../store/slices/allFilterSlice";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";
import MobilePagination from "./MobilePagination";
import AuthenticationPage from "../../components/Authentication/AuthenticationPage";
import MobileSelectVarient from "../MobileEndpoints/MobileSelectVarient";
import MobileShopByPriceRange from "./ShopBy/MobileShopByPriceRange";
import MobileShopByRating from "./ShopBy/MobileShopByRating";
import MobileShopByBrand from "./ShopBy/MobileShopByBrand";
import MobileNoProductPage from "./MobileNoProductPage";
import badgeIcon from "../../icons/badges/PriceDrop.png";
import { calculateDealerPrice, calculateDiscountPercentage, calculateOfferPrice, formatWithCommasWithoutDecimal } from "../../utils/utils";

function MobilePdts({ isOverlayVisible, toggleOverlay,currentPage,setCurrentPage }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pdts = useSelector(selectAllCategoryProducts);
  const products = pdts?.Data;
  const qtys = pdts?.quantity;
  // console.log(products);
  const wishlist = useSelector(selectAllWishlists);
  const cart = useSelector(selectAllCarts);
  const userDetail = useSelector(selectUser);
  const [openMobileVarient, setOpenMobileVarient] = useState(null);
  const [items, setItems] = useState(products?.product || []);
  const [currentItems, setCurrentItems] = useState([]);
  
  const itemsPerPage = 15;
  const allfilter = useSelector(selectAllFilter);
  const { CategoryID, categoryName } = useParams();
  const [filtering, setFiltering] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail.UserID) {
      dispatch(fetchAllWishlistsAsync(userDetail.UserID));
      dispatch(fetchAllCartsAsync(userDetail.UserID));
    }
  }, [dispatch, userDetail.UserID]);

  // set selectedCategory id
  useEffect(() => {
    if (CategoryID) {
      dispatch(setSelectedCategoryID(CategoryID));
    }

    // Set all Brands of respective category,subCategory
    if (products?.manufacture_name && products?.manufacture_name) {
      dispatch(
        setBrands(products?.manufacture_name && products?.manufacture_name)
      );
    }
    if (products?.material && products?.material) {
      dispatch(setMaterials(products?.material && products?.material));
    }

    // Update breadcrumb
    dispatch(
      setBreadcrumb({
        categoryID: CategoryID,
        categoryName: categoryName,
        // subCategoryID: null,
        subCategoryName: null,
        ProductID: null,
        productName: "",
      })
    );
  }, [
    dispatch,
    CategoryID,
    categoryName,
    products?.manufacture_name,
    products?.material,
  ]);

  useEffect(() => {
    // console.log(allfilter);
    if (
      products &&
      products.product &&
      Array.isArray(allfilter.brands) &&
      Array.isArray(allfilter.materials)
    ) {
      const filteredItems = products.product.filter((product) => {
        const brandFilterPassed =
          allfilter.brands.length === 0 ||
          allfilter.brands.includes(product?.manufacture_name);
        const materialFilterPassed =
          allfilter.materials.length === 0 ||
          allfilter.materials.includes(product?.material_name);
        const shippingFilterPassed =
          !allfilter.shipping ||
          allfilter.shipping === "standard" ||
          allfilter.shipping.includes(product?.shipping);
        const ratingFilterPassed =
          allfilter.rating === 0 ||
          parseInt(product.net_rating) >= parseInt(allfilter.rating);
        const dealerMarginFilterPassed =
          allfilter.dealerMargin === 0 ||
          parseInt(
            calculateDiscountPercentage(
              product?.skuDetails[0]?.MRP_price,
              product?.skuDetails[0]?.Offer_price
                ? calculateOfferPrice(
                    parseInt(product?.skuDetails[0]?.MRP_price),
                    parseInt(product?.skuDetails[0]?.Offer_price),
                    product?.GST
                  )
                : calculateDealerPrice(
                    parseInt(product?.skuDetails[0]?.MRP_price),
                    parseInt(product?.skuDetails[0]?.NDP_Price),
                    product?.GST
                  )
            )
          ) >= parseInt(allfilter.dealerMargin);
        const minPriceFilterPassed =
          allfilter.minPrice === 0 ||
          calculateDealerPrice(
            parseInt(product?.skuDetails[0]?.MRP_price),
            parseInt(product?.skuDetails[0]?.NDP_Price),
            product?.GST
          ) >= parseInt(allfilter.minPrice);
        const maxPriceFilterPassed =
          allfilter.maxPrice === 0 ||
          calculateDealerPrice(
            parseInt(product?.skuDetails[0]?.MRP_price),
            parseInt(product?.skuDetails[0]?.NDP_Price),
            parseInt(product?.GST)
          ) <= parseInt(allfilter.maxPrice);

        // Check if the product has the specified badge (excluding ".png" extension)
        const badgeFilterPassed =
          allfilter.shopByOffer === "" ||
          (product?.Badges && product?.Badges.replace(".png", "")) ===
            allfilter.shopByOffer;

        return (
          brandFilterPassed &&
          materialFilterPassed &&
          shippingFilterPassed &&
          ratingFilterPassed &&
          dealerMarginFilterPassed &&
          minPriceFilterPassed &&
          maxPriceFilterPassed &&
          badgeFilterPassed
        );
      });

      // console.log(filteredItems);

      let sortedItems = [...filteredItems];

      if (allfilter.sortPrice === "desc") {
        sortedItems.sort(
          (a, b) =>
            calculateDealerPrice(
              parseInt(b?.skuDetails[0]?.MRP_price),
              parseInt(b?.skuDetails[0]?.NDP_Price),
              parseInt(b?.GST)
            ) -
            calculateDealerPrice(
              parseInt(a?.skuDetails[0]?.MRP_price),
              parseInt(a?.skuDetails[0]?.NDP_Price),
              parseInt(a?.GST)
            )
        );
      } else if (allfilter.sortPrice === "asc") {
        sortedItems.sort(
          (a, b) =>
            calculateDealerPrice(
              parseInt(a?.skuDetails[0]?.MRP_price),
              parseInt(a?.skuDetails[0]?.NDP_Price),
              parseInt(a?.GST)
            ) -
            calculateDealerPrice(
              parseInt(b?.skuDetails[0]?.MRP_price),
              parseInt(b?.skuDetails[0]?.NDP_Price),
              parseInt(b?.GST)
            )
        );
      } else if (allfilter.sortRating === "desc") {
        sortedItems.sort((a, b) => b?.net_rating - a?.net_rating);
      } else if (allfilter.sortRating === "asc") {
        sortedItems.sort((a, b) => a?.net_rating - b?.net_rating);
      }

      setItems(sortedItems);
    } else {
      return;
    }
  }, [products, allfilter]);

  const handleLoginOverlay = (e) => {
    toggleOverlay();
    e.preventDefault();
  };

  // TO ADD PRODUCT INTO WISHLIST
  const handleAddToWishlist = async (data, e) => {
    handleOpen();
    let wishlistData = {
      UserID: userDetail?.UserID,
      prodID: data?.prod_ID,
    };
    dispatch(addToWishlistsAsync(wishlistData)).then(() =>
      dispatch(fetchAllWishlistsAsync(userDetail.UserID)).then(() => {
        handleClose();
      })
    ); // Fetch updated wishlist
    e.preventDefault();
  };

  const handleRemoveFromWishlist = async (data, e) => {
    handleOpen();
    let wishlistData = {
      UserID: userDetail?.UserID,
      prodID: data?.prod_ID,
    };
    dispatch(removeFromWishlistAsync(wishlistData)).then(() => {
      dispatch(fetchAllWishlistsAsync(userDetail.UserID)).then(() => {
        handleClose();
      });
    });
    e.preventDefault();
  };

  // PAGINATION
  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //  setCurrentItems(Array.isArray(items)
  //     ? items.slice(indexOfFirstItem, indexOfLastItem)
  //     : []);
  //   console.log(currentItems);

  useEffect(() => {
    // console.log("change product on pagination",indexOfFirstItem, indexOfLastItem)
    setCurrentItems(
      Array.isArray(items) ? items.slice(indexOfFirstItem, indexOfLastItem) : []
    );
  }, [items, indexOfFirstItem, indexOfLastItem]);

  const isInWishlist = (productId) => {
    return (
      Array.isArray(wishlist && wishlist?.product) &&
      wishlist?.product?.some((item) => item.prod_ID === productId)
    );
  };

  const handleNavigate = (prod_id, event) => {
    event.preventDefault();
    navigate(`/product/${prod_id}`);
  };

  const handleOpenMobileVarient = (id, e) => {
    setOpenMobileVarient(id);
    e.preventDefault();
  };



 



  // console.log(products);
  return (
    <>
      <div className="mobile-pdts-product-container">
        {currentItems && currentItems.length <= 0 && <MobileNoProductPage />}
        {((currentItems && currentItems.slice(0, 4)) || []).map((data) => (
          <div
            className="mobile-pdts-product-main-container"
            key={data?.prod_ID}
          >
            <div className="mobile-pdts-product-top-div">
              <div
                className="mobile-pdts-img-div"
                onClick={(event) => handleNavigate(data.prod_ID, event)}
              >
                {/* {data?.Badges && ( */}
                {/* <div className="mobile-pdts-product-badges-container">
                  <img
                    // src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Logo/${
                    //   data?.Badges && data?.Badges
                    // }`}
                    src={badgeIcon}
                    alt=""
                    className="product-slider-card-product-badges"
                  />
                </div> */}
                {/* )} */}
                {userDetail?.UserID === 1 && (
                  <span
                    className="products-list-card-product-badges-container"
                    style={{
                      padding: "3px",
                      fontSize: "12px",
                      letterSpacing: "0.3px",
                    }}
                  >
                    {data?.prod_ID?.startsWith("9") ? (
                      <span
                        style={{
                          background: "var(--color-peach)",
                          color: "white",
                        }}
                      >
                        CC EXCLUSIVE
                      </span>
                    ) : (
                      <span
                        style={{
                          background: "var(--color-green)",
                          color: "white",
                        }}
                      >
                        CC INCLUSIVE
                      </span>
                    )}
                  </span>
                )}

                <HoverSlider images={data?.images && data.images} />
                <div className="mobile-pdts-product-details-rating">
                  <span className="mobile-pdts-product-ratings-rate">
                    {parseInt(data.net_rating).toFixed(1)}
                    <StarIcon />
                  </span>
                  <span className="mobile-pdts-product-ratings-divider">|</span>
                  <span className="mobile-pdts-product-ratings-number">
                    313
                  </span>
                </div>
              </div>
              <div className="mobile-pdts-product-details-div">
                {data?.manufacture_name !== "ESI" && (
                  <div className="mobile-pdts-product-favourite-container">
                    {isInWishlist(data.prod_ID) ? (
                      <img
                        src={cartFilledLogo}
                        alt=""
                        onClick={(e) => {
                          handleRemoveFromWishlist(data, e);
                        }}
                        id="mobile-pdts-product-slider-card-favourite-svg"
                      />
                    ) : (
                      <img
                        src={cartOutlineLogo}
                        alt=""
                        onClick={(e) => {
                          userDetail && userDetail?.UserID
                            ? handleAddToWishlist(data, e)
                            : handleLoginOverlay(e);
                        }}
                        id="mobile-pdts-product-slider-card-favourite-svg"
                      />
                    )}
                  </div>
                )}
                <div className="mobile-pdts-product-brands-name-img">
                  {data && data?.manufacture_images ? (
                    <img
                      className="mobile-pdts-product-slider-card-product-brand-img"
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                        data && data?.manufacture_images
                      }`}
                      alt=""
                    />
                  ) : (
                    <span className="mobile-pdts-product-slider-card-product-brand-name">
                      {data.manufacture_name && data.manufacture_name}
                    </span>
                  )}
                </div>
                <div className="mobile-pdts-product-details-name">
                  <span className="mobile-pdts-product-name">
                    {data.prod_name &&
                      data.prod_name
                        .slice(0, 45)
                        .toLowerCase()
                        .charAt(0)
                        .toUpperCase() +
                        data.prod_name.slice(0, 45).toLowerCase().slice(1)}
                    {data.prod_name.length > 45 && "..."}
                  </span>
                </div>
                {data &&
                  data?.skuDetails &&
                  data?.skuDetails?.length > 0 &&
                  data?.skuDetails[0]?.NDP_Price && (
                    <div className="mobile-pdts-product-dealer-price-div">
                      <span className="mobile-pdts-product-dealer-price">
                        Dealer Price-{" "}
                        {Object.keys(userDetail).length > 0 ? (
                          <b
                            // className="price-font"
                            style={{
                              textDecoration:
                                data?.skuDetails[0].Offer_price !== null &&
                                "line-through",
                              fontSize:
                                data?.skuDetails[0].Offer_price !== null &&
                                "3vw",
                              marginLeft: "0.5vw",
                            }}
                          >
                            ₹
                            {formatWithCommasWithoutDecimal(
                              calculateDealerPrice(
                                parseInt(data.skuDetails[0].MRP_price),
                                parseInt(data.skuDetails[0].NDP_Price),
                                data?.GST
                              )
                            )}
                          </b>
                        ) : (
                          <b
                            className="mobile-login-to-see-price"
                            onClick={toggleOverlay}
                          >
                            Login to see Price
                          </b>
                        )}
                      </span>
                      {Object.keys(userDetail).length > 0 &&
                        data?.skuDetails[0].Offer_price !== null && (
                          <span className="mobile-pdts-product-offer-price">
                            ₹
                            {formatWithCommasWithoutDecimal(
                              calculateOfferPrice(
                                parseInt(data.skuDetails[0].MRP_price),
                                parseInt(data.skuDetails[0].Offer_price),
                                data?.GST
                              )
                            )}
                          </span>
                        )}
                    </div>
                  )}
                {data &&
                  data?.skuDetails &&
                  data?.skuDetails?.length > 0 &&
                  data?.skuDetails[0]?.MRP_price && (
                    <div className="mobile-pdts-product-mrp-price-div">
                      <span className="mobile-pdts-product-mrp-price">
                        Product MRP- ₹
                        {formatWithCommasWithoutDecimal(
                          parseInt(
                            data.skuDetails[0] && data.skuDetails[0].MRP_price
                          )
                        )}
                      </span>
                    </div>
                  )}
                <div className="mobile-pdts-product-dealer-margin-div">
                  {Object.keys(userDetail).length > 0 ? (
                    <div className="mobile-pdts-product-dealer-margin-login">
                      <LocalOfferOutlinedIcon />{" "}
                      <span
                        className="mobile-pdts-product-dealer-margin-span"
                        style={{
                          textDecoration:
                            data?.skuDetails[0].Offer_price !== null &&
                            "line-through",
                          fontSize:
                            data?.skuDetails[0].Offer_price !== null && "3vw",
                          margin: "0 0.8vw",
                        }}
                      >
                        {" "}
                        {calculateDiscountPercentage(
                          data?.skuDetails[0]?.MRP_price,
                          calculateDealerPrice(
                            parseInt(data.skuDetails[0].MRP_price),
                            parseInt(data.skuDetails[0].NDP_Price),
                            data?.GST
                          )
                        )}
                        %
                      </span>
                      {data?.skuDetails[0].Offer_price !== null && (
                        <span className="mobile-pdts-product-dealer-margin-offer-ribbon">
                          {calculateDiscountPercentage(
                            data?.skuDetails[0]?.MRP_price,
                            calculateOfferPrice(
                              parseInt(data.skuDetails[0].MRP_price),
                              parseInt(data.skuDetails[0].Offer_price),
                              data?.GST
                            )
                          )}
                          %
                        </span>
                      )}{" "}
                    </div>
                  ) : (
                    <div className="mobile-not-visible-container">
                      <VisibilityOffOutlinedIcon onClick={toggleOverlay} />{" "}
                      <span>%</span>
                    </div>
                  )}{" "}
                  Dealer Margin
                </div>
              </div>
            </div>
            <div className="mobile-pdts-product-bottom-div">
              <div
                className="mobile-pdts-product-cart-btn"
                onClick={(e) => {
                  userDetail && userDetail?.UserID
                    ? handleOpenMobileVarient(data.prod_ID, e)
                    : handleLoginOverlay(e);
                }}
              >
                <ShoppingCartOutlinedIcon
                  style={{ color: "var(--color-green)" }}
                />
                <span className="mobile-pdts-product-cart-span">
                  Add to cart
                </span>
              </div>
              <div
                className="mobile-pdts-product-buy-btn"
                onClick={(e) => {
                  userDetail && userDetail?.UserID
                    ? handleOpenMobileVarient(data.prod_ID, e)
                    : handleLoginOverlay(e);
                }}
              >
                <span className="mobile-pdts-product-buy-span">Buy Now</span>
              </div>
            </div>
          </div>
        ))}
        {currentItems && currentItems?.length > 4 && <MobileShopByPriceRange />}
        {((currentItems && currentItems.slice(4, 8)) || []).map((data) => (
          <div
            className="mobile-pdts-product-main-container"
            key={data?.prod_ID}
          >
            <div className="mobile-pdts-product-top-div">
              <div
                className="mobile-pdts-img-div"
                onClick={(event) => handleNavigate(data.prod_ID, event)}
              >
                {/* {data?.Badges && ( */}
                {/* <div className="mobile-pdts-product-badges-container">
                    <img
                      // src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Logo/${
                      //   data?.Badges && data?.Badges
                      // }`}
                      src={badgeIcon}
                      alt=""
                      className="product-slider-card-product-badges"
                    />
                  </div> */}
                {/* )} */}
                {userDetail?.UserID === 1 && (
                  <span
                    className="products-list-card-product-badges-container"
                    style={{
                      padding: "3px",
                      fontSize: "12px",
                      letterSpacing: "0.3px",
                    }}
                  >
                    {data?.prod_ID?.startsWith("9") ? (
                      <span
                        style={{
                          background: "var(--color-peach)",
                          color: "white",
                        }}
                      >
                        CC EXCLUSIVE
                      </span>
                    ) : (
                      <span
                        style={{
                          background: "var(--color-green)",
                          color: "white",
                        }}
                      >
                        CC INCLUSIVE
                      </span>
                    )}
                  </span>
                )}
                <HoverSlider images={data?.images && data.images} />
                <div className="mobile-pdts-product-details-rating">
                  <span className="mobile-pdts-product-ratings-rate">
                    {parseInt(data.net_rating).toFixed(1)}
                    <StarIcon />
                  </span>
                  <span className="mobile-pdts-product-ratings-divider">|</span>
                  <span className="mobile-pdts-product-ratings-number">
                    313
                  </span>
                </div>
              </div>
              <div className="mobile-pdts-product-details-div">
                {data?.manufacture_name !== "ESI" && (
                  <div className="mobile-pdts-product-favourite-container">
                    {isInWishlist(data.prod_ID) ? (
                      <img
                        src={cartFilledLogo}
                        alt=""
                        onClick={(e) => {
                          handleRemoveFromWishlist(data, e);
                        }}
                        id="mobile-pdts-product-slider-card-favourite-svg"
                      />
                    ) : (
                      <img
                        src={cartOutlineLogo}
                        alt=""
                        onClick={(e) => {
                          userDetail && userDetail?.UserID
                            ? handleAddToWishlist(data, e)
                            : handleLoginOverlay(e);
                        }}
                        id="mobile-pdts-product-slider-card-favourite-svg"
                      />
                    )}
                  </div>
                )}
                <div className="mobile-pdts-product-brands-name-img">
                  {data && data?.manufacture_images ? (
                    <img
                      className="mobile-pdts-product-slider-card-product-brand-img"
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                        data && data?.manufacture_images
                      }`}
                      alt=""
                    />
                  ) : (
                    <span className="mobile-pdts-product-slider-card-product-brand-name">
                      {data.manufacture_name && data.manufacture_name}
                    </span>
                  )}
                </div>
                <div className="mobile-pdts-product-details-name">
                  <span className="mobile-pdts-product-name">
                    {data.prod_name &&
                      data.prod_name
                        .slice(0, 45)
                        .toLowerCase()
                        .charAt(0)
                        .toUpperCase() +
                        data.prod_name.slice(0, 45).toLowerCase().slice(1)}
                    {data.prod_name.length > 45 && "..."}
                  </span>
                </div>
                {data &&
                  data?.skuDetails &&
                  data?.skuDetails?.length > 0 &&
                  data?.skuDetails[0]?.NDP_Price && (
                    <div className="mobile-pdts-product-dealer-price-div">
                      <span className="mobile-pdts-product-dealer-price">
                        Dealer Price-{" "}
                        {Object.keys(userDetail).length > 0 ? (
                          <b
                            // className="price-font"
                            style={{
                              textDecoration:
                                data?.skuDetails[0].Offer_price !== null &&
                                "line-through",
                              fontSize:
                                data?.skuDetails[0].Offer_price !== null &&
                                "3vw",
                              marginLeft: "0.5vw",
                            }}
                          >
                            ₹
                            {formatWithCommasWithoutDecimal(
                              calculateDealerPrice(
                                parseInt(data.skuDetails[0].MRP_price),
                                parseInt(data.skuDetails[0].NDP_Price),
                                data?.GST
                              )
                            )}
                          </b>
                        ) : (
                          <b
                            className="mobile-login-to-see-price"
                            onClick={toggleOverlay}
                          >
                            Login to see Price
                          </b>
                        )}
                      </span>
                      {Object.keys(userDetail).length > 0 &&
                        data?.skuDetails[0].Offer_price !== null && (
                          <span className="mobile-pdts-product-offer-price">
                            ₹
                            {formatWithCommasWithoutDecimal(
                              calculateOfferPrice(
                                parseInt(data.skuDetails[0].MRP_price),
                                parseInt(data.skuDetails[0].Offer_price),
                                data?.GST
                              )
                            )}
                          </span>
                        )}
                    </div>
                  )}
                {data &&
                  data?.skuDetails &&
                  data?.skuDetails?.length > 0 &&
                  data?.skuDetails[0]?.MRP_price && (
                    <div className="mobile-pdts-product-mrp-price-div">
                      <span className="mobile-pdts-product-mrp-price">
                        Product MRP-₹
                        {formatWithCommasWithoutDecimal(
                          parseInt(
                            data.skuDetails[0] && data.skuDetails[0].MRP_price
                          )
                        )}
                      </span>
                    </div>
                  )}
                <div className="mobile-pdts-product-dealer-margin-div">
                  {Object.keys(userDetail).length > 0 ? (
                    <div className="mobile-pdts-product-dealer-margin-login">
                      <LocalOfferOutlinedIcon />{" "}
                      <span
                        className="mobile-pdts-product-dealer-margin-span"
                        style={{
                          textDecoration:
                            data?.skuDetails[0].Offer_price !== null &&
                            "line-through",
                          fontSize:
                            data?.skuDetails[0].Offer_price !== null && "3vw",
                          margin: "0 0.8vw",
                        }}
                      >
                        {" "}
                        {calculateDiscountPercentage(
                          data?.skuDetails[0]?.MRP_price,
                          calculateDealerPrice(
                            parseInt(data.skuDetails[0].MRP_price),
                            parseInt(data.skuDetails[0].NDP_Price),
                            data?.GST
                          )
                        )}
                        %
                      </span>
                      {data?.skuDetails[0].Offer_price !== null && (
                        <span className="mobile-pdts-product-dealer-margin-offer-ribbon">
                          {calculateDiscountPercentage(
                            data?.skuDetails[0]?.MRP_price,
                            calculateOfferPrice(
                              parseInt(data.skuDetails[0].MRP_price),
                              parseInt(data.skuDetails[0].Offer_price),
                              data?.GST
                            )
                          )}
                          %
                        </span>
                      )}{" "}
                    </div>
                  ) : (
                    <div className="mobile-not-visible-container">
                      <VisibilityOffOutlinedIcon onClick={toggleOverlay} />{" "}
                      <span>%</span>
                    </div>
                  )}{" "}
                  Dealer Margin
                </div>
              </div>
            </div>
            <div className="mobile-pdts-product-bottom-div">
              <div
                className="mobile-pdts-product-cart-btn"
                onClick={(e) => {
                  userDetail && userDetail?.UserID
                    ? handleOpenMobileVarient(data.prod_ID, e)
                    : handleLoginOverlay(e);
                }}
              >
                <ShoppingCartOutlinedIcon
                  style={{ color: "var(--color-green)" }}
                />
                <span className="mobile-pdts-product-cart-span">
                  Add to cart
                </span>
              </div>
              <div
                className="mobile-pdts-product-buy-btn"
                onClick={(e) => {
                  userDetail && userDetail?.UserID
                    ? handleOpenMobileVarient(data.prod_ID, e)
                    : handleLoginOverlay(e);
                }}
              >
                <span className="mobile-pdts-product-buy-span">Buy Now</span>
              </div>
            </div>
          </div>
        ))}
        {currentItems && currentItems?.length > 8 && <MobileShopByRating />}
        {((currentItems && currentItems.slice(8, 12)) || []).map((data) => (
          <div
            className="mobile-pdts-product-main-container"
            key={data?.prod_ID}
          >
            <div className="mobile-pdts-product-top-div">
              <div
                className="mobile-pdts-img-div"
                onClick={(event) => handleNavigate(data.prod_ID, event)}
              >
                {/* {data?.Badges && ( */}
                {/* <div className="mobile-pdts-product-badges-container">
                    <img
                      // src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Logo/${
                      //   data?.Badges && data?.Badges
                      // }`}
                      src={badgeIcon}
                      alt=""
                      className="product-slider-card-product-badges"
                    />
                  </div> */}
                {/* )} */}
                {userDetail?.UserID === 1 && (
                  <span
                    className="products-list-card-product-badges-container"
                    style={{
                      padding: "3px",
                      fontSize: "12px",
                      letterSpacing: "0.3px",
                    }}
                  >
                    {data?.prod_ID?.startsWith("9") ? (
                      <span
                        style={{
                          background: "var(--color-peach)",
                          color: "white",
                        }}
                      >
                        CC EXCLUSIVE
                      </span>
                    ) : (
                      <span
                        style={{
                          background: "var(--color-green)",
                          color: "white",
                        }}
                      >
                        CC INCLUSIVE
                      </span>
                    )}
                  </span>
                )}
                <HoverSlider images={data?.images && data.images} />
                <div className="mobile-pdts-product-details-rating">
                  <span className="mobile-pdts-product-ratings-rate">
                    {parseInt(data.net_rating).toFixed(1)}
                    <StarIcon />
                  </span>
                  <span className="mobile-pdts-product-ratings-divider">|</span>
                  <span className="mobile-pdts-product-ratings-number">
                    313
                  </span>
                </div>
              </div>
              <div className="mobile-pdts-product-details-div">
                {data?.manufacture_name !== "ESI" && (
                  <div className="mobile-pdts-product-favourite-container">
                    {isInWishlist(data.prod_ID) ? (
                      <img
                        src={cartFilledLogo}
                        alt=""
                        onClick={(e) => {
                          handleRemoveFromWishlist(data, e);
                        }}
                        id="mobile-pdts-product-slider-card-favourite-svg"
                      />
                    ) : (
                      <img
                        src={cartOutlineLogo}
                        alt=""
                        onClick={(e) => {
                          userDetail && userDetail?.UserID
                            ? handleAddToWishlist(data, e)
                            : handleLoginOverlay(e);
                        }}
                        id="mobile-pdts-product-slider-card-favourite-svg"
                      />
                    )}
                  </div>
                )}
                <div className="mobile-pdts-product-brands-name-img">
                  {data && data?.manufacture_images ? (
                    <img
                      className="mobile-pdts-product-slider-card-product-brand-img"
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                        data && data?.manufacture_images
                      }`}
                      alt=""
                    />
                  ) : (
                    <span className="mobile-pdts-product-slider-card-product-brand-name">
                      {data.manufacture_name && data.manufacture_name}
                    </span>
                  )}
                </div>
                <div className="mobile-pdts-product-details-name">
                  <span className="mobile-pdts-product-name">
                    {data.prod_name &&
                      data.prod_name
                        .slice(0, 45)
                        .toLowerCase()
                        .charAt(0)
                        .toUpperCase() +
                        data.prod_name.slice(0, 45).toLowerCase().slice(1)}
                    {data.prod_name.length > 45 && "..."}
                  </span>
                </div>
                {data &&
                  data?.skuDetails &&
                  data?.skuDetails?.length > 0 &&
                  data?.skuDetails[0]?.NDP_Price && (
                    <div className="mobile-pdts-product-dealer-price-div">
                      <span className="mobile-pdts-product-dealer-price">
                        Dealer Price-{" "}
                        {Object.keys(userDetail).length > 0 ? (
                          <b
                            // className="price-font"
                            style={{
                              textDecoration:
                                data?.skuDetails[0].Offer_price !== null &&
                                "line-through",
                              fontSize:
                                data?.skuDetails[0].Offer_price !== null &&
                                "3vw",
                              marginLeft: "0.5vw",
                            }}
                          >
                            ₹
                            {formatWithCommasWithoutDecimal(
                              calculateDealerPrice(
                                parseInt(data.skuDetails[0].MRP_price),
                                parseInt(data.skuDetails[0].NDP_Price),
                                data?.GST
                              )
                            )}
                          </b>
                        ) : (
                          <b
                            className="mobile-login-to-see-price"
                            onClick={toggleOverlay}
                          >
                            Login to see Price
                          </b>
                        )}
                      </span>
                      {Object.keys(userDetail).length > 0 &&
                        data?.skuDetails[0].Offer_price !== null && (
                          <span className="mobile-pdts-product-offer-price">
                            ₹
                            {formatWithCommasWithoutDecimal(
                              calculateOfferPrice(
                                parseInt(data.skuDetails[0].MRP_price),
                                parseInt(data.skuDetails[0].Offer_price),
                                data?.GST
                              )
                            )}
                          </span>
                        )}
                    </div>
                  )}
                {data &&
                  data?.skuDetails &&
                  data?.skuDetails?.length > 0 &&
                  data?.skuDetails[0]?.MRP_price && (
                    <div className="mobile-pdts-product-mrp-price-div">
                      <span className="mobile-pdts-product-mrp-price">
                        Product MRP-₹
                        {formatWithCommasWithoutDecimal(
                          parseInt(
                            data.skuDetails[0] && data.skuDetails[0].MRP_price
                          )
                        )}
                      </span>
                    </div>
                  )}
                <div className="mobile-pdts-product-dealer-margin-div">
                  {Object.keys(userDetail).length > 0 ? (
                    <div className="mobile-pdts-product-dealer-margin-login">
                      <LocalOfferOutlinedIcon />{" "}
                      <span
                        className="mobile-pdts-product-dealer-margin-span"
                        style={{
                          textDecoration:
                            data?.skuDetails[0].Offer_price !== null &&
                            "line-through",
                          fontSize:
                            data?.skuDetails[0].Offer_price !== null && "3vw",
                          margin: "0 0.8vw",
                        }}
                      >
                        {" "}
                        {calculateDiscountPercentage(
                          data?.skuDetails[0]?.MRP_price,
                          calculateDealerPrice(
                            parseInt(data.skuDetails[0].MRP_price),
                            parseInt(data.skuDetails[0].NDP_Price),
                            data?.GST
                          )
                        )}
                        %
                      </span>
                      {data?.skuDetails[0].Offer_price !== null && (
                        <span className="mobile-pdts-product-dealer-margin-offer-ribbon">
                          {calculateDiscountPercentage(
                            data?.skuDetails[0]?.MRP_price,
                            calculateOfferPrice(
                              parseInt(data.skuDetails[0].MRP_price),
                              parseInt(data.skuDetails[0].Offer_price),
                              data?.GST
                            )
                          )}
                          %
                        </span>
                      )}{" "}
                    </div>
                  ) : (
                    <div className="mobile-not-visible-container">
                      <VisibilityOffOutlinedIcon onClick={toggleOverlay} />{" "}
                      <span>%</span>
                    </div>
                  )}{" "}
                  Dealer Margin
                </div>
              </div>
            </div>
            <div className="mobile-pdts-product-bottom-div">
              <div
                className="mobile-pdts-product-cart-btn"
                onClick={(e) => {
                  userDetail && userDetail?.UserID
                    ? handleOpenMobileVarient(data.prod_ID, e)
                    : handleLoginOverlay(e);
                }}
              >
                <ShoppingCartOutlinedIcon
                  style={{ color: "var(--color-green)" }}
                />
                <span className="mobile-pdts-product-cart-span">
                  Add to cart
                </span>
              </div>
              <div
                className="mobile-pdts-product-buy-btn"
                onClick={(e) => {
                  userDetail && userDetail?.UserID
                    ? handleOpenMobileVarient(data.prod_ID, e)
                    : handleLoginOverlay(e);
                }}
              >
                <span className="mobile-pdts-product-buy-span">Buy Now</span>
              </div>
            </div>
          </div>
        ))}
        {/* <MobileShopByBrand/> */}
        {((currentItems && currentItems.slice(12)) || []).map((data) => (
          <div
            className="mobile-pdts-product-main-container"
            key={data?.prod_ID}
          >
            <div className="mobile-pdts-product-top-div">
              <div
                className="mobile-pdts-img-div"
                onClick={(event) => handleNavigate(data.prod_ID, event)}
              >
                {/* {data?.Badges && ( */}
                {/* <div className="mobile-pdts-product-badges-container">
                    <img
                      // src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Logo/${
                      //   data?.Badges && data?.Badges
                      // }`}
                      src={badgeIcon}
                      alt=""
                      className="product-slider-card-product-badges"
                    />
                  </div> */}
                {/* )} */}
                {userDetail?.UserID === 1 && (
                  <span
                    className="products-list-card-product-badges-container"
                    style={{
                      padding: "3px",
                      fontSize: "12px",
                      letterSpacing: "0.3px",
                    }}
                  >
                    {data?.prod_ID?.startsWith("9") ? (
                      <span
                        style={{
                          background: "var(--color-peach)",
                          color: "white",
                        }}
                      >
                        CC EXCLUSIVE
                      </span>
                    ) : (
                      <span
                        style={{
                          background: "var(--color-green)",
                          color: "white",
                        }}
                      >
                        CC INCLUSIVE
                      </span>
                    )}
                  </span>
                )}
                <HoverSlider images={data?.images && data.images} />
                <div className="mobile-pdts-product-details-rating">
                  <span className="mobile-pdts-product-ratings-rate">
                    {parseInt(data.net_rating).toFixed(1)}
                    <StarIcon />
                  </span>
                  <span className="mobile-pdts-product-ratings-divider">|</span>
                  <span className="mobile-pdts-product-ratings-number">
                    313
                  </span>
                </div>
              </div>
              <div className="mobile-pdts-product-details-div">
                {data?.manufacture_name !== "ESI" && (
                  <div className="mobile-pdts-product-favourite-container">
                    {isInWishlist(data.prod_ID) ? (
                      <img
                        src={cartFilledLogo}
                        alt=""
                        onClick={(e) => {
                          handleRemoveFromWishlist(data, e);
                        }}
                        id="mobile-pdts-product-slider-card-favourite-svg"
                      />
                    ) : (
                      <img
                        src={cartOutlineLogo}
                        alt=""
                        onClick={(e) => {
                          userDetail && userDetail?.UserID
                            ? handleAddToWishlist(data, e)
                            : handleLoginOverlay(e);
                        }}
                        id="mobile-pdts-product-slider-card-favourite-svg"
                      />
                    )}
                  </div>
                )}
                <div className="mobile-pdts-product-brands-name-img">
                  {data && data?.manufacture_images ? (
                    <img
                      className="mobile-pdts-product-slider-card-product-brand-img"
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                        data && data?.manufacture_images
                      }`}
                      alt=""
                    />
                  ) : (
                    <span className="mobile-pdts-product-slider-card-product-brand-name">
                      {data.manufacture_name && data.manufacture_name}
                    </span>
                  )}
                </div>
                <div className="mobile-pdts-product-details-name">
                  <span className="mobile-pdts-product-name">
                    {data.prod_name &&
                      data.prod_name
                        .slice(0, 45)
                        .toLowerCase()
                        .charAt(0)
                        .toUpperCase() +
                        data.prod_name.slice(0, 45).toLowerCase().slice(1)}
                    {data.prod_name.length > 45 && "..."}
                  </span>
                </div>
                {data &&
                  data?.skuDetails &&
                  data?.skuDetails?.length > 0 &&
                  data?.skuDetails[0]?.NDP_Price && (
                    <div className="mobile-pdts-product-dealer-price-div">
                      <span className="mobile-pdts-product-dealer-price">
                        Dealer Price-{" "}
                        {Object.keys(userDetail).length > 0 ? (
                          <b
                            // className="price-font"
                            style={{
                              textDecoration:
                                data?.skuDetails[0].Offer_price !== null &&
                                "line-through",
                              fontSize:
                                data?.skuDetails[0].Offer_price !== null &&
                                "3vw",
                              marginLeft: "0.5vw",
                            }}
                          >
                            ₹
                            {formatWithCommasWithoutDecimal(
                              calculateDealerPrice(
                                parseInt(data.skuDetails[0].MRP_price),
                                parseInt(data.skuDetails[0].NDP_Price),
                                data?.GST
                              )
                            )}
                          </b>
                        ) : (
                          <b
                            className="mobile-login-to-see-price"
                            onClick={toggleOverlay}
                          >
                            Login to see Price
                          </b>
                        )}
                      </span>
                      {Object.keys(userDetail).length > 0 &&
                        data?.skuDetails[0].Offer_price !== null && (
                          <span className="mobile-pdts-product-offer-price">
                            ₹
                            {formatWithCommasWithoutDecimal(
                              calculateOfferPrice(
                                parseInt(data.skuDetails[0].MRP_price),
                                parseInt(data.skuDetails[0].Offer_price),
                                data?.GST
                              )
                            )}
                          </span>
                        )}
                    </div>
                  )}
                {data &&
                  data?.skuDetails &&
                  data?.skuDetails?.length > 0 &&
                  data?.skuDetails[0]?.MRP_price && (
                    <div className="mobile-pdts-product-mrp-price-div">
                      <span className="mobile-pdts-product-mrp-price">
                        Product MRP-₹
                        {formatWithCommasWithoutDecimal(
                          parseInt(
                            data.skuDetails[0] && data.skuDetails[0].MRP_price
                          )
                        )}
                      </span>
                    </div>
                  )}
                <div className="mobile-pdts-product-dealer-margin-div">
                  {Object.keys(userDetail).length > 0 ? (
                    <div className="mobile-pdts-product-dealer-margin-login">
                      <LocalOfferOutlinedIcon />{" "}
                      <span
                        className="mobile-pdts-product-dealer-margin-span"
                        style={{
                          textDecoration:
                            data?.skuDetails[0].Offer_price !== null &&
                            "line-through",
                          fontSize:
                            data?.skuDetails[0].Offer_price !== null && "3vw",
                          margin: "0 0.8vw",
                        }}
                      >
                        {" "}
                        {calculateDiscountPercentage(
                          data?.skuDetails[0]?.MRP_price,
                          calculateDealerPrice(
                            parseInt(data.skuDetails[0].MRP_price),
                            parseInt(data.skuDetails[0].NDP_Price),
                            data?.GST
                          )
                        )}
                        %
                      </span>
                      {data?.skuDetails[0].Offer_price !== null && (
                        <span className="mobile-pdts-product-dealer-margin-offer-ribbon">
                          {calculateDiscountPercentage(
                            data?.skuDetails[0]?.MRP_price,
                            calculateOfferPrice(
                              parseInt(data.skuDetails[0].MRP_price),
                              parseInt(data.skuDetails[0].Offer_price),
                              data?.GST
                            )
                          )}
                          %
                        </span>
                      )}{" "}
                    </div>
                  ) : (
                    <div className="mobile-not-visible-container">
                      <VisibilityOffOutlinedIcon onClick={toggleOverlay} />{" "}
                      <span>%</span>
                    </div>
                  )}{" "}
                  Dealer Margin
                </div>
              </div>
            </div>
            <div className="mobile-pdts-product-bottom-div">
              <div
                className="mobile-pdts-product-cart-btn"
                onClick={(e) => {
                  userDetail && userDetail?.UserID
                    ? handleOpenMobileVarient(data.prod_ID, e)
                    : handleLoginOverlay(e);
                }}
              >
                <ShoppingCartOutlinedIcon
                  style={{ color: "var(--color-green)" }}
                />
                <span className="mobile-pdts-product-cart-span">
                  Add to cart
                </span>
              </div>
              <div
                className="mobile-pdts-product-buy-btn"
                onClick={(e) => {
                  userDetail && userDetail?.UserID
                    ? handleOpenMobileVarient(data.prod_ID, e)
                    : handleLoginOverlay(e);
                }}
              >
                <span className="mobile-pdts-product-buy-span">Buy Now</span>
              </div>
            </div>
          </div>
        ))}
        <div className="mobile-pdts-pagination-container">
          {currentItems && currentItems.length > 0 && (
            <MobilePagination
              totalItems={items && items.length}
              itemsPerPage={itemsPerPage}
              onPageChange={onPageChange}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
        {openMobileVarient !== null && (
          <MobileSelectVarient
            openMobileVarient={openMobileVarient}
            setOpenMobileVarient={setOpenMobileVarient}
            product={products?.product.find(
              (item) => item.prod_ID === openMobileVarient
            )}
            qtys={qtys}
          />
        )}
      </div>
      {isOverlayVisible && (
        <div className="overlay-container">
          <AuthenticationPage />
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});
const mapDispatchToProps = {
  toggleOverlay,
};
export default connect(mapStateToProps, mapDispatchToProps)(MobilePdts);
