import React, { useState, useEffect } from "react";
import "./Discover.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { scrollModePlugin } from "@react-pdf-viewer/scroll-mode";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { IMAGE_BASE } from "../../config";
import { ProgressBar } from "@react-pdf-viewer/core";
import pdfLocal from "../../img/banners/samplepdf.pdf";
import { Slide } from "react-reveal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Discover({ productDesc }) {
  const [openPdf, setOpenPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const newplugin = defaultLayoutPlugin();
  const scrollPlugin = scrollModePlugin();

  useEffect(() => {
    const fetchPdf = async () => {
      if (productDesc && productDesc[0]?.manual) {
        const url = `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUAL/${productDesc[0]?.manual}`;

        try {
          // Fetch the PDF file
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Failed to fetch PDF from URL");
          }
          const blob = await response.blob();

          // Create a Blob URL for the fetched PDF
          const blobUrl = URL.createObjectURL(blob);
          setPdfUrl(blobUrl);
        } catch (error) {
          // If there's an error fetching the PDF, set the URL to the local PDF
          console.error("Error fetching PDF:", error);
          setPdfUrl(pdfLocal);
        }
      }
    };
    fetchPdf();
  }, [productDesc]);

  const renderLoader = (percentages) => (
    <div style={{ width: "240px" }}>
      <ProgressBar progress={Math.round(percentages)} />
    </div>
  );

  useEffect(() => {
    scrollPlugin.switchScrollMode("Horizontal");
  }, []);

  return (
    <div className="discover-container">
      <div className="discover-pdf-container">
        <div className="discover-pdf-div">
          Instruction Manual:{" "}
          <span>{productDesc && productDesc[0]?.prod_name.slice(0, 15)}</span>
        </div>
        <span
          className="discover-click-here"
          onClick={() => setOpenPdf(!openPdf)}
        >
          {openPdf ? "Close PDF" : "Click here"}
        </span>
      </div>
      {openPdf && (
        <Slide bottom>
          <div className="discover-pdf-open-container">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer
                fileUrl={pdfUrl || pdfLocal} // Use pdfUrl if available, otherwise use pdfLocal
                renderLoader={renderLoader}
                theme={{ theme: "dark" }}
                plugins={[newplugin, scrollPlugin]}
              />
            </Worker>
          </div>
        </Slide>
      )}
      <div className="discover-img-grid">
        <iframe
          src="https://www.youtube.com/embed/rgArtK1ag-w?si=ykuGDcHQmxJTcifG"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
        <LazyLoadImage
          src="https://assets.specialized.com/i/specialized/74922-01_ROAD-4643_05?$hybris-pdp-photo-carousel$"
          alt="Specialized Bike"
          effect="blur"
          wrapperProps={{
            // If you need to, you can tweak the effect transition using the wrapper style.
            style: { transitionDelay: "1s" },
          }}
        />
        <LazyLoadImage
          src="https://www.carbonbikeframe7.com/image/cache/catalog/0000SL7/SpecializedSL7WHITE%20(13)-800x600.jpg"
          alt="Carbon Bike Frame"
          effect="blur"
          wrapperProps={{
            // If you need to, you can tweak the effect transition using the wrapper style.
            style: { transitionDelay: "1s" },
          }}
        />
      </div>
    </div>
  );
}

export default Discover;
