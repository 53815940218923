import React, { useState } from "react";
import "./AddNewAddress.css";
import { selectUser } from "../../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  modifyAddressAsync,
  fetchAllAddressAsync,
} from "../../../features/cartPage/addressSlice";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function EditAddressDetail({ handleEditAddressDetail, sendAddress }) {
  // console.log(sendAddress);
  const dispatch = useDispatch();
  const [customAddressType, setCustomAddressType] = useState(null);
  const [customAddressOpen, setCustomAddressOpen] = useState(false);
  const userDetail = useSelector(selectUser);
  const [finalData, setFinalData] = useState({
    shippingID: sendAddress && sendAddress?.shippingID,
    FirstName: (sendAddress && sendAddress?.FirstName) || null,
    LastName: (sendAddress && sendAddress?.LastName) || null,
    Email: (sendAddress && sendAddress?.Email) || null,
    PhoneNumber: (sendAddress && sendAddress?.PhoneNumber) || null,
    address_1: (sendAddress && sendAddress.address_1) || null,
    address_2: (sendAddress && sendAddress.address_2) || null,
    pin_code: (sendAddress && sendAddress.pin_code) || null,
    city: (sendAddress && sendAddress.city) || null,
    district: (sendAddress && sendAddress.district) || null,
    state_id: (sendAddress && sendAddress.state_id) || null,
    save_address: (sendAddress && sendAddress.save_address) || "Home",
    is_default: sendAddress && sendAddress.is_default === 1 ? "yes" : "no",
  });
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const updateFinalData = (dataToUpdate) => {
    setFinalData((prevData) => ({
      ...prevData,
      ...dataToUpdate,
    }));
    // If makeDefault is included in dataToUpdate, update the state
    if ("is_default" in dataToUpdate) {
      setMakeDefault(dataToUpdate.is_default);
    }
  };
  const [selectedAddressType, setSelectedAddressType] = useState(
    (sendAddress && sendAddress.save_address) || "Dealership"
  );
  const [makeDefault, setMakeDefault] = useState(
    sendAddress && sendAddress.is_default === 1 ? "yes" : "no"
  );
  // UPDATE ADDRESS
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    handleOpen();

    // Compare sendAddress with finalData to find the changed fields
    const changedFields = {};
    for (const key in finalData) {
      if (finalData[key] !== sendAddress[key]) {
        changedFields[key] = finalData[key];
      }
    }

    if (Object.keys(changedFields).length === 0) {
      handleEditAddressDetail();
      return; // Exit early
    }

    // Dispatch the modifyAddressAsync action with the changedFields
    const addressData = {
      data: {
        // UserID: userDetail.UserID,
        ...changedFields,
      },
      shippingID: finalData.shippingID,
    };

    dispatch(modifyAddressAsync(addressData))
      .then(() => {
        // Address modified successfully, show success message
        dispatch(fetchAllAddressAsync(userDetail?.UserID));
        handleEditAddressDetail();
        handleClose();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Address modified successfully",
        });
      })
      .catch((error) => {
        handleClose();
        // Handle the error if the address modification fails
        console.error("Error modifying address:", error);
        let errorMessage = "Failed to modify address. Please try again later.";

        if (error.response && error.response.status === 404) {
          errorMessage =
            "Address not found. Please refresh the page and try again.";
        }

        Swal.fire({
          icon: "error",
          title: "Error!",
          text: errorMessage,
        });
      });
  };
  const getStateId = (stateName) => {
    switch (stateName) {
      case "Andhra Pradesh":
        return "1";
      case "Arunachal Pradesh":
        return "2";
      case "Assam":
        return "3";
      case "Bihar":
        return "4";
      case "Chhattisgarh":
        return "5";
      case "Goa":
        return "6";
      case "Gujarat":
        return "7";
      case "Haryana":
        return "8";
      case "Himachal Pradesh":
        return "9";
      case "Jharkhand":
        return "10";
      case "Karnataka":
        return "11";
      case "Kerala":
        return "12";
      case "Madhya Pradesh":
        return "13";
      case "Maharashtra":
        return "14";
      case "Manipur":
        return "15";
      case "Meghalaya":
        return "16";
      case "Mizoram":
        return "17";
      case "Nagaland":
        return "18";
      case "Odisha":
        return "19";
      case "Punjab":
        return "20";
      case "Rajasthan":
        return "21";
      case "Sikkim":
        return "22";
      case "Tamil Nadu":
        return "23";
      case "Telangana":
        return "24";
      case "Tripura":
        return "25";
      case "Uttar Pradesh":
        return "26";
      case "Uttarakhand":
        return "27";
      case "West Bengal":
        return "28";
      case "Chandigarh":
        return "29";
      case "Delhi":
        return "30";
      case "Ladakh":
        return "31";
      case "Jammu and Kashmir":
        return "32";
      default:
        return "";
    }
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    updateFinalData({ [name]: value });

    if (name === "pin_code" && value.length === 6) {
      // Fetch data based on pin code
      try {
        handleOpen();
        const response = await fetch(
          `https://api.postalpincode.in/pincode/${value}`
        );
        const data = await response.json();
        if (response.ok && data.length > 0) {
          // Update state, district, location based on API response
          const { State, District, Division } = data[0].PostOffice[0];
          updateFinalData({
            state_id: getStateId(State),
            district: District,
            city: Division,
          });
          handleClose();
        } else {
          // Show error message if pin code is invalid
          handleClose();
          Swal.fire({
            title: "Invalid Pin Code",
            text: "Please enter a valid pin code.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        handleClose();
        console.error("Error fetching data:", error);
        // Show error message if API call fails
        Swal.fire({
          title: "Error",
          text: "An unexpected error occurred. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleEnterKey = (e, nextInputId) => {
    if (e.key === "Enter") {
      const nextInput = document.getElementById(nextInputId);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleSaveAddressType = (addressType) => {
    setSelectedAddressType(addressType);
    updateFinalData({ ["save_address"]: addressType });
    setCustomAddressOpen(false);
  };

  const handleCustomAddressTypeChange = (event) => {
    setCustomAddressType(event.target.value);
  };
  const handleSaveAddressTypeOk = (addressType) => {
    setSelectedAddressType(addressType);
    updateFinalData({ ["save_address"]: addressType });
    setCustomAddressOpen(false);

    // Fetch the span element by ID
    const spanElement = document.getElementById("fetch-others");

    // Check if the element exists and set its value to customAddressType
    if (spanElement) {
      spanElement.textContent = customAddressType;
    }
  };

  const handleMakeDefault = (value) => {
    setMakeDefault(value);
    updateFinalData({ ["is_default"]: value });
  };
  return (
    <div className="profile-add-new-address-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <h3>Address Details</h3>
      <div className="my-profile-add-address-main-container">
        <span className="my-profile-add-address-heading">Address</span>
        <form
          className="my-profile-basic-address-basic-detail-form-1"
          onSubmit={handleSubmit}
        >
          <div className="my-profile-basic-address-basic-detail-level-1">
            <fieldset className="input-fieldset">
              <legend className="input-legend">Pincode&nbsp;<b>*</b></legend>
              <input
              className="input-type-write"
                type="phone"
                onKeyPress={(e) => {
                  // Allow only numbers (0-9) and backspace (8)
                  if (!/[0-9\b]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                name="pin_code"
                value={finalData.pin_code || ""}
                placeholder="Enter your pincode "
                id="pincodeInput"
                onChange={handleInputChange}
                required
                pattern="\d{6}"
                maxLength="6"
              />
            </fieldset>
            <fieldset className="input-fieldset">
              <legend className="input-legend">Address 1&nbsp;<b>*</b></legend>
              <input
              className="input-type-write"
                type="text"
                name="address_1"
                value={finalData.address_1 || ""}
                placeholder="Address line 1"
                id="address1Input"
                onKeyDown={(e) => handleEnterKey(e, "address2Input")}
                onChange={handleInputChange}
                required
              />
            </fieldset>
            <fieldset className="input-fieldset">
              <legend className="input-legend">Address 2</legend>
              <input
              className="input-type-write"
                type="text"
                name="address_2"
                value={finalData.address_2 || ""}
                placeholder="Address line 2"
                id="address2Input"
                onKeyDown={(e) => handleEnterKey(e, "cityInput")}
                onChange={handleInputChange}
              />
            </fieldset>
            <fieldset className="input-fieldset">
              <legend className="input-legend">City&nbsp;<b>*</b></legend>
              <input
              className="input-type-write"
                type="text"
                name="city"
                value={finalData.city || ""}
                placeholder="Enter your city name"
                id="cityInput"
                onKeyDown={(e) => handleEnterKey(e, "districtInput")}
                onChange={handleInputChange}
                required
              />
            </fieldset>

            <fieldset className="input-fieldset">
              <legend className="input-legend">District&nbsp;<b>*</b></legend>
              <input
              className="input-type-write"
                type="text"
                name="district"
                value={finalData.district || ""}
                placeholder="Enter your district name"
                id="districtInput"
                onKeyDown={(e) => handleEnterKey(e, "state_id")}
                onChange={handleInputChange}
                required
              />
            </fieldset>
            <fieldset className="input-fieldset">
              <legend className="input-legend">State&nbsp;<b>*</b></legend>
              <select
                id="state_id"
                name="state_id"
                value={finalData.state_id}
                className=" select-state-select"
                onKeyDown={(e) => handleEnterKey(e, "pincodeInput")}
                onChange={handleInputChange}
                required
              >
               <option unselectable="">select State</option>
                <option value="1">Andhra Pradesh</option>
                <option value="2">Arunachal Pradesh</option>
                <option value="3">Assam</option>
                <option value="4">Bihar</option>
                <option value="5">Chhattisgarh</option>
                <option value="29">Chandigarh</option>
                <option value="30">Delhi</option>
                <option value="6">Goa</option>
                <option value="7">Gujarat</option>
                <option value="8">Haryana</option>
                <option value="9">Himachal Pradesh</option>
                <option value="32">Jammu and Kashmir</option>
                <option value="10">Jharkhand</option>
                <option value="11">Karnataka</option>
                <option value="12">Kerala</option>
                <option value="31">Ladakh</option>
                <option value="13">Madhya Pradesh</option>
                <option value="14">Maharashtra</option>
                <option value="15">Manipur</option>
                <option value="16">Meghalaya</option>
                <option value="17">Mizoram</option>
                <option value="18">Nagaland</option>
                <option value="19">Odisha</option>
                <option value="20">Punjab</option>
                <option value="21">Rajasthan</option>
                <option value="22">Sikkim</option>
                <option value="23">Tamil Nadu</option>
                <option value="24">Telangana</option>
                <option value="25">Tripura</option>
                <option value="26">Uttar Pradesh</option>
                <option value="27">Uttarakhand</option>
                <option value="28">West Bengal</option>
              </select>
            </fieldset>
          </div>
          <div className="my-profile-save-address-as">
            <div className="my-profile-save-address-option">
              <span
                onClick={() => handleSaveAddressType("Dealership")}
                className={
                  selectedAddressType === "Dealership" ? "type-active" : ""
                }
              >
                Dealership
              </span>
              <span
                onClick={() => handleSaveAddressType("Office")}
                className={
                  selectedAddressType === "Office" ? "type-active" : ""
                }
              >
                Office
              </span>
              <span
                onClick={() => {
                  setCustomAddressOpen(true);
                  setSelectedAddressType(null);
                }}
                id="fetch-others"
                className={
                  selectedAddressType !== "Office" &&
                  selectedAddressType !== "Dealership"
                    ? "type-active"
                    : ""
                }
              >
                {selectedAddressType !== "Office" &&
                  selectedAddressType !== "Dealership" && selectedAddressType !== null
                  ? selectedAddressType
                  : "Others"}
              </span>
              {customAddressOpen && (
                <div className="dealership-detail-form-2-please-specify-default-div">
                  <input
                    type="text"
                    placeholder="Please specify"
                    className={`please-specify-default-input  ${
                      !customAddressType ? "specify-error" : ""
                    }`}
                    value={customAddressType}
                    onChange={handleCustomAddressTypeChange}
                  />
                  <span
                    className="dealership-detail-specify-default-btn"
                    onClick={() => handleSaveAddressTypeOk(customAddressType)}
                  >
                    Ok
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="my-profile-dealership-detail-form-2-default-address">
            <fieldset>
              <legend style={{ marginLeft: "-15px" }}>
                Make address default
              </legend>
              <div className="my-profile-dealership-detail-default-address-div">
                <input
                  type="radio"
                  name="default_address"
                  value="yes"
                  checked={makeDefault === "yes"}
                  onChange={() => handleMakeDefault("yes")}
                />
                Yes
              </div>
              <div className="my-profile-dealership-detail-default-address-div">
                <input
                  type="radio"
                  name="default_address"
                  value="no"
                  defaultChecked
                  checked={makeDefault === "no"}
                  onChange={() => handleMakeDefault("no")}
                />
                No
              </div>
            </fieldset>
          </div>
          <button className="my-profile-address-submit-btn" style={{cursor:"pointer"}} type="submit">
            Save
          </button>
          <button
            className="my-profile-address-cancel-btn"
            style={{cursor:"pointer"}}
            type="cancel"
            onClick={handleEditAddressDetail}
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditAddressDetail;
