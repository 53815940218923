import { API_BASE_URL } from "../../config";

export function getWallet(userID) {
    return new Promise(async (resolve) => {
      const response = await fetch(`${API_BASE_URL}wallet/balance/${userID}`);
      const data = await response.json();
      // console.log("from API",data)
      resolve({ data });
    });
  }


  export function debitWallet(walletData) {
    const UserID = walletData.UserID;
    const amount = walletData.amount;
    const orderNo = walletData.orderNo;
    return new Promise(async (resolve) => {
      const response = await fetch(`${API_BASE_URL}wallet/debit/${UserID}/${amount}/${orderNo}`);
      const data = await response.json();
      // console.log("from API",data)
      resolve({ data });
    });
  }
  export function fetchCoupon() {
    return new Promise(async (resolve) => {
      const response = await fetch(`${API_BASE_URL}wallet/fetch_coupons`);
      const data = await response.json();
      // console.log("from API",data)
      resolve({ data });
    });
  }

  export function validCoupon(couponData) {
    const userID = couponData.userID;
    const coupon = couponData.coupon;
    return new Promise(async (resolve) => {
      const response = await fetch(`${API_BASE_URL}wallet/coupon/${userID}/${coupon}`);
      const data = await response.json();
      // console.log("from API",data)
      resolve({ data });
    });
  }

  export function ccHundred(userID) {
    return new Promise(async (resolve) => {
      const response = await fetch(`${API_BASE_URL}wallet/operation_team/${userID}`);
      const data = await response.json();
      // console.log("from API",data)
      resolve({ data });
    });
  }

  export function tenPercentageDiscount(userID) {
    return new Promise(async (resolve) => {
      const response = await fetch(`${API_BASE_URL}wallet/ten_percent/${userID}`);
      const data = await response.json();
      // console.log("from API",data)
      resolve({data});
    });
  }

  export function premiumUser(userID) {
    return new Promise(async (resolve) => {
      const response = await fetch(`${API_BASE_URL}wallet/premium_user/${userID}`);
      const data = await response.json();
      // console.log("from API",data)
      resolve({data});
    });
  }
  export function applyCoupon(couponData) {
    // console.log(couponData);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${API_BASE_URL}wallet/apply_coupon`, {
        // const response = await fetch(`http://localhost:8000/api/v1/create_order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
        body: JSON.stringify(couponData),
      });

      if (!response.ok) {
        // Handle non-successful responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // console.log(response);
      const data = await response.json();
      // const newData = data.carts;
      // console.log("from API",newData)
      // console.log(data);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getWalletDetail(userID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}wallet/details/${userID}`);
    const data = await response.json();
    // console.log("from API",data)
    resolve({ data });
  });
}