import React, { useEffect } from "react";
import { IMAGE_BASE } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCartsAsync,
  selectAllCarts,
  removeFromCartsAsync,
} from "../../features/cartPage/cartPageSlice";
import { selectUser } from "../../features/auth/authSlice";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useNavigate } from "react-router";
import { calculateDealerPrice, calculateOfferPrice, formatWithCommasWithDecimal } from "../../utils/utils";
function MiniCart() {
  const userDetail = useSelector(selectUser);
  const dispatch = useDispatch();
  const carts = useSelector(selectAllCarts);
  // console.log(carts);
  // console.log(userDetail);
  const navigate = useNavigate();

  const handleCartNavigate = () => {
    navigate("/carts");
  };
  useEffect(() => {
    if (userDetail && userDetail?.UserID) {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail]);

  const handleRemoveFromCarts = (data) => {
    let cartData = {
      userID: userDetail?.UserID,
      SKU: data?.skuDetails?.SKU_ID,
    };
    dispatch(removeFromCartsAsync(cartData)).then(() => {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
    });
  };

  

 
  return (
    <>
      {carts && carts.length > 0 && (
        <div className="mini-cart-container">
          {carts &&
            carts?.length > 0 &&
            carts?.slice(0, 2).map((data, index) => (
              <div className="mini-cart-each-product-div" key={index}>
                <CloseSharpIcon
                  className="remove-cross"
                  onClick={() => handleRemoveFromCarts(data)}
                />
                <img
                  className="mini-cart-each-product-main-img"
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                    data?.images && data?.images
                  }`}
                  alt=""
                />
                <div className="mini-cart-each-product-details">
                  {data && data?.skuDetails?.manufacture_images ? (
                    <img
                      className="mini-carts-product-brand-img"
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                        data && data?.skuDetails?.manufacture_images
                      }`}
                      alt=""
                    />
                  ) : (
                    <h3 className="mini-carts-product-brand-name">
                      {data?.skuDetails?.manufacture_name &&
                        data?.skuDetails?.manufacture_name}
                    </h3>
                  )}
                  <span className="mini-cart-each-product-name">
                    {(data?.skuDetails?.prod_name)?.slice(0, 40)}
                  </span>
                  <div className="mini-cart-each-product-qty-price">
                    <span className="mini-cart-each-product-qty">
                      Qty:{data?.buy_quantity}
                    </span>
                    <span className="mini-cart-each-product-price">
                      ₹
                      {formatWithCommasWithDecimal(
                        data?.skuDetails?.Offer_price
                          ? calculateOfferPrice(
                              parseInt(data?.skuDetails?.MRP_price),
                              parseInt(data?.skuDetails?.Offer_price),
                              data?.skuDetails?.GST
                            )
                          : calculateDealerPrice(
                              parseInt(data?.skuDetails?.MRP_price),
                              parseInt(data?.skuDetails?.NDP_Price),
                              data?.skuDetails?.GST
                            )
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          <p className="view-cart-btn" onClick={handleCartNavigate}>
            View Cart
          </p>
        </div>
      )}
    </>
  );
}

export default MiniCart;
