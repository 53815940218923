import React, { useEffect } from 'react';
import "./MobileShopByPriceRange.css";
import { useDispatch, useSelector } from "react-redux";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { setPriceRange } from '../../../store/slices/listingPageFilterSlice';
import { setMinPrice,setMaxPrice } from '../../../store/slices/allFilterSlice';

function MobileShopByPriceRange() {
    const priceRange = useSelector((state) => state.listingPageFilter.priceRange);
    const dispatch = useDispatch();
  
    const priceRanges = [
      { label: "₹1 - ₹500" },
      { label: "₹501 - ₹1000" },
      { label: "₹1001 - ₹2500" },
      { label: "₹2501 - ₹5000" },
      { label: "₹5001 - ₹10000" },
    ];
  
    useEffect(() => {
      if (priceRange) {
        const prices = priceRange
          .replace(/₹/g, "") // Remove "₹" from the string
          .split(" - ");
  
        // Convert the values to numbers
        const minPrice = parseInt(prices[0], 10);
        const maxPrice = parseInt(prices[1], 10);
        dispatch(setMinPrice(minPrice));
        dispatch(setMaxPrice(maxPrice));
      }
    }, [dispatch, priceRange]);
  
    const handlePriceSelect = (ranges) => {
      if(ranges === priceRange){
        dispatch(setPriceRange(""));
        dispatch(setMinPrice(0));
        dispatch(setMaxPrice(0));
      }
      else if (ranges) {
        const prices = ranges.replace(/₹/g, "").split(" - ");
        // Convert the values to numbers
        const minPrice = parseInt(prices[0], 10);
        const maxPrice = parseInt(prices[1], 10);
        dispatch(setMinPrice(minPrice));
        dispatch(setMaxPrice(maxPrice));
        dispatch(setPriceRange(ranges));
      }
    };
  return (
    <div className="mobile-shopby-price-range-container">
      <span className="mobile-shopby-price-range-header">Shop by Price Range</span>
      <div className="mobile-shopby-price-range-main-div">
        {priceRanges.map((range, index) => (
          <span
            key={index}
            className={`mobile-shopby-price-range-each-price ${
              priceRange === range.label ? "mobile-price-selected" : ""
            }`}
            style={priceRange === range.label ? { paddingRight: "6px" } : {}}
            onClick={() => handlePriceSelect(range.label)}
          >
            {range.label}{" "}
            {priceRange === range.label ? (
              <CloseSharpIcon onClick={() => handlePriceSelect(range.label)} />
            ) : null}
          </span>
        ))}
      </div>
    </div>
  )
}

export default MobileShopByPriceRange