import React, { useState } from "react";
import "./MobileEditContactDetail.css";
import {
  modifyAddressAsync,
  fetchAllAddressAsync,
} from "../../features/cartPage/addressSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API_BASE_URL } from "../../config";

function MobileEditContactDetail({ setEditAddressIndex, sendAddress }) {
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const [emailExists, setEmailExists] = useState(false);
  const [phoneExists, setPhoneExists] = useState(false);
  const [makeDefault, setMakeDefault] = useState(
    sendAddress?.is_default || "no"
  );
  const [customAddressOpen, setCustomAddressOpen] = useState(false);
  const [customAddressType, setCustomAddressType] = useState(null);
  const [finalData, setFinalData] = useState({
    FirstName: sendAddress?.FirstName,
    LastName: sendAddress?.LastName,
    Email: sendAddress?.Email,
    PhoneNumber: sendAddress?.PhoneNumber,
    address_1: sendAddress?.address_1,
    address_2: sendAddress?.address_2,
    pin_code: sendAddress?.pin_code,
    city: sendAddress?.city,
    district: sendAddress?.district,
    state_id: sendAddress?.state_id,
    save_address: sendAddress?.save_address,
    shippingID: sendAddress?.shippingID,
    is_default: sendAddress?.is_default === 1 ? "yes" : "no",
  });

  const updateFinalData = (dataToUpdate) => {
    setFinalData((prevData) => ({
      ...prevData,
      ...dataToUpdate,
    }));
    // If makeDefault is included in dataToUpdate, update the state
    if ("is_default" in dataToUpdate) {
      setMakeDefault(dataToUpdate.is_default);
    }
  };
  const [errors, setErrors] = useState({});
  const [pincodeExists, setPincodeExists] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const [selectedAddressType, setSelectedAddressType] = useState(
    sendAddress?.save_address
  );
  const getStateId = (stateName) => {
    switch (stateName) {
      case "Andhra Pradesh":
        return "1";
      case "Arunachal Pradesh":
        return "2";
      case "Assam":
        return "3";
      case "Bihar":
        return "4";
      case "Chhattisgarh":
        return "5";
      case "Goa":
        return "6";
      case "Gujarat":
        return "7";
      case "Haryana":
        return "8";
      case "Himachal Pradesh":
        return "9";
      case "Jharkhand":
        return "10";
      case "Karnataka":
        return "11";
      case "Kerala":
        return "12";
      case "Madhya Pradesh":
        return "13";
      case "Maharashtra":
        return "14";
      case "Manipur":
        return "15";
      case "Meghalaya":
        return "16";
      case "Mizoram":
        return "17";
      case "Nagaland":
        return "18";
      case "Odisha":
        return "19";
      case "Punjab":
        return "20";
      case "Rajasthan":
        return "21";
      case "Sikkim":
        return "22";
      case "Tamil Nadu":
        return "23";
      case "Telangana":
        return "24";
      case "Tripura":
        return "25";
      case "Uttar Pradesh":
        return "26";
      case "Uttarakhand":
        return "27";
      case "West Bengal":
        return "28";
      case "Chandigarh":
        return "29";
      case "Delhi":
        return "30";
      case "Ladakh":
        return "31";
      case "Jammu and Kashmir":
        return "32";
      default:
        return "";
    }
  };

  const handleCustomAddressTypeChange = (event) => {
    setCustomAddressType(event.target.value);
  };
  const handleSaveAddressTypeOk = (addressType) => {
    setCustomAddressOpen(false);
    if (addressType && addressType.length > 0) {
      setSelectedAddressType(addressType);
      updateFinalData({ ["save_address"]: addressType });
      setCustomAddressOpen(false);

      // Fetch the span element by ID
      const spanElement = document.getElementById("fetch-others");

      // Check if the element exists and set its value to customAddressType
      if (spanElement) {
        spanElement.textContent = customAddressType;
      }
    }
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    updateFinalData({ [name]: value });
    if (name === "Email" && emailExists) {
      setEmailExists(false);
    }
    if (name === "PhoneNumber" && phoneExists) {
      setPhoneExists(false);
    }
    if (name === "pin_code" && value.length < 6) {
      setPincodeExists(false);
      setErrors((prevErrors) => ({
        ...prevErrors,
        Pincode: "",
      }));
    }
    if (name === "pin_code" && value.length === 6) {
      // Fetch data based on pin code
      try {
        handleOpen();
        const responseDelivery = await fetch(`${API_BASE_URL}pincode/${value}`);
        const dataDelivery = await responseDelivery.json();
        // console.log(dataDelivery[0]?.Pincode);
        if (
          dataDelivery &&
          dataDelivery[0] &&
          dataDelivery[0]?.Pincode === parseInt(value)
        ) {
          setPincodeExists(false);
          setErrors((prevErrors) => ({
            ...prevErrors,
            Pincode: "",
          }));
          const response = await fetch(
            `https://api.postalpincode.in/pincode/${value}`
          );

          const data = await response.json();
          if (response.ok && data.length > 0) {
            // Update state, district, location based on API response
            const { State, District, Division } = data[0].PostOffice[0];
            updateFinalData({
              state_id: getStateId(State),
              district: District,
              city: Division,
            });
            handleClose();
          } else {
            // Show error message if pin code is invalid
            handleClose();
            Swal.fire({
              title: "Invalid Pin Code",
              text: "Please enter a valid pin code.",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        } else {
          setPincodeExists(true);
          setErrors((prevErrors) => ({
            ...prevErrors,
            Pincode: "Delivery at this pincode is not available",
          }));
          handleClose();
          return;
        }
      } catch (error) {
        handleClose();
        console.error("Error fetching data:", error);
        // Show error message if API call fails
        Swal.fire({
          title: "Error",
          text: "An unexpected error occurred. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleEnterKey = (e, nextInputId) => {
    if (e.key === "Enter") {
      const nextInput = document.getElementById(nextInputId);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleSaveAddressType = (addressType) => {
    setSelectedAddressType(addressType);
    updateFinalData({ ["save_address"]: addressType });
  };

  const handleMakeDefault = (value) => {
    setMakeDefault(value);
    updateFinalData({ ["is_default"]: value });
  };

  const handleSubmit = (e) => {
    handleOpen();
    e.preventDefault(); // Prevent the default form submission behavior

    // Compare sendAddress with finalData to find the changed fields
    const changedFields = {};
    for (const key in finalData) {
      if (finalData[key] !== sendAddress[key]) {
        changedFields[key] = finalData[key];
      }
    }
    if (Object.keys(changedFields).length === 0) {
      // No changes detected
      setEditAddressIndex(null); // Close the edit form
      return; // Exit early
    }

    // Dispatch the modifyAddressAsync action with the changedFields
    const addressData = {
      data: {
        // UserID: userDetail.UserID,
        ...changedFields,
      },
      shippingID: finalData.shippingID,
    };

    dispatch(modifyAddressAsync(addressData))
      .then(() => {
        // Address added successfully, you can handle the success here
        dispatch(fetchAllAddressAsync(userDetail?.UserID));
        setEditAddressIndex(null);
        handleClose();
        Swal.fire({
          title: "Address Updated Successfully",
          text: "Address updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
      })
      .catch((error) => {
        handleClose();
        Swal.fire({
          title: "Address not updated",
          text: "Please try again",
          icon: "error",
          confirmButtonText: "OK",
        });
        // Handle the error if the address addition fails
        console.error("Error adding address:", error);
      });
  };
  return (
    <div className="mobile-edit-address-main-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <h3 className="mobile-address-page-name">Edit Details</h3>
      <form
        className="mobile-edit-address-edit-detail-form"
        onSubmit={handleSubmit}
      >
        <fieldset
          className="mobile-input-fieldset"
          style={{
            border:
              !finalData?.pin_code || finalData?.pin_code?.length < 6
                ? "2px solid rgba(0, 0, 0, 0.5)"
                : finalData?.pin_code &&
                  finalData?.pin_code?.length === 6 &&
                  !pincodeExists &&
                  !errors["Pincode"]
                ? "2px solid var(--color-green)"
                : "2px solid red",
          }}
        >
          <legend className="mobile-input-legend">
            Pincode&nbsp;<b>*</b>
          </legend>
          <input
            className="mobile-input-type-write"
            type="number"
            onKeyPress={(e) => {
              // Allow only numbers (0-9) and backspace (8)
              if (!/[0-9\b]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            name="pin_code"
            value={finalData.pin_code || ""}
            placeholder="Enter your pincode "
            id="pincodeInput"
            onChange={handleInputChange}
            required
            pattern="\d{6}"
            maxLength="6"
            onInput={(e) => {
              // Limit input to 10 digits
              if (e.target.value.length > 6) {
                e.target.value = e.target.value.slice(0, 6);
              }
            }}
          />
          {errors["Pincode"] && finalData?.Email && (
            <span
              className="mobile-error-message"
              style={{ position: "absolute", top: "5.2vh", color: "red" }}
            >
              {errors["Pincode"]}
            </span>
          )}
        </fieldset>
        <fieldset className="mobile-input-fieldset">
          <legend className="mobile-input-legend">
            First name&nbsp;<b>*</b>
          </legend>
          <input
            className="mobile-input-type-write"
            type="text"
            name="FirstName"
            value={finalData.FirstName || ""}
            placeholder="Enter your first name"
            onKeyDown={(e) => handleEnterKey(e, "secondNameInput")}
            onChange={handleInputChange}
            required
          />
        </fieldset>
        <fieldset className="mobile-input-fieldset">
          <legend className="mobile-input-legend">
            Second name&nbsp;<b>*</b>
          </legend>
          <input
            className="mobile-input-type-write"
            type="text"
            name="LastName"
            value={finalData.LastName || ""}
            placeholder="Enter your second name"
            id="secondNameInput"
            onKeyDown={(e) => handleEnterKey(e, "emailInput")}
            onChange={handleInputChange}
            required
          />
        </fieldset>
        <fieldset className="mobile-input-fieldset">
          <legend className="mobile-input-legend">
            Email id&nbsp;<b>*</b>
          </legend>
          <input
            className="mobile-input-type-write"
            type="email"
            name="Email"
            value={finalData.Email || ""}
            placeholder="Enter your email id"
            id="emailInput"
            onKeyDown={(e) => handleEnterKey(e, "phoneInput")}
            onChange={handleInputChange}
            required
            pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
          />
        </fieldset>
        <fieldset className="mobile-input-fieldset">
          <legend className="mobile-input-legend">
            Phone number&nbsp;<b>*</b>
          </legend>
          <input
            className="mobile-input-type-write"
            type="number"
            onKeyPress={(e) => {
              // Allow only numbers (0-9) and backspace (8)
              if (!/[0-9\b]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            name="PhoneNumber"
            value={finalData.PhoneNumber || ""}
            placeholder="Enter your phone number"
            id="phoneInput"
            maxLength="10"
            onKeyDown={(e) => handleEnterKey(e, "genderInput")}
            onChange={handleInputChange}
            required
            pattern="\d{10}"
            onInput={(e) => {
              // Limit input to 10 digits
              if (e.target.value.length > 10) {
                e.target.value = e.target.value.slice(0, 10);
              }
            }}
          />
        </fieldset>
        <fieldset className="mobile-input-fieldset">
          <legend className="mobile-input-legend">
            Address 1&nbsp;<b>*</b>
          </legend>
          <input
            className="mobile-input-type-write"
            type="text"
            name="address_1"
            value={finalData.address_1 || ""}
            placeholder="Address line 1"
            id="address1Input"
            onKeyDown={(e) => handleEnterKey(e, "address2Input")}
            onChange={handleInputChange}
            required
          />
        </fieldset>
        <fieldset className="mobile-input-fieldset">
          <legend className="mobile-input-legend">Address 2</legend>
          <input
            className="mobile-input-type-write"
            type="text"
            name="address_2"
            value={finalData.address_2 || ""}
            placeholder="Address line 2"
            id="address2Input"
            onKeyDown={(e) => handleEnterKey(e, "cityInput")}
            onChange={handleInputChange}
          />
        </fieldset>
        <fieldset className="mobile-input-fieldset">
          <legend className="mobile-input-legend">
            City&nbsp;<b>*</b>
          </legend>
          <input
            className="mobile-input-type-write"
            type="text"
            name="city"
            value={finalData.city || ""}
            placeholder="Enter your city name"
            id="cityInput"
            onKeyDown={(e) => handleEnterKey(e, "districtInput")}
            onChange={handleInputChange}
            required
          />
        </fieldset>
        <fieldset className="mobile-input-fieldset">
          <legend className="mobile-input-legend">
            District&nbsp;<b>*</b>
          </legend>
          <input
            className="mobile-input-type-write"
            type="text"
            name="district"
            value={finalData.district || ""}
            placeholder="Enter your district name"
            id="districtInput"
            onKeyDown={(e) => handleEnterKey(e, "state_id")}
            onChange={handleInputChange}
            required
          />
        </fieldset>
        <fieldset className="mobile-input-fieldset">
          <legend className="mobile-input-legend">
            State&nbsp;<b>*</b>
          </legend>
          <select
            className="mobile-select-state-select"
            id="state_id"
            name="state_id"
            value={finalData?.state_id}
            onKeyDown={(e) => handleEnterKey(e, "pincodeInput")}
            onChange={handleInputChange}
            required
          >
           <option unselectable="">select State</option>
                <option value="1">Andhra Pradesh</option>
                <option value="2">Arunachal Pradesh</option>
                <option value="3">Assam</option>
                <option value="4">Bihar</option>
                <option value="5">Chhattisgarh</option>
                <option value="29">Chandigarh</option>
                <option value="30">Delhi</option>
                <option value="6">Goa</option>
                <option value="7">Gujarat</option>
                <option value="8">Haryana</option>
                <option value="9">Himachal Pradesh</option>
                <option value="32">Jammu and Kashmir</option>
                <option value="10">Jharkhand</option>
                <option value="11">Karnataka</option>
                <option value="12">Kerala</option>
                <option value="31">Ladakh</option>
                <option value="13">Madhya Pradesh</option>
                <option value="14">Maharashtra</option>
                <option value="15">Manipur</option>
                <option value="16">Meghalaya</option>
                <option value="17">Mizoram</option>
                <option value="18">Nagaland</option>
                <option value="19">Odisha</option>
                <option value="20">Punjab</option>
                <option value="21">Rajasthan</option>
                <option value="22">Sikkim</option>
                <option value="23">Tamil Nadu</option>
                <option value="24">Telangana</option>
                <option value="25">Tripura</option>
                <option value="26">Uttar Pradesh</option>
                <option value="27">Uttarakhand</option>
                <option value="28">West Bengal</option>
          </select>
        </fieldset>
        <div className="mobile-edit-save-address-as">
          <h3>Save address as</h3>
          <div className="mobile-edit-save-address-option">
            <span
              onClick={() => handleSaveAddressType("Dealership")}
              className={
                selectedAddressType === "Dealership" ? "mobile-type-active" : ""
              }
            >
              Dealership
            </span>
            <span
              onClick={() => handleSaveAddressType("Office")}
              className={
                selectedAddressType === "Office" ? "mobile-type-active" : ""
              }
            >
              Office
            </span>
            <span
              onClick={() => {
                setCustomAddressOpen(true);
                setSelectedAddressType(null);
              }}
              id="fetch-others"
              className={
                selectedAddressType !== "Office" &&
                selectedAddressType !== "Dealership"
                  ? "mobile-type-active"
                  : ""
              }
            >
              Others
            </span>
            {customAddressOpen && (
              <div className="mobile-edit-detail-form-2-please-specify-default-div">
                <input
                  type="text"
                  placeholder="Please specify"
                  className={`mobile-edit-please-specify-default-input  ${
                    !customAddressType ? "specify-error" : ""
                  }`}
                  value={customAddressType}
                  onChange={handleCustomAddressTypeChange}
                />
                <span
                  className="mobile-edit-detail-specify-default-btn"
                  onClick={() => handleSaveAddressTypeOk(customAddressType)}
                >
                  Ok
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="mobile-edit-detail-form-2-default-address">
          <fieldset className="mobile-edit-make-default-container">
            <legend
              className="mobile-input-legend"
              style={{ fontWeight: "700", marginLeft: "-2vh", fontSize: "4vw" }}
            >
              Make address default
            </legend>
            <div className="mobile-detail-default-address-div">
              <input
                type="radio"
                name="is_default"
                value="yes"
                checked={finalData?.is_default === "yes"}
                onChange={() => handleMakeDefault("yes")}
              />
              <span>Yes</span>
            </div>
            <div className="mobile-detail-default-address-div">
              <input
                type="radio"
                name="is_default"
                checked={finalData?.is_default === "no"}
                value={"no"}
                onChange={() => handleMakeDefault("no")}
              />
              <span>No</span>
            </div>
          </fieldset>
        </div>
        <button className="mobile-address-submit-btn" type="submit">
          Save
        </button>
        <button
          className="mobile-address-cancel-btn"
          type="cancel"
          onClick={() => setEditAddressIndex(null)}
        >
          Cancel
        </button>
      </form>
    </div>
  );
}

export default MobileEditContactDetail;
