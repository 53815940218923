import React, { useEffect } from "react";
import "./MobileShopByRating.css";
import { connect, useDispatch } from "react-redux";
import { setRating } from "../../../store/slices/allFilterSlice";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { setRatings } from "../../../store/slices/listingPageFilterSlice";
import StarIcon from "@mui/icons-material/Star";

function MobileShopByRating({ ratings, setRatings }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (ratings) {
      dispatch(setRating(parseInt(ratings)));
    }
  }, [dispatch, ratings]);

  const handleRatingChange = (value) => {
    if (ratings === value) {
      setRatings("");
    } else {
      setRatings(value);
    }
  };
  return (
    <div className="mobile-shopby-ratings-container">
      <span className="mobile-shopby-ratings-header">Shop by Ratings</span>
      <div className="mobile-shopby-ratings-main-div">
        {[4, 3, 2, 1].map((value) => (
          <span
            key={value}
            className={`mobile-shopby-ratings-each-rating ${
              ratings === value ? "mobile-ratings-selected" : ""
            }`}
            style={ratings === value ? { paddingRight: "6px" } : {}}
            onClick={() => handleRatingChange(value)}
          >
            {value}<StarIcon style={{color:"goldenrod"}}/> and Above{" "}
            {ratings === value ? (
              <CloseSharpIcon onClick={() => handleRatingChange(value)} />
            ) : null}
          </span>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ratings: state.listingPageFilter.ratings,
});

const mapDispatchToProps = {
  setRatings,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileShopByRating);
