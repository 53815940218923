import React, { useState } from "react";
import "./MobileCartBreadcrum.css";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PaymentIcon from "@mui/icons-material/Payment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector } from "react-redux";
import { selectSelectedAddress } from "../../features/cartPage/addressSlice";
import Swal from "sweetalert2";
import { selectAllCarts } from "../../features/cartPage/cartPageSlice";

function MobileCartBreadcrum({ currentStep, setCurrentStep }) {
  const selectedAddress = useSelector(selectSelectedAddress);
  const cart = useSelector(selectAllCarts);
  const steps = [
    { label: "Cart", icon: <ShoppingCartOutlinedIcon /> },
    { label: "Address", icon: <LocationOnOutlinedIcon /> },
    { label: "Payment", icon: <PaymentIcon /> },
  ];
  // const [currentStep, setCurrentStep] = useState(1);
  const [complete, setComplete] = useState(false);

  const handleStepClick = (stepIndex) => {
    if (stepIndex <= currentStep || complete) {
      setCurrentStep(stepIndex);
    }
  };
  const handleStepClicksPrev = (stepIndex) => {
    setCurrentStep(stepIndex);
  };
  const handleStepClicks = (stepIndex) => {
    if (currentStep < 2) {
      if (cart && cart?.length <= 0) {
        Swal.fire({
          icon: "warning",
          title: "Please add items in cart",
          text: "You need to add an items before proceeding.",
          confirmButtonText: "OK",
        });
      } else {
        setCurrentStep(stepIndex + 1);
      }
    } else if (currentStep === 2 && selectedAddress !== null) {
      setCurrentStep(3);
    } else if (currentStep === 2 && selectedAddress === null) {
      Swal.fire({
        icon: "warning",
        title: "Please add an address",
        text: "You need to add an address before proceeding.",
        confirmButtonText: "OK",
      });
    }
  };
  return (
    <div className="mobile-breadcrum-container">
      {steps?.map((step, i) => (
        <div
          key={i}
          className={`mobile-step-item ${
            currentStep === i + 1 && "mobile-active"
          } ${(i + 1 < currentStep || complete) && "mobile-complete"} `}
          onClick={() => handleStepClick(i + 1)}
        >
          <div className="mobile-step">{step.icon}</div>
          {/* <p className="mobile-text-gray">{step.label}</p> */}
        </div>
      ))}
      {currentStep > 1 && (
        <div
          className="mobile-breadcrum-prev-state"
          onClick={() =>
            currentStep > 1 && handleStepClicksPrev(currentStep - 1)
          }
        >
          <ArrowBackIosIcon />
        </div>
      )}
      {currentStep < 3 && (
        <div
          className="mobile-breadcrum-next-state"
          onClick={() => currentStep < 3 && handleStepClicks(currentStep)}
        >
          <ArrowForwardIosIcon />
        </div>
      )}
      <span className="mobile-breadcrum-current-step-name">
        {steps[currentStep - 1]?.label}
      </span>
    </div>
  );
}

export default MobileCartBreadcrum;
