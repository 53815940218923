import React, { useEffect, useState } from "react";
import "./MobileListingPage.css";
import MobileSubCategory from "./MobileSubCategory";
import MobileSortByFilter from "./MobileSortByFilter";
import MobilePdts from "./MobilePdts";
import MobileRelatedSearch from "./MobileRelatedSearch";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCategoryProductsAsync,
  fetchAllSubCategoryProductsAsync,
  fetchThroughBrandAsync,
  selectAllCategoryProducts,
  selectAllSubCategories,
  setSubCategories,
} from "../../features/listingPage/listingPageSlice";
import {
  selectedSelectedSubcategory,
  setSelectedSubcategory,
} from "../../store/slices/subCategorySlice";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";
import MobileCtgProduct from "./MobileCtgProduct";

function MobileListingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { CategoryID, categoryName } = useParams();
  const subCategories = useSelector(selectAllSubCategories);
  const selectedSubcategory = useSelector(selectedSelectedSubcategory);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const searchCnt = useSelector(selectAllCategoryProducts);
  const searchedContent = searchCnt.Data;
  const [cat, setCat] = useState([
    (searchedContent && searchedContent.Categories) || [],
  ]);
  const [subCat, setSubCat] = useState([
    (searchedContent && searchedContent.SubCategories) || [],
  ]);
  const brandname = useParams(); // Fetch brand name from URL
  // Convert Category name to Uppercase
  const formattedName = (name) => {
    return name?.charAt(0).toUpperCase() + name?.slice(1).toLowerCase();
  };

  useEffect(() => {
    setCat((searchedContent && searchedContent.Categories) || []);
    setSubCat((searchedContent && searchedContent.SubCategories) || []);
  }, [searchedContent]);

  const handleCatNavigate = (data) => {
    navigate(`/listing-page/${data.id}/${data.name}`);
    dispatch(fetchAllCategoryProductsAsync(data.id));
  };
  const handleSubCatNavigate = (data) => {
    navigate(`/listing-page/${data.CategoryID}/${data.category_name}`);
    dispatch(setSelectedSubcategory(data.id));
    dispatch(fetchAllSubCategoryProductsAsync(data.id));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (brandname?.brand) {
        handleOpen();
        // console.log(open);
        await dispatch(fetchThroughBrandAsync(brandname.brand));
        await dispatch(setSubCategories([]));
        await dispatch(setSelectedSubcategory(null));
        await dispatch(
          setBreadcrumb({
            categoryID: null,
            categoryName: brandname.brand,
            subCategoryID: null,
            subCategoryName: "",
            productName: "",
            ProductID: null,
          })
        );
      } else if (
        selectedSubcategory ||
        (subCategories && subCategories?.length > 0)
      ) {
        handleOpen();
        // console.log(open);
        const isSubCategorySelected =
          subCategories &&
          subCategories?.some((item) => item.sc_id === selectedSubcategory);

        if (isSubCategorySelected) {
          await dispatch(fetchAllSubCategoryProductsAsync(selectedSubcategory));
        } else {
          await dispatch(fetchAllCategoryProductsAsync(CategoryID));
        }
      } else {
        handleOpen();
        // console.log(open);
        await dispatch(fetchAllCategoryProductsAsync(CategoryID));
      }
      handleClose();
      // console.log(open);
    };
    fetchData();
    return () => clearTimeout(2000);
  }, [
    dispatch,
    CategoryID,
    categoryName,
    brandname.brand,
    selectedSubcategory,
    // subCategories,
  ]);

  const [currentPage, setCurrentPage] = useState(1); // SETUP FOR PAGINATION
  return (
    <div className="mobile-listing-page-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mobile-listing-page-ctg-product-container">
        <MobileCtgProduct categoryName={categoryName}/>
      </div>
      {/* <div className="mobile-listing-page-subCat-container">
        <MobileSubCategory
          categoryName={categoryName}
          CategoryID={CategoryID}
        />
      </div> */}
      <div className="mobile-listing-page-sortby-filter-container">
        <MobileSortByFilter
          categoryName={categoryName}
          CategoryID={CategoryID}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div className="mobile-listing-page-product-container">
        <MobilePdts currentPage={currentPage} setCurrentPage={setCurrentPage}/>
      </div>
      <div className="mobile-listing-page-related-search-container">
        <MobileRelatedSearch />
      </div>
    </div>
  );
}

export default MobileListingPage;
