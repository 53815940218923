import React, { useState, useRef, useEffect } from "react";
import "./MobileDefaultContact.css";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import addressHome from "../../img/address-home.png";
import addressTick from "../../img/address-ticks.png";
import addressDefault from "../../img/address-default.png";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  fetchAllAddressAsync,
  selectAllAddress,
  removeAddressAsync,
  setSelectedAddress,
  selectSelectedAddress,
  modifyAddressAsync,
} from "../../features/cartPage/addressSlice";
import { selectUser } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MobileEditContactDetail from "./MobileEditContactDetail";
import MobileContactDetail from "./MobileContactDetail";

function MobileDefaultContact() {
  const dispatch = useDispatch();
  const addresses = useSelector(selectAllAddress);
  const selectedAddress = useSelector(selectSelectedAddress);
  // console.log(addresses);
  const userDetail = useSelector(selectUser);
  const [address, setAddress] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [openedAddressIndex, setOpenedAddressIndex] = useState(null);
  const [isDefault, setIsDefault] = useState(false);
  const [openClick, setOpenClick] = useState(false);
  // const [selectedAddress, setSelectedAddress] = useState(null);
  const openClickRef = useRef(null);
  const [sendAddress, setSendAddress] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const [editAddressIndex, setEditAddressIndex] = useState(null);

  const handleEditAddress = (index, address) => {
    setEditAddressIndex(index);
    setSendAddress(address);
  };

  useEffect(() => {
    if (userDetail?.UserID) {
      dispatch(fetchAllAddressAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail]);

  useEffect(() => {
    if (addresses && addresses.length > 0) {
      const defaultAddress = addresses.find((addr) => addr.is_default === 1);
      dispatch(
        setSelectedAddress(defaultAddress ? defaultAddress.shippingID : null)
      );
    }
  }, [addresses]);

  // Function to find state name by state_id
  const getStateNameById = (stateId) => {
    switch (stateId) {
      case "1":
        return "Andhra Pradesh";
      case "2":
        return "Arunachal Pradesh";
      case "3":
        return "Assam";
      case "4":
        return "Bihar";
      case "5":
        return "Chhattisgarh";
      case "6":
        return "Goa";
      case "7":
        return "Gujarat";
      case "8":
        return "Haryana";
      case "9":
        return "Himachal Pradesh";
      case "10":
        return "Jharkhand";
      case "11":
        return "Karnataka";
      case "12":
        return "Kerala";
      case "13":
        return "Madhya Pradesh";
      case "14":
        return "Maharashtra";
      case "15":
        return "Manipur";
      case "16":
        return "Meghalaya";
      case "17":
        return "Mizoram";
      case "18":
        return "Nagaland";
      case "19":
        return "Odisha";
      case "20":
        return "Punjab";
      case "21":
        return "Rajasthan";
      case "22":
        return "Sikkim";
      case "23":
        return "Tamil Nadu";
      case "24":
        return "Telangana";
      case "25":
        return "Tripura";
      case "26":
        return "Uttar Pradesh";
      case "27":
        return "Uttarakhand";
      case "28":
        return "West Bengal";
      default:
        return ""; // Return empty string for unknown state_id
    }
  };

  const handleAddAddress = () => {
    setAddAddress(true);
  };
  const handleRemoveAddress = (shippingID) => {
    // console.log(shippingID);
    dispatch(removeAddressAsync(shippingID)).then(() => {
      dispatch(fetchAllAddressAsync(userDetail?.UserID));
    });
  };

  const handleAddress = () => {
    setAddress(true);
    setAddAddress(true);
  };
  const handleOutsideClick = (event) => {
    if (openClickRef.current && !openClickRef.current.contains(event.target)) {
      setOpenClick(false);
      if (openedAddressIndex !== null) {
        handleOpenThreeDots(null);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOpenThreeDots = (index) => {
    if (openedAddressIndex === index) {
      setOpenedAddressIndex(null); // Close the opened container if clicked again
    } else {
      setOpenedAddressIndex(index); // Open the clicked container
    }
  };

  const handleSelectAddress = (shippingID) => {
    dispatch(setSelectedAddress(shippingID));
  };

  const handleModifyAddress = (address) => {
    handleOpen();
    if (address.is_default === 1) {
      const addressData = {
        data: {
          is_default: "no",
        },
        shippingID: address.shippingID,
      };

      dispatch(modifyAddressAsync(addressData))
        .then(() => {
          // Address added successfully, you can handle the success here
          dispatch(fetchAllAddressAsync(userDetail?.UserID));
          setIsDefault(!isDefault);
          handleClose();
          handleOpenThreeDots(null);
          Swal.fire({
            title: "Make Undefault Successfully",
            text: "Make Undefault Successfully",
            icon: "success",
            confirmButtonText: "OK",
          });
        })
        .catch((error) => {
          handleClose();
          Swal.fire({
            title: "Default not working",
            text: "Please try again",
            icon: "error",
            confirmButtonText: "OK",
          });
          // Handle the error if the address addition fails
          console.error("Error adding address:", error);
        });
    } else {
      const addressData = {
        data: {
          is_default: "yes",
        },
        shippingID: address.shippingID,
      };

      dispatch(modifyAddressAsync(addressData))
        .then(() => {
          // Address added successfully, you can handle the success here
          dispatch(fetchAllAddressAsync(userDetail?.UserID));
          setIsDefault(!isDefault);
          handleClose();
          handleOpenThreeDots(null);
          Swal.fire({
            title: "Make Default Successfully",
            text: "Make default Successfully",
            icon: "success",
            confirmButtonText: "OK",
          });
        })
        .catch((error) => {
          handleClose();
          Swal.fire({
            title: "Default not working",
            text: "Please try again",
            icon: "error",
            confirmButtonText: "OK",
          });
          // Handle the error if the address addition fails
          console.error("Error adding address:", error);
        });
    }
  };
  return (
    <div className="mobile-default-contact-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <h3>Shipping Address</h3>
      {(!addresses || addresses?.length <= 0) && !address ? (
        <div
          className="mobile-add-new-address-container"
          onClick={handleAddress}
        >
          <AddOutlinedIcon />
          <span>Add new address</span>
        </div>
      ) : (
        <>
          {!addAddress ? (
            <>
              <div className="mobile-add-new-address-again">
                <AddOutlinedIcon />
                <span onClick={handleAddAddress}>Add Address</span>
              </div>
              <div className="mobile-saved-addresses-container">
                {addresses &&
                  addresses.length > 0 &&
                  addresses?.map((address) => (
                    <div
                      className={`mobile-saved-address-div-container ${
                        address.shippingID === 1 && "address-default-active"
                      } ${
                        selectedAddress === address.shippingID &&
                        "address-selected-active"
                      }`}
                      key={address.shippingID}
                    >
                      <div className="mobile-make-default-radio">
                        <input
                          type="radio"
                          value={address.shippingID}
                          onChange={() =>
                            handleSelectAddress(address.shippingID)
                          }
                          checked={selectedAddress === address.shippingID}
                        />
                      </div>
                      <div className="mobile-address-three-dots">
                        <MoreHorizOutlinedIcon
                          onClick={() =>
                            handleOpenThreeDots(address.shippingID)
                          }
                        />
                        {openedAddressIndex === address.shippingID && (
                          <div
                            className="mobile-three-dots-click-open-div"
                            ref={openClickRef}
                          >
                            <div className="mobile-three-dots-edit-div">
                              <BorderColorOutlinedIcon
                                onClick={() => {
                                  handleOpenThreeDots(address.shippingID);
                                  handleEditAddress(address.shippingID);
                                }}
                              />
                              <span
                                onClick={() => {
                                  handleOpenThreeDots(address.shippingID);
                                  handleEditAddress(
                                    address.shippingID,
                                    address
                                  );
                                }}
                              >
                                Edit
                              </span>
                            </div>
                            <div className="mobile-three-dots-delete-div">
                              <DeleteIcon
                                onClick={() => {
                                  handleRemoveAddress(address.shippingID);
                                  handleOpenThreeDots(address.shippingID);
                                }}
                              />
                              <span
                                onClick={() => {
                                  handleRemoveAddress(address.shippingID);
                                  handleOpenThreeDots(address.shippingID);
                                }}
                              >
                                Delete
                              </span>
                            </div>
                            <div className="mobile-three-dots-default-div">
                              <span>Default</span>
                              <FormControlLabel
                                value="default"
                                control={
                                  <Switch
                                    color="primary"
                                    checked={address.is_default === 1}
                                    onChange={() =>
                                      handleModifyAddress(address)
                                    }
                                  />
                                }
                                labelPlacement="start"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="mobile-address-level-1">
                        <div className="mobile-address-level-1-img-div">
                          <img
                            className={`mobile-address-home ${
                              address.shippingID === 1 &&
                              "mobile-address-default-active"
                            } ${
                              selectedAddress === address.shippingID &&
                              "mobile-address-selected-active"
                            }`}
                            src={addressHome}
                            alt=""
                          />
                        </div>
                        <span className="mobile-address-type">
                          My {address.save_address}
                        </span>
                        {address.is_default === 1 && (
                          <div className="mobile-address-default">
                            <img src={addressDefault} alt="" />
                            <span>Default</span>
                          </div>
                        )}
                      </div>
                      <div className="mobile-address-level-2">
                        <p className="mobile-address-local-address">
                          {address.FirstName} {address.LastName},{" "}
                          {address.address_1} {address.address_2},{address.city}{" "}
                          {address.district} {address.pin_code},
                          {getStateNameById(address.state_id)}
                        </p>
                        <p className="mobile-address-mob-no">
                          +91-{address.PhoneNumber}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
              {editAddressIndex !== null && (
                <MobileEditContactDetail
                  setEditAddressIndex={setEditAddressIndex}
                  sendAddress={sendAddress}
                />
              )}
            </>
          ) : (
            <MobileContactDetail setAddAddress={setAddAddress} />
          )}
        </>
      )}
    </div>
  );
}

export default MobileDefaultContact;
