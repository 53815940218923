import React, { useState, useRef, useCallback, useEffect } from "react";
import "./MobileContactInfo.css";
import CheckIcon from "@mui/icons-material/Check";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import { API_BASE_URL } from "../../../config";
import aadharLogo from "../../../img/banners/aadhar-logo.png";
import panLogo from "../../../img/banners/pan-card-logo.png";
import gstLogo from "../../../img/banners/gst-logo.png";
import certificateLogo from "../../../img/banners/certificate-icon.png";
import CloseIcon from "@mui/icons-material/Close";

function MobileContactInfo({
  updateFinalData,
  finalData,
  emailVerify,
  setEmailVerify,
  phoneVerify,
  setPhoneVerify,
  tempEmail,
  setTempEmail,
}) {
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef(
    Array.from({ length: 6 }).map(() => React.createRef())
  );
  const [verify, setVerify] = useState(false);
  const [errors, setErrors] = useState({});
  const [emailExists, setEmailExists] = useState(false);
  // const [tempEmail, setTempEmail] = useState(null);
  const [timer, setTimer] = useState(60);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const startTimer = () => {
    setResendDisabled(true);
    setTimer(60);
  };

  // Effect to decrement timer every second
  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setResendDisabled(false);
      clearInterval(intervalId);
    }
    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, [timer]);

  // EMAIL VALIDATION
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  // INPUT CHANGE
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update final data with the input value

    updateFinalData({ [name]: value });

    // Reset emailExists state and clear Email error if name is Email and emailExists is true
    if (name === "Email" && emailExists) {
      setEmailExists(false);
      setErrors((prevErrors) => ({
        ...prevErrors,
        Email: "",
      }));
    }
    if (name === "Email" && value.includes("@")) {
      handleEmailBlur(e);
    }
  };

  // SEND OTP TO VERIFY EMAIL
  const handleEmailVerificationClick = async () => {
    setOTP(["", "", "", "", "", ""]);
    const email = finalData.Email;
    if (!email || !email.includes("@")) {
      Swal.fire({
        title: "Please fill email correctly",
        text: "Email is either not filled or not in correct form",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      // if (timer > 0) {
      //   // Timer is still running, do nothing
      //   return;
      // }
      // startTimer();
      handleOpen();
      try {
        const response = await fetch(`${API_BASE_URL}email_auth/${email}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        if (response.ok) {
          // console.log(data);
          setVerify(true);
        } else {
          console.error("API request failed:", response.statusText);
          Swal.fire({
            title: "OTP not Sent",
            text: data.error,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        Swal.fire({
          title: "Error",
          text: "An unexpected error occurred. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
      handleClose();
    }
  };

  // SEND OTP AGAIN TO VERIFY EMAIL
  const handleEmailVerificationAgain = async () => {
    const email = finalData.Email;
    if (!email || !email.includes("@")) {
      Swal.fire({
        title: "Please fill email correctly",
        text: "Email is either not filled or not in correct form",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      if (timer > 0) {
        // Timer is still running, do nothing
        return;
      }
      startTimer();
      handleOpen();
      try {
        const response = await fetch(`${API_BASE_URL}email_auth/${email}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        if (response.ok) {
          Swal.fire({
            title: "OTP Sent Again",
            text: "Please check your phone message box",
            icon: "success",
            confirmButtonText: "OK",
          });
          // console.log(data);
          setVerify(true);
        } else {
          console.error("API request failed:", response.statusText);
          Swal.fire({
            title: "OTP not Sent",
            text: data.error,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        Swal.fire({
          title: "Error",
          text: "An unexpected error occurred. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
      handleClose();
    }
  };

  // CHECK EMAIL EXIST IN DATABASE OR NOT
  const handleEmailBlur = async (e) => {
    const email = e.target.value;
    if (!isValidEmail(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        Email: "Please enter a valid email address",
      }));
      return;
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}singup_email_verify/${email}`
      );
      const data = await response.json();

      if (data.status === "error") {
        setEmailExists(true);
        setErrors((prevErrors) => ({
          ...prevErrors,
          Email: "Email already registered with Cyclecircle",
        }));
      } else {
        setEmailExists(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          Email: "",
        }));
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
    }
  };

  const handleFocus = (index) => {
    // Move the cursor to the last digit if there's already a value entered
    if (index === 5 && otp[index] !== "") {
      inputRefs.current[index].current.setSelectionRange(1, 1);
    }
  };

  const handleKeyDown = (index,value,e) =>{
    if(e.keyCode === 8 && value === "" && index > 0){
      inputRefs.current[index-1].current.focus();
     }
  }

  const handleOTPInputChange = useCallback(
    (index, value,e) => {
      // Accept only numeric values
      //  console.log(value);
      const numericValue = value.replace(/\D/g, "");
  
      const newOTP = [...otp];
      newOTP[index] = numericValue.slice(-1); // Only take the last digit
  
      setOTP(newOTP);
  
      // Move to the previous input when deleting a digit
     //  if(e.inputType === 'deleteContentBackward' && value === "" && index > 0){
     //   inputRefs.current[index-1].current.focus();
     //  }
     // console.log(e.keyCode);
      if ((numericValue === "" || e.keyCode === 8) && index > 0 && inputRefs.current[index - 1]) {
        inputRefs.current[index - 1].current.focus();
      }
      // Move to the next input when entering a digit
      else if (numericValue !== "" && index < 5 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].current.focus();
      }
    },
    [otp]
  );

  // HANDLE OTP PASTE
  const handleOTPPaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData("text/plain");
    const pastedDigits = clipboardData.replace(/\D/g, "").slice(0, 6); // Filter out non-numeric characters and take the first 6 digits
    const newOTP = [...otp];
    pastedDigits.split("").forEach((digit, index) => {
      newOTP[index] = digit;
    });
    setOTP(newOTP);
    inputRefs.current[5].current.focus();
  };

  useEffect(() => {
    if (finalData?.Email !== tempEmail) {
      setEmailVerify(false);
    }
  }, [finalData?.Email, tempEmail, setEmailVerify]);

  // VERIFY OTP
  const handleVerify = async () => {
    handleOpen();
    const enteredOTP = otp.join("");
    if (!enteredOTP || enteredOTP.length < 6) {
      Swal.fire({
        title: "Enter OTP correctly",
        text: "Please Enter OTP correctly",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}email_auth_otp/${finalData?.Email}/${enteredOTP}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          // console.log(data);
          setVerify(false);
          setEmailVerify(true);
          setTempEmail(finalData?.Email);
        } else {
          console.error("API request failed:", response.statusText);
          Swal.fire({
            title: "Invalid OTP",
            text: "Please check and try again",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
      }
      handleClose();
    }
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    updateFinalData({ ReceiveWhatsApp: checked });
  };
  return (
    <div className="mobile-contact-info-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!emailVerify && (
        <div className="mobile-contact-info-prerequisites-info">
          <span className="mobile-contact-info-prerequisites-heading">
            To sign up,you'll need these prerequisites.
          </span>
          <div className="mobile-contact-info-prerequisites-ul">
            <div className="mobile-contact-info-each-li">
              <img src={aadharLogo} alt="" className="mobile-contact-info-each-img" />
              <span className="mobile-contact-info-each-span">Aadhar Card</span>
            </div>
            <div className="mobile-contact-info-each-li">
              <img src={panLogo} alt="" className="mobile-contact-info-each-img" />
              <span className="mobile-contact-info-each-span">PAN Card</span>
            </div>
            <div className="mobile-contact-info-each-li">
              <img src={gstLogo} alt="" className="mobile-contact-info-each-img" />
              <span className="mobile-contact-info-each-span">GST</span>
            </div>
            {/* <div className="mobile-contact-info-each-li">
              <img
                src={certificateLogo}
                alt=""
                className="mobile-contact-info-each-img"
              />
              <span className="mobile-contact-info-each-span">
                Certificate/License for Shop
              </span>
            </div> */}
          </div>
        </div>
      )}
      {/* <span className="mobile-contact-info-heading">Contact Information</span> */}
      <span className="mobile-contact-info-text">
        We will contacting you on the below details for any account related
        updates
      </span>

      <fieldset
        className={`mobile-input-fieldset ${
          !errors["Email"] &&
          !emailExists &&
          finalData.Email &&
          isValidEmail(finalData.Email)
            ? "valid"
            : (errors["Email"] || emailExists) && isValidEmail(finalData.Email)
            ? "error"
            : ""
        }`}
      >
        <legend className="mobile-input-legend">
          Email&nbsp;<b>*</b>
        </legend>
        <input
          className="mobile-input-type-write"
          type="email"
          name="Email"
          value={finalData?.Email || ""}
          onChange={handleInputChange}
          onBlur={handleEmailBlur}
          placeholder="Enter your email id"
          id="emailInput"
          required
          pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
        />
      </fieldset>
      {errors["Email"] && finalData?.Email && (
        <span className="error-message">{errors["Email"]}</span>
      )}
      {!emailVerify && finalData?.Email && finalData.Email.includes("@") && (
        <div className="mobile-email-verification-container">
          <span
            className="mobile-email-verification-verify-btn"
            onClick={handleEmailVerificationClick}
          >
            Verify
          </span>
          <div className="mobile-email-verification-text-div">
            <span className="mobile-verify-symbol">!</span>
            <span className="mobile-email-verification-text">
              Please verify to proceed
            </span>
          </div>
        </div>
      )}
      {emailVerify && (
        <div className="mobile-phone-number-verified-check">
          <CheckIcon />
          <span className="mobile-phone-number-verified-span">Verified</span>
        </div>
      )}

      <fieldset
        className="mobile-input-fieldset"
        style={{borderColor: "rgba(0,0,0,0.4)" }}
      >
        <legend className="mobile-input-legend" style={{ color: "rgba(0,0,0,0.6)" }}>
          Mobile number&nbsp;<b style={{ color: "rgba(0,0,0,0.6)" }}>*</b>
        </legend>
        <input
          className="mobile-input-type-write"
          type="text"
          // style={{color:"rgba(0,0,0,0.6)"}}
          name="PhoneNumber"
          value={finalData?.PhoneNumber || ""}
          placeholder="Enter your Mobile number"
          id="phoneInput"
          readOnly
        />
      </fieldset>
      {phoneVerify && (
        <div className="mobile-phone-number-verified-check">
          <CheckIcon />
          <span className="mobile-phone-number-verified-span">Verified</span>
        </div>
      )}
      <div className="mobile-receive-update-via-whatsapp">
        <input
          type="checkbox"
          onChange={handleCheckboxChange}
          checked={finalData.ReceiveWhatsApp}
        />
        <span className="mobile-receive-update-whatsapp-span">
          Receive updates via WhatsApp
        </span>
      </div>
      {verify && <div className="mobile-signup-overlay"></div>}
      {verify && (
        <div className="mobile-email-verification-overlay-container">
          <div className="mobile-email-verification-upper-heading-container">
            <CheckIcon />
            <span className="mobile-email-verification-upper-heading-span">
              Verification OTP sent
            </span>
          </div>
          <span className="mobile-email-verification-details-text">
            Enter the 6-digit OTP sent to your Email <b>{finalData?.Email}</b>
          </span>
          <div className="mobile-email-verification-otp-enter-input-container">
            <span className="mobile-email-otp-input-legend">Enter OTP</span>
            <div className="mobile-email-verification-otp-input-boxes">
              {otp.map((digit, index) => (
                <input
                  type="number"
                  maxlength="1"
                  oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                  key={index}
                  // maxLength={index === 5 ? 1 : 0}
                  value={digit}
                  onChange={(e) => handleOTPInputChange(index, e.target.value,e)}
                  onKeyDown={(e) => handleKeyDown(index, e.target.value,e)}
                  ref={inputRefs.current[index]}
                  onFocus={() => handleFocus(index)}
                  className={digit ? "filled" : ""}
                  onPaste={handleOTPPaste}
                  required
                />
              ))}
            </div>
            <span
              className="mobile-email-verification-resend-otp"
              onClick={handleEmailVerificationAgain}
            >
              Resend OTP {resendDisabled ? `(${timer}s)` : ""}
            </span>
          </div>
          <span
            className="mobile-email-verification-cancel-btn"
            onClick={() => {
              setVerify(false);
              setTimer(0);
            }}
          >
            <CloseIcon />
          </span>
          <span
            className="mobile-email-verification-confirm-btn"
            onClick={handleVerify}
          >
            Confirm
          </span>
        </div>
      )}
    </div>
  );
}

export default MobileContactInfo;
