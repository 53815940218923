import React, { useState, useEffect, useRef } from "react";
import "../HomePage/OurBrands.css";
import { useNavigate } from "react-router";
import { selectUser } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

function ShopByBrand() {
  // const videoRef = useRef(null);
  const userDetail = useSelector(selectUser);
  const navigate = useNavigate();
  const [hoverBrand, setHoverBrand] = useState("cratoni");
  const [imgIdx, setImgIdx] = useState(0);

  const handleFetchThroughBrands = (brand) => {
    navigate(`/listing-page/${brand}`);
  };
  const handleFetchThroughBrandsImg = (idx) => {
    switch (idx) {
      case 0:
        navigate(`/listing-page/${"cratoni"}`);
        break;
      case 1:
        navigate(`/listing-page/${"moon sports"}`);
        break;
      case 2:
        navigate(`/listing-page/${"funn"}`);
        break;
      case 3:
        navigate(`/listing-page/${"granite"}`);
        break;
      default:
        break;
    }
  };

  const img = [
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_BRAND_LOGO_IMAGE/1711189761694-Brand%20Banner-Cratoni-1.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_BRAND_LOGO_IMAGE/1711189697427-Brand%20Banner-Moon-1.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_BRAND_LOGO_IMAGE/1711189728130-Brand%20Banner-Funn-3.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_BRAND_LOGO_IMAGE/1711189931064-Brand%20Banner-Granite-3.png",
  ];

  const imgHidden = [
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_BRAND_LOGO_IMAGE/1711189761694-Brand%20Banner-Cratoni-1.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_BRAND_LOGO_IMAGE/1711189697427-Brand%20Banner-Moon-1.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_BRAND_LOGO_IMAGE/1711189728130-Brand%20Banner-Funn-3.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_BRAND_LOGO_IMAGE/1711189931064-Brand%20Banner-Granite-3.png",
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      const nextIdx = (imgIdx + 1) % img.length;
      setImgIdx(nextIdx);
      switch (nextIdx) {
        case 0:
          setHoverBrand("cratoni");
          break;
        case 1:
          setHoverBrand("moon");
          break;
        case 2:
          setHoverBrand("funn");
          break;
        case 3:
          setHoverBrand("granite");
          break;
        default:
          break;
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [imgIdx, img.length]);

  const handleHoverBrand = (brand, idx) => {
    setHoverBrand(brand);
    setImgIdx(idx);
  };

  return (
    <div className="our-brands-container">
      <div className="our-exclusive-brands-container">
        <span className="our-exclusive-brands-heading" style={{width:"100%",textAlign:"left",paddingLeft:"2vw"}}>Shop by Brands</span>
        <div className="our-exclusive-brands-top-img-container">
          <img
            src={
              Object.keys(userDetail).length > 0
                ? img[imgIdx]
                : imgHidden[imgIdx]
            }
            alt=""
            className="our-exclusive-brands-top-img"
            onClick={() => handleFetchThroughBrandsImg(imgIdx)}
          />
        </div>
        <div className="our-exclusive-brands-logo-navigator-container">
          <div className="our-exclusive-brands-logo-navigator">
            <div
              className={`our-exclusive-brands-each-brand-container ${
                hoverBrand === "cratoni" && "each-brand-selected"
              }`}
              onMouseEnter={() => handleHoverBrand("cratoni", 0)}
              onClick={() => handleFetchThroughBrands("cratoni")}
            >
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQohbUwx79PkQDbs4YO695jU0OXIAmfqRB-0jAxz05cag&s"
                alt=""
                className={`our-exclusive-brands-each-logo ${
                  hoverBrand === "cratoni"
                } && "img-active"`}
              />
              <span className="our-exclusive-brands-each-brand-what-sell">
                Helmets
              </span>
              <span
                className={`our-exclusive-brands-each-brand-offers ${
                  hoverBrand === "cratoni" && "offers-active"
                }`}
              >
                Upto {Object.keys(userDetail).length > 0 ? "35":<VisibilityOffOutlinedIcon style={{margin:"0 0.2vw",fontSize:"1vw"}}/>}% Dealer Margin
              </span>
              <hr
                className={`our-exclusive-brands-each-brand-line ${
                  hoverBrand === "cratoni" && "hr-active"
                }`}
              />
            </div>
            <div
              className={`our-exclusive-brands-each-brand-container ${
                hoverBrand === "moon" && "each-brand-selected"
              }`}
              onMouseEnter={() => handleHoverBrand("moon", 1)}
              onClick={() => handleFetchThroughBrands("moon sports")}
            >
              <img
                src="https://www.handshake.fi/wp-content/uploads/2018/06/logo-moon.png"
                alt=""
                className={`our-exclusive-brands-each-logo ${
                  hoverBrand === "moon"
                } && "img-active"`}
              />
              <span className="our-exclusive-brands-each-brand-what-sell">
                Lights
              </span>
              <span
                className={`our-exclusive-brands-each-brand-offers ${
                  hoverBrand === "moon" && "offers-active"
                }`}
              >
                Upto {Object.keys(userDetail).length > 0 ? "30":<VisibilityOffOutlinedIcon style={{margin:"0 0.2vw",fontSize:"1vw"}}/>}% Dealer Margin
              </span>
              <hr
                className={`our-exclusive-brands-each-brand-line ${
                  hoverBrand === "moon" && "hr-active"
                }`}
              />
            </div>
            <div
              className={`our-exclusive-brands-each-brand-container ${
                hoverBrand === "funn" && "each-brand-selected"
              }`}
              onMouseEnter={() => handleHoverBrand("funn", 2)}
              onClick={() => handleFetchThroughBrands("funn")}
            >
              <img
                src="https://images.bike24.com/i/mb/0c/67/20/logofunn-3903.jpg"
                alt=""
                className={`our-exclusive-brands-each-logo ${
                  hoverBrand === "funn"
                } && "img-active"`}
              />
              <span className="our-exclusive-brands-each-brand-what-sell">
                Cockpit
              </span>
              <span
                className={`our-exclusive-brands-each-brand-offers ${
                  hoverBrand === "funn" && "offers-active"
                }`}
              >
                Upto {Object.keys(userDetail).length > 0 ? "30":<VisibilityOffOutlinedIcon style={{margin:"0 0.2vw",fontSize:"1vw"}}/>}% Dealer Margin
              </span>

              <hr
                className={`our-exclusive-brands-each-brand-line ${
                  hoverBrand === "funn" && "hr-active"
                }`}
              />
            </div>
            <div
              className={`our-exclusive-brands-each-brand-container ${
                hoverBrand === "granite" && "each-brand-selected"
              }`}
              onMouseEnter={() => handleHoverBrand("granite", 3)}
              onClick={() => handleFetchThroughBrands("granite")}
            >
              <img
                src="https://www.stifmtb.com/cdn/shop/collections/Granite_logo_WH_1200x1200_13e278a3-8114-419c-94c0-0f6c6a0a86cc_1200x1200.jpg?v=1646754378"
                alt=""
                className={`our-exclusive-brands-each-logo ${
                  hoverBrand === "granite"
                } && "img-active"`}
              />
              <span className="our-exclusive-brands-each-brand-what-sell">
                Tools
              </span>
              <span
                className={`our-exclusive-brands-each-brand-offers ${
                  hoverBrand === "granite" && "offers-active"
                }`}
              >
                Upto {Object.keys(userDetail).length > 0 ? "30":<VisibilityOffOutlinedIcon style={{margin:"0 0.2vw",fontSize:"1vw"}}/>}% Dealer Margin
              </span>
              <hr
                className={`our-exclusive-brands-each-brand-line ${
                  hoverBrand === "granite" && "hr-active"
                }`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopByBrand;
