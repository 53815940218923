import React from "react";
import "./ProfileComponent.css";
import MyOrder from "./MyOrder";
import SideNav from "./SideNav";
import { useParams } from "react-router";
import MyProfile from "./MyProfile";
import Security from "./Security";
import Payment from "./Payment";
import Wallet from "./Wallet";
import MyPreOrder from "./MyPreOrder";

function ProfileComponent() {
  const url = useParams();

  return (
    <div className="profile-component-container">
      <SideNav />
      <div className="profile-content">
        {url.profileUrl === "profile" && <MyProfile />}
        {url.profileUrl === "preorders" && <MyPreOrder />}
        {url.profileUrl === "orders" && <MyOrder />}
        {url.profileUrl === "security" && <Security />}
        {url.profileUrl === "payment" && <Payment />}
        {url.profileUrl === "wallet" && <Wallet/>}
      </div>
    </div>
  );
}

export default ProfileComponent;
