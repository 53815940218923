import React, { useState } from "react";
import "./Promises.css";
import Fade from "react-reveal/Fade";
import promise1 from "../../img/parts/promise1.png";
import promise2 from "../../img/parts/promise2.png";
import promise3 from "../../img/parts/promise3.png";
import promise4 from "../../img/parts/promise4.png";
import freeShippingGrey from "../../img/Promisess/Free-shipping-Inactive.jpg";
import freeShippingColored from "../../img/Promisess/Free-shipping-Active.jpg";
import expressShippingGrey from "../../img/Promisess/Express-shipping-Inactive.jpg";
import expressShippingColored from "../../img/Promisess/Express-shipping-Active.jpg";
import bestPriceGrey from "../../img/Promisess/Best-Price-Inactive.jpg";
import bestPriceColored from "../../img/Promisess/Best-Price-Active.jpg";
import hourGrey from "../../img/Promisess/Resolution-Inactive.jpg";
import hourColored from "../../img/Promisess/Resolution-Active.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropTypes from "prop-types"; // Import PropTypes

const PromiseItem = ({ imgSrc, title, description }) => (
  <div className="promiseImg">
    <img className="img" alt={title} src={imgSrc} />
    <div className="frame-2">
      <div className="text-wrapper-2">{title}</div>
      <p className="p">{description}</p>
    </div>
  </div>
);

PromiseItem.propTypes = {
  imgSrc: PropTypes.string.isRequired, // Require imgSrc to be a string and isRequired
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const coloredImages = {
  freeShipping: freeShippingColored,
  expressShipping: expressShippingColored,
  bestPrice: bestPriceColored,
  hour: hourColored,
};

const greyImages = {
  freeShipping: freeShippingGrey,
  expressShipping: expressShippingGrey,
  bestPrice: bestPriceGrey,
  hour: hourGrey,
};

function Promises() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 650,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    vertical: false,
    arrows: false,
    beforeChange: (current, next) => setActiveIndex(next),
  };
  const dummyData = [
    {
      imgSrc: promise1,
      title: "Free shipping",
      description: "We provide you Free Delivery above ₹3000.",
      coloredImage: coloredImages.freeShipping,
      greyImage: greyImages.freeShipping,
    },
    {
      imgSrc: promise2,
      title: "Express shipping",
      description: "Ensuring your items reach you with fast and care.",
      coloredImage: coloredImages.expressShipping,
      greyImage: greyImages.expressShipping,
    },
    {
      imgSrc: promise4,
      title: "24 Hours Issue resolution",
      description:
        "Count on us for swift and efficient resolution within 24 hours.",
      coloredImage: coloredImages.hour,
      greyImage: greyImages.hour,
    },
    {
      imgSrc: promise3,
      title: "Best price",
      description: "You are getting the best prices on top-quality products.",
      coloredImage: coloredImages.bestPrice,
      greyImage: greyImages.bestPrice,
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Fade bottom>
      <div className="our-promisesImg">
        <div className="promisesImg">
          <div className="frameImg">
            <div className="text-wrapper">ELEVATING YOUR EXPERIENCE</div>
            <p className="our-promises-to-your">Why CycleCircle ?</p>
            <div className="in-a-world-of-wrapper">
              <p className="in-a-world-of">
                We understand the everyday problems of your business, we
                understand how unwanted inventory and slow moving stock stifels
                your growth , we understand how the lack of trasparency on
                prices and availability results in loss of sales. We understand
                our partners like no else and thats why we strive to deliver
                value at each step of this value chain. The Cyclecircle promise
                lets you experiment with new product lines , inventory planning
                whereas ensuring your store always has what your customers want
                .
              </p>
            </div>
            <div className="divImg">
              {dummyData.map((data, index) => (
                <PromiseItem key={index} {...data} />
              ))}
            </div>
          </div>
        </div>
        <div className="flower-images">
          <div className="frame-img-3">
            <div className="frame-img-4">
              <img
                className="rectangleImg"
                alt="Rectangle"
                src={
                  dummyData[3][activeIndex === 3 ? "coloredImage" : "greyImage"]
                }
              />
              <img
                className="rectangleImg"
                alt="Rectangle"
                src={
                  dummyData[2][activeIndex === 2 ? "coloredImage" : "greyImage"]
                }
              />
            </div>
            <div className="frame-img-4">
              <img
                className="rectangleImg"
                alt="Rectangle"
                src={
                  dummyData[0][activeIndex === 0 ? "coloredImage" : "greyImage"]
                }
              />
              <img
                className="rectangleImg"
                alt="Rectangle"
                src={
                  dummyData[1][activeIndex === 1 ? "coloredImage" : "greyImage"]
                }
              />
            </div>
          </div>
        </div>
        <div className="div-slider-img">
          <Slider {...settings}>
            {dummyData.map((data, index) => (
              <div className="servicePromices" key={index}>
                <img src={data.imgSrc} alt="" />
                <div className="servicePromices-text">
                  <span className="servicePromices-heading">{data.title}</span>
                  <div className="servicePromices-desc">{data.description}</div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </Fade>
  );
}

export default Promises;
