import React, { useEffect, useRef, useState } from "react";
import "./MobileHeader.css";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import cyclecircleLogo from "../../img/parts/Cycle-Circle-Logo-White-1.png";
import { fetchAllNavigationsAsync } from "../../features/HomePage/homeSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { selectAllCarts } from "../../features/cartPage/cartPageSlice";
import cartOutline from "../../img/navigation/header-cart-outline.png";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import userOutline from "../../img/navigation/header-user-outline.png";
import userFilled from "../../img/navigation/header-user-filled.png";
import MobileNavigation from "./MobileNavigation";
import MobileBigSearch from "./MobileBigSearch";
import MobileSideBar from "./MobileSideBar";
import verticalIcon from "../../img/mobileIcon/Button-Category.png";
import {
  selectIsOverlayVisible,
  toggleOverlay,
} from "../../store/slices/headerSlice";
import MobileBigNavigation from "./MobileBigNavigation";
import { IMAGE_BASE } from "../../config";

function MobileHeader({ isOverlayVisible, toggleOverlay }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userDetail = useSelector(selectUser);
  const carts = useSelector(selectAllCarts);
  const bigNavigationContainerRef = useRef(null);
  const [showNavigation, setShowNavigation] = useState(null);
  const [showVerticalContainer, setShowVerticalContainer] = useState(true);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [bigSearch, setBigSearch] = useState(false);
  const [openBurger, setOpenBurger] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (showSearchInput) {
      inputRef.current.focus();
    }
  }, [showSearchInput]);

  const handleEmptyBreadcrum = () => {
    // dispatch(setBreadcrumb({
    //   categoryID: null,
    //   categoryName: "",
    //   subCategoryID: null,
    //   subCategoryName: "",
    //   ProductID: null,
    //   productName: "",
    // }));
    // navigate("/");
  };

  const handleCartNavigate = () => {
    navigate("/carts");
  };

  const handleNavigations = (vertical) => {
    if (showNavigation === vertical) {
      setShowNavigation(null);
    } else {
      dispatch(fetchAllNavigationsAsync(vertical));
      setShowNavigation(vertical);
    }
  };

  // const handleAnimationContainerClick = () => {
  //   setShowVerticalContainer(false);
  //   setShowSearchInput(true);
  // };

  const words = ["“Frameset”", "“Cockpit”", "“Light”", "“Hydration”"];

  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState("");
  const [forward, setForward] = useState(true);

  useEffect(() => {
    let animationTimeout;

    const animate = () => {
      if (forward) {
        animationTimeout = setTimeout(() => {
          setCurrentWord((prevWord) => {
            if (prevWord.length < words[currentWordIndex].length) {
              return prevWord + words[currentWordIndex][prevWord.length];
            } else if (prevWord === words[currentWordIndex]) {
              setTimeout(() => {
                setForward(false);
                setCurrentWordIndex((currentWordIndex + 1) % words.length);
              }, 3000);
              return prevWord;
            } else {
              return prevWord;
            }
          });
        }, 50);
      } else {
        animationTimeout = setTimeout(() => {
          setCurrentWord((prevWord) =>
            prevWord.substring(0, prevWord.length - 1)
          );
          if (currentWord === "") {
            setForward(true);
          } else {
            animate();
          }
        }, 50);
      }
    };

    animate();

    return () => clearTimeout(animationTimeout);
  }, [currentWord, currentWordIndex, forward]);

  function handleMouseOver(event) {
    const element = event.target;
    element.style.transform = "scale(1.1)";
    element.style.transition = "transform 0.3s ease";
  }

  function handleMouseOut(event) {
    const element = event.target;
    element.style.transform = "scale(1)";
  }

  return (
    <div className="mobile-header-container">
      <div className="mobile-header-left-container">
        <Link
          to="/"
          className="mobile-header-website-logo-div"
          onClick={handleEmptyBreadcrum}
        >
          <img
            src={cyclecircleLogo}
            alt="website-logo"
            className="mobile-header-website-logo"
          />
        </Link>
      </div>
      <div className="mobile-header-right-container">
        <SearchSharpIcon
          id="mobile-header-middle-search-icon"
          onClick={() => setBigSearch(true)}
        />
        {bigSearch && (
          <MobileBigSearch setBigSearch={setBigSearch} bigSearch={bigSearch} />
        )}
        {/* {showNavigation && <MobileBigNavigation setShowNavigation={setShowNavigation}/>} */}
        <div className="mobile-header-cart-div">
          <img
            src={cartOutline}
            alt=""
            className="mobile-header-cart-img"
            onClick={() =>
              Object.keys(userDetail).length
                ? handleCartNavigate()
                : toggleOverlay()
            }
          />
          {carts && carts.length > 0 && (
            <sup>
              {" "}
              {Object.keys(userDetail).length > 0 && carts && carts?.length
                ? carts.length
                : "0"}
            </sup>
          )}
        </div>
        <div className="mobile-header-profile-section-v2">
          {Object.keys(userDetail).length ? (
            <>
              <img
                src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
                  userDetail?.UserImage || ""
                }`}
                alt={userDetail?.UserImage || ""}
                className="mobile-headers-user-img-v2"
                onClick={() => setOpenBurger(true)}
              />
            </>
          ) : (
            <img
              src={userOutline}
              alt=""
              className="mobile-headers-user-icon-v2"
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              
              onClick={() => toggleOverlay()}
            />
          )}
        </div>
        {openBurger && (
          <MobileSideBar
            setOpenBurger={setOpenBurger}
            openBurger={openBurger}
          />
        )}
        {/* <img
          src={verticalIcon}
          alt=""
          className="mobile-header-vertical-icon"
          onClick={() => setShowNavigation(true)}
        /> */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});
const mapDispatchToProps = {
  toggleOverlay,
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
