import React, { useEffect, useState } from "react";
import "./MobileDetailedPromises.css";
import freeShippingGrey from "../../img/Promisess/Free-shipping-Inactive.jpg";
import freeShippingColored from "../../img/Promisess/Free-shipping-Active.jpg";
import expressShippingGrey from "../../img/Promisess/Express-shipping-Inactive.jpg";
import expressShippingColored from "../../img/Promisess/Express-shipping-Active.jpg";
import bestPriceGrey from "../../img/Promisess/Best-Price-Inactive.jpg";
import bestPriceColored from "../../img/Promisess/Best-Price-Active.jpg";
import hourGrey from "../../img/Promisess/Resolution-Inactive.jpg";
import hourColored from "../../img/Promisess/Resolution-Active.jpg";


const coloredImages = {
  freeShipping: freeShippingColored,
  expressShipping: expressShippingColored,
  bestPrice: bestPriceColored,
  hour: hourColored,
};

const greyImages = {
  freeShipping: freeShippingGrey,
  expressShipping: expressShippingGrey,
  bestPrice: bestPriceGrey,
  hour: hourGrey,
};
function MobileDetailedPromises() {
  const dummyData = [
    {
      coloredImage: coloredImages.freeShipping,
      greyImage: greyImages.freeShipping,
    },
    {
      coloredImage: coloredImages.expressShipping,
      greyImage: greyImages.expressShipping,
    },
    {
      coloredImage: coloredImages.hour,
      greyImage: greyImages.hour,
    },
    {
      coloredImage: coloredImages.bestPrice,
      greyImage: greyImages.bestPrice,
    },
  ];


  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % dummyData.length);
    }, 2000); // Change index every second

    return () => clearInterval(intervalId); // Cleanup the interval on unmount
  }, [dummyData.length]);
  return (
    <div className="mobile-detailed-promises-container">
      <span className="mobile-detailed-promises-heading">
        Elevating Your Experience
      </span>
      <span className="mobile-detailed-promises-sub-heading">Our Promises</span>
      <div className="flower-images">
        <div className="frame-img-3">
          <div className="frame-img-4">
            <img
              className="rectangleImg"
              alt="Rectangle"
              src={
                dummyData[0][activeIndex === 0 ? "coloredImage" : "greyImage"]
              }
            />
            <img
              className="rectangleImg"
              alt="Rectangle"
              src={
                dummyData[1][activeIndex === 1 ? "coloredImage" : "greyImage"]
              }
            />
          </div>
          <div className="frame-img-4">
            <img
              className="rectangleImg"
              alt="Rectangle"
              src={
                dummyData[3][activeIndex === 3 ? "coloredImage" : "greyImage"]
              }
            />
            <img
              className="rectangleImg"
              alt="Rectangle"
              src={
                dummyData[2][activeIndex === 2 ? "coloredImage" : "greyImage"]
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileDetailedPromises;
