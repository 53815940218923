import React, { useEffect, useState } from 'react';
import "./MobileWishlist.css";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllWishlistsAsync, removeAllFromWishlistAsync, selectAllWishlists } from '../../features/wishlist/wishlistSlice';
import { selectUser } from '../../features/auth/authSlice';
import MobileProductCard from './MobileProductCard';
import MobileNoProductPage from '../MobileListingPage/MobileNoProductPage';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function MobileWishlist() {
    const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const dispatch = useDispatch();
  const wishlist = useSelector(selectAllWishlists);
  const user = useSelector(selectUser);

  const handleRemoveAll = async () => {
    handleOpen();
    await dispatch(removeAllFromWishlistAsync(user?.UserID))
      .then(() => {
        dispatch(fetchAllWishlistsAsync(user?.UserID));
      })
      .then(() => {
        handleClose();
      });
  };

  useEffect(() => {
    dispatch(fetchAllWishlistsAsync(user?.UserID));
  }, [dispatch, user]);
  return (
    <div className="mobile-mywishlist-container">
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open} // Show backdrop when either open or filtering state is true
      onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    <div className="mobile-text-container">
      <h3>My Wishlist</h3>
      <span className="mobile-mywishlist-total-no">
        ({wishlist && wishlist?.product && wishlist?.product?.length} Items)
      </span>
      {wishlist && wishlist?.product && wishlist?.product?.length > 0 && (
        <span className="mobile-mywishlist-remove-all" onClick={handleRemoveAll}>
          Clear all
        </span>
      )}
    </div>
    <div className="mobile-product-card-container">
      {wishlist && wishlist?.product && wishlist?.product?.length > 0 ? (
        <MobileProductCard />
      ) : (
        <MobileNoProductPage />
      )}
    </div>
  </div>
  )
}

export default MobileWishlist