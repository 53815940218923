import React from "react";
import "./BrandBanner.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IMAGE_BASE } from "../../../config";
// import { banners } from "../../../data";
import outlineArrow from "../../../icons/Chevron-Outline-1.svg";
import styled from "styled-components";
import BrandsData from "./BrandsData";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function BrandBanner({ brandName, about, banners }) {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  // console.log(banners);
  return (
    <div className="brand-banner-container">
      <Slider {...settings} className="brand-banner-slider">
        {banners &&
          banners.map((banner, index) => (
            <LazyLoadImage
              key={index}
              src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_BRAND_LOGO_IMAGE/${
                banner && banner
              }`}
              alt=""
              className="brand-banner-img"
              effect="blur"
              wrapperProps={{
                // If you need to, you can tweak the effect transition using the wrapper style.
                style: { transitionDelay: "1s" },
              }}
            />
          ))}
      </Slider>
      <div className="brand-below-about-container">
        <span className="brand-below-about-heading">About {brandName}</span>
        {/* <p className="brand-below-about-para">
          {about && about}
        </p> */}
        <BrandsData />
      </div>
    </div>
  );
}

export default BrandBanner;

const PrevArrow = styled.img`
  content: url(${outlineArrow});
  transform: rotate(-180deg);
  position: absolute;
  top: 45%;
  left: 10%;
  width: 2.8vw;
  height: 2.8vw;
  z-index: 10;
  cursor: pointer;
  color: white;
  outline: black;
  transition: font-size 0.5s ease;

  @media (max-width: 500px) {
    display: none; // Hide on mobile
  }
`;

const NextArrow = styled.img`
  content: url(${outlineArrow});
  transform: rotate(0deg);
  position: absolute;
  top: 45%;
  right: 10%;
  width: 2.8vw;
  height: 2.8vw;
  z-index: 10;
  cursor: pointer;
  color: white;
  transition: font-size 0.5s ease;

  @media (max-width: 500px) {
    display: none; // Hide on mobile
  }
`;
