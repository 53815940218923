import React, { useState } from "react";
import "./SideNav.css";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import WalletIcon from '@mui/icons-material/Wallet';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import LogoutOverlay from "../EndPages/LogoutOverlay";

function SideNav({ setUser }) {
  const [openLogout, setOpenLogout] = useState(false);
  const navigate = useNavigate();
  const url = useParams();

  const handleOpenLogout = ()=>{
    setOpenLogout(!openLogout);
  }

  return (
    <div className="sideNav-container">
      <div
        className="sidenav-item-div"
        onClick={() => navigate("/user/profile")}
        style={{ color: url.profileUrl === "profile" && "rgb(132, 114, 249)" }}
      >
        <PermIdentityOutlinedIcon />
        <span>My Profile</span>
      </div>
      <div
        className="sidenav-item-div"
        onClick={() => navigate("/user/preorders")}
        style={{ color: url.profileUrl === "preorders" && "rgb(132, 114, 249)" }}
      >
        <WorkHistoryOutlinedIcon />
        <span>My Pre-Orders</span>
      </div>
      <div
        className="sidenav-item-div"
        onClick={() => navigate("/user/orders")}
        style={{ color: url.profileUrl === "orders" && "rgb(132, 114, 249)" }}
      >
        <ShoppingBagOutlinedIcon />
        <span>My Orders</span>
      </div>
      <div
        className="sidenav-item-div"
        onClick={() => navigate("/user/wallet")}
        style={{ color: url.profileUrl === "wallet" && "rgb(132, 114, 249)" }}
      >
        <WalletIcon />
        <span>Wallet</span>
      </div>
      <div
        className="sidenav-item-div"
        onClick={() => navigate("/user/security")}
        style={{
          color: url.profileUrl === "security" && "rgb(132, 114, 249)",
        }}
      >
        <VerifiedUserOutlinedIcon />
        <span>Security</span>
      </div>
      <div className="sidenav-item-div" onClick={handleOpenLogout}>
        <LogoutOutlinedIcon />
        <span>Log Out</span>
        {openLogout && <LogoutOverlay handleOpenLogout={handleOpenLogout} />}
      </div>
    </div>
  );
}

export default SideNav;
