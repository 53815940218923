import React from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import "./Breadcumb.css";
import KeyboardArrowRightSharpIcon from "@mui/icons-material/KeyboardArrowRightSharp";
import {
  toggleNavigation,
  selectIsNavOpen,
  selectBreadcrumbs,
  setBreadcrumb,
} from "../../store/slices/breadCrumbsSlice";
import { selectAllCategoryProducts } from "../../features/listingPage/listingPageSlice";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";

function Breadcumb({
  isNavOpen,
  toggleNavigation,
  setBreadcrumb,
  breadcrumbs,
}) {
  const dispatch = useDispatch();
  const products = useSelector(selectAllCategoryProducts);
  const { vertical, category, subCategory, productName } = breadcrumbs;
  // console.log("breadcrumbs...", breadcrumbs);

  const formattedSubcategory = subCategory?.toUpperCase();
  // const bread = useSelector(selectBreadcrumbs);
  // console.log(bread);

  const handleSubCategorySelected = () => {
    dispatch(
      setSelectedSubcategory(
        breadcrumbs && breadcrumbs?.subCategoryID && breadcrumbs?.subCategoryID
      )
    );
  };
  const handleCategorySelected = () => {
    dispatch(
      setBreadcrumb({
        categoryID: breadcrumbs && breadcrumbs?.categoryID,
        categoryName: breadcrumbs && breadcrumbs?.category,
        subCategoryID: 0,
        subCategoryName: "",
        ProductID: 0,
        productName: "",
      })
    );
    dispatch(setSelectedSubcategory(null));
  };
  return (
    <>
      <nav className="custom-dropdown">
        <Link to="/"> Home</Link> <KeyboardArrowRightSharpIcon />
        {vertical && (
          <>
            <Link to="/">{vertical}</Link> <KeyboardArrowRightSharpIcon />
          </>
        )}
        {category && (
          <>
            <Link
              to={`/listing-page/${breadcrumbs.categoryID}/${breadcrumbs.category}`}
              onClick={handleCategorySelected}
            >
              {category}
            </Link>{" "}
            <KeyboardArrowRightSharpIcon />
          </>
        )}
        {subCategory && (
          <>
            <Link
              to={`/listing-page/${breadcrumbs.categoryID}/${breadcrumbs.category}`}
              onClick={handleSubCategorySelected}
            >
              {formattedSubcategory}
            </Link>{" "}
            <KeyboardArrowRightSharpIcon />
          </>
        )}
        {!productName &&
          products &&
          products?.Data &&
          products?.Data?.product && (
            <span className="total-product-length">
              {products?.Data?.product?.length} products found
            </span>
          )}
        {productName && (
          <>
            <Link
              to=""
              className="product-name-underline"
              style={{ color: "var(--color-blue)", fontWeight: "600" }}
            >
              {productName}
            </Link>
          </>
        )}
      </nav>
    </>
  );
}

const mapStateToProps = (state) => ({
  isNavOpen: selectIsNavOpen(state),
  breadcrumbs: selectBreadcrumbs(state),
});

const mapDispatchToProps = {
  toggleNavigation,
  setBreadcrumb,
};

export default connect(mapStateToProps, mapDispatchToProps)(Breadcumb);
