import React, { useState } from "react";
import "./MobileCarts.css";
import MobileCartsAllProduct from "./MobileCartsAllProduct";
import MobileDefaultContact from "./MobileDefaultContact";
import MobilePriceDetail from "./MobilePriceDetail";
import MobileCartBreadcrum from "./MobileCartBreadcrum";

function MobileCarts() {
  const [currentStep, setCurrentStep] = useState(1);
  const [showContactDetail, setShowContactDetail] = useState(true);
  const [showDefaultContact, setShowDefaultContact] = useState(false);

  const handleCheckout = () => {
    setShowContactDetail(false);
    setShowDefaultContact(false);
  };

  const handleAddNewAddress = () => {
    setShowContactDetail(true);
    setShowDefaultContact(false);
  };
  return (
    <>
      <div className="mobile-cartsProduct-breadcrum-container">
        <MobileCartBreadcrum
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      </div>
      <div className="mobile-cartsProduct-container">
        <div className="mobile-carts-container" style={{minHeight: currentStep === 3 && "0"}}>
          {currentStep === 1 && <MobileCartsAllProduct />}
          {currentStep === 2 && (
            <MobileDefaultContact onAddNewAddress={handleAddNewAddress} />
          )}
        </div>
      </div>
      <div className="mobile-cartsProduct-estimates-container">
        <div className="mobile-cartsProduct-checkout-container">
          <MobilePriceDetail
            onCheckout={handleCheckout}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </div>
      </div>
    </>
  );
}

export default MobileCarts;
