import React, { useEffect, useCallback, useState } from "react";
import "./LoginPage.css";
import { Link, useNavigate } from "react-router-dom";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import OTPVerification from "./OTPVerification";
import { connect, useDispatch, useSelector } from "react-redux";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import {
  setLoginMode,
  setPhoneNumber,
  setCodeEntered,
  setOTPVerified,
} from "../../store/slices/userSlice";
import { toggleOverlay } from "../../store/slices/headerSlice";
import { setToken } from "../../features/auth/authSlice";
import Swal from "sweetalert2";
// import "bootstrap/dist/css/bootstrap.min.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API_BASE_URL } from "../../config";
import promises1 from "../../img/banners/promise-1.png";
import promises2 from "../../img/banners/promise-2.png";
import promises3 from "../../img/banners/promise-3.png";
import promises4 from "../../img/banners/promise-4.png";
import NearbyStore from "../ProductDescription/NearbyStore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function LoginPage({
  user,
  setLoginMode,
  setPhoneNumber,
  setCodeEntered,
  setOTPVerified,
  toggleOverlay,
  setToken,
}) {
  const { loginMode, phoneNumber } = user;

  const [open, setOpen] = React.useState(false);
  const [phoneExists, setPhoneExists] = useState(false);
  const [openStore, setOpenStore] = useState(null);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handletoggle = () => {
    if (loginMode === "login") {
      setLoginMode("signup");
      setPhoneNumber(null);
    } else {
      setLoginMode("login");
      setPhoneNumber(null);
    }
  };
  const handleLoginTab = () => {
    setLoginMode("login");
    setPhoneNumber(null);
  };
  const handleSignUpTab = () => {
    setLoginMode("signup");
    setPhoneNumber(null);
  };

  const handleSendOTP = async (event) => {
    handleOpen();
    event.preventDefault();
    const formData = new FormData(event.target);
    const phoneNumber = formData.get("phoneNumber");
    if (loginMode === "login") {
      try {
        const response = await fetch(
          `${API_BASE_URL}login_number/${phoneNumber}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          // console.log(data);
          // Swal.fire({
          //   title: data.success,
          //   text: data.success,
          //   icon: "success",
          //   confirmButtonText: "OK",
          // });
          setPhoneNumber(phoneNumber);
        } else {
          console.error("API request failed:", response.statusText);
          Swal.fire({
            title: "OTP not Sent",
            text: data.error,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        Swal.fire({
          title: "Error",
          text: "An unexpected error occurred. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}phone_auth/${phoneNumber}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          // console.log(data);
          // Swal.fire({
          //   title: data.success,
          //   text: data.success,
          //   icon: "success",
          //   confirmButtonText: "OK",
          // });
          setPhoneNumber(phoneNumber);
        } else {
          console.error("API request failed:", response.statusText);
          Swal.fire({
            title: "OTP not Sent",
            text: data.error,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        Swal.fire({
          title: "Error",
          text: "An unexpected error occurred. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }

    handleClose();
  };
  const handlePhoneNumberExist = async (phone) => {
    // console.log("enter exist func")
    try {
      const response = await fetch(
        `${API_BASE_URL}singup_phone_verify/${phone}`
      );
      const data = await response.json();

      if (data.status === "error") {
        setPhoneExists(true);
      } else {
        setPhoneExists(false);
      }
    } catch (error) {
      console.error("Error checking phone number existence:", error);
    }
  };

  const handleInputChange = (e) => {
    // console.log("enter input change")
    const { name, value } = e.target;
    // console.log(name,value)
    if (
      loginMode === "signup" &&
      name === "phoneNumber" &&
      value.length === 10
    ) {
      // console.log("enter condintion")
      handlePhoneNumberExist(value);
    } else if (
      loginMode === "signup" &&
      name === "phoneNumber" &&
      value.length < 10
    ) {
      setPhoneExists(false);
    }
  };

  const handleSubmitPincode = (e) => {
    e.preventDefault();
    const pincode = e.target.elements.pincode.value;
    // console.log(pincode);
    setOpenStore(pincode);
  };
  return (
    <div className="loginPage-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="close-icon"
        onClick={() => {
          toggleOverlay();
          setPhoneNumber(null);
          // window.location.assign(window.location.href);
        }}
      >
        <CloseSharpIcon />
      </div>
      <div className="login-upper-navigation">
        <span
          className={`login-upper-login-tab ${
            loginMode === "login" && "login-tab-active"
          }`}
          onClick={handleLoginTab}
        >
          Login
        </span>
        <hr className="login-upper-nav-divider" />
        <span
          className={`login-upper-signup-tab ${
            loginMode === "signup" && "login-tab-active"
          }`}
          onClick={handleSignUpTab}
        >
          SignUp
        </span>
      </div>
      {/* <h2>{loginMode === "login"?"Login":"Sign Up"}</h2> */}
      <div className="form-container">
        {phoneNumber ? (
          <OTPVerification
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        ) : (
          <form onSubmit={handleSendOTP}>
            <label htmlFor="phoneNumber">Mobile Number</label>
            <input
              type="text"
              id="phoneNumber"
              placeholder="Mobile number"
              maxLength="10"
              name="phoneNumber"
              onChange={handleInputChange}
              pattern="\d{10}"
              onKeyPress={(e) => {
                // Allow only numbers (0-9) and backspace (8)
                if (!/[0-9\b]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              required
            />
            {phoneExists && (
              <span className="phone-exist-or-not">
                Phone number already registered with Cyclecircle
              </span>
            )}
            <button type="submit">Send OTP</button>
          </form>
        )}
      </div>
      {/* <div className="dont-have-an-account">
        <p>
          {loginMode === "login"
            ? "Don't have an account"
            : "Already have an account"}
          ?
        </p>
        <span className="dont-have-an-account-sign-up" onClick={handletoggle}>
          {loginMode === "login" ? "Sign up" : "Login"}
        </span>
      </div> */}
      {/* <hr className="signUp-separation" /> */}

      <div className="login-customer-page-detail">
        <p className="login-customer-page-p">
          Find the nearest Cyclecircle.one dealership to make your purchase
          directly.
        </p>
        <form
          className="login-customer-search-pincode"
          onSubmit={handleSubmitPincode}
        >
          <input
            type="text"
            inputmode="numeric"
            name="pincode"
            pattern="[0-9]*"
            onKeyPress={(e) => {
              // Allow only numbers (0-9) and backspace (8)
              if (!/[0-9\b]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onInput={(e) => {
              // Limit input to 10 digits
              if (e.target.value.length > 6) {
                e.target.value = e.target.value.slice(0, 6);
              }
            }}
            maxLength="6"
            max="999999"
            placeholder="Enter Pincode"
            required
          />
          <button type="submit" className="login-customer-check-btn">
            Check
          </button>
        </form>
      </div>
      <div className="having-trouble-contact-div">
        <span className="trouble-text">
          Having trouble? Feel free to reach us.
        </span>
        <div className="phone-email-support-container">
          <div className="phone-contact-div">
            <LocalPhoneIcon />
            <span className="login-page-phone-contact">+91-9429693000</span>
          </div>
          <hr />
          <div className="email-contact-div">
            <EmailIcon />
            <span className="login-page-email-contact">
              support@cyclecircle.one
            </span>
          </div>
        </div>
        <div className="login-page-privacy-terms-container">
          <Link to="/support" onClick={() => toggleOverlay()}>
            Privacy Policy
          </Link>{" "}
          &{" "}
          <Link to="/support" onClick={() => toggleOverlay()}>
            Terms of Service
          </Link>{" "}
          apply.
        </div>
      </div>
      <hr className="signUp-separation" />
      <div className="login-page-black-promises-container">
        <div className="each-black-promises-container">
          <LazyLoadImage
            src={promises1}
            alt=""
            className="each-black-promises-img"
            effect="blur"
            wrapperProps={{
              // If you need to, you can tweak the effect transition using the wrapper style.
              style: { transitionDelay: "1s" },
            }}
          />
          <span className="each-black-promises-top">
            &nbsp; Free &nbsp; Delivery
          </span>
        </div>
        <div className="each-black-promises-container">
          <LazyLoadImage
            src={promises4}
            alt=""
            className="each-black-promises-img"
            effect="blur"
            wrapperProps={{
              // If you need to, you can tweak the effect transition using the wrapper style.
              style: { transitionDelay: "1s" },
            }}
          />
          <span className="each-black-promises-top">Quick Tracking Info</span>
        </div>
        <div className="each-black-promises-container">
          <LazyLoadImage
            src={promises2}
            alt=""
            className="each-black-promises-img"
            effect="blur"
            wrapperProps={{
              // If you need to, you can tweak the effect transition using the wrapper style.
              style: { transitionDelay: "1s" },
            }}
          />
          <span className="each-black-promises-top">Hassle Free Return</span>
        </div>
        <div
          className="each-black-promises-container"
          style={{ border: "none" }}
        >
          <LazyLoadImage
            src={promises3}
            alt=""
            className="each-black-promises-img"
            effect="blur"
            wrapperProps={{
              // If you need to, you can tweak the effect transition using the wrapper style.
              style: { transitionDelay: "1s" },
            }}
          />
          <span className="each-black-promises-top">Express Delivery</span>
        </div>
      </div>
      {openStore && openStore?.length === 6 && (
        <>
          <div className="nearby-overlay"></div>
          <div className="nearby-dealers-store-container-overlay">
            <NearbyStore openStore={openStore} setOpenStore={setOpenStore} />
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  setLoginMode,
  setPhoneNumber,
  setCodeEntered,
  setOTPVerified,
  toggleOverlay,
  setToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
