import React, { useEffect, useRef } from 'react';
import "./MobileReview.css";
import { connect } from "react-redux";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { useState } from "react";
import { WriteReviews } from '../../../components/ProductDescription/WriteReviews';
import health from "../../../img/healthwhite.svg";
import release from "../../../img/releasewhite.svg";
import service from "../../../img/servicewhite.svg";
import health_black from "../../../img/health.svg";
import release_black from "../../../img/release.svg";
import service_black from "../../../img/service.svg";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import {
  fetchAllReviewsAsync,
  selectAllReviews,
  likeOrDislikeReviewAsync,
} from "../../../features/productDesc/reviewSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectUser } from "../../../features/auth/authSlice";
import { IMAGE_BASE } from "../../../config";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { Slide } from "react-reveal";
import {
  toggleOverlay,
  selectIsOverlayVisible,
} from "../../../store/slices/headerSlice";

function MobileReview({isOverlayVisible, toggleOverlay}) {
    const userDetail = useSelector(selectUser);
  const { prod_ID } = useParams();
  const dispatch = useDispatch();
  const allReviews = useSelector(selectAllReviews);
  // console.log(allReviews);
  const [showReviewPopup, setShowReviewPopup] = useState(false);
  const [reviewData, setReviewData] = useState(allReviews);
  const [sortBy, setSortBy] = useState("");
  const [reviewsToShow, setReviewsToShow] = useState(4);
  const [sortByOpen, setSortByOpen] = useState(false);

  const handleOpenSortby = () => {
    setSortByOpen(!sortByOpen);
  };

  const handleLoadMore = () => {
    // Increase the number of reviews to show by 4
    setReviewsToShow((prev) => prev + 4);
  };
  useEffect(() => {
    setReviewData(allReviews);
  }, [allReviews]);

  useEffect(() => {
    dispatch(fetchAllReviewsAsync(prod_ID));
  }, [dispatch, prod_ID]);

  const handleSortChange = (event) => {
    const selectedSortBy = event.target.value;
    setSortBy(selectedSortBy);

    if (!allReviews) {
      return; // or handle the case when allReviews is undefined
    }

    let sortedReviews = [...allReviews];
    if (selectedSortBy === "most-recent") {
      // Sort by most recent logic if needed
      sortedReviews = sortedReviews.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
    } else if (selectedSortBy === "most-ratings") {
      // Sort by top ratings logic
      sortedReviews = sortedReviews.sort(
        (a, b) =>
          (b.ProductDesign + b.ValueForMoney + b.ProductService) / 3 -
          (a.ProductDesign + a.ValueForMoney + a.ProductService) / 3
      );
    }
    // console.log(sortedReviews);

    setReviewData(sortedReviews);
  };
  const handleMobileSortChange = (value) => {
    const selectedSortBy = value;
    setSortBy(selectedSortBy);

    if (!allReviews) {
      return; // or handle the case when allReviews is undefined
    }

    let sortedReviews = [...allReviews];
    if (selectedSortBy === "most-recent") {
      // Sort by most recent logic if needed
      sortedReviews = sortedReviews.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
    } else if (selectedSortBy === "most-ratings") {
      // Sort by top ratings logic
      sortedReviews = sortedReviews.sort(
        (a, b) =>
          (b.ProductDesign + b.ValueForMoney + b.ProductService) / 3 -
          (a.ProductDesign + a.ValueForMoney + a.ProductService) / 3
      );
    }
    // console.log(sortedReviews);

    setReviewData(sortedReviews);
  };

  const toggleReviewPopup = () => {
    if (Object.keys(userDetail).length > 0) {
      setShowReviewPopup(!showReviewPopup);
    } else {
      toggleOverlay();
    }
  };
  const closeReviewPopup = () => {
    setShowReviewPopup(false);
  };

  const handleLike = (index, reviewID) => {
    let likeOrDislikeData = {
      prod_ID: prod_ID,
      reviewID: reviewID,
      userID: userDetail.UserID,
    };
    // console.log(reviewID, userDetail?.UserID);
    if (!reviewData[index]?.userArray?.includes(userDetail?.UserID)) {
      dispatch(likeOrDislikeReviewAsync(likeOrDislikeData));
      setReviewData(allReviews);
    }
  };

  const handleDislike = (index, reviewID) => {
    let likeOrDislikeData = {
      prod_ID: prod_ID,
      reviewID: reviewID,
      userID: userDetail.UserID,
    };
    if (!reviewData[index]?.userArray?.includes(userDetail?.UserID)) {
      dispatch(likeOrDislikeReviewAsync(likeOrDislikeData));
    }
  };

  // Make the time into readable format
  const formatTimeAgo = (timestamp) => {
    const currentDate = new Date();
    const reviewDate = new Date(timestamp);

    const timeDifference = currentDate - reviewDate;
    const seconds = Math.floor(timeDifference / 1000);

    let interval = Math.floor(seconds / 31536000);

    if (interval >= 1) {
      return interval + " year" + (interval === 1 ? "" : "s") + " ago";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return interval + " month" + (interval === 1 ? "" : "s") + " ago";
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return interval + " day" + (interval === 1 ? "" : "s") + " ago";
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return interval + " hour" + (interval === 1 ? "" : "s") + " ago";
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return interval + " minute" + (interval === 1 ? "" : "s") + " ago";
    }
    return (
      Math.floor(seconds) + " second" + (seconds === 1 ? "" : "s") + " ago"
    );
  };

  function calculateAverageReviews(allReviews) {
    if (!allReviews || allReviews.length === 0) {
      return null;
    }

    const totalReviews = allReviews.length;

    const averageProductDesign =
      allReviews.reduce((sum, review) => sum + review.ProductDesign, 0) /
      totalReviews;

    const averageValueForMoney =
      allReviews.reduce((sum, review) => sum + review.ValueForMoney, 0) /
      totalReviews;

    const averageProductService =
      allReviews.reduce((sum, review) => sum + review.ProductService, 0) /
      totalReviews;

    const averageAllRatings =
      (averageProductDesign + averageValueForMoney + averageProductService) / 3;
    return {
      averageProductDesign,
      averageValueForMoney,
      averageProductService,
      averageAllRatings,
      totalReviews,
    };
  }

  const averageReviews = calculateAverageReviews(allReviews);
  // console.log(averageReviews);

  // Function to generate stars based on the average rating
  const generateStars = (averageRating) => {
    const fullStars = Math.floor(averageRating);
    const halfStar = averageRating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    const starsArray = Array.from({ length: fullStars }, (_, i) => (
      <StarRoundedIcon key={i} />
    ));

    if (halfStar) {
      starsArray.push(<StarHalfRoundedIcon key="half-star" />);
    }

    starsArray.push(
      ...Array.from({ length: emptyStars }, (_, i) => (
        <StarOutlineRoundedIcon key={`empty-star-${i}`} />
      ))
    );

    return starsArray;
  };

  const totalReview = (averageReviews) => {
    const ret = (
      (averageReviews?.averageProductDesign +
        averageReviews?.averageProductService +
        averageReviews?.averageValueForMoney) /
      3
    ).toFixed(1);
    return isNaN(ret) ? (0).toFixed(1) : ret;
  };

  // Function to calculate total reviews for each star rating
  const calculateTotalReviewsByStarRating = () => {
    const totalReviewsByStarRating = {
      5: 0,
      4: 0,
      3: 0,
      2: 0,
      1: 0,
    };

    allReviews &&
      allReviews?.forEach((review) => {
        const averageRating =
          (review.ProductDesign +
            review.ValueForMoney +
            review.ProductService) /
          3;
        totalReviewsByStarRating[Math.round(averageRating)]++;
      });

    // Calculate percentages
    const percentage5Stars = isNaN(
      (totalReviewsByStarRating[5] / averageReviews?.totalReviews) * 100
    )
      ? 0
      : (totalReviewsByStarRating[5] / averageReviews?.totalReviews) * 100;
    const percentage4Stars = isNaN(
      (totalReviewsByStarRating[4] / averageReviews?.totalReviews) * 100
    )
      ? 0
      : (totalReviewsByStarRating[4] / averageReviews?.totalReviews) * 100;
    const percentage3Stars = isNaN(
      (totalReviewsByStarRating[3] / averageReviews?.totalReviews) * 100
    )
      ? 0
      : (totalReviewsByStarRating[3] / averageReviews?.totalReviews) * 100;
    const percentage2Stars = isNaN(
      (totalReviewsByStarRating[2] / averageReviews?.totalReviews) * 100
    )
      ? 0
      : (totalReviewsByStarRating[2] / averageReviews?.totalReviews) * 100;
    const percentage1Stars = isNaN(
      (totalReviewsByStarRating[1] / averageReviews?.totalReviews) * 100
    )
      ? 0
      : (totalReviewsByStarRating[1] / averageReviews?.totalReviews) * 100;

    return {
      totalReviewsByStarRating,
      percentage5Stars,
      percentage4Stars,
      percentage3Stars,
      percentage2Stars,
      percentage1Stars,
    };
  };

  const totalReviewsByStarRating = calculateTotalReviewsByStarRating();
  // console.log(totalReviewsByStarRating);

  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        // Clicked outside the container, so close it
        handleOpenSortby(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef, handleOpenSortby]);
  return (
    <React.Fragment>
    {showReviewPopup && <div className="overlay" />}
    <div className="containers">
      <div className="ratings-box">
        {reviewData && reviewData?.length && reviewData.length > 0 ? (
          <>
            <div className="ratings-types-reviews-container">
              <div className="ratings-types-review-container-div">
                <p className="rating_number" style={{ fontSize: "1.8rem" }}>
                  {totalReview(averageReviews && averageReviews)}
                </p>
                <div className="total-ratings">
                  <div className="ratings-details">
                    <div className="ratings-details-svg-div">
                      {generateStars(
                        totalReview(averageReviews && averageReviews)
                      )}
                    </div>
                    <p
                      className="ratings-totals"
                      style={{ color: "#eeeeee" }}
                    >
                      {totalReview(averageReviews && averageReviews)} rating
                      of {allReviews && allReviews.length} reviews
                    </p>
                    <button onClick={toggleReviewPopup}>
                      write a review
                    </button>
                  </div>
                </div>
              </div>
              <div className="rating-types-div-container">
                <div className="ratings-types-div">
                  <div className="ratings-types-div-img-container">
                    <img src={service} alt="" />
                    <p>Product Design</p>
                  </div>
                  <div className="ratings-gold">
                    {generateStars(
                      averageReviews &&
                        averageReviews?.averageProductDesign.toFixed(1)
                    )}
                  </div>
                  <span>
                    {allReviews &&
                      averageReviews?.averageProductDesign.toFixed(1)}
                  </span>
                </div>
                <div className="ratings-types-div">
                  <div className="ratings-types-div-img-container">
                    <img src={release} alt="" />
                    <p>Value for money</p>
                  </div>
                  <div className="ratings-gold">
                    {generateStars(
                      averageReviews &&
                        averageReviews?.averageValueForMoney.toFixed(1)
                    )}
                  </div>
                  <span>
                    {allReviews &&
                      averageReviews?.averageValueForMoney.toFixed(1)}
                  </span>
                </div>
                <div className="ratings-types-div">
                  <div className="ratings-types-div-img-container">
                    <img src={health} alt="" />
                    <p>Product Service</p>
                  </div>
                  <div className="ratings-gold">
                    {generateStars(
                      averageReviews &&
                        averageReviews?.averageProductService.toFixed(1)
                    )}
                  </div>
                  <span>
                    {allReviews &&
                      averageReviews?.averageProductService.toFixed(1)}
                  </span>
                </div>
              </div>
            </div>
            <div className="ratings-graph">
              <h4>Ratings</h4>
              <li>
                <div className="ratings_5">
                  <div className="ratings_5_details">
                    <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                      {totalReviewsByStarRating.percentage5Stars.toFixed(1)}%
                    </span>
                    <span
                      className="ratings_5_details_no_span"
                      style={{ fontSize: "0.7rem" }}
                    >
                      {totalReviewsByStarRating.totalReviewsByStarRating[5]}{" "}
                      reviews
                    </span>
                  </div>
                  <div className="ratings_5_n">
                    {Array.from({ length: 5 }, (_, i) => (
                      <StarRoundedIcon key={i} />
                    ))}
                  </div>
                </div>
                <div className="ratings-color-div">
                  <p
                    style={{
                      width: `${totalReviewsByStarRating.percentage5Stars.toFixed(
                        1
                      )}%`,
                    }}
                  ></p>
                </div>
              </li>
              <li>
                <div className="ratings_5">
                  <div className="ratings_5_details">
                    <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                      {totalReviewsByStarRating.percentage4Stars.toFixed(1)}%
                    </span>
                    <span
                      className="ratings_5_details_no_span"
                      style={{ fontSize: "0.7rem" }}
                    >
                      {totalReviewsByStarRating.totalReviewsByStarRating[4]}{" "}
                      reviews
                    </span>
                  </div>
                  <div className="ratings_5_n">
                    {Array.from({ length: 4 }, (_, i) => (
                      <StarRoundedIcon key={i} />
                    ))}
                  </div>
                </div>
                <div className="ratings-color-div">
                  <p
                    style={{
                      width: `${totalReviewsByStarRating.percentage4Stars.toFixed(
                        1
                      )}%`,
                    }}
                  ></p>
                </div>
              </li>
              <li>
                <div className="ratings_5">
                  <div className="ratings_5_details">
                    <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                      {totalReviewsByStarRating.percentage3Stars.toFixed(1)}%
                    </span>
                    <span
                      className="ratings_5_details_no_span"
                      style={{ fontSize: "0.7rem" }}
                    >
                      {totalReviewsByStarRating.totalReviewsByStarRating[3]}{" "}
                      reviews
                    </span>
                  </div>
                  <div className="ratings_5_n">
                    {Array.from({ length: 3 }, (_, i) => (
                      <StarRoundedIcon key={i} />
                    ))}
                  </div>
                </div>
                <div className="ratings-color-div">
                  <p
                    style={{
                      width: `${totalReviewsByStarRating.percentage3Stars.toFixed(
                        1
                      )}%`,
                    }}
                  ></p>
                </div>
              </li>
              <li>
                <div className="ratings_5">
                  <div className="ratings_5_details">
                    <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                      {totalReviewsByStarRating.percentage2Stars.toFixed(1)}%
                    </span>
                    <span
                      className="ratings_5_details_no_span"
                      style={{ fontSize: "0.7rem" }}
                    >
                      {totalReviewsByStarRating.totalReviewsByStarRating[2]}{" "}
                      reviews
                    </span>
                  </div>
                  <div className="ratings_5_n">
                    {Array.from({ length: 2 }, (_, i) => (
                      <StarRoundedIcon key={i} />
                    ))}
                  </div>
                </div>
                <div className="ratings-color-div">
                  <p
                    style={{
                      width: `${totalReviewsByStarRating.percentage2Stars.toFixed(
                        1
                      )}%`,
                    }}
                  ></p>
                </div>
              </li>
              <li>
                <div className="ratings_5">
                  <div className="ratings_5_details">
                    <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                      {totalReviewsByStarRating.percentage1Stars.toFixed(1)}%
                    </span>
                    <span
                      className="ratings_5_details_no_span"
                      style={{ fontSize: "0.7rem" }}
                    >
                      {totalReviewsByStarRating.totalReviewsByStarRating[1]}{" "}
                      reviews
                    </span>
                  </div>
                  <div className="ratings_5_n">
                    {Array.from({ length: 1 }, (_, i) => (
                      <StarRoundedIcon key={i} />
                    ))}
                  </div>
                </div>
                <div className="ratings-color-div">
                  <p
                    style={{
                      width: `${totalReviewsByStarRating.percentage1Stars.toFixed(
                        1
                      )}%`,
                    }}
                  ></p>
                </div>
              </li>
            </div>
          </>
        ):(
          <div className="ratings-no-review-yet-container">
            <span className="ratings-no-review-yet-heading">The Product has no reviews yet</span>
            <span className="ratings-be-the-first">Be the first one to give the valuable review</span>
            <span className="ratings-be-the-first-review-btn" onClick={toggleReviewPopup}>Write a review</span>
          </div>
        )}
      </div>
      {showReviewPopup && <WriteReviews onClose={closeReviewPopup} />}
      {reviewData && reviewData?.length && reviewData.length > 0 && (
        <div className="reviews-containers">
          <h3>Reviews</h3>
          <div className="reviews-filter-containers">
            <p className="write-review" onClick={toggleReviewPopup}>
              <CreateOutlinedIcon style={{}} />
              Write a Review
            </p>
            <div className="reviews-filter">
              <select
                name="filter"
                id="filter"
                onChange={handleSortChange}
                onClick={handleOpenSortby}
                value={sortBy}
              >
                <option value="" disabled>
                  Sort by
                </option>
                <option value="most-recent">Most Recent</option>
                <option value="most-ratings">Top Ratings</option>
              </select>
            </div>
            {sortByOpen && (
              <>
                <div className="overlay-sortby" />
                <Slide bottom>
                  <div
                    className="mobile-reviews-filter-sortby"
                    ref={containerRef}
                  >
                    <div className="mobile-review-filter-sortby-header-div">
                      <span>Sort By</span>
                      <CloseSharpIcon onClick={handleOpenSortby} />
                    </div>
                    <div className="mobile-review-filter-sortby-main-div">
                      <span
                        className={`${
                          sortBy === "most-recent" ? "sortby-selected" : ""
                        }`}
                        onClick={() => {
                          sortBy === "most-recent"
                            ? handleMobileSortChange("")
                            : handleMobileSortChange("most-recent");
                          handleOpenSortby();
                        }}
                      >
                        Most Recent
                      </span>
                      <span
                        className={`${
                          sortBy === "most-ratings" ? "sortby-selected" : ""
                        }`}
                        onClick={() => {
                          sortBy === "most-ratings"
                            ? handleMobileSortChange("")
                            : handleMobileSortChange("most-ratings");
                          handleOpenSortby();
                        }}
                      >
                        Top Ratings
                      </span>
                    </div>
                  </div>
                </Slide>
              </>
            )}
          </div>
        </div>
      )}
      <div className="reviews-info-containers">
        {reviewData && reviewData?.length && reviewData.length && (
          <div className="totalNoOfReviews">
            {reviewData && reviewData?.length && reviewData.length} reviews
            found
          </div>
        )}
        {reviewData &&
          reviewData?.slice(0, reviewsToShow).map((review, index) => (
            <div key={index} className="review-detail">
              <div className="review-name-containers">
                <div className="review-logo">
                  <img
                    src={
                      review.type === "public"
                        ? `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${review.UserProfile}`
                        : "https://cdn.vectorstock.com/i/preview-1x/28/63/profile-placeholder-image-gray-silhouette-vector-21542863.jpg"
                    }
                    alt=""
                  />
                  <div className="review-name-time">
                    <h4>
                      {review.type === "public"
                        ? review.UserName
                        : "Anonymous"}
                    </h4>
                    <div className="ratings-gold user-total-ratings">
                      {generateStars(
                        (review.ProductDesign +
                          review.ProductService +
                          review.ValueForMoney) /
                          3
                      )}
                    </div>
                    <span>{formatTimeAgo(review.date)}</span>
                  </div>
                </div>
                <div className="review-ratings">
                  <div className="review-user-ratings-details-container">
                    <h2>
                      {(
                        (review.ProductDesign +
                          review.ProductService +
                          review.ValueForMoney) /
                        3
                      ).toFixed(1)}{" "}
                    </h2>
                    <div className="review-user-ratings-details-types-container">
                      <div className="review-user-ratings-details-types-each-div">
                        <div className="review-user-ratings-types-img-name-div">
                          <img
                            src={service_black}
                            alt=""
                            style={{ width: "27px", height: "27px" }}
                          />
                          <span>Product Design</span>
                        </div>
                        <div className="review-user-ratings-details-types-ratings-div">
                          {Array.from(
                            { length: review.ProductDesign },
                            (_, index) => (
                              <StarRoundedIcon
                                key={index}
                                style={{ color: "var(--color-blue)" }}
                              />
                            )
                          )}
                        </div>
                      </div>
                      <div className="review-user-ratings-details-types-each-div">
                        <div className="review-user-ratings-types-img-name-div">
                          <img
                            src={release_black}
                            alt=""
                            style={{ width: "27px", height: "27px" }}
                          />
                          <span>Value for Money</span>
                        </div>
                        <div className="review-user-ratings-details-types-ratings-div">
                          {Array.from(
                            { length: review.ValueForMoney },
                            (_, index) => (
                              <StarRoundedIcon
                                key={index}
                                style={{ color: "var(--color-green)" }}
                              />
                            )
                          )}
                        </div>
                      </div>
                      <div className="review-user-ratings-details-types-each-div">
                        <div className="review-user-ratings-types-img-name-div">
                          <img
                            src={health_black}
                            alt=""
                            style={{ width: "27px", height: "27px" }}
                          />
                          <span>Product Service</span>
                        </div>
                        <div className="review-user-ratings-details-types-ratings-div">
                          {Array.from(
                            { length: review.ProductService },
                            (_, index) => (
                              <StarRoundedIcon
                                key={index}
                                style={{ color: "#BA3737" }}
                              />
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="review-ratings-desc-reaction-container">
                  <div className="review-desc">{review?.Text}</div>
                  <div className="review-reaction">
                    {review?.userArray?.includes(userDetail?.UserID) ? (
                      <ThumbUpAltIcon
                        style={{
                          cursor: "pointer",
                          color: "var(--color-green)",
                          marginBottom: "5px",
                        }}
                        onClick={() => handleLike(index, review?.UserID)}
                      />
                    ) : (
                      <ThumbUpOutlinedIcon
                        style={{
                          cursor: "pointer",
                          color: "var(--color-green)",
                          marginBottom: "5px",
                        }}
                        onClick={() => handleLike(index, review?.UserID)}
                      />
                    )}
                    <span>{review.countLikes}</span>
                    {!review?.userArray?.includes(userDetail?.UserID) ? (
                      <ThumbDownIcon
                        style={{
                          cursor: "pointer",
                          color: "rgb(230, 11, 29)",
                          marginTop: "3px",
                        }}
                        onClick={() => handleDislike(index, review?.UserID)}
                      />
                    ) : (
                      <ThumbDownAltOutlinedIcon
                        style={{
                          cursor: "pointer",
                          color: "rgb(230, 11, 29)",
                          marginTop: "3px",
                        }}
                        onClick={() => handleDislike(index, review?.UserID)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        {reviewData && reviewData.length > reviewsToShow && (
          <div className="load-more-review-btn" onClick={handleLoadMore}>
            Load More
          </div>
        )}
      </div>
    </div>
  </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
    isOverlayVisible: selectIsOverlayVisible(state),
  });
  const mapDispatchToProps = {
    toggleOverlay,
  };
  export default connect(mapStateToProps, mapDispatchToProps)(MobileReview)