import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAllWishlists, addToWishlists,removeFromWishlists,removeAllFromWishlists } from "./wishlistAPI";

const initialState = {
  wishlists: [],
  status: "idle",
};

export const fetchAllWishlistsAsync = createAsyncThunk(
  "wishlist/fetchAllWishlists",
  async (UserID) => {
    const response = await fetchAllWishlists(UserID);
    // console.log(response)
    return response.data;
  }
);

export const addToWishlistsAsync = createAsyncThunk(
  "wishlist/addToWishlist",
  async (wishlistData) => { // Pass wishlistData to the async function
    const response = await addToWishlists(wishlistData);
    // console.log(response);
    return response;
  }
);
export const removeFromWishlistAsync = createAsyncThunk(
  "wishlist/removeFromWishlists",
  async (wishlistData) => { // Pass wishlistData to the async function
    const response = await removeFromWishlists(wishlistData);
    // console.log(response);
    return response;
  }
);

export const removeAllFromWishlistAsync = createAsyncThunk(
  "wishlist/removeAllFromWishlists",
  async (UserID) => { // Pass wishlistData to the async function
    const response = await removeAllFromWishlists(UserID);
    // console.log(response);
    return response;
  }
);

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllWishlistsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllWishlistsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.wishlists = action.payload;
      })
      .addCase(addToWishlistsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addToWishlistsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // state.wishlists = action.payload;
      })
      .addCase(removeFromWishlistAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeFromWishlistAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload);
        // state.wishlists = action.payload;
      })
      .addCase(removeAllFromWishlistAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeAllFromWishlistAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload);
        // state.wishlists = action.payload;
      })

  },
});

export const selectAllWishlists = (state) => state.wishlist.wishlists;

export default wishlistSlice.reducer;
