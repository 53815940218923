import React, { useState, useRef, useEffect } from "react";
import "./WriteReviews.css";
import ReviewTypesStar from "./ReviewTypesStar";
import star from "../../img/star.svg";
import solarStar from "../../img/solar-smallstar.svg";
import globe from "../../img/globe.svg";
import close from "../../img/close.svg";
import health from "../../img/health.svg";
import Service from "../../img/service.svg";
import release from "../../img/release.svg";
import { addReviewAsync } from "../../features/productDesc/reviewSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectUser } from "../../features/auth/authSlice";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import { Slide } from "react-reveal";

export const WriteReviews = ({ onClose }) => {
  const dispatch = useDispatch();
  const { prod_ID } = useParams();
  const userDetail = useSelector(selectUser);
  // console.log(userDetail);
  const [isAnonymous, setIsAnonymous] = useState("public");
  const [publicLabelColor, setPublicLabelColor] = useState("var(--color-green)");
  const [anonymousLabelColor, setAnonymousLabelColor] = useState("darkgray");
  const [productDesignRating, setProductDesignRating] = useState(0);
  const [valueForMoneyRating, setValueForMoneyRating] = useState(0);
  const [productServiceRating, setProductServiceRating] = useState(0);
  const [reviewText, setReviewText] = useState("");


   // Set "Public" as selected by default
   useEffect(() => {
    setIsAnonymous("public");
    setPublicLabelColor("var(--color-green)");
    setAnonymousLabelColor("darkgray");
  }, []);

  const handlePrivacyChange = (event) => {
    const isAnonymousSelected = event.target.value === "anonymous";
    setIsAnonymous(event.target.value);

    if (isAnonymousSelected) {
      setPublicLabelColor("darkgray");
      setAnonymousLabelColor("var(--color-green)");
    } else {
      setPublicLabelColor("var(--color-green)");
      setAnonymousLabelColor("darkgray");
    }
  };

  const handleRatingChange = (type, value) => {
    switch (type) {
      case "productDesign":
        setProductDesignRating(value);
        break;
      case "valueForMoney":
        setValueForMoneyRating(value);
        break;
      case "productService":
        setProductServiceRating(value);
        break;
      default:
        break;
    }
  };

  const handleReviewText = (value) => {
    setReviewText(value);
    // console.log(value, reviewText);
  };

  const handleAddReview = () => {
    let reviewData = {
      ProductID: prod_ID,
      UserID: userDetail.UserID,
      UserName: userDetail.FirstName + " " + userDetail.LastName,
      UserProfile: userDetail.UserImage,
      Text: reviewText,
      ProductDesign: productDesignRating,
      ValueForMoney: valueForMoneyRating,
      ProductService: productServiceRating,
      type: isAnonymous,
    };
    dispatch(addReviewAsync(reviewData));
  };

  const avgRating =
    (productDesignRating + valueForMoneyRating + productServiceRating) / 3;

  const infoRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (infoRef.current && !infoRef.current.contains(event.target)) {
  //       // Clicked outside the component, you can close it here
  //       onClose();
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [onClose]);
  return (
    <>
      <div ref={infoRef} className="write-review-container">
        <div className="div">Write Review</div>
        <img
          className="octicon-x"
          alt="Octicon x"
          src={close}
          onClick={onClose}
        />
        <div className="overlap">
          <textarea
            className="text-wrapper-3"
            placeholder="write a review..."
            onChange={(e) => handleReviewText(e.target.value)}
          />
        </div>
        <div className="policy">
          <img className="gridicons-globe" alt="Gridicons globe" src={globe} />
          <p className="text-wrapper-4">
            Your review will be in our web page under cyclecircle privacy policy
          </p>
        </div>
        <div className="text-wrapper-2">
          {isAnonymous === "anonymous" ? (
            "Anonymous"
          ) : (
            <p
              style={{
                fontSize: "20px",
                margin: 0,
                textTransform: "capitalize",
              }}
            >
              {userDetail?.FirstName},
              <span
                style={{
                  fontSize: "15px",
                  marginBottom: "3px",
                  textTransform: "capitalize",
                }}
              >
                {userDetail?.location}
              </span>
            </p>
          )}
        </div>
        <div className="publish-privacy">
          <div className="publish-container-div">
            <input
              type="radio"
              id="public"
              name="privacy"
              value="public"
              checked={isAnonymous === "public"}
              onChange={handlePrivacyChange}
            />
            <label style={{ color: publicLabelColor }} htmlFor="public">
              Public
            </label>
          </div>
          <div className="publish-container-div">
            <input
              type="radio"
              id="private"
              name="privacy"
              value="anonymous"
              checked={isAnonymous === "anonymous"}
              onChange={handlePrivacyChange}
            />
            <label style={{ color: anonymousLabelColor }} htmlFor="anonymous">
              Anonymous
            </label>
          </div>
        </div>
        <div className="publish" onClick={onClose}>
          <div className="overlap-group">
            <div className="text-wrapper-5" onClick={handleAddReview}>
              Publish
            </div>
          </div>
        </div>
        <div className="cancel" onClick={onClose}>
          <div className="div-wrapper">
            <div className="text-wrapper-6">Cancel</div>
          </div>
        </div>
        <div className="ratings-types-container">
          <div className="ratings-types-div">
            <div className="ratings-types-img-div">
              <img src={Service} alt="" />
              <span>Product Design</span>
            </div>
            <ReviewTypesStar
              className="ratings-instance"
              img={star}
              solarStarBold={solarStar}
              onRatingChange={(value) =>
                handleRatingChange("productDesign", value)
              }
            />
          </div>
          <div className="ratings-types-div">
            <div className="ratings-types-img-div">
              <img src={release} alt="" />
              <span>Value for Money</span>
            </div>
            <ReviewTypesStar
              className="ratings-instance"
              img={star}
              solarStarBold={solarStar}
              onRatingChange={(value) =>
                handleRatingChange("valueForMoney", value)
              }
            />
          </div>
          <div className="ratings-types-div">
            <div className="ratings-types-img-div">
              <img src={health} alt="" />
              <span>Product Service</span>
            </div>
            <ReviewTypesStar
              className="ratings-instance"
              img={star}
              solarStarBold={solarStar}
              onRatingChange={(value) =>
                handleRatingChange("productService", value)
              }
            />
          </div>
        </div>
        <div className="user-avg-review">{avgRating.toFixed(1)}</div>
      </div>
      <Slide right>
      <div className="mobile-write-review-container">
        <div className="mobile-write-review-header-div">
          <div className="mobile-write-review-back-btn">
            <ArrowBackIosNewSharpIcon onClick={onClose} />
            <span onClick={onClose}>Back</span>
          </div>
          <span className="mobile-write-review-name">Write Review</span>
        </div>
        <div className="mobile-total-review-number">
          <h4>{avgRating.toFixed(1)}</h4>
        </div>
        <div className="mobile-ratings-types-container">
          <div className="mobile-ratings-types-div">
            <div className="mobile-ratings-types-img-div">
              <img src={Service} alt="" />
              <span>Product Design</span>
            </div>
            <ReviewTypesStar
              className="mobile-ratings-instance"
              img={star}
              solarStarBold={solarStar}
              onRatingChange={(value) =>
                handleRatingChange("productDesign", value)
              }
            />
          </div>
          <div className="mobile-ratings-types-div">
            <div className="mobile-ratings-types-img-div">
              <img src={release} alt="" />
              <span>Value for Money</span>
            </div>
            <ReviewTypesStar
              className="mobile-ratings-instance"
              img={star}
              solarStarBold={solarStar}
              onRatingChange={(value) =>
                handleRatingChange("valueForMoney", value)
              }
            />
          </div>
          <div className="mobile-ratings-types-div">
            <div className="mobile-ratings-types-img-div">
              <img src={health} alt="" />
              <span>Product Service</span>
            </div>
            <ReviewTypesStar
              className="mobile-ratings-instance"
              img={star}
              solarStarBold={solarStar}
              onRatingChange={(value) =>
                handleRatingChange("productService", value)
              }
            />
          </div>
        </div>
        <div className="mobile-text-area-div">
          <textarea
            className="mobile-text-wrapper-3"
            placeholder="write a review..."
            onChange={(e) => handleReviewText(e.target.value)}
          />
        </div>
        <div className="mobile-text-wrapper-2">
          {isAnonymous ? (
            "Anonymous"
          ) : (
            <p
              style={{
                fontSize: "1.3rem",
                margin: 0,
                textTransform: "capitalize",
              }}
            >
              {userDetail?.FirstName},
              <span
                style={{
                  fontSize: "1rem",
                  marginBottom: "3px",
                  textTransform: "capitalize",
                }}
              >
                {userDetail?.location}
              </span>
            </p>
          )}
        </div>
        <div className="mobile-policy">
          <img
            className="mobile-gridicons-globe"
            alt="Gridicons globe"
            src={globe}
          />
          <p className="mobile-text-wrapper-4">
            Your review will be in our web page under cyclecircle privacy policy
          </p>
        </div>
        <div className="mobile-publish-privacy">
          <div className="mobile-publish-container-div">
            <input
              type="radio"
              id="public"
              name="privacy"
              value="public"
              checked={!isAnonymous}
              onChange={handlePrivacyChange}
            />
            <label style={{ color: publicLabelColor }} htmlFor="public">
              Public
            </label>
          </div>
          <div className="mobile-publish-container-div">
            <input
              type="radio"
              id="private"
              name="privacy"
              value="anonymous"
              onChange={handlePrivacyChange}
            />
            <label style={{ color: anonymousLabelColor }} htmlFor="anonymous">
              Anonymous
            </label>
          </div>
        </div>
        <div className="mobile-publish" >
          <div className="mobile-overlap-group" onClick={onClose}>
            <div className="mobile-text-wrapper-5" onClick={handleAddReview}>
              Publish
            </div>
          </div>
        </div>
        <div className="mobile-cancel" >
          <div className="mobile-div-wrapper" onClick={onClose}>
            <div className="mobile-text-wrapper-6">Cancel</div>
          </div>
        </div>
      </div>
      </Slide>
    </>
  );
};
