import React from "react";
import "./Description.css";
import { IMAGE_BASE } from "../../config";

function Description({ productDesc }) {
  // console.log(productDesc);
  return (
    <div className="description-containers">
      <div className="description-combine">
        <div className="description-container-desc-points">
          {productDesc &&
            productDesc[0]?.descriptions_points &&
            productDesc[0]?.descriptions_points.map((point, index) => (
              <ul key={index}>
                <li className="description-container-desc-each-point">
                  {point}
                </li>
              </ul>
            ))}
        </div>
        {productDesc && productDesc[0]?.features && (
          <div className="description-container-features-div">
            <h3 className="features-heading">Features:</h3>
            <ul>
              {productDesc &&
                productDesc[0]?.features &&
                productDesc[0]?.features?.map(
                  (feature, index) =>
                    feature.length > 0 && <li key={index}>{feature}</li>
                )}
            </ul>
          </div>
        )}
      </div>
      <div className="description-container-img-gallery">
        {productDesc &&
          productDesc[0]?.descriptions_images &&
          productDesc[0]?.descriptions_images.map((img, index) => (
            <div className="descriptions-each-image" key={index}>
              <img
                src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCT_DESCRIPTION_IMAGE/${img}`}
                alt="description_image"
              />
            </div>
          ))}
      </div>
    </div>
  );
}

export default Description;
