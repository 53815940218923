import React, { useEffect } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { banners } from "../../data";
// import { mobileBanners } from "../../data";
import { useState } from "react";
import outlineArrow from "../../icons/Chevron-Outline-1.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllBanners } from "../../features/HomePage/homeAPI";
import {
  fetchAllBannersAsync,
  selectAllBanners,
} from "../../features/HomePage/homeSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

function ImgSlider({ setMobileView }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const banners = useSelector(selectAllBanners);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  useEffect(() => {
    const fetchData = async () => {
      handleOpen();
      await dispatch(fetchAllBannersAsync()).then(() => {
        handleClose();
      });
    };
    fetchData();
    return () => clearTimeout(2000);
  }, [dispatch]);

  // const data = banners;

  return (
    <Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Carousel {...settings}>
        {banners?.map((banner, index) => (
          <Wrap key={index}>
            <LazyLoadImage
              src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/${banner}`}
              alt=""
              effect="blur"
              wrapperProps={{
                // If you need to, you can tweak the effect transition using the wrapper style.
                style: { transitionDelay: "1s" },
              }}
            />
          </Wrap>
        ))}
      </Carousel>
    </Container>
  );
}

export default ImgSlider;

const Container = styled.div`
  width: 100%;
  height: auto;
  // overflow: hidden;
  background: white;
  @media (max-width: 500px) {
    height: 60vh;
    margin: 0 auto;
  }
`;

const Carousel = styled(Slider)`
  width: 100%;
  height: auto;
  margin: 0 auto 0 auto;
  ul li button {
    &:before {
      font-size: 10px;
    }
  }
  .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .slick-dots.slick-active {
    button {
      // display: block;
      width: 2vw;
      height: auto;
      padding: 0;
      border: none;
      border-radius: 100%;
      background-color: transparent;
      text-indent: -9999px;
    }
  }
  .slick-dots li {
    width: 2.2vw !important;
    height: 0.6vh !important;
    margin: 0 0.5vw;
    // border-radius:5px;
    border-radius: 50% 0 50% 0 / 150px 0 150px 0;
    transform: skewX(-45deg);
  }
  .slick-dots li button {
    width: 2.2vw;
    height: 0.6vh;
    padding: 0;
    background: var(--color-blue);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.4) inset;
    opacity: 0.3;
    // border-radius: 5px;
  }

  button {
    z-index: 1;
  }
  button:before {
    background: var(--color-blue);
    content: "";
    // border-radius:5px;
  }
  .slick-dots li button:before {
    width: 2.2vw;
    height: 0.6vh;
    opacity: 0;
    // border-radius:5px;
  }
  .slick-active {
    width: 2vw;
    height: 0.6vh;
    background: var(--color-blue);
    opacity: 1;
    // border-radius:5px;
  }
  .slick-dots li button:hover {
    // border-radius:5px;
  }
  .slick-list {
    width: 100%;
    overflow: hidden;
  }
  .slick-next {
    right: 3.2vw;
    font-size: 3vw;
    transition: font-size 0.5s all ease;
  }
  .slick-next:before {
    font-size: 50px;
    color: white;
  }
  .slick-next:hover {
    color: white;
    font-size: 3.5vw;
  }
  .slick-prev {
    left: 2.5vw;
    font-size: 3vw;
    transition: font-size 0.5s all ease;
  }
  .slick-prev:before {
    font-size: 50px;
    color: #eeeeee;
  }
  .slick-prev:hover {
    color: white;
    font-size: 3.5vw;
  }

  @media screen and (min-width: 501px) and (max-width: 1000px) {
    .slick-dots li {
      width: 35px !important;
      height: 4px !important;
      margin: 0 5px;
      // border-radius:5px;
      border-radius: 50% 0 50% 0 / 150px 0 150px 0;
      transform: skewX(-45deg);
    }
    .slick-dots li button {
      width: 35px;
      height: 4px;
      padding: 0;
      background: var(--color-blue);
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.4) inset;
      opacity: 0.3;
      // border-radius: 5px;
    }
  }
`;
const Wrap = styled.div`
  img {
    border: 1px solid transparent;
    width: 100%;
    height: auto;
    transition-duration: 300ms;
    object-fit: cover;
    border-radius: 0;
    @media (max-width: 500px) {
      height: 54vh;
    }
    &:hover {
      // border: 4px solid rgba(249, 249, 249, 0.8);
    }
  }
`;
const PrevArrow = styled.img`
  content: url(${outlineArrow});
  transform: rotate(-180deg);
  position: absolute;
  top: 45%;
  left: 10%;
  width: 2.8vw;
  height: 2.8vw;
  z-index: 10;
  cursor: pointer;
  color: white;
  outline: black;
  transition: font-size 0.5s ease;

  @media (max-width: 500px) {
    display: none; // Hide on mobile
  }
`;

const NextArrow = styled.img`
  content: url(${outlineArrow});
  transform: rotate(0deg);
  position: absolute;
  top: 45%;
  right: 10%;
  width: 2.8vw;
  height: 2.8vw;
  z-index: 10;
  cursor: pointer;
  color: white;
  transition: font-size 0.5s ease;

  @media (max-width: 500px) {
    display: none; // Hide on mobile
  }
`;
