import React, { useEffect, useState } from "react";
import "./MobilePreOrders.css";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import MobileProfileSideBar from "./MobileProfileSideBar";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { selectUser } from "../../features/auth/authSlice";
import Backdrop from "@mui/material/Backdrop";
import wishlistMoreLogo from "../../img/wishlist-more.png";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpSharpIcon from "@mui/icons-material/KeyboardArrowUpSharp";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import {
  fetchAllPreorderCartsAsync,
  removeAllFromPreorderCartsAsync,
  removeFromPreorderCartsAsync,
  selectAllPreorders,
} from "../../features/cartPage/cartPageSlice";
import { IMAGE_BASE } from "../../config";
import MobileCartNoProduct from "../MobileCartPage/MobileCartNoProduct";
import {
  calculateDealerPrice,
  calculateOfferPrice,
  formatWithCommasWithDecimal,
} from "../../utils/utils";

function MobilePreOrders() {
  const navigate = useNavigate();
  const [openSidebar, setOpenSidebar] = useState(false);
  const handleSidebar = () => {
    // console.log(openSidebar);
    setOpenSidebar(!openSidebar);
  };

  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const preorderCart = useSelector(selectAllPreorders);
  // console.log(preorderCart);
  const [openAccordian, setOpenAccordian] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleAccordian = (index) => {
    if (openAccordian !== index) {
      setOpenAccordian(index);
    } else {
      setOpenAccordian(null);
    }
  };

  useEffect(() => {
    if (userDetail && userDetail?.UserID)
      dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID));
  }, [dispatch, userDetail]);

  const handleRemoveAllFromPreorderCarts = () => {
    handleOpen();
    dispatch(removeAllFromPreorderCartsAsync(userDetail.UserID)).then(() => {
      dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID));
      handleClose();
    });
  };

  const [productToRemove, setProductToRemove] = useState(null);

  // Function to handle setting the product to be removed
  const handleSetProductToRemove = (skuId) => {
    setProductToRemove(skuId);
  };

  const calculateGST = (price, gstPercentage) => {
    // console.log(price,gstPercentage)
    // Ensure both price and gstPercentage are valid numbers
    if (
      typeof price !== "number" ||
      isNaN(price) ||
      typeof gstPercentage !== "number" ||
      isNaN(gstPercentage)
    ) {
      console.log("Invalid input. Price and GST percentage must be numbers.");
    }

    // Calculate GST amount
    const gstAmount = (parseInt(price) * parseInt(gstPercentage)) / 100;
    return gstAmount;
  };

  const handleCurrentPrice = (data) => {
    const originalPrice = data?.Offer_price
      ? calculateOfferPrice(
          parseInt(data?.MRP_price),
          parseInt(data?.Offer_price),
          data?.GST
        )
      : calculateDealerPrice(
          parseInt(data?.MRP_price),
          parseInt(data?.NDP_price),
          data?.GST
        );
    const discountedPrice = originalPrice;
    const finalPrice = discountedPrice * data?.quantity;
    return finalPrice;
  };

  const handleRemoveFromCarts = (data) => {
    dispatch(removeFromPreorderCartsAsync(data?.id)).then(() => {
      dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID));
    });
  };

  const calculateTotalAmount = () => {
    let totalAmount = 0;

    if (preorderCart && preorderCart?.length > 0) {
      for (const preorder of preorderCart) {
        // Choose the price: offer price if available, otherwise NDP price
        const price = preorder?.Offer_price
          ? calculateOfferPrice(
              parseInt(preorder?.MRP_price),
              parseInt(preorder?.Offer_price),
              preorder?.GST
            )
          : calculateDealerPrice(
              parseInt(preorder?.MRP_price),
              parseInt(preorder?.NDP_price),
              preorder?.GST
            );

        // Calculate the amount for this preorder item
        const amount = price * preorder?.quantity;

        // Add to the total amount
        totalAmount += amount;
      }
    }

    return totalAmount;
  };

  return (
    <div className="my-profile-container-div">
      <div className="mobile-my-order-header-container">
        <div className="mobile-my-order-header-left-container">
          <ChevronLeftOutlinedIcon onClick={() => navigate("/")} />
          <span className="mobile-my-order-header-left-span">
            My Pre-Orders
          </span>
        </div>
        <div className="mobile-my-order-header-right-container">
          <MoreHorizOutlinedIcon onClick={handleSidebar} />
        </div>
        {openSidebar && <MobileProfileSideBar handleSidebar={handleSidebar} />}
      </div>
      <div className="mobile-pre-order-inner-container">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="mobile-preorder-total-container">
          <span className="total-pre-order-key-amount">Total Amount</span>
          <span className="total-pre-order-value-amount">
            ₹{formatWithCommasWithDecimal(calculateTotalAmount())}
          </span>
        </div>
        <div className="mobile-continue-remove-items-div">
          <span
            className="mobile-continue-to-shopping-btn"
            onClick={() => navigate("/")}
          >
            <ChevronLeftOutlinedIcon /> Back to shopping
          </span>
          {preorderCart && preorderCart?.length > 0 && (
            <span
              className="mobile-remove-all-items-btn"
              onClick={handleRemoveAllFromPreorderCarts}
              style={{ position: "absolute", right: "10px" }}
            >
              Remove all items
            </span>
          )}
        </div>
        <div className="mobile-main-carts-products-div-container">
          {preorderCart && preorderCart.length > 0 ? (
            <div className="mobile-main-carts-products-contain-div">
              {preorderCart?.map((data, index) => (
                <div className="mobile-main-carts-product-div-each-container" key={index}>
                  <div className="mobile-carts-product-delete">
                    <CloseIcon onClick={() => handleRemoveFromCarts(data)} />
                  </div>
                  <div className="mobile-main-carts-product-div-each-main-container">
                    <div className="mobile-main-carts-product-img-div">
                      <img
                        src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                          data?.image && data?.image
                        }`}
                        alt=""
                      />
                    </div>
                    <div className="mobile-main-carts-product-details-div">
                      <div className="mobile-main-carts-products-brands-logo">
                        {data && data?.manufacture_images ? (
                          <img
                            className="mobile-main-carts-product-brand-img"
                            src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                              data && data?.manufacture_images
                            }`}
                            alt=""
                          />
                        ) : (
                          <h3 className="mobile-main-parts-product-name">
                            {data?.manufacture_name && data?.manufacture_name}
                          </h3>
                        )}
                      </div>
                      <span className="mobile-main-carts-product-name">
                        {data?.prod_name &&
                          (data?.prod_name)
                            .slice(0, 45)
                            .toLowerCase()
                            .charAt(0)
                            .toUpperCase() +
                            (data?.prod_name)
                              .slice(0, 45)
                              .toLowerCase()
                              .slice(1)}
                        {data?.prod_name?.length > 45 && "..."}
                      </span>
                      <div className="mobile-main-carts-product-ratings-div">
                        <div className="mobile-main-carts-products-avg-rating-div">
                          <span>4.7</span>
                          <StarRoundedIcon />
                        </div>
                        <span className="rating-divider">|</span>
                        <span className="mobile-main-carts-products-ratings-total">
                          4K
                        </span>
                      </div>
                      <div className="mobile-main-carts-products-price-div">
                        <span className="mobile-main-carts-products-sale-price">
                          Dealer Price- ₹
                          <b
                            style={{
                              textDecoration:
                                data?.Offer_price !== null && "line-through",
                              fontSize: data?.Offer_price !== null && "3vw",
                            }}
                          >
                            {formatWithCommasWithDecimal(
                              calculateDealerPrice(
                                parseInt(data?.MRP_price),
                                parseInt(data?.NDP_price),
                                data?.GST
                              )
                            )}
                          </b>
                          {Object.keys(userDetail).length > 0 &&
                            data?.Offer_price !== null && (
                              <span className="mobile-promotion-product-offer-price">
                                ₹
                                {formatWithCommasWithDecimal(
                                  calculateOfferPrice(
                                    parseInt(data?.MRP_price),
                                    parseInt(data?.Offer_price),
                                    data?.GST
                                  )
                                )}
                              </span>
                            )}
                        </span>
                        <span className="mobile-main-carts-products-mrp-price">
                          Product MRP- ₹
                          {formatWithCommasWithDecimal(
                            parseInt(data?.MRP_price)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mobile-main-carts-products-qty-price-details">
                    <div className="mobile-main-carts-products-qty">
                      <span>Qty</span>
                      <div className="mobile-main-carts-products-inc-desc-qty">
                        <span className="mobile-main-carts-qty-added">
                          {data?.quantity}
                        </span>
                      </div>
                    </div>
                    <div className="mobile-main-carts-products-price-accordian-open">
                      <div className="mobile-main-carts-products-show-price">
                        <span className="mobile-main-carts-products-pricing">
                          ₹{" "}
                          {formatWithCommasWithDecimal(
                            (data?.Offer_price
                              ? calculateOfferPrice(
                                  parseInt(data?.MRP_price),
                                  parseInt(data?.Offer_price),
                                  data?.GST
                                )
                              : calculateDealerPrice(
                                  parseInt(data?.MRP_price),
                                  parseInt(data?.NDP_price),
                                  data?.GST
                                )) * data?.quantity
                          )}
                        </span>
                        <span className="mobile-main-carts-products-price-details-click">
                          Price Details
                        </span>
                      </div>
                      {openAccordian !== index && (
                        <KeyboardArrowDownSharpIcon
                          className="accordian-carts-icon"
                          style={{ fontSize: "1.8rem" }}
                          onClick={() => handleAccordian(index)}
                        />
                      )}
                      {openAccordian === index && (
                        <KeyboardArrowUpSharpIcon
                          className="accordian-carts-icon"
                          style={{ fontSize: "1.8rem" }}
                          onClick={() => handleAccordian(index)}
                        />
                      )}
                    </div>
                  </div>
                  {openAccordian === index && (
                    <div className="mobile-main-carts-products-pricing-details-accordian-container">
                      <div className="mobile-product-pricing-details">
                        <div className="mobile-product-pricing-div">
                          <p>Price Per Quantity</p>
                          <hr />
                          <span>
                            ₹
                            {formatWithCommasWithDecimal(
                              parseInt(
                                data?.Offer_price
                                  ? calculateOfferPrice(
                                      parseInt(data?.MRP_price),
                                      parseInt(data?.Offer_price),
                                      data?.GST
                                    )
                                  : calculateDealerPrice(
                                      parseInt(data?.MRP_price),
                                      parseInt(data?.NDP_price),
                                      data?.GST
                                    )
                              )
                            )}
                          </span>
                        </div>
                        <div className="mobile-product-pricing-div">
                          <p>GST</p>
                          <hr />
                          <span>
                            ₹
                            {formatWithCommasWithDecimal(
                              calculateGST(
                                parseInt(
                                  data?.NDP_price && parseInt(data?.NDP_price)
                                ),
                                parseInt(data?.GST && data?.GST)
                              )
                            )}
                          </span>
                        </div>
                        <div className="mobile-product-pricing-div">
                          <p>Shipping To</p>
                          <hr />
                          <span>
                            {userDetail?.location}-{userDetail?.pin_code}
                          </span>
                        </div>

                        <div className="mobile-product-pricing-div">
                          <p className="mobile-total-price-text">Total Price</p>
                          <hr />
                          <span className="mobile-total-price-text">
                            ₹
                            {formatWithCommasWithDecimal(
                              handleCurrentPrice(data)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <MobileCartNoProduct text={"preorders"} />
          )}
        </div>
      </div>
    </div>
  );
}

export default MobilePreOrders;
