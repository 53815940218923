import React, { useEffect, useState } from "react";
import "./NearbyStore.css";
import EastIcon from "@mui/icons-material/East";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import noProductLogo from "../../img/no-products-logo.png";
import { API_BASE_URL } from "../../config";

function NearbyStore({ openStore, setOpenStore }) {
  const [dealerStores, setDealerStores] = useState([]);
  const [pcode, setPCode] = useState(openStore && openStore);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [error, setError] = useState(null);
  const handleSubmitPincode = (e) => {
    e.preventDefault();
    const pincode = e.target.elements.pincode.value;
    if (pincode?.length === 6) {
      setOpenStore(pincode);
      fetchDealerStores(pincode);
    }
  };
  const openGoogleMaps = (address) => {
    // console.log(address);
    // Construct Google Maps URL with the address
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
    // Open Google Maps URL in a new tab
    window.open(googleMapsUrl, "_blank");
  };
  // console.log(dealerStores);

  useEffect(() => {
    fetchDealerStores(openStore);
  }, [openStore]);

  const fetchDealerStores = (pincode) => {
    handleOpen();
    fetch(`${API_BASE_URL}fetch_dealers/${pincode}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch dealer stores");
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        setDealerStores(data);
        handleClose();
      })
      .catch((error) => {
        setError(error.message);
        Swal.fire({
          title: "Invalid Pincode",
          text: "Please Enter Valid Pincode.",
          icon: "error",
          confirmButtonText: "OK",
        });
        handleClose();
      });
  };

  const useCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        getAddressFromCoordinates(latitude, longitude);
      });
    } else {
      alert("Geolocation is not supported by this browser.");
      Swal.fire({
        title: "Geolocation not supported",
        text: "Geolocation is not supported by this browser.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const getAddressFromCoordinates = (latitude, longitude) => {
    handleOpen();
    fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setPCode(data?.address?.postcode);
        setOpenStore(data?.address?.postcode);
        handleClose();
      })
      .catch((error) => {
        handleClose();
        console.error("Error:", error);
      });
  };

  const handleInputChange = (e) => {
    setPCode(e.target.value);
  };

  return (
    <div className="nearby-store-container">
      <Backdrop
        sx={{
          color: "#fff",
          borderRadius: "10px",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CloseOutlinedIcon
        id="nearby-store-cross-btn"
        onClick={() => setOpenStore(null)}
      />
      <div className="nearby-store-top-container">
        <span className="nearby-store-heading">Find Your Nearby Store</span>
        <div className="nearby-store-input-container">
          <form
            className="nearby-store-form-container"
            onSubmit={handleSubmitPincode}
          >
            <label
              htmlFor="nearby-store-form-input"
              className="nearby-store-form-label"
            >
              Pincode
            </label>
            <div className="nearby-store-input-btn-container">
              <input
                type="text"
                inputmode="numeric"
                id="nearby-store-form-input"
                name="pincode"
                pattern="[0-9]*"
                onKeyPress={(e) => {
                  // Allow only numbers (0-9) and backspace (8)
                  if (!/[0-9\b]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                onInput={(e) => {
                    // Limit input to 10 digits
                    if (e.target.value.length > 6) {
                      e.target.value = e.target.value.slice(0, 6);
                    }
                  }}
                value={pcode}
                onChange={(e) => handleInputChange(e)}
                maxLength="6"
                max="999999"
                placeholder="Enter Pincode"
                required
              />
              <button type="submit" className="nearby-store-btn">
                <EastIcon />
              </button>
            </div>
          </form>
        </div>
        <span
          className="nearby-store-current-location"
          onClick={useCurrentLocation}
        >
          <LocationOnIcon />
          Use current location
        </span>
      </div>
      <div className="nearby-store-bottom-container">
        <span className="nearby-store-total-count">
          {dealerStores && dealerStores?.length} Suggested Stores
        </span>
        <div className="nearby-store-all-store-container">
          {dealerStores?.length > 0 ? (
            dealerStores?.map((store, index) => (
              <div className="nearby-store-each-store" key={index}>
                <span className="nearby-store-website">{store && store?.business_name}</span>
                <span className="nearby-store-address">
                  {store && store?.address_line_1},&nbsp;
                  {store &&
                    store?.address_line_2 !== "null" &&
                    store?.address_line_2}
                  ,&nbsp;{store && store?.district},&nbsp;
                  {store && store?.state_name},&nbsp;
                  {store && store?.pin_code}
                </span>
                <div className="nearby-store-contact">
                  <span className="nearby-store-phone">
                    <LocalPhoneIcon />
                    +91-{store && store?.PhoneNumber}
                  </span>
                  {store && store?.email && (
                    <hr id="nearby-store-contact-divider" />
                  )}
                  {store && store?.email && (
                    <span className="nearby-store-phone">
                      <MailIcon />
                      {store && store?.email}
                    </span>
                  )}
                </div>
                <span
                  className="nearby-store-direction"
                  onClick={() =>
                    openGoogleMaps(
                      `${store && store?.address_line_1} ${
                        store && store?.district
                      } ${store && store?.state_name} ${
                        store && store?.pin_code
                      }`
                    )
                  }
                >
                  <NearMeOutlinedIcon />
                  Directions
                </span>
              </div>
            ))
          ) : (
            <div className="nearby-store-no-dealer-container">
              <img
                src={noProductLogo}
                alt=""
                className="nearby-store-no-dealer-img"
              />
              <span className="nearby-store-no-dealers">
                No store available for this Pincode
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NearbyStore;
