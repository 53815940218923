import React, { useEffect, useState } from "react";
import "./MobileViewOrder.css";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import GppGoodIcon from "@mui/icons-material/GppGood";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  selectViewOrderDetail,
  viewOrderDetailAsync,
} from "../../features/cartPage/OrderSlice";
import { API_BASE_URL, IMAGE_BASE, TRACKING_ORDER } from "../../config";
import { selectUser } from "../../features/auth/authSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import { formatWithCommasWithDecimal } from "../../utils/utils";

function MobileViewOrder() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const orderDetail = useSelector(selectViewOrderDetail);
  const [expanded, setExpanded] = React.useState("");
  const order_ID = useParams();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  // console.log(orderDetail);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    const fetchData = async () => {
      handleOpen();
      dispatch(viewOrderDetailAsync(order_ID && order_ID?.order_ID));
      handleClose();
    };
    fetchData();
    return () => clearTimeout(2000);
  }, [dispatch]);

  const steps = [
    {
      label: "Order Confirmed",
      icon: <CheckSharpIcon />,
      date:
        orderDetail && new Date(orderDetail?.order_date).toLocaleDateString(),
    },
    { label: "Quality Check", icon: <GppGoodIcon />, date: "Est..." },
    { label: "Dispatched Item", icon: <LocalShippingIcon />, date: "Est..." },
    { label: "Product Delivered", icon: <AllInboxIcon />, date: "Est..." },
  ];

  const [complete, setComplete] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const handleStepClick = (stepIndex) => {
    if (stepIndex <= currentStep || complete) {
      setCurrentStep(stepIndex);
    }
  };

  const calculateShippingCost = (products) => {
    let totalCost = 0;
    if (products) {
      for (const product of products) {
        totalCost +=
          parseInt(product?.Offer_price && product?.Offer_price) ||
          parseInt(product?.NDP_price && product?.NDP_price);
        // *product?.quantity;
      }
    }
    return totalCost >= 3000 ? 0 : 250;
  };

  const calculateTotalProductCost = (products) => {
    let totalCost = 0;
    if (products) {
      for (const product of products) {
        totalCost +=
          parseInt(product?.Offer_price && product?.Offer_price) ||
          parseInt(product?.NDP_price && product?.NDP_price);
        // *product?.quantity;
      }
    }
    return totalCost;
  };
  const calculateTotalGST = (products) => {
    // console.log(products);
    let totalGST = 0;
    if (products) {
      for (const product of products) {
        // Use offer price if available, otherwise use NDP price
        const price = product?.Offer_price
          ? parseFloat(product?.Offer_price)
          : parseFloat(product?.NDP_price);
        const gstRate = product?.GST;

        // Calculate the price excluding GST
        const priceExcludingGST = price / (1 + gstRate / 100);

        // Calculate the GST amount
        const gstAmount = price - priceExcludingGST;

        // Accumulate total GST
        totalGST += gstAmount;
      }
    }
  };

  const calculateTotalPrice = (products) => {
    return (
      calculateTotalProductCost(orderDetail && orderDetail?.orderDetails) +
      // calculateTotalGST(orderDetail && orderDetail?.orderDetails) +
      (calculateTotalProductCost(orderDetail && orderDetail?.orderDetails) >=
      3000
        ? 0
        : 250.0)
    );
  };

  const handleDownloadInvoice = async () => {
    try {
      // Construct the URL for downloading the PDF invoice
      const invoiceUrl = `https://cyclecircle.blr1.digitaloceanspaces.com/${IMAGE_BASE}_INVOICE/${orderDetail?.order_no}.pdf`;

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = invoiceUrl;
      link.setAttribute("download", "invoice.pdf");

      // Simulate a click event to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up resources
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading invoice:", error);
    }
  };
  return (
    <div className="mobile-view-order-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open} // Show backdrop when either open or filtering state is true
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mobile-view-order-header">
        <div className="mobile-view-order-header-left">
          <ChevronLeftOutlinedIcon onClick={() => navigate("/user/orders")} />
          <span className="mobile-view-order-header-left-order-id">
            Order ID: #{orderDetail && orderDetail?.order_no}
          </span>
        </div>
        {orderDetail &&
          orderDetail?.orderStatus &&
          orderDetail?.orderStatus?.tracking_no && (
            <div
              className="mobile-view-order-header-right"
              onClick={handleDownloadInvoice}
            >
              <ReceiptIcon />
              <span>Invoice</span>
            </div>
          )}
      </div>
      <div className="mobile-view-order-date-container">
        <span className="mobile-view-order-order-date">
          Order Date:{" "}
          <b>
            {orderDetail &&
              // new Date(orderDetail?.order_date).toLocaleDateString()}
              new Date(orderDetail?.order_date).toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
          </b>
        </span>
        <div className="mobile-view-order-delivery-order-date">
          <AirplanemodeActiveIcon />{" "}
          <span>
            Est. delivery:{" "}
            {new Date(
              new Date(orderDetail?.order_date).getTime() +
                5 * 24 * 60 * 60 * 1000
            ).toLocaleDateString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </span>{" "}
        </div>
      </div>
      <div className="mobile-view-order-product-container">
        <div className="mobile-view-order-product-total-number">
          {" "}
          Items (
          {orderDetail &&
            orderDetail?.orderDetails &&
            orderDetail?.orderDetails?.length}
          )
        </div>
        <div className="mobile-view-order-product-main-container">
          {orderDetail &&
            orderDetail?.orderDetails &&
            orderDetail?.orderDetails?.map((product) => (
              <div
                className={`my-order-each-order-product-details-container`}
                key={product.item_no_SKU}
              >
                <div className="my-order-each-order-product-top-container">
                  <div className="my-order-each-order-product-left-container">
                    <img
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${product.image}`}
                      alt=""
                      className="my-order-each-order-product-left-img"
                      onClick={() =>
                        navigate(`/product/${product?.product_id}`)
                      }
                    />
                  </div>
                  <div className="my-order-each-order-product-right-container">
                    <img
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                        product && product?.manufacture_images
                      }`}
                      alt=""
                      className="my-order-each-order-product-manufacture-img"
                    />
                    <span className="my-order-each-order-product-name">
                      {product.prod_name}
                    </span>
                    <span className="my-order-each-order-product-tech">
                      {product.prodTech &&
                        product.prodTech
                          .slice(0, 1)
                          .map((tech, index) => <span key={index}>{tech} |</span>)}
                    </span>
                    <div className="my-order-each-order-product-qty-varient-details-container">
                      <span className="my-order-each-order-product-qty">
                        Qty:&nbsp;<b>{product && product?.quantity}</b>
                      </span>
                      <span className="my-order-each-order-product-size">
                        Size: &nbsp;
                        <b>
                          {product && product?.size_name
                            ? product?.size_name?.slice(0, 10)
                            : "---"}
                        </b>
                      </span>
                      <span className="my-order-each-order-product-size">
                        Color: &nbsp;
                        <b>
                          {product && product?.colour_name
                            ? product?.colour_name?.slice(0, 10)
                            : "---"}
                        </b>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="my-order-each-order-product-review-container">
                  <span className="my-order-each-order-prduct-review">
                    Write a review
                  </span>
                  <span className="my-order-each-order-product-return">
                    Return this Item
                  </span>
                </div>
              </div>
            ))}
        </div>
        <div className="mobile-view-order-order-summary-container">
          <span className="mobile-view-order-order-summary-heading">
            Order Summary
          </span>
          <hr />
          <div className="mobile-view-order-order-summary-each-container">
            <span>Order status</span>
            <span>
              {orderDetail && orderDetail?.orderStatus?.status === "pending"
                ? "Confirmed"
                : orderDetail?.orderStatus?.status}
            </span>
          </div>
          <div className="mobile-view-order-order-summary-each-container">
            <span>Order ID</span>
            <span>#{orderDetail && orderDetail?.order_no}</span>
          </div>
          <div className="mobile-view-order-order-summary-each-container">
            <span>Order Placed</span>
            <span>
              {orderDetail &&
                orderDetail?.order_date &&
                new Date(orderDetail?.order_date).toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
            </span>
          </div>
          <div className="mobile-view-order-order-summary-each-container">
            <span>Estimated Delivery Date</span>
            <span>
              {new Date(
                new Date(orderDetail?.order_date).getTime() +
                  5 * 24 * 60 * 60 * 1000
              ).toLocaleDateString("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </span>
          </div>
          <div className="mobile-view-order-order-summary-each-container">
            <span>GST</span>
            <span>{userDetail && userDetail?.GST}</span>
          </div>
          <hr />
          <div className="mobile-view-order-order-summary-each-container">
            <span>Total Products</span>
            <span>{orderDetail && orderDetail?.orderDetails?.length}</span>
          </div>
          <div className="mobile-view-order-order-summary-each-container">
            <span>
              {" "}
              Total Product Cost{" "}
              <b style={{ fontWeight: "400", fontSize: "2.8vw" }}>
                (incl. of all Taxes)
              </b>
            </span>
            <span>
              ₹
              {formatWithCommasWithDecimal(
                calculateTotalProductCost(
                  orderDetail &&
                    orderDetail?.orderDetails &&
                    orderDetail?.orderDetails
                )
              )}
            </span>
          </div>
          <div className="mobile-view-order-order-summary-each-container">
            <span>Shipping & Handling</span>
            <span>
              ₹
              {formatWithCommasWithDecimal(
                orderDetail && orderDetail?.Shipping_Amount === null
                  ? "0.00"
                  : orderDetail?.Shipping_Amount
              )}
            </span>
          </div>
          <div className="mobile-view-order-order-summary-each-container">
            <span>Discount</span>
            <span>
              -₹
              {formatWithCommasWithDecimal(
                orderDetail && orderDetail?.Discount_Amount === null
                  ? "0.00"
                  : orderDetail?.Discount_Amount
              )}
            </span>
          </div>
          <div className="mobile-view-order-order-summary-each-container">
            <span>GST</span>
            <span>
              {" "}
              ₹
              {formatWithCommasWithDecimal(
                calculateTotalGST(orderDetail && orderDetail?.orderDetails)
              )}
            </span>
          </div>
          {/* <hr /> */}
          <div className="mobile-view-order-order-summary-total-price-container">
            <span>Total (₹)</span>
            <span>
              ₹
              {formatWithCommasWithDecimal(
                orderDetail && orderDetail?.TotalAmount
              )}
            </span>
          </div>
        </div>
        <div className="mobile-view-order-payment-method-container">
          <span>Payment Method</span>
          <img
            src="https://myhq.s3.amazonaws.com/benefits/5dfba19e8236fc88159e1a2d.png"
            alt=""
          />
        </div>
        <div className="mobile-view-order-booking-address-container">
          <div className="mobile-view-order-support-address-div">
            <span className="mobile-view-order-support-address-heading">
              Support Address
            </span>
            <span className="mobile-view-order-support-address-main">
              Radkaat Ventures Private Limited, 1st Floor, Unit no.124, Paras
              Trade Centre, Gurgaon Faridabad Road, Gwal Pahari, Gurugram,
              District, Gurugram, Haryana 122003
            </span>
          </div>
          <div className="mobile-view-order-booking-address">
            <span className="mobile-view-order-booking-address-heading">
              Delivery Address
            </span>
            <span className="mobile-view-order-booking-address-main">
              {orderDetail && orderDetail?.Address?.FirstName}{" "}
              {orderDetail && orderDetail?.Address?.LastName} ,{" "}
              {orderDetail && orderDetail?.Address?.address_1},{" "}
              {orderDetail && orderDetail?.Address?.address_2},{" "}
              {orderDetail && orderDetail?.Address?.city},<br />
              {orderDetail && orderDetail?.Address?.district},{" "}
              {orderDetail && orderDetail?.Address?.pin_code},{" "}
              {orderDetail && orderDetail?.Address?.stateName},{" "}
              {orderDetail && orderDetail?.Address?.PhoneNumber}
            </span>
          </div>
        </div>
      </div>
      <div className="mobile-view-order-have-question-container">
        <div className="mobile-view-order-have-question-main-container">
          <span className="mobile-view-order-have-question-heading">
            Have Questions About Your Order?
          </span>
          <span className="mobile-view-order-have-question-supporting-text">
            {" "}
            Our Friendly support team is here for question about status of your
            order,billing,shipping and other order related questions
          </span>
          <Link
            to="https://wa.me/9429693000"
            target="_blank"
            className="mobile-view-order-have-question-contact-support-span"
          >
            Contact Support
          </Link>
        </div>
      </div>
      <div className="mobile-view-order-track-order-order-again-container">
        {orderDetail && orderDetail?.order_no && (
          <a
            href={`${TRACKING_ORDER}${orderDetail?.order_no}`}
            target="_blank"
            rel="noreferrer"
            className="mobile-track-order-btn"
          >
            Track Order
          </a>
        )}
        <span className="mobile-view-order-order-again">Order Again</span>
      </div>
    </div>
  );
}

export default MobileViewOrder;
