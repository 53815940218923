import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import cyclecircleLogo from "../../img/parts/Cycle-Circle-Logo-White-1.png";
import cyclecircleOrangeLogo from "../../img/parts/Cycle-Circle-Logo-Orange-1.png";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import AuthenticationPage from "../Authentication/AuthenticationPage";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import userOutline from "../../img/navigation/header-user-outline.png";
import userFilled from "../../img/navigation/header-user-filled.png";
import favouriteFilled from "../../img/navigation/header-favourite-filled.png";
import favouriteOutline from "../../img/navigation/header-favorite-outline.png";
import cartFilled from "../../img/navigation/header-cart-filled.png";
import cartOutline from "../../img/navigation/header-cart-outline.png";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import kingLogo from "../../icons/badges/king.png";

import {
  setSearchTerm,
  toggleOverlay,
  selectSearchTerm,
  selectIsOverlayVisible,
} from "../../store/slices/headerSlice";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import {
  fetchThroughSearchAsync,
  setSubCategories,
} from "../../features/listingPage/listingPageSlice";
import debounce from "lodash.debounce";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";
import { IMAGE_BASE } from "../../config";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import {
  fetchAllCartsAsync,
  selectAllCarts,
} from "../../features/cartPage/cartPageSlice";
import MiniCart from "./MiniCart";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { API_BASE_URL } from "../../config";
import { setUser } from "../../features/auth/authSlice";
import LogoutOverlay from "../EndPages/LogoutOverlay";
import BigSearch from "./BigSearch";
import { fetchAllRecommendSearchAsync } from "../../features/search/searchSlice";
import Navigations from "./Navigations";
import PartsIcon from "../../img/navigation/parts.png";
import accessoriesIcon from "../../img/navigation/accessories.png";
import clothingIcon from "../../img/navigation/clothing.png";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";
import {
  fetchAllNavigationsAsync,
  selectAllNavigations,
} from "../../features/HomePage/homeSlice";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import userLogo from "../../img/banners/header-user-icon.png";
import cartLogo from "../../img/banners/header-cart-icon.png";
import wishlistFilled from "../../img/banners/Wishlist-Filled.png";
import wishlistOutline from "../../img/banners/Wishlist-Outline.png";
import { ccHundred, premiumUser } from "../../features/cartPage/walletAPI";

function Header({
  searchTerm,
  isOverlayVisible,
  setSearchTerm,
  toggleOverlay,
  setUser,
}) {
  const [isOptionsVisible, setOptionsVisible] = useState(false);
  const [chooseLocation, setChooseLocation] = useState(false);
  const [openMiniCart, setOpenMiniCart] = useState(false);
  const [openProfileOption, setOpenProfileOption] = useState(false);
  const [premUser, setPremUser] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const optionsContainerRef = useRef(null);
  const profileOptionRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userDetail = useSelector(selectUser);
  const carts = useSelector(selectAllCarts);
  const [showBigSearch, setShowBigSearch] = useState(false);
  const bigSearchContainerRef = useRef(null);
  const bigNavigationContainerRef = useRef(null);
  const [showNavigation, setShowNavigation] = useState(null);

  const handleClickOutsideClick = (event) => {
    if (
      bigSearchContainerRef.current &&
      !bigSearchContainerRef.current.contains(event.target)
    ) {
      handleClickInput("false");
      setSearchTerm("");
    }
  };
  const handleClickOutsideNavigationClick = (event) => {
    if (
      !event.target.closest(".headers-middle-vertical-open-container") &&
      !event.target.closest(".headers-middle-each-vertical-container")
    ) {
      setShowNavigation(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mouseover", handleClickOutsideNavigationClick);
    return () => {
      document.removeEventListener(
        "mouseover",
        handleClickOutsideNavigationClick
      );
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideClick);
    return () => {
      document.removeEventListener("click", handleClickOutsideClick);
    };
  }, []);

  const handleClickInput = (value) => {
    if (value === "true") {
      setShowBigSearch(true);
    } else {
      setShowBigSearch(false);
    }
  };

  const handleOpenLogout = () => {
    setOpenLogout(!openLogout);
  };

  const handleClickOutsideProfile = (event) => {
    if (
      profileOptionRef.current &&
      !profileOptionRef.current.contains(event.target)
    ) {
      // Clicked outside the profile option container, close it
      setOpenProfileOption(false);
    }
  };

  useEffect(() => {
    if (openProfileOption) {
      // Attach the event listener when the component mounts
      document.addEventListener("click", handleClickOutsideProfile);
    }

    // Clean up the event listener when the component unmounts or when openProfileOption becomes false
    return () => {
      document.removeEventListener("click", handleClickOutsideProfile);
    };
  }, [openProfileOption]);

  useEffect(() => {
    if (userDetail && userDetail?.UserID) {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail]);

  const addressContainerRef = useRef(null);

  const handleClickOutsideAddressContainer = (event) => {
    if (
      addressContainerRef.current &&
      !addressContainerRef.current.contains(event.target)
    ) {
      setChooseLocation(false); // Close the address container
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideAddressContainer);
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutsideAddressContainer
      );
    };
  }, []);

  const [recentSearches, setRecentSearches] = useState([]);

  // Function to store recent searches in local storage
  const storeRecentSearches = (searches) => {
    const encryptedSearches = btoa(JSON.stringify(searches)); // Convert to non-readable form
    localStorage.setItem("recentSearches", encryptedSearches);
  };

  // Function to fetch recent searches from local storage
  const fetchRecentSearches = () => {
    const encryptedSearches = localStorage.getItem("recentSearches");
    if (encryptedSearches) {
      const decryptedSearches = JSON.parse(atob(encryptedSearches)); // Convert back to readable form
      setRecentSearches(decryptedSearches);
    }
  };

  useEffect(() => {
    fetchRecentSearches();
  }, []);

  const handleSearchDebounced = debounce(() => {
    if (searchTerm.trim().length > 0) {
      dispatch(fetchThroughSearchAsync(searchTerm.trim()));
      dispatch(setSubCategories(null));
      dispatch(setSelectedSubcategory(null));
      dispatch(
        setBreadcrumb({
          categoryID: 0,
          categoryName: "",
          subCategoryID: 0,
          subCategoryName: "",
          ProductID: 0,
          productName: "",
        })
      );
      navigate(`/search/${searchTerm}`);
      if (!recentSearches.includes(searchTerm)) {
        const updatedSearches = [searchTerm, ...recentSearches.slice(0, 4)]; // Limit to maximum of 4 items
        setRecentSearches(updatedSearches);
        storeRecentSearches(updatedSearches);
      }
      setSearchTerm("");
      setShowBigSearch(false);
    }
  }, 300); // Adjust the delay as needed

  const sTerm = useParams();
  useEffect(() => {
    if (sTerm.text && sTerm.text.trim().length > 0) {
      dispatch(fetchThroughSearchAsync(sTerm.text.trim()));
    }
  }, [dispatch, sTerm]);

  const handleSearch = () => {
    if (searchTerm.trim().length === 0) {
      // Show SweetAlert for empty search term
      Swal.fire({
        icon: "error",
        title: "Empty Search Term",
        text: "Please enter a search term before searching.",
      });
      return; // Exit the function early
    }
    handleSearchDebounced();
    setShowVerticalContainer(true);
    setShowSearchInput(false);
    document.getElementById("searchInput").blur();
  };
  const handleKeyInput = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
    // console.log(e.target.value.length);
    if (e.target.value === "") {
      dispatch(fetchAllRecommendSearchAsync("a"));
    } else {
      dispatch(fetchAllRecommendSearchAsync(e.target.value));
    }
  };
  useEffect(() => {
    dispatch(fetchAllRecommendSearchAsync("a"));
  }, [dispatch]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (e.target.value.trim().length === 0) {
        // Show SweetAlert for empty search term
        Swal.fire({
          icon: "error",
          title: "Empty Search Term",
          text: "Please enter a search term before searching.",
        });
        return; // Exit the function early
      }

      // Proceed with the search
      handleSearch();
      navigate(`/search/${e.target.value.trim()}`);
      e.target.value = "";
      setShowBigSearch(false);
    }
  };
  const handleEmptyBreadcrum = () => {
    // dispatch(setBreadcrumb({
    //   categoryID: null,
    //   categoryName: "",
    //   subCategoryID: null,
    //   subCategoryName: "",
    //   ProductID: null,
    //   productName: "",
    // }));
    // navigate("/");
  };

  // Close options container when clicking outside
  const handleClickOutside = (event) => {
    if (
      optionsContainerRef.current &&
      !optionsContainerRef.current.contains(event.target)
    ) {
      setOptionsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOptionsVisible]);

  // Inside the Header component
  useEffect(() => {
    const handleClickOutsideMiniCart = (event) => {
      const miniCart = document.querySelector(".mini-cart-container");
      if (miniCart && !miniCart.contains(event.target)) {
        setOpenMiniCart(false); // Close the MiniCart
      }
    };

    document.addEventListener("mousedown", handleClickOutsideMiniCart);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMiniCart);
    };
  }, []);

  const handleWishlist = () => {
    if (Object.keys(userDetail).length) {
      navigate("/wishlist");
    } else {
      toggleOverlay();
    }
  };
  const handleCart = () => {
    // console.log(userDetail);
    if (Object.keys(userDetail).length > 0) {
      if (location.pathname !== "/carts") {
        setOpenMiniCart(!openMiniCart);
      }
    } else {
      toggleOverlay();
    }
  };
  const handleProfile = () => {
    setOpenProfileOption(!openProfileOption);
  };

  const handleChooseLocation = () => {
    setChooseLocation(!chooseLocation);
  };
  const navigateURL = (url) => {
    navigate(url);
  };
  const words = ["“Frameset”", "“Cockpit”", "“Light”", "“Hydration”"];

  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState("");
  const [forward, setForward] = useState(true);

  useEffect(() => {
    let animationTimeout;

    const animate = () => {
      if (forward) {
        animationTimeout = setTimeout(() => {
          setCurrentWord((prevWord) => {
            if (prevWord.length < words[currentWordIndex].length) {
              return prevWord + words[currentWordIndex][prevWord.length];
            } else if (prevWord === words[currentWordIndex]) {
              setTimeout(() => {
                setForward(false);
                setCurrentWordIndex((currentWordIndex + 1) % words.length);
              }, 3000);
              return prevWord;
            } else {
              return prevWord;
            }
          });
        }, 50);
      } else {
        animationTimeout = setTimeout(() => {
          setCurrentWord((prevWord) =>
            prevWord.substring(0, prevWord.length - 1)
          );
          if (currentWord === "") {
            setForward(true);
          } else {
            animate();
          }
        }, 50);
      }
    };

    animate();

    return () => clearTimeout(animationTimeout);
  }, [currentWord, currentWordIndex, forward]);

  const [showVerticalContainer, setShowVerticalContainer] = useState(true);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const inputRef = useRef(null);

  const handleAnimationContainerClick = () => {
    setShowVerticalContainer(false);
    setShowSearchInput(true);
  };

  useEffect(() => {
    if (showSearchInput) {
      inputRef.current.focus();
    }
  }, [showSearchInput]);

  const handleDocumentClick = (e) => {
    if (
      !e.target.closest(".headers-middle-search-container") &&
      !e.target.closest(".headers-middle-search-big-search-container")
    ) {
      setShowVerticalContainer(true);
      setShowSearchInput(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleNavigations = (vertical) => {
    if (showNavigation === vertical) {
      return;
    } else {
      dispatch(fetchAllNavigationsAsync(vertical));
      setShowNavigation(vertical);
    }
  };

  function handleMouseOver(event) {
    event.target.src = userFilled;
  }

  function handleMouseOut(event) {
    event.target.src = userOutline;
  }

  function handleCartMouseOver(event) {
    event.target.src = cartFilled;
  }

  function handleCartMouseOut(event) {
    event.target.src = cartOutline;
  }

  const handleMouseEnter = () => {
    document.getElementById("logoImg").src = cyclecircleOrangeLogo;
  };
  const handleMouseLeave = () => {
    document.getElementById("logoImg").src = cyclecircleLogo;
  };

  useEffect(() => {
    const responsePromise = ccHundred(userDetail?.UserID);
    responsePromise
      .then((response) => {
        // console.log(response); // Ensure response is as expected
        if (response && response?.data && response?.data?.message) {
          setPremUser(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error if necessary
      });
  }, [userDetail?.UserID]);
  return (
    <>
      {showNavigation && <div className="navigations-overlay"></div>}
      <div className="headers-container">
        {showSearchInput && <div className="search-overlay"></div>}
        <Link
          to="/"
          onClick={handleEmptyBreadcrum}
          className="headers-left-logo-container"
        >
          <img
            id="logoImg"
            src={cyclecircleLogo}
            alt="website logo"
            className="headers-left-website-logo"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </Link>
        <div className="headers-middle-vertical-and-search-container">
          {showVerticalContainer && (
            <div className="headers-middle-vertical-container">
              <div
                className="headers-middle-each-vertical-container"
                onMouseEnter={() => handleNavigations(1)}
                onClick={() => handleNavigations(1)}
                ref={bigNavigationContainerRef}
              >
                <img
                  src={PartsIcon}
                  alt=""
                  className="headers-middle-each-vertical-img"
                  style={{ background: "rgb(175, 187, 212)" }}
                />
                <span className="headers-middle-each-vertical-name">Parts</span>
                <hr
                  className="headers-middle-parts-vartical-hr"
                  id={`${showNavigation === 1 && "parts-vertical-active"}`}
                />
              </div>
              <div
                className="headers-middle-each-vertical-container"
                onMouseEnter={() => handleNavigations(2)}
                onClick={() => handleNavigations(2)}
                ref={bigNavigationContainerRef}
              >
                <img
                  src={accessoriesIcon}
                  alt=""
                  className="headers-middle-each-vertical-img"
                  style={{ background: "rgb(168, 213, 180)" }}
                />
                <span className="headers-middle-each-vertical-name">
                  Accessories
                </span>
                <hr
                  className="headers-middle-accessories-vartical-hr"
                  id={`${
                    showNavigation === 2 && "accessories-vertical-active"
                  }`}
                />
              </div>
              <div
                className="headers-middle-each-vertical-container"
                onMouseEnter={() => handleNavigations(3)}
                onClick={() => handleNavigations(3)}
                ref={bigNavigationContainerRef}
              >
                <img
                  src={clothingIcon}
                  alt=""
                  className="headers-middle-each-vertical-img"
                  style={{ background: "rgb(216, 170, 169)" }}
                />
                <span className="headers-middle-each-vertical-name">
                  Maintenance
                </span>
                <hr
                  className="headers-middle-maintenance-vartical-hr"
                  id={`${
                    showNavigation === 3 && "maintenance-vertical-active"
                  }`}
                />
              </div>
            </div>
          )}
          <div className="headers-middle-search-container">
            {showSearchInput && (
              <div className="headers-middle-search-main-container">
                <input
                  id="searchInput"
                  ref={inputRef}
                  type="text"
                  placeholder="Search over 3000+ products"
                  className="headers-middle-search-input"
                  value={searchTerm}
                  onChange={(e) => handleKeyInput(e)}
                  onKeyPress={handleKeyPress}
                  onFocus={() => handleClickInput("true")}
                  onClick={(e) => e.stopPropagation()}
                />
                <CloseOutlinedIcon id="search-remove-cross" />
              </div>
            )}
            <div
              className="headers-middle-search-animation-container"
              style={{ display: showSearchInput ? "none" : "flex" }}
              onClick={handleAnimationContainerClick}
            >
              <span className="headers-middle-search-animation-search-for">
                Search for
              </span>
              <span className="headers-middle-search-animation-parts">
                {currentWord}
                <b>|</b>
              </span>
            </div>
            <SearchSharpIcon
              id="headers-middle-search-icon"
              onClick={handleSearch}
            />
          </div>
          {showBigSearch && (
            <div
              className="headers-middle-search-big-search-container"
              ref={bigSearchContainerRef}
            >
              <BigSearch
                searchTerm={searchTerm}
                recentSearches={recentSearches}
                storeRecentSearches={storeRecentSearches}
                setRecentSearches={setRecentSearches}
                handleClickInput={handleClickInput}
                setShowVerticalContainer={setShowVerticalContainer}
                setShowSearchInput={setShowSearchInput}
                setShowBigSearch={setShowBigSearch}
              />
            </div>
          )}
          {showNavigation && (
            <div
              className="headers-middle-vertical-open-container"
              ref={bigNavigationContainerRef}
            >
              <Navigations
                handleNavigations={handleNavigations}
                showNavigation={showNavigation}
              />
            </div>
          )}
        </div>
        <div className="headers-right-container">
          <div className="headers-join-us-container">
            {Object.keys(userDetail).length ? (
              <div
                className="headers-login-user-address-container"
                ref={addressContainerRef}
              >
                <div className="headers-login-user-address-main-container">
                  <LocationOnOutlinedIcon />
                  <div className="headers-login-user-main-address-span-div">
                    {userDetail?.FirstName !== null && userDetail?.LastName ? (
                      <div
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "column",
                        }}
                        onClick={handleChooseLocation}
                      >
                        <span className="headers-user-name-address">
                          Deliver to {userDetail?.FirstName}
                        </span>
                        <span className="headers-user-location-address">
                          {userDetail?.location} {userDetail.pin_code}
                        </span>
                      </div>
                    ) : (
                      <div className="user-pick-location">
                        <span
                          className="user-update-location"
                          onClick={handleChooseLocation}
                        >
                          Update location
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <span className="headers-join-us-btn" onClick={toggleOverlay}>
                Join Us
              </span>
            )}
            {chooseLocation && (
              <div className="user-address-component">
                <div
                  className="user-added-address-container"
                  onClick={handleChooseLocation}
                >
                  <b className="user-added-firstName">
                    {userDetail?.FirstName}
                  </b>{" "}
                  <span className="user-added-address-1">
                    {userDetail?.address_line_1},
                  </span>
                  <span className="user-added-address-2">
                    {userDetail?.address_line_2},
                  </span>
                  <p className="user-added-city">{userDetail?.location},</p>
                  <span className="user-added-district">
                    {userDetail?.district}
                  </span>{" "}
                  -{" "}
                  <span className="user-added-pin_code">
                    {userDetail?.pin_code}
                  </span>
                </div>
                <div className="user-address-add-more-div">
                  <AddSharpIcon />
                  <span>Add new address or pick-up point</span>
                </div>
              </div>
            )}
          </div>
          <div className="headers-user-container">
            {Object.keys(userDetail).length ? (
              <>
                <img
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
                    userDetail?.UserImage || ""
                  }`}
                  alt={userDetail?.UserImage || ""}
                  onClick={handleProfile}
                  className="headers-user-img"
                />
              </>
            ) : (
              <img
                src={userOutline}
                alt=""
                className="headers-user-icon"
                onClick={handleProfile}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              />
            )}
            {openProfileOption && (
              <div
                ref={profileOptionRef}
                className="headers-user-profile-option-container"
              >
                <div className="headers-user-profile-option-img-name-div">
                  {Object.keys(userDetail).length ? (
                    <>
                      <img
                        src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
                          userDetail?.UserImage || ""
                        }`}
                        alt={userDetail?.UserImage || ""}
                        className="headers-user-profile-option-img-tag"
                      />
                      <span className="headers-user-profile-option-name-tag">
                        {userDetail?.FirstName} {userDetail?.LastName}
                      </span>
                    </>
                  ) : (
                    <>
                      <PersonAddAltOutlinedIcon onClick={handleProfile} />
                      <span
                        className="headers-user-login-profile-inner"
                        onClick={toggleOverlay}
                      >
                        Login/SignUp
                      </span>
                    </>
                  )}
                </div>
                <div className="headers-user-profile-option-main-container">
                  {premUser && (
                    <div
                      className="headers-user-profile-option-my-profile-div"
                      onClick={() => {
                        if (Object.keys(userDetail).length) {
                          navigateURL("/admin/dashboard");
                        } else {
                          toggleOverlay();
                        }
                        setOpenProfileOption(false);
                      }}
                    >
                      <img
                        src={kingLogo}
                        alt=""
                        className="king-logo"
                        style={{ width: "1.7vw", marginRight: "0.55vw" }}
                      />
                      <span>Admin Dashboard</span>
                    </div>
                  )}

                  <div
                    className="headers-user-profile-option-my-profile-div"
                    onClick={() => {
                      if (Object.keys(userDetail).length) {
                        navigateURL("/user/profile");
                      } else {
                        toggleOverlay();
                      }
                      setOpenProfileOption(false);
                    }}
                  >
                    <AccountCircleOutlinedIcon />
                    <span>My profile</span>
                  </div>
                  <div
                    className="headers-user-profile-option-my-profile-div"
                    onClick={() => {
                      if (Object.keys(userDetail).length) {
                        navigateURL("/user/preorders");
                      } else {
                        toggleOverlay();
                      }
                      setOpenProfileOption(false);
                    }}
                  >
                    <WorkHistoryOutlinedIcon />
                    <span>Pre-Orders</span>
                  </div>
                  <div
                    className="headers-user-profile-option-my-profile-div"
                    onClick={() => {
                      if (Object.keys(userDetail).length) {
                        navigateURL("/user/orders");
                      } else {
                        toggleOverlay();
                      }
                      setOpenProfileOption(false);
                    }}
                  >
                    <Inventory2OutlinedIcon />
                    <span>Orders</span>
                  </div>
                  <div
                    className="headers-user-profile-option-my-profile-div"
                    onClick={() => {
                      if (Object.keys(userDetail).length) {
                        navigateURL("/wishlist");
                      } else {
                        toggleOverlay();
                      }
                      setOpenProfileOption(false);
                    }}
                  >
                    <FavoriteBorderIcon />
                    <span>Wishlist</span>
                  </div>
                  {Object.keys(userDetail).length > 0 && (
                    <div
                      className="headers-user-profile-option-logout-div"
                      onClick={handleOpenLogout}
                    >
                      <LogoutIcon />
                      <span>Logout</span>
                      {openLogout && (
                        <LogoutOverlay handleOpenLogout={handleOpenLogout} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="headers-wishlist-container">
            {location.pathname === "/wishlist" ? (
              <img
                src={favouriteFilled}
                alt=""
                className="headers-wishlist-filled-img"
              />
            ) : (
              <img
                src={favouriteOutline}
                alt=""
                className="headers-wishlist-filled-img"
                onClick={handleWishlist}
              />
            )}
          </div>
          <div className="headers-cart-container" onClick={handleCart}>
            <img
              src={cartOutline}
              onMouseOver={handleCartMouseOver}
              onMouseOut={handleCartMouseOut}
              alt=""
              className="headers-cart-filled-img"
            />
            {carts && carts.length > 0 && (
              <sup>
                {Object.keys(userDetail).length > 0 && carts && carts?.length
                  ? carts.length
                  : "0"}
              </sup>
            )}
            {openMiniCart && <MiniCart />}
          </div>
        </div>
        {isOverlayVisible && (
          <div className="overlay-container">
            <AuthenticationPage />
          </div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  searchTerm: selectSearchTerm(state),
  isOverlayVisible: selectIsOverlayVisible(state),
  user: state.user,
});
const mapDispatchToProps = {
  setSearchTerm,
  toggleOverlay,
  setUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
