import React from "react";
import "./LogoutOverlay.css";
import { connect } from "react-redux";
import { setUser } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";

function LogoutOverlay({handleOpenLogout,setUser}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLogout = async () => {
        try {
          // Remove token and user_id from cookies
          document.cookie = "token=;";
          document.cookie = "UserID=;";
    
          // Show SweetAlert for successful logout
          Swal.fire({
            icon: "success",
            title: "Logged Out Successfully",
            text: "You have been successfully logged out.",
          });
    
          dispatch(setUser({}));
        //   setOpenProfileOption(false);
        handleOpenLogout();
          // navigate("/");
          window.location.reload();
    
          // Perform any additional actions after successful logout, such as redirecting to the login page
        } catch (error) {
          // Handle error if logout fails
          console.error("Logout Error:", error);
          // Show SweetAlert for error during logout
          Swal.fire({
            icon: "error",
            title: "Logout Failed",
            text: "An error occurred while logging out. Please try again later.",
          });
        }
      };

      const handleNo = ()=>{
        handleOpenLogout();
      }
  return (
    <>
      <div className="mobile-logout-overlay"></div>
      <div className="logout-overlay-container">
        <h3>Log out?</h3>
        <span className="want-logout">Are you sure want to logout?</span>
        <div className="logout-btn-div">
          <span className="no-btn" onClick={handleNo}>No</span>
          <span className="yes-btn" onClick={handleLogout}>Yes</span>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
    user: state.user,
  });
  const mapDispatchToProps = {
    setUser,
  };
  export default connect(mapStateToProps, mapDispatchToProps)(LogoutOverlay);
