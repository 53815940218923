import React from 'react';
import "./MobileCartNoProduct.css";
import noCartLogo from "../../img/no-cart-item.png";
import { useNavigate } from "react-router";

function MobileCartNoProduct({text}) {
    const navigate = useNavigate();
    const handleAddToCart = () => {
      navigate("/");
    };
  return (
    <div className="no-cart-item-container">
      <div className="no-cart-item-div">
        <img src={noCartLogo} alt="" />
        <h3>No Items Found!</h3>
        <span className="sorry-mate">
          Sorry mate... no items found inside your {text}
        </span>
        <span className="add-items-to-cart-btn" onClick={handleAddToCart}>
          Add items To {text}
        </span>
      </div>
    </div>
  )
}

export default MobileCartNoProduct