import React, { useEffect, useState } from "react";
import "./MobileSortByFilter.css";
import SortIcon from "@mui/icons-material/Sort";
import FilterListIcon from "@mui/icons-material/FilterList";
import { connect, useDispatch, useSelector } from "react-redux";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import {
  setBrands,
  setDealerMargin,
  setMaterials,
  setMaxPrice,
  setMinPrice,
  setRating,
  setShipping,
  setShopByOffer,
} from "../../store/slices/allFilterSlice";
import {
  clearAllFilters,
  setDealerMargins,
  setPriceRange,
  setRatings,
  setShippingMode,
  setShopByOffers,
} from "../../store/slices/listingPageFilterSlice";
import FilterComponent from "../../components/ListingPage/Filter/FilterComponent";
import MobileSortBy from "./MobileSortBy";
import SortSharpIcon from "@mui/icons-material/SortSharp";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import { useParams } from "react-router";
import {
  fetchAllCategoryProductsAsync,
  fetchAllSubCategoriesAsync,
  fetchAllSubCategoryProductsAsync,
  fetchThroughBrandAsync,
  selectAllCategoryProducts,
  selectAllSubCategories,
  setSubCategories,
} from "../../features/listingPage/listingPageSlice";
import {
  selectedSelectedSubcategory,
  setSelectedSubcategory,
} from "../../store/slices/subCategorySlice";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";
import { IMAGE_BASE } from "../../config";
import { Slide } from "react-reveal";

function MobileSortByFilter({
  shippingMode,
  priceRange,
  ratings,
  dealerMargins,
  shopByOffers,
  setShippingMode,
  setPriceRange,
  setRatings,
  setDealerMargins,
  setShopByOffers,
  clearAllFilters,
  categoryName,
  CategoryID,
  setCurrentPage
}) {
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.allFilter?.brands);
  const materials = useSelector((state) => state.allFilter?.materials);
  const sortPrice = useSelector((state) => state.allFilter?.sortPrice);
  const sortRating = useSelector((state) => state.allFilter?.sortRating);
  // console.log(brands, materials);
  const [openFilter, setOpenFilter] = useState(null);
  const [isSortByOpen, setIsSortByOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [openCtg, setOpenCtg] = useState(false);

  const handleFilterOpen = () => {
    setFilterOpen(!filterOpen);
  };
  const toggleSortBy = () => {
    setIsSortByOpen((prevIsSortByOpen) => !prevIsSortByOpen);
  };
  const toggleFilter = (filter) => {
    setOpenFilter((prevFilter) => (prevFilter === filter ? null : filter));
  };
  const handleSelection = (selectedOption) => {
    // console.log(selectedOption);
    setOpenFilter(null);

    if (selectedOption?.brands) {
      dispatch(setBrands(selectedOption?.brands));
    }

    if (selectedOption?.materials) {
      dispatch(setMaterials(selectedOption?.materials));
    }
  };
  const handleClearAll = () => {
    clearAllFilters();
    setRatings("");
    setDealerMargins("");
    setShopByOffers("");
    dispatch(setRating(0));
    dispatch(setDealerMargin(0));
    dispatch(setShopByOffer(""));
    setPriceRange("");
    dispatch(setMinPrice(0));
    dispatch(setMaxPrice(0));
    setShippingMode("");
    dispatch(setShipping(""));
    dispatch(setBrands([]));
    dispatch(setMaterials([]));
  };
  const getTotalFiltersCount = () => {
    let totalFilters = 0;
    if (shippingMode) totalFilters++;
    if (priceRange) totalFilters++;
    if (ratings) totalFilters++;
    if (dealerMargins) totalFilters++;
    if (shopByOffers) totalFilters++;
    totalFilters += brands?.length;
    totalFilters += materials?.length;
    return totalFilters;
  };

  // SubCategory

  const handleOpenCtg = () => {
    setOpenCtg(!openCtg);
  };

  const brandname = useParams();
  const subCategories = useSelector(selectAllSubCategories);
  const selectedSubcategory = useSelector(selectedSelectedSubcategory);
  const listProduct = useSelector(selectAllCategoryProducts);
  const listingProduct = listProduct?.Data;
  const totalProductLength = listingProduct?.product?.length || 0;

  useEffect(() => {
    if (CategoryID && brandname?.brand === undefined) {
      dispatch(fetchAllSubCategoriesAsync(CategoryID));
    }
  }, [dispatch, CategoryID, brandname?.brand]);

  // Set selected subCategory
  const handleApplyClick = (subCategoryID, subCategoryName) => {
    dispatch(
      setSelectedSubcategory(
        selectedSubcategory === subCategoryID ? null : subCategoryID
      )
    );
    // Set subcategory in breadcrumb
    dispatch(
      setBreadcrumb({
        categoryID: CategoryID,
        categoryName: categoryName,
        subCategoryID: subCategoryID,
        subCategoryName: subCategoryID ? subCategoryName : "",
        productName: "",
        ProductID: null,
      })
    );
    setCurrentPage(1);
  };

  // Run when coming to another page and sub-category is already selected previously
  const fetchDataBasedOnSelection = () => {
    if (brandname?.brand) {
      dispatch(fetchThroughBrandAsync(brandname?.brand));
      dispatch(setSubCategories([]));
      dispatch(setSelectedSubcategory(null));
      dispatch(
        setBreadcrumb({
          categoryID: null,
          categoryName: brandname?.brand,
          subCategoryID: null,
          subCategoryName: "",
          productName: "",
          ProductID: null,
        })
      );
    } else if (
      selectedSubcategory ||
      (subCategories && subCategories?.length > 0)
    ) {
      const isSubCategorySelected = subCategories?.some(
        (item) => item.sc_id === selectedSubcategory
      );

      if (isSubCategorySelected) {
        dispatch(fetchAllSubCategoryProductsAsync(selectedSubcategory));
      }
      if (!selectedSubcategory) {
        // dispatch(fetchAllSubCategoryProductsAsync(selectedSubcategory));
        dispatch(fetchAllCategoryProductsAsync(CategoryID));
      }
    }
  };

  useEffect(() => {
    fetchDataBasedOnSelection();
  }, [selectedSubcategory, brandname?.brand]);

  // Convert Category name to Uppercase
  const formattedCategoryName =
    categoryName?.charAt(0).toUpperCase() +
    categoryName?.slice(1).toLowerCase();

  return (
    <div>
      <div className="mobile-filter-sortby-upper-container">
        <div
          className="mobile-sortby-upper-div"
          onClick={() => toggleFilter("sortBy")}
        >
          <SortSharpIcon />
          <span>Sort By</span>
        </div>
        {openFilter === "sortBy" && (
          <MobileSortBy
            onSelect={handleSelection}
            closeSortBySelect={() => setOpenFilter(null)}
            previouslySortPrice={sortPrice}
            previouslySortRating={sortRating}
          />
        )}
        <span className="mobile-filter-seperate-line">|</span>
        <div className="mobile-filter-upper-div" onClick={handleFilterOpen}>
          <FilterListSharpIcon />
          <span>Filter</span>
          {getTotalFiltersCount() > 0 && (
            <span className="filter-added-number">
              {getTotalFiltersCount()}
            </span>
          )}
        </div>
        <span
          className="mobile-filter-subcategory-container"
          onClick={handleOpenCtg}
        >
          Subcategories
        </span>
      </div>
      <div className="mobile-filter-main-z-container">
        {filterOpen && (
          <FilterComponent
            setFilterOpen={setFilterOpen}
            onSelect={handleSelection}
            closeBrandSelect={() => setOpenFilter(null)}
            previouslySelectedBrands={brands}
            closeBikesSelect={() => setOpenFilter(null)}
            closeMaterialSelect={() => setOpenFilter(null)}
            previouslySelectedMaterials={materials}
            handleClearAll={handleClearAll}
          />
        )}
      </div>
      {getTotalFiltersCount() > 0 && (
        <div className="mobile-all-filter-added-component">
          <div className="mobile-all-filter-added-main-container">
            {shippingMode && (
              <div className="mobile-all-filter-shipping-mode-container">
                <>
                  <span>{shippingMode} </span>
                  <CloseSharpIcon
                    onClick={() => {
                      setShippingMode("");
                      dispatch(setShipping(""));
                    }}
                  />
                </>
              </div>
            )}
            {priceRange && (
              <div className="mobile-all-filter-price-range-container">
                <span>{priceRange} </span>
                <CloseSharpIcon
                  onClick={() => {
                    setPriceRange("");
                    dispatch(setMinPrice(0));
                    dispatch(setMaxPrice(0));
                  }}
                />
              </div>
            )}
            {ratings && (
              <div className="mobile-all-filter-rating-range-container">
                <span>{ratings}+</span>
                <StarRoundedIcon
                  style={{
                    color: "gold",
                    fontSize: "1.2rem",
                    marginLeft: "0",
                    cursor: "default",
                  }}
                />
                <CloseSharpIcon
                  onClick={() => {
                    setRatings("");
                    dispatch(setRating(0));
                  }}
                />
              </div>
            )}
            <div className="mobile-all-filter-brands-container">
              {brands &&
                brands?.map((brand, index) => (
                  <div
                    className="mobile-all-filter-brand-container"
                    key={index}
                  >
                    <span>{brand}</span>
                    <CloseSharpIcon
                      onClick={() => {
                        dispatch(
                          setBrands(brands.filter((b, i) => i !== index))
                        );
                      }}
                    />
                  </div>
                ))}
            </div>
            {dealerMargins && (
              <div className="mobile-all-filter-rating-range-container">
                <>
                  <span>{dealerMargins}%+ Dealer Margin</span>
                  <CloseSharpIcon
                    onClick={() => {
                      setDealerMargins("");
                      dispatch(setDealerMargin(0));
                    }}
                  />
                </>
              </div>
            )}
            {shopByOffers && (
              <div className="mobile-all-filter-rating-range-container">
                <>
                  <span>{shopByOffers}</span>
                  <CloseSharpIcon
                    onClick={() => {
                      setShopByOffers("");
                      dispatch(setShopByOffer(""));
                    }}
                  />
                </>
              </div>
            )}
            <div className="mobile-all-filter-materials-container">
              {materials &&
                materials?.map((material, index) => (
                  <div
                    className="mobile-all-filter-material-container"
                    key={index}
                  >
                    <span>{material}</span>
                    <CloseSharpIcon
                      onClick={() => {
                        dispatch(
                          setMaterials(materials.filter((m, i) => i !== index))
                        );
                      }}
                    />
                  </div>
                ))}
            </div>
            {shippingMode ||
            priceRange ||
            ratings ||
            brands?.length > 0 ||
            materials?.length > 0 ? (
              <div className="mobile-all-filter-clear-container">
                <span
                  className="mobile-allFilterComponent-clearAll"
                  onClick={handleClearAll}
                >
                  Clear all
                </span>
                {/* <DeleteForeverSharpIcon onClick={handleClearAll} /> */}
              </div>
            ) : null}
          </div>
        </div>
      )}
      {openCtg && <div className="subctg-overlay-mobile"></div>}
      {openCtg && (
        <Slide bottom>
          <div className="mobile-filter-subctg-bottom-container">
            <div className="mobile-filter-subctg-bottom-header">
              <span className="mobile-filter-subctg-bottom-header-name">
                All {categoryName} Subcategories
              </span>
              <CloseSharpIcon onClick={handleOpenCtg} />
            </div>
            <div className="mobile-filter-subctg-bottom-main-ctg-list">
              {subCategories?.map((item) => (
                <div
                  className={`mobile-filter-each-subcategory`}
                  
                  key={item?.sc_id}
                  onClick={() => {
                    handleApplyClick(
                      selectedSubcategory === item?.sc_id ? null : item?.sc_id,
                      item?.SubcategorieName
                    );
                    handleOpenCtg();
                  }}
                >
                  <img
                    src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_SUBCATEGORY_IMAGE/${item?.image}`}
                    alt="sub-cat-logo"
                    className="mobile-filter-each-subctg-logo"
                    style={{border:selectedSubcategory === item?.sc_id ? "2px solid var(--color-blue)":"1px solid rgba(0, 0, 0, 0.1)"}}
                  />
                  <span className={`mobile-filter-each-subctg-name `}>
                    {item?.SubcategorieName}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </Slide>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  shippingMode: state.listingPageFilter?.shippingMode,
  priceRange: state.listingPageFilter?.priceRange,
  ratings: state.listingPageFilter?.ratings,
  dealerMargins: state.listingPageFilter?.dealerMargins,
  shopByOffers: state.listingPageFilter?.shopByOffers,
});

const mapDispatchToProps = {
  setShippingMode,
  setPriceRange,
  setRatings,
  setDealerMargins,
  setShopByOffers,
  clearAllFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileSortByFilter);
