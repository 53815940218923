import { API_BASE_URL } from "../../config";
export function fetchAllAddress(UserID) {
    return new Promise(async (resolve) => {
      // const response = await fetch(`http://localhost:8000/api/v1/shipping_address/${UserID}`);
      const response = await fetch(`${API_BASE_URL}shipping_address/${UserID}`);
      const data = await response.json();
      // console.log("from API",data)
      resolve({ data });
    });
  }
  export function addAddress(addressData) {
    // console.log(addressData);
    return new Promise(async (resolve, reject) => {
      try {
        // const response = await fetch(`http://localhost:8000/api/v1/shipping_address`, {
        const response = await fetch(`${API_BASE_URL}shipping_address`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any additional headers if needed
          },
          body: JSON.stringify(addressData),
        });
  
        if (!response.ok) {
          // Handle non-successful responses
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        // const newData = data.carts;
        // console.log("from API",newData)
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
  export function removeAddress(shippingID) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${API_BASE_URL}shipping_address/${shippingID}`, {
          method: 'DELETE', // Use the DELETE method for removing an address
        });
        if (!response.ok) {
          throw new Error('Failed to remove address');
        }
        const data = await response.json();
        resolve({ data });
      } catch (error) {
        reject(error);
      }
    });
  }
  
  export function modifyAddress(addressData) {
    // console.log(addressData);
    let modifyData = addressData.data;
    let shippingID = addressData.shippingID;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${API_BASE_URL}shipping_address/${shippingID}`, {
          // const response = await fetch(`http://localhost:8000/api/v1/shipping_address/${shippingID}`, {
          
          method: 'PATCH', // Use the PATCH method for modifying an address
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(modifyData), // Send the modified data in the request body
        });
        if (!response.ok) {
          throw new Error('Failed to modify address');
        }
        const data = await response.json();
        resolve({ data });
      } catch (error) {
        reject(error);
      }
    });
  }
  
  