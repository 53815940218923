import React from 'react';
import "./MobileSignUpSideBar.css"
import { useNavigate } from 'react-router';
import DoneIcon from "@mui/icons-material/Done";
import CyclecircleLogo from "../../../img/parts/Cycle-Circle-Logo-Orange-1.png";

function MobileSignUpSideBar({ currentStep, steps }) {
  const navigate = useNavigate();
  const handleNavigate = ()=>{
    navigate("/");
  }
  return (
    <div className="mobile-signup-sidebar-container">
    <div className="mobile-sign-up-website-logo-container">
      <img src={CyclecircleLogo} alt="" className="mobile-sign-up-website-logo-img" onClick={handleNavigate}/>
    </div>
    <div className="mobile-signup-sidebar-main-container">
      {steps.map((step, index) => (
        <div key={index} className="mobile-signup-sidebar-each-information-div">
          <div
            className={`mobile-signup-sidebar-each-info-dot-div ${
              index < currentStep ? "completed" : ""
            } ${index === currentStep ? "active-step" : ""}`}
          >
            {index < currentStep ? (
              <DoneIcon />
            ) : (
              <span
                className={`mobile-signup-sidebar-each-info-dot ${
                  index < currentStep ? "completed" : ""
                } ${index === currentStep ? "active-step" : ""}`}
              ></span>
            )}
          </div>
          {index !== steps.length - 1 && (
            <hr
              className={`mobile-signup-sidebar-each-info-hr ${
                index < currentStep ? "completed" : ""
              }  ${index === currentStep ? "active-step" : ""}`}
            />
          )}
        </div>
      ))}
    </div>
    <span className="mobile-signup-current-step-name">
              {steps[currentStep].name}
    </span>
  </div>
  )
}

export default MobileSignUpSideBar