import React, { useState, useRef, useEffect, useCallback } from "react";
import "./ChangeEmail.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setOTPVerified } from "../../../store/slices/userSlice";
import Swal from "sweetalert2";
// import "bootstrap/dist/css/bootstrap.min.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API_BASE_URL } from "../../../config";
import { toggleOverlay } from "../../../store/slices/headerSlice";
import {
  fetchUserAsync,
  selectUser,
  setToken,
} from "../../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";

function ChangeEmail({
  handleChangeVerify,
  phoneNumber,
  setPhoneNumber,
  setOTPVerified,
  toggleOverlay,
  setToken,
}) {
  const userDetail = useSelector(selectUser);
  const [openEmailOTP, setOpenEmailOTP] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const [newEmail, setNewEmail] = useState(null);
  const inputRefs = useRef(
    Array.from({ length: 6 }).map(() => React.createRef())
  );

  const handleInputChange = useCallback(
    (index, value) => {
      // Accept only numeric values
      const numericValue = value.replace(/\D/g, "");

      const newOTP = [...otp];
      newOTP[index] = numericValue.slice(0, 1); // Only take the first digit

      setOTP(newOTP);

      // Move to the next input
      if (numericValue !== "" && index < 5 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].current.focus();
      }
    },
    [otp]
  );

  const handleOpenOTP = async () => {
    if (!newEmail || !newEmail.includes("@")) {
      Swal.fire({
        title: "Enter phone number correctly",
        text: "Please Enter phone number correctly",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      handleOpen();
      try {
        const response = await fetch(
          `${API_BASE_URL}update_new_email/${userDetail?.UserID}/${newEmail}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          handleClose();
          // console.log(data);
          setOpenEmailOTP(!openEmailOTP);
        } else {
          handleClose();
          console.error("API request failed:", response.statusText);
          Swal.fire({
            title: "Invalid Phone number",
            text: "Please check and try again",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        handleClose();
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        Swal.fire({
          title: "Error",
          text: "An unexpected error occurred. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
      handleClose();
    }
  };

  const handleVerifyOTP = async () => {
    handleOpen();
    const enteredOTP = otp.join("");
    // Perform OTP verification logic here using enteredOTP

    if (!enteredOTP || enteredOTP.length < 6) {
      handleClose();
      Swal.fire({
        title: "Enter OTP correctly",
        text: "Please Enter OTP correctly",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}verify_new_email_otp/${userDetail?.UserID}/${enteredOTP}/${newEmail}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          dispatch(fetchUserAsync(userDetail?.UserID));
          handleClose();
          // console.log(data);
          Swal.fire({
            title: data.message,
            text: data.message,
            icon: "success",
            confirmButtonText: "OK",
          });
          handleChangeVerify();
        } else {
          handleClose();
          console.error("API request failed:", response.statusText);
          Swal.fire({
            title: "Invalid OTP",
            text: "Please check and try again",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        handleClose();
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        Swal.fire({
          title: "Error",
          text: "An unexpected error occurred. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setNewEmail(email);
  };

  useEffect(() => {
    const enteredOTP = otp.join("");
    if (enteredOTP.length === 6) {
      handleVerifyOTP();
    }
  }, [otp]);

  const handleSendOTPAgain = async () => {
    handleOpen();
    try {
      const response = await fetch(
        `${API_BASE_URL}update_new_email/${userDetail?.UserID}/${newEmail}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        handleClose();
        // console.log(data);
        Swal.fire({
          title: "OTP sent Again",
          text: "OTP Send Again",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        handleClose();
        console.error("API request failed:", response.statusText);
        Swal.fire({
          title: "OTP not Sent",
          text: data.error,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      handleClose();
      console.error("An error occurred during the API request:", error.message);
      Swal.fire({
        title: "Error",
        text: "An unexpected error occurred. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleFocus = (index) => {
    // Move the cursor to the last digit if there's already a value entered
    if (index === 5 && otp[index] !== "") {
      inputRefs.current[index].current.setSelectionRange(1, 1);
    }
  };
  return (
    <>
      <div className="overlay"></div>
      {!openEmailOTP ? (
        <div className="change-email-container">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <span className="change-email-heading">Change email Number</span>
          <div className="change-email-main-container">
            <span className="change-email-label">Enter New email Number</span>
            <input
              type="email"
              value={newEmail}
              onChange={handleEmailChange}
              placeholder="Enter New Email"
            />
            <span className="change-email-send-otp-btn" onClick={handleOpenOTP}>
              Send OTP
            </span>
            <span
              className="change-email-cancel-btn"
              onClick={handleChangeVerify}
            >
              Cancel
            </span>
          </div>
        </div>
      ) : (
        <div className="change-email-enter-otp-container">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <span className="change-email-enter-otp-top-heading">
            We have send you a code
          </span>
          <span className="change-email-enter-otp-next-heading">
            Phease enter it below to verify your email number
          </span>
          <span className="change-email-enter-otp-email-number">
            {newEmail}
          </span>
          <div className="change-email-enter-otp-email-number-otp-container">
            <div className="change-email-otp-input-boxes">
              {otp.map((digit, index) => (
                <input
                  type="text"
                  maxlength="1"
                  oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                  key={index}
                  // maxLength={index === 5 ? 1 : 0}
                  value={digit}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  ref={inputRefs.current[index]}
                  onFocus={() => handleFocus(index)}
                  className={digit ? "filled" : ""}
                  required
                />
              ))}
            </div>
          </div>
          <span className="change-email-enter-otp-verify-btn">Verify</span>
          <div className="change-email-enter-otp-dont-receive-otp-div">
            <span className="change-email-enter-otp-dont-receive-text">
              Don't receive code yet?
            </span>
            <span
              className="change-email-enter-otp-send-again-btn"
              onClick={handleSendOTPAgain}
            >
              send again
            </span>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  // You can map any state needed for the OTPVerification component here
});

const mapDispatchToProps = {
  setOTPVerified,
  toggleOverlay,
  setToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmail);
