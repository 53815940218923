import React, { useEffect, useMemo, useState } from "react";
import "./MobileVarientSelection.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import {
  addToCartsAsync,
  fetchAllCartsAsync,
  removeFromCartsAsync,
  selectAllCarts,
} from "../../features/cartPage/cartPageSlice";
import { findPDPqty } from "../../data";
import { setSelectedVariant } from "../../features/productDesc/productDescSlice";
import { setSelectedColor } from "../../store/slices/colorSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { calculateDealerPrice, calculateOfferPrice, colourNameToHex } from "../../utils/utils";

function MobileVarientSelection({ selectProduct, qtys }) {
  const dispatch = useDispatch();
  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const [openSizeSelection, setOpenSizeSelection] = useState(false);
  const [openColorSelection, setOpenColorSelection] = useState(false);
  const [cartVal, setCartVal] = useState(0);
  const userDetail = useSelector(selectUser);
  const selectedVariant = useSelector(
    (state) => state.productDetail.selectedVariant
  );
  const quantity = findPDPqty(selectedVariant?.SKU_ID, qtys && qtys) || 0;
  const [selectedSize, setSelectedSize] = useState(
    selectedVariant?.size_name || null
  );
  const [selectColor, setSelectColor] = useState(
    selectedVariant?.colour_name || null
  );
  const [availableSizes, setAvailableSizes] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);
  const [isCartValueZero, setIsCartValueZero] = useState(false);
  const cart = useSelector(selectAllCarts);
  const [selectTab, setSelectTab] = useState("variant");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const variations = useMemo(
    () =>
      (selectProduct &&
        selectProduct[0]?.skuDetails &&
        selectProduct[0]?.skuDetails) ||
      [],
    [selectProduct]
  );

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    // console.log(
    //   "USEDEEE",
    //   cartVal,
    //   findPDPqty(selectedVariant?.SKU_ID, qtys && qtys)
    // );
    if (cartVal > findPDPqty(selectedVariant?.SKU_ID, qtys && qtys)) {
      setCartVal(findPDPqty(selectedVariant?.SKU_ID, qtys && qtys));
    }
  }, [selectedVariant]);

  const handleRequestMore = () => {
    setRequestSubmitted(true);

    setTimeout(() => {
      setRequestSubmitted(false);
    }, 2000); // Remove message after 3 seconds (3000 milliseconds)
  };
  const handleInputChange = (e) => {
    // Ensure the entered value is a number
    let inputValue = parseInt(e.target.value);

    const newQuantity = Math.min(parseInt(inputValue) || 0, quantity || 1);

    setCartVal(newQuantity);
  };

  const handleInputBlur = () => {
    // If the user enters a quantity beyond the product quantity, adjust it
    const newQuantity = Math.min(cartVal, quantity || 0);
    if (cartVal < quantity) {
      setCartVal(String(newQuantity));
    } else {
      const updateqty = parseInt(
        quantity % parseInt(selectProduct && selectProduct[0]?.Bundle)
      );
      setCartVal(String(quantity - updateqty));
    }
  };


  function increaseCarts(cartVal) {
    if (cartVal >= findPDPqty(selectedVariant.SKU_ID, qtys && qtys)) {
      return;
    }
    setCartVal(cartVal + 1);
  }
  function decreaseCarts() {
    if (cartVal <= 1) {
      setCartVal(cartVal);
      return;
    }
    setCartVal(cartVal - 1);
  }

  useEffect(() => {
    const sizes = [...new Set(variations.map((variant) => variant.size_name))];
    const colors = [
      ...new Set(variations.map((variant) => variant.colour_name)),
    ];
    // console.log(sizes, colors);
    setSelectedSize(selectedVariant?.size_name || null);
    setSelectColor(selectedVariant?.colour_name || null);
    dispatch(setSelectedVariant(variations[0]));
    setAvailableSizes(sizes);
    setAvailableColors(colors);
  }, [variations, selectedVariant, dispatch]);

  // console.log(availableSizes, availableColors);

  useEffect(() => {
    let filteredSizes = [];
    let filteredColors = [];

    if (!selectedSize && !selectColor) {
      filteredSizes = [
        ...new Set(variations.map((variant) => variant.size_name)),
      ];
      filteredColors = [
        ...new Set(variations.map((variant) => variant.colour_name)),
      ];
      dispatch(setSelectedVariant(variations[0]));
    } else {
      if (selectedSize && selectColor) {
        const selectedSku = variations.find(
          (variant) =>
            variant.size_name === selectedSize &&
            variant.colour_name === selectColor
        );

        if (selectedSku) {
          dispatch(setSelectedVariant(selectedSku));
        }
      } else if (selectedSize && !selectColor) {
        const selectedSku = variations.find(
          (variant) => variant.size_name === selectedSize
        );
        if (selectedSku) {
          dispatch(setSelectedVariant(selectedSku));
        }
      } else if (!selectedSize && selectColor) {
        const selectedSku = variations.find(
          (variant) => variant.colour_name === selectColor
        );
        if (selectedSku) {
          dispatch(setSelectedVariant(selectedSku));
        }
      }

      // console.log(selectedSize, selectColor);

      filteredSizes = [
        ...new Set(variations.map((variant) => variant.size_name)),
      ];
      filteredColors = [
        ...new Set(variations.map((variant) => variant.colour_name)),
      ];

      if (selectedSize) {
        // console.log(variations);
        filteredColors = variations
          .filter((variant) => variant.size_name === selectedSize)
          .map((variant) => variant.colour_name);
      }

      if (selectColor) {
        filteredSizes = variations
          .filter((variant) => variant.colour_name === selectColor)
          .map((variant) => variant.size_name);
      }
    }

    setAvailableSizes([...new Set(filteredSizes)]);
    setAvailableColors([...new Set(filteredColors)]);
  }, [selectedSize, selectColor, variations, dispatch, selectedVariant]);


  const handleRemoveSize = () => {
    setSelectedSize(null);
  };
  const handleRemoveColor = () => {
    dispatch(setSelectedColor(null));
    setSelectColor(null);
  };
  const handleSelectSize = (size) => {
    setSelectedSize(size);
  };
  const handleSelectColor = (color) => {
    setSelectColor(color);
  };



  const handleCurrentPrice = () => {
    const originalPrice =
      selectedVariant && selectedVariant?.Offer_price
        ? calculateOfferPrice(
            parseInt(selectedVariant?.MRP_price),
            parseInt(selectedVariant?.Offer_price),
            selectProduct && selectProduct[0] && selectProduct[0]?.GST
          )
        : calculateDealerPrice(
            parseInt(selectedVariant?.MRP_price),
            parseInt(selectedVariant?.NDP_Price),
            selectProduct && selectProduct[0] && selectProduct[0]?.GST
          );

    const discountedPrice = originalPrice;
    const finalPrice = discountedPrice * cartVal;

    return finalPrice;
  };

  // Function to check if a product is in the cart
  const isInCart = () => {
    return (
      Array.isArray(cart) &&
      cart?.some(
        (item) =>
          (item?.skuDetails?.SKU_ID) ===
          (selectedVariant?.SKU_ID)
      )
    );
  };

  const handleBuyNow = (data, e) => {
    if (Object.keys(userDetail).length) {
    } else {
      setIsLoggedIn(true);
    }
    e.preventDefault();
  };

  useEffect(() => {
    if (cartVal > 0) {
      setIsCartValueZero(false);
    }
  }, [cartVal]);

  useEffect(() => {
    if (Object.keys(userDetail).length > 0) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, [userDetail]);

  // TO ADD PRODUCT INTO CART
  const addToCart = () => {
    handleOpen();
    let cartData = {
      userID: userDetail?.UserID,
      SKU: selectedVariant?.SKU_ID,
      buyQty: cartVal,
    };
    if (Object.keys(userDetail).length > 0 && cartVal >= 1) {
      dispatch(addToCartsAsync(cartData)).then(() => {
        dispatch(fetchAllCartsAsync(userDetail.UserID)).then(() => {
          handleClose();
        });
      });
    } else if (Object.keys(userDetail).length > 0 && cartVal === 0) {
      setIsCartValueZero(true);
      handleClose();
    } else {
      setSelectTab("price");
      setIsLoggedIn(true);
      handleClose();
    }
  };

  const removeFromCart = () => {
    handleOpen();
    let cartData = {
      userID: userDetail?.UserID,
      SKU: selectedVariant?.SKU_ID,
    };
    dispatch(removeFromCartsAsync(cartData)).then(() => {
      dispatch(fetchAllCartsAsync(userDetail.UserID)).then(() => {
        handleClose();
      });
    });
  };
  return (
    <div className="mobile-varient-selection-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open} // Show backdrop when either open or filtering state is true
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {availableSizes &&
        availableSizes[0] !== null &&
        availableSizes.length > 0 && (
          <div
            className="mobile-size-selection-container"
            style={{
              boxShadow: openSizeSelection && "0 -3px 5px 0 rgba(0,0,0,0.1",
            }}
          >
            <div className="mobile-size-selection-heading-container">
              <div
                className="mobile-size-selection-heading-span"
                style={{ flexDirection: openSizeSelection ? "column" : "row" }}
              >
                Size:{" "}
                <span
                  className="mobile-size-selected-size-span"
                  style={{ marginLeft: openSizeSelection ? "0" : "1vw" }}
                >
                  {selectedSize &&
                    selectedSize.split(" ").slice(0, 2).join(" ")}
                </span>
              </div>
              {!openSizeSelection && (
                <KeyboardArrowDownIcon
                  onClick={() => setOpenSizeSelection(true)}
                />
              )}
              {openSizeSelection && (
                <KeyboardArrowUpIcon
                  onClick={() => setOpenSizeSelection(false)}
                />
              )}
            </div>
            {openSizeSelection && (
              <div className="mobile-size-selection-option-container">
                {availableSizes &&
                  availableSizes?.map((size) => (
                    <span
                      className={`mobile-size-each-selection-option ${
                        selectedSize === size ? "mobile-size-active" : ""
                      }`}
                      key={size}
                      onClick={() =>
                        selectedSize === size
                          ? handleRemoveSize()
                          : handleSelectSize(size)
                      }
                    >
                      {size}
                    </span>
                  ))}
              </div>
            )}
          </div>
        )}
      {availableColors &&
        availableColors[0] !== null &&
        availableColors?.length > 0 && (
          <div
            className="mobile-color-selection-container"
            style={{
              boxShadow: openColorSelection && "0 -3px 5px 0 rgba(0,0,0,0.1",
            }}
          >
            <div className="mobile-color-selection-heading-container">
              <div
                className="mobile-color-selection-heading-span"
                style={{ flexDirection: openColorSelection ? "column" : "row" }}
              >
                Color:{" "}
                <span
                  className="mobile-color-selected-size-span"
                  style={{ marginLeft: openColorSelection ? "0" : "1vw" }}
                >
                  {selectColor && selectColor.split(" ").slice(0, 2).join(" ")}
                </span>
              </div>
              {!openColorSelection && (
                <KeyboardArrowDownIcon
                  onClick={() => setOpenColorSelection(true)}
                />
              )}
              {openColorSelection && (
                <KeyboardArrowUpIcon
                  onClick={() => setOpenColorSelection(false)}
                />
              )}
            </div>
            {openColorSelection &&
              availableColors &&
              availableColors[0] !== undefined && (
                <div className="mobile-color-selection-option-container">
                  {availableColors.map((color) => (
                    <span
                      className={`desktop-select-variant-bottom-product-color-div ${
                        color === selectColor ? "active" : ""
                      }`}
                      key={color}
                      style={{ marginRight: "3vw" }}
                      onClick={() => {
                        color === selectColor
                          ? handleRemoveColor()
                          : handleSelectColor(color);
                      }}
                    >
                      {" "}
                      <button
                        className={color === selectColor ? "active" : ""}
                        style={{ background: colourNameToHex(color) }}
                      />
                    </span>
                  ))}
                </div>
              )}
          </div>
        )}
    </div>
  );
}

export default MobileVarientSelection;
