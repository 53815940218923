import React, { useState } from "react";
import "./MobileProfileSideBar.css";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { useNavigate, useParams } from "react-router";
import LogoutOverlay from "../../components/EndPages/LogoutOverlay";
import { Slide } from "react-reveal";
import { IMAGE_BASE } from "../../config";
import WalletIcon from '@mui/icons-material/Wallet';
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";

function MobileProfileSideBar({handleSidebar}) {
  const userDetail = useSelector(selectUser);
  const [openLogout, setOpenLogout] = useState(false);
  const navigate = useNavigate();
  const url = useParams();
  // console.log(url);

  const handleOpenLogout = () => {
    setOpenLogout(!openLogout);
  };
  return (
    <Slide right>
      <div className="mobile-profile-sidebar-container">
        <div className="mobile-profile-remove-icon">
          <CloseOutlinedIcon onClick={handleSidebar}/>
        </div>
        <div className="mobile-profile-sidebar-each-row">
            <img src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
                    userDetail?.UserImage || ""
                  }`} alt="" className="mobile-profile-user-img" />
            <span className="mobile-profile-user-name">{userDetail?.FirstName}{" "}{userDetail?.LastName}</span>
        </div>
        <div
          className="mobile-profile-sidebar-each-row"
          onClick={() => {navigate("/user/profile"); handleSidebar()}}
          style={{
            color: url.profileUrl === "profile" && "white",
            background:url.profileUrl === "profile" && "rgba(0,0,0,0.7)"
          }}
        >
          <PermIdentityOutlinedIcon style={{
            color: url.profileUrl === "profile" && "white",
          }}/>
          <span className="mobile-profile-sidebar-each-row-name" style={{
            color: url.profileUrl === "profile" && "white",
          }}>
            My Profile
          </span>
        </div>
        <div
          className="mobile-profile-sidebar-each-row"
          onClick={() => {navigate("/user/preorders"); handleSidebar()}}
          style={{
            color: url.profileUrl === "preorders" && "white",
            background:url.profileUrl === "preorders" && "rgba(0,0,0,0.7)"
          }}
        >
          <WorkHistoryOutlinedIcon style={{
            color: url.profileUrl === "preorders" && "white",
          }}/>
          <span className="mobile-profile-sidebar-each-row-name"
          style={{
            color: url.profileUrl === "preorders" && "white",
          }}>
            My Pre-Orders
          </span>
        </div>
        <div
          className="mobile-profile-sidebar-each-row"
          onClick={() => {navigate("/user/orders"); handleSidebar()}}
          style={{
            color: url.profileUrl === "orders" && "white",
            background:url.profileUrl === "orders" && "rgba(0,0,0,0.7)"
          }}
        >
          <ShoppingBagOutlinedIcon style={{
            color: url.profileUrl === "orders" && "white",
          }}/>
          <span className="mobile-profile-sidebar-each-row-name"
          style={{
            color: url.profileUrl === "orders" && "white",
          }}>
            My Orders
          </span>
        </div>
        <div
          className="mobile-profile-sidebar-each-row"
          onClick={() => {navigate("/user/wallet"); handleSidebar()}}
          style={{
            color: url.profileUrl === "wallet" && "white",
            background:url.profileUrl === "wallet" && "rgba(0,0,0,0.7)"
          }}
        >
          <WalletIcon style={{
            color: url.profileUrl === "wallet" && "white",
          }}/>
          <span className="mobile-profile-sidebar-each-row-name"
          style={{
            color: url.profileUrl === "wallet" && "white",
          }}>
            My Wallet
          </span>
        </div>
        <div
          className="mobile-profile-sidebar-each-row"
          onClick={() => {navigate("/user/security"); handleSidebar()}}
          style={{
            color: url.profileUrl === "security" && "white",
            background:url.profileUrl === "security" && "rgba(0,0,0,0.7)"
          }}
        >
          <VerifiedUserOutlinedIcon style={{
            color: url.profileUrl === "security" && "white",
          }}/>
          <span className="mobile-profile-sidebar-each-row-name" style={{
            color: url.profileUrl === "security" && "white",
          }}>Security</span>
        </div>
        <div
          className="mobile-profile-sidebar-each-row"
          onClick={handleOpenLogout}
        >
          <LogoutOutlinedIcon style={{color:"var(--color-peach)"}}/>
          <span className="mobile-profile-sidebar-each-row-name" style={{color:"var(--color-peach)"}}>Log Out</span>
          {openLogout && <LogoutOverlay handleOpenLogout={handleOpenLogout} />}
        </div>
      </div>
    </Slide>
  );
}

export default MobileProfileSideBar;
