import React, { useEffect, useState } from "react";
import "./MyPreOrder.css";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fetchAllPreorderCartsAsync,
  removeAllFromPreorderCartsAsync,
  selectAllPreorders,
} from "../../features/cartPage/cartPageSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { IMAGE_BASE } from "../../config";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloseIcon from "@mui/icons-material/Close";
import NoCartItem from "../Carts/NoCartItem";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import WantDeletePreorder from "../Carts/WantDeletePreorder";
import { useNavigate } from "react-router";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  calculateDealerPrice,
  calculateDiscountPercentage,
  calculateOfferPrice,
  formatWithCommasWithDecimal,
  formatWithCommasWithoutDecimal,
} from "../../utils/utils";

function MyPreOrder() {
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const preorderCart = useSelector(selectAllPreorders);
  const navigate = useNavigate();
  // console.log(preorderCart);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail && userDetail?.UserID)
      dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID));
  }, [dispatch, userDetail]);

  const handleRemoveAllFromPreorderCarts = () => {
    handleOpen();
    dispatch(removeAllFromPreorderCartsAsync(userDetail.UserID)).then(() => {
      dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID));
      handleClose();
    });
  };

  const [productToRemove, setProductToRemove] = useState(null);

  // Function to handle setting the product to be removed
  const handleSetProductToRemove = (skuId) => {
    setProductToRemove(skuId);
  };

  const calculateTotalAmount = () => {
    let totalAmount = 0;

    if (preorderCart && preorderCart?.length > 0) {
      for (const preorder of preorderCart) {
        // Choose the price: offer price if available, otherwise NDP price
        const price = preorder?.Offer_price
          ? calculateOfferPrice(
              parseInt(preorder?.MRP_price),
              parseInt(preorder?.Offer_price),
              preorder?.GST
            )
          : calculateDealerPrice(
              parseInt(preorder?.MRP_price),
              parseInt(preorder?.NDP_price),
              preorder?.GST
            );

        // Calculate the amount for this preorder item
        const amount = price * preorder?.quantity;

        // Add to the total amount
        totalAmount += amount;
      }
    }

    return totalAmount;
  };

  return (
    <div className="my-preorder-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="my-preorder-header">
        <div
          className="my-preorder-back-to-shopping"
          onClick={() => navigate("/")}
        >
          <ChevronLeftOutlinedIcon /> Back to shopping
        </div>
        <span className="my-preorder-center">Preorder At No Cost</span>
      </div>
      <div className="my-preorder-main-div">
        <div
          className="continue-remove-items-div"
          style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"space-between", marginTop: "10px" }}
        >
          <span className="total-preorder-user-amount">
            Total Amount:{" "}
            <b style={{fontSize:"18px"}}>₹{formatWithCommasWithDecimal(calculateTotalAmount())}</b>
          </span>
          {preorderCart && preorderCart?.length > 0 && (
            <button
              className="remove-all-items-btn"
              onClick={handleRemoveAllFromPreorderCarts}
            >
              Remove all items
            </button>
          )}
        </div>
        <div className="main-carts-products-div-container">
          {preorderCart && preorderCart.length > 0 ? (
            <>
              <div className="main-carts-products-details-align-key">
                <span className="product-name-img">Product</span>
                <span className="product-carts-quantity">Qty</span>
                <span className="product-carts-price-per-unit">
                  Price per unit
                </span>
                <span className="product-carts-price">
                  {" "}
                  Total Price <b>(Incl. GST)</b>
                </span>
              </div>
              <div className="main-carts-products-contain-div">
                {preorderCart?.map((data, index) => (
                  <div
                    className="main-carts-product-div-each-container"
                    key={index}
                  >
                    {productToRemove === data.id ? (
                      <WantDeletePreorder
                        productData={data}
                        setProductToRemove={setProductToRemove}
                      />
                    ) : (
                      <>
                        <div className="main-carts-product-div-name-img">
                          <div className="main-carts-product-img">
                            <LazyLoadImage
                              src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                                data?.image && data.image
                              }`}
                              alt=""
                              effect="blur"
                              wrapperProps={{
                                // If you need to, you can tweak the effect transition using the wrapper style.
                                style: { transitionDelay: "1s" },
                              }}
                            />
                          </div>
                          <div className="main-carts-product-name-desc">
                            <div className="main-carts-product-name-desc-only">
                              {data && data?.manufacture_images ? (
                                <LazyLoadImage
                                  effect="blur"
                                  wrapperProps={{
                                    // If you need to, you can tweak the effect transition using the wrapper style.
                                    style: { transitionDelay: "1s" },
                                  }}
                                  className="main-carts-product-brand-img"
                                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                                    data && data?.manufacture_images
                                  }`}
                                  alt=""
                                />
                              ) : (
                                <h3
                                  className="main-parts-product-name"
                                  style={{
                                    color: "var(--color-peach)",
                                    fontStyle: "italic",
                                  }}
                                >
                                  {data?.manufacture_name &&
                                    data?.manufacture_name}
                                </h3>
                              )}
                              <span className="main-parts-product-desc">
                                {(data?.prod_name).slice(0, 65)}
                              </span>
                              <div className="main-cart-product-properties-main-container">
                                {data?.Size_Name && (
                                  <div className="main-cart-product-size-container">
                                    <span className="main-cart-product-size">
                                      {data?.Size_Name &&
                                        (data?.Size_Name).slice(0, 10)}
                                    </span>
                                    <CloseIcon />
                                  </div>
                                )}
                                {data?.Colour_Name && (
                                  <div className="main-cart-product-size-container">
                                    <span className="main-cart-product-size">
                                      {data?.Colour_Name &&
                                        (data?.Colour_Name).slice(0, 15)}
                                    </span>
                                    <CloseIcon />
                                  </div>
                                )}
                                {data?.Varient_Name && (
                                  <div className="main-cart-product-size-container">
                                    <span className="main-cart-product-size">
                                      {data?.Varient_Name &&
                                        (data?.Varient_Name).slice(0, 15)}
                                    </span>
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="main-carts-product-quantity-div">
                          <span>{data?.quantity}</span>
                        </div>
                        <div className="main-carts-product-pricing-container">
                          <div className="main-carts-product-ndp-offer-price">
                            {data?.NDP_price && (
                              <span className="main-carts-product-npd-price-container">
                                Dealer Price -{" "}
                                <b
                                  style={{
                                    textDecoration:
                                      data?.Offer_price !== null &&
                                      "line-through",
                                    fontSize:
                                      data?.Offer_price !== null && "10px",
                                  }}
                                >
                                  {" "}
                                  ₹
                                  {formatWithCommasWithDecimal(
                                    calculateDealerPrice(
                                      parseInt(data?.MRP_price),
                                      parseInt(data?.NDP_price),
                                      data?.GST
                                    )
                                  )}
                                </b>
                              </span>
                            )}
                            {data?.Offer_price !== null && (
                              <span className="main-carts-product-offer-price-span">
                                ₹
                                {formatWithCommasWithDecimal(
                                  calculateOfferPrice(
                                    parseInt(data?.MRP_price),
                                    parseInt(data?.Offer_price),
                                    data?.GST
                                  )
                                )}
                              </span>
                            )}{" "}
                          </div>
                          {data?.MRP_price && (
                            <span className="main-carts-product-mrp-price-span">
                              Product MRP - ₹
                              {formatWithCommasWithDecimal(
                                parseInt(data?.MRP_price)
                              )}
                            </span>
                          )}
                        </div>
                        <div className="main-carts-product-price-div">
                          <div className="main-carts-product-price-main-container">
                            ₹
                            {formatWithCommasWithDecimal(
                              (data?.Offer_price
                                ? calculateOfferPrice(
                                    parseInt(data?.MRP_price),
                                    parseInt(data?.Offer_price),
                                    data?.GST
                                  )
                                : calculateDealerPrice(
                                    parseInt(data?.MRP_price),
                                    parseInt(data?.NDP_price),
                                    data?.GST
                                  )) * data?.quantity
                            )}
                          </div>
                          {calculateDiscountPercentage(
                            data?.MRP_price,
                            data?.NDP_price
                          ) > 0 && (
                            <div className="main-carts-product-dealer-margin-span">
                              <LocalOfferOutlinedIcon />{" "}
                              <span
                                style={{
                                  textDecoration:
                                    data?.Offer_price !== null &&
                                    "line-through",
                                  fontSize:
                                    data?.Offer_price !== null && "10px",
                                  margin: "0 0.2vw",
                                }}
                              >
                                {" "}
                                {calculateDiscountPercentage(
                                  data?.MRP_price,
                                  calculateDealerPrice(
                                    parseInt(data?.MRP_price),
                                    parseInt(data?.NDP_price),
                                    data?.GST
                                  )
                                )}
                                %
                              </span>
                              {data?.Offer_price !== null && (
                                <span className="main-carts-product-dealer-margin-offer-ribbon">
                                  {calculateDiscountPercentage(
                                    data?.MRP_price,
                                    calculateOfferPrice(
                                      parseInt(data?.MRP_price),
                                      parseInt(data?.Offer_price),
                                      data?.GST
                                    )
                                  )}
                                  %
                                </span>
                              )}{" "}
                              Dealer Margin
                            </div>
                          )}
                        </div>
                        <div className="remove-product-cross">
                          <CloseOutlinedIcon
                            onClick={() => handleSetProductToRemove(data?.id)}
                            // onClick={() => handleRemoveFromCarts(data)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <NoCartItem text={"Preorder"} />
          )}
        </div>
      </div>
    </div>
  );
}

export default MyPreOrder;
