import React, { useState, useEffect, useRef, useCallback } from "react";
import "./PriceDetail.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCartsAsync,
  selectAllCarts,
} from "../../features/cartPage/cartPageSlice";
import { selectUser } from "../../features/auth/authSlice";
import {
  premiumUser,
} from "../../features/cartPage/walletAPI";
import {
  calculateDealerPrice,
  calculateOfferPrice,
  calculateTotalAmount,
  fetchUserShipping,
  formatWithCommasWithDecimal,
} from "../../utils/utils";
function PriceDetail({
  confirmOverlay,
  setConfirmOverlay,
  handleConfirmOverlay,
  onCheckout,
  currentStep,
  setCurrentStep,
  handleGeneratePaymentURL,
  paymentSessionId,
  transactionId,
}) {
  const dispatch = useDispatch();
  const cart = useSelector(selectAllCarts);
  const userDetail = useSelector(selectUser);
  const [freeShipping, setFreeShipping] = useState(false);
  const [premUser, setPremUser] = useState(false);
  const infoRef = useRef(null);

  const [shippingOpen, setShippingOpen] = useState(false);

  useEffect(() => {
    if (userDetail && userDetail?.UserID)
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
  }, [dispatch, userDetail]);

  const checkPremiumUser = useCallback(async () => {
    try {
      const response = await premiumUser(userDetail?.UserID);
      if (response && response.data && response.data.message) {
        setPremUser(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [userDetail?.UserID]);

  // Use useEffect to call the function
  useEffect(() => {
    checkPremiumUser();
  }, [checkPremiumUser]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (infoRef.current && !infoRef.current.contains(event.target)) {
        // Clicked outside the component, you can close it here
        setShippingOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchAndSetShipping = useCallback(async () => {
    if (userDetail?.UserID) {
      try {
        const userShipping = await fetchUserShipping(userDetail?.UserID);

        if (userShipping === "false") {
          setFreeShipping(true);
        } else {
          setFreeShipping(false);
        }
      } catch (error) {
        console.error("Error fetching user shipping info:", error);
      }
    }
  }, [userDetail?.UserID]);

  useEffect(() => {
    fetchAndSetShipping();
  }, [fetchAndSetShipping]);

  // Function to calculate the shipping cost for the selected shipping mode
  const calculateShippingCost = () => {
    let totalAmount = 0;
    cart &&
      cart?.forEach((product) => {
        totalAmount +=
          !product?.skuDetails?.SKU_ID?.startsWith("D") &&
          (product.skuDetails?.Offer_price
            ? calculateOfferPrice(
                parseInt(product.skuDetails?.MRP_price),
                parseInt(product.skuDetails?.Offer_price),
                product?.skuDetails?.GST
              )
            : calculateDealerPrice(
                parseInt(product.skuDetails?.MRP_price),
                parseInt(product.skuDetails?.NDP_Price),
                product?.skuDetails?.GST
              )) * product?.buy_quantity;
      });
    if (totalAmount >= 3000 || premUser || freeShipping) {
      return 0;
    } else {
      return 250;
    }
  };

  // Function to calculate the amount to pay (total amount + shipping cost)
  const calculateAmountToPay = () => {
    const totalAmount = calculateTotalAmount(cart);
    const shippingCost = calculateShippingCost();
    return totalAmount + shippingCost;
  };


  return (
    <>
      {cart && cart?.length > 0 && (
        <div className="priceDetail-container">
          <h3>Payment Summary</h3>
          <div className="product-cart-pricing-details">
            <div className="product-cart-pricing-div">
              <p>Total Price</p>
              <hr />
              <span>
                ₹
                {formatWithCommasWithDecimal(
                  parseInt(calculateTotalAmount(cart))
                )}
              </span>
            </div>
            <div className="product-cart-pricing-div">
              <p>Shipping Cost</p>
              <hr />
              <span>
                ₹{formatWithCommasWithDecimal(calculateShippingCost())}
              </span>
            </div>

            <div
              className="product-cart-pricing-div"
              style={{
                borderTop: "2px solid rgba(0,0,0,0.1)",
                paddingTop: "10px",
              }}
            >
              <p className="total-cart-price-text">Amount to pay</p>
              <hr />
              <span className="total-cart-price-text">
                ₹{formatWithCommasWithDecimal(calculateAmountToPay())}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PriceDetail;
