import React from 'react';
import "./MoblieShopByBrand.css";
import { useDispatch } from 'react-redux';

function MobileShopByBrand() {
    const brands = [
        "Shimano",
        "Ritchey",
        "Surly",
        "Fox Bikes",
        "Salsa",
        "Cratoni"
      ];
    
      const dispatch = useDispatch();
  return (
    <div className="mobile-shopby-brands-container">
    <span className="mobile-shopby-brands-header">Shop by Brands</span>
    <div className="mobile-shopby-brands-main-div">
      {brands.map((brand, index) => (
        <span key={index} className="mobile-shopby-brands-each-brand">
          {brand}
        </span>
      ))}
    </div>
  </div>
  )
}

export default MobileShopByBrand