import React, { useState, useEffect } from "react";
import "./MobileOrderConfirm.css";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp";
import CloudDownloadSharpIcon from "@mui/icons-material/CloudDownloadSharp";
import confirmOrderLogo from "../../img/order_confirm-logo.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ccLogo from "../../img/parts/Cycle-Circle-Logo-Orange-1.png";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { Default } from "react-spinners-css";
import { API_BASE_URL, IMAGE_BASE } from "../../config";
import { useNavigate } from "react-router";
import {
  fetchAllCartsAsync,
  selectAllCarts,
} from "../../features/cartPage/cartPageSlice";
import { selectUser } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrderAsync,
  freezeQuantityAsync,
  updatePaymentStatusAsync,
} from "../../features/cartPage/OrderSlice";
import paymentTrailLogo from "../../img/payment/time-trail-cycle.gif";
import paymentFailedLogo from "../../img/payment/cancel-payment-logo.png";
import cashbackCard from "../../img/banners/cashback-card.jpg";
import { Link } from "react-router-dom";
import {
  applyCouponAsync,
  debitWalletAsync,
} from "../../features/cartPage/walletSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import cyclegif from "../../img/signup/cyclegif.gif";
import {
  calculateDealerPrice,
  calculateOfferPrice,
  calculateTotalAmount,
  formatDateAndTime,
  formatWithCommasWithDecimal,
} from "../../utils/utils";
import { fetchUserRoleById } from "../../features/cartPage/cartPageAPI";
import cashfree from "../../services/Cashfree";
import { LazyLoadImage } from "react-lazy-load-image-component";

export function PaymentFailed({ setConfirmOverlay, calculateAmountToPay }) {
  return (
    <div className="payment-failed-container">
      <LazyLoadImage
        src={paymentFailedLogo}
        alt="Payment Failed"
        effect="blur"
        wrapperProps={{
          // If you need to, you can tweak the effect transition using the wrapper style.
          style: { transitionDelay: "1s" },
        }}
      />
      <span className="payment-price">
        ₹{formatWithCommasWithDecimal(calculateAmountToPay())}
      </span>
      <span className="payment-failed-red-text">Payment Failed!</span>
      <span className="payment-failed-big-text">
        Hey it seems like there was some trouble. We are there with you. Just
        hold back.
      </span>
      <span className="payment-failed-order-id-date">
        {formatDateAndTime(new Date())}
      </span>
      <span
        className="payment-again-btn"
        onClick={() => setConfirmOverlay(false)}
      >
        Try again
      </span>
    </div>
  );
}

export function PaymentPending({ setConfirmOverlay, calculateAmountToPay }) {
  return (
    <div className="payment-pending-container">
      <LazyLoadImage
        src={paymentFailedLogo}
        alt="Payment Pending"
        effect="blur"
        wrapperProps={{
          style: { transitionDelay: "1s" },
        }}
      />
      <span className="payment-price">
        ₹{formatWithCommasWithDecimal(calculateAmountToPay())}
      </span>
      <span className="payment-pending-yellow-text">Payment Pending</span>
      <span className="payment-pending-big-text">
        Your payment is currently pending. We're here to help you.
      </span>
      <span className="payment-pending-order-id-date">
        {formatDateAndTime(new Date())}
      </span>
      <span className="support-text">
        Please reach out to our support team to confirm your order:
      </span>
      <div className="support-contact">
        <span>
          <strong>Phone:</strong> <a href="tel:+919429693000">+91-9429693000</a>
        </span>
        <span>
          <strong>Email:</strong>{" "}
          <a href="mailto:support@cyclecircle.one">support@cyclecircle.one</a>
        </span>
      </div>
      <span
        className="payment-again-btn"
        onClick={() => setConfirmOverlay(false)}
      >
        Close
      </span>
    </div>
  );
}
export function MoveToPaymentGateway({
  handleClose,
  setPlaced,
  openPlaced,
  setOpenPlaced,
}) {
  const [time, setTime] = useState(300);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsRemaining = seconds % 60;
    return `${minutes}:${secondsRemaining < 10 ? "0" : ""}${secondsRemaining}`;
  };
  return (
    <>
      {openPlaced && (
        <div className="want-to-place-order">
          <span className="want-to-place-text">Want to place order?</span>
          <div className="want-to-place-btn-div">
            <span
              className="want-to-place-yes"
              onClick={() => {
                setPlaced(true);
                setOpenPlaced(false);
              }}
            >
              Yes
            </span>
            <span
              className="want-to-place-no"
              onClick={() => {
                setOpenPlaced(false);
                handleClose();
              }}
            >
              No
            </span>
          </div>
        </div>
      )}
      <div className="move-to-payment-container">
        <span
          className="red-line-window"
          style={{ position: "absolute", top: "50px" }}
        >
          PLEASE DO NOT REFRESH OR CLOSE THIS WINDOW WHILE PAYMENT
        </span>
        {/* <span className="move-to-payment-close" onClick={handleClose}>
        Close
      </span> */}
        <img src={cyclegif} alt="Payment Trail" />
        <span>
          Your Session will expire on <b>{formatTime(time)}</b> minutes
        </span>
        <span>
          Please wait while we redirecting you to the payment gateway.
        </span>
        <span>Please do not Refresh or Close the window.</span>
      </div>
    </>
  );
}

function MobileOrderConfirm({
  setConfirmOverlay,
  paymentSessionId,
  transactionId,
  selectedAddress,
  coupon,
  addedCoupon,
  discount,
  walletPaymentFinalPrice,
  tenPercentDiscount,
  premUser,
  currentStep,
  setCurrentStep,
  freeShipping,
}) {
  const dispatch = useDispatch();
  const cart = useSelector(selectAllCarts);
  const userDetail = useSelector(selectUser);
  const navigate = useNavigate();
  const [showPaymentGateway, setShowPaymentGateway] = useState(true);
  const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
  const [showFailedPayment, setShowFailedPayment] = useState(false);
  const [showPendingPayment, setShowPendingPayment] = useState(false);
  const [orderNo, setOrderNo] = useState(null);
  const [visitPaymentURL, setVisitPaymentURL] = useState(false);
  const [openPlaced, setOpenPlaced] = useState(false);
  const [placed, setPlaced] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleCloses = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleOrderCreate = () => {
    if (!cart || cart.length === 0) {
      console.error("Cart is empty");
      return;
    }
    // Construct the items array from the cart
    const items = cart
      .filter((product) => !product.skuDetails?.SKU_ID.startsWith("D"))
      .map((product) => ({
        item_no: product?.skuDetails?.SKU_ID,
        quantity: product?.buy_quantity,
        mrp: product?.skuDetails?.MRP_price,
        sales_amount:
          calculateDealerPrice(
            parseInt(product?.skuDetails?.MRP_price),
            parseInt(product?.skuDetails?.NDP_Price),
            product?.skuDetails?.GST
          ) * product?.buy_quantity,
        discount_amount:
          calculateOfferPrice(
            parseInt(product?.skuDetails?.MRP_price),
            parseInt(product?.skuDetails?.Offer_price),
            product?.skuDetails?.GST
          ) * product?.buy_quantity || "",
      }));

    let orderData = {
      merchantTransactionId: transactionId,
      shippingID: selectedAddress,
      Discount_Amount: parseInt(walletPaymentFinalPrice + discount),
      Shipping_Amount:
        premUser || freeShipping
          ? 0
          : parseInt(calculateTotalAmount(cart)) >= 3000
          ? 0
          : 250,
      items: items,
    };

    // console.log(orderData);

    dispatch(createOrderAsync(orderData))
      .then((response) => {
        // console.log("Response...", response?.payload?.OrderNumber);
        setOrderNo(response?.payload?.OrderNumber);
        // Any further actions you want to perform with the response can be placed here
        coupon?.length > 0 &&
          addedCoupon &&
          applyCoupon(response?.payload?.OrderNumber, discount, coupon);
        tenPercentDiscount &&
          tenPercentDiscount > 0 &&
          applyCoupon(
            response?.payload?.OrderNumber,
            tenPercentDiscount,
            "CCTEN"
          );
        walletPaymentFinalPrice &&
          walletPaymentFinalPrice > 0 &&
          debitFromWallet(response?.payload?.OrderNumber);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    // console.log("Response...", response);
  };
  const debitFromWallet = (orderNumber) => {
    let walletData = {
      UserID: userDetail?.UserID,
      amount: walletPaymentFinalPrice,
      orderNo: orderNumber,
    };
    dispatch(debitWalletAsync(walletData));
  };
  const applyCoupon = (orderID, discount, couponCode) => {
    let couponData = {
      userID: userDetail?.UserID,
      couponCode: couponCode,
      discountAmount: discount,
      orderNo: orderID,
    };
    dispatch(applyCouponAsync(couponData));
  };
  const handleFreezeQuantity = () => {
    if (!cart || cart.length === 0) {
      console.error("Cart is empty");
      return;
    }
    // Construct the items array from the cart
    const items = cart
      .filter((product) => !product.skuDetails?.SKU_ID.startsWith("D"))
      .map((product) => ({
        item_no: product?.skuDetails?.SKU_ID,
        quantity: product?.buy_quantity,
        mrp: product?.skuDetails?.MRP_price,
        sales_amount:
          calculateDealerPrice(
            parseInt(product?.skuDetails?.MRP_price),
            parseInt(product?.skuDetails?.NDP_Price),
            product?.skuDetails?.GST
          ) * product?.buy_quantity,
        discount_amount:
          calculateOfferPrice(
            parseInt(product?.skuDetails?.MRP_price),
            parseInt(product?.skuDetails?.Offer_price),
            product?.skuDetails?.GST
          ) * product?.buy_quantity || "",
      }));

    // console.log(items);

    let quantityData = {
      LinkID: transactionId,
      UserID: userDetail?.UserID,
      items: items,
    };
    // console.log(quantityData);

    const response = dispatch(freezeQuantityAsync(quantityData));

    // console.log("Response...", response);
  };
  useEffect(() => {
    // console.log("handleFreeze");
    handleFreezeQuantity();
  }, [transactionId]);

  const fetchUserRole = async () => {
    try {
      const response = await fetchUserRoleById(userDetail?.UserID);
      if (response && response.data) {
        return response?.data?.UserType;
      }
      throw new Error("UserType not found in response");
    } catch (error) {
      console.error("Error fetching user role:", error);
      return null;
    }
  };


  useEffect(() => {
    if (userDetail && userDetail?.UserID) {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail]);


  // Order placed without payment if ADMIN
  useEffect(() => {
    const handleOrder = async () => {
      if (placed) {
        try {
          
          await handleOrderCreate();
          setShowPaymentGateway(false);
          setShowOrderConfirmation(true);
          console.log("Order created");
        } catch (error) {
          console.error("Error while creating order:", error);
        }
      }
    };

    handleOrder();
  }, [placed]);

  // Cashfree setup
  useEffect(() => {
    const initiatePayment = async () => {
      const userRole = await fetchUserRole();
      await new Promise((resolve) => setTimeout(resolve, 4000));

      if (userRole === "ADMIN") {
        dispatch(updatePaymentStatusAsync(transactionId)).then(async () => {
          setOpenPlaced(true);
        });
      } else {
        setShowPaymentGateway(false);

        let checkoutOptions = {
          paymentSessionId,
          redirectTarget: document.getElementById("cashfree-payment"),
          appearance: {
            width: "100%",
            height: "80vh",
          },
        };

        cashfree.checkout(checkoutOptions).then(async (result) => {
          if (result.error) {
            // console.log(result);
            console.error("Payment error, check payment status:", result.error);
            // setShowFailedPayment(true);
          }
          if (result.redirect) {
            // console.log(result);
            console.log("Payment will be redirected");
          }
          if (result.paymentDetails) {
            console.log(
              "Payment completed, check payment status:",
              result?.paymentDetails?.paymentMessage
            );
            try {
              const response = await fetch(
                `${API_BASE_URL}cashfree_status/${transactionId}`
              );
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              const data = await response.json();
              // console.log(data);
              if (data && data?.paymentStatuses[0] === "SUCCESS") {
                await handleOrderCreate();
                setShowPaymentGateway(false);
                setShowOrderConfirmation(true);
              } else if (data && data?.paymentStatuses[0] === "FAILED") {
                setShowPaymentGateway(false);
                setShowOrderConfirmation(false);
                setShowFailedPayment(true);
              } else if (data && data?.paymentStatuses[0] === "PENDING") {
                setShowPaymentGateway(false);
                setShowOrderConfirmation(false);
                setShowPendingPayment(true);
              } else if (data && data?.paymentStatuses[0] === "NOT_ATTEMPTED") {
                setShowPaymentGateway(false);
                setShowOrderConfirmation(false);
                setConfirmOverlay(false);
              } else if (data && data?.paymentStatuses[0] === "USER_DROPPED") {
                setShowPaymentGateway(false);
                setShowOrderConfirmation(false);
                setConfirmOverlay(false);
              }
            } catch (error) {
              console.error("Error checking payment status:", error);
            }
          }
        });
      }
    };

    initiatePayment();
  }, [paymentSessionId, transactionId]);

  const steps = [
    { label: "Order Confirmed", icon: <CheckSharpIcon /> },
    { label: "Quality Check", icon: <CheckSharpIcon /> },
    { label: "Dispatched Item", icon: <CheckSharpIcon /> },
    { label: "Product Delivered", icon: <CheckSharpIcon /> },
  ];
  const [complete, setComplete] = useState(false);
  const handleStepClick = (stepIndex) => {
    if (stepIndex <= currentStep || complete) {
      setCurrentStep(stepIndex);
    }
  };

  const handleClose = () => {
    const fetchData = async () => {
      handleOpen();
      await dispatch(fetchAllCartsAsync(userDetail?.UserID)).then(() => {
        navigate("/carts");
        setCurrentStep(1);
        setShowOrderConfirmation(false);
        setConfirmOverlay(false);
        handleCloses();
      });
    };
    fetchData();
    return () => clearTimeout(2000);
  };

  // Function to calculate the shipping cost for the selected shipping mode
  const calculateShippingCost = () => {
    let totalAmount = 0;
    cart &&
      cart?.forEach((product) => {
        totalAmount +=
          !product?.skuDetails?.SKU_ID?.startsWith("D") &&
          (product.skuDetails?.Offer_price
            ? calculateOfferPrice(
                parseInt(product?.skuDetails?.MRP_price),
                parseInt(product?.skuDetails?.Offer_price),
                product?.skuDetails?.GST
              )
            : calculateDealerPrice(
                parseInt(product?.skuDetails?.MRP_price),
                parseInt(product?.skuDetails?.NDP_Price),
                product?.skuDetails?.GST
              )) * product.buy_quantity;
      });

    const amountAfterDiscount = totalAmount - (addedCoupon && discount);
    const amountToPay =
      amountAfterDiscount > walletPaymentFinalPrice
        ? amountAfterDiscount - walletPaymentFinalPrice
        : 0;
    if (amountToPay >= 3000 || freeShipping) {
      return 0;
    } else {
      return 250;
    }
  };

  // Function to calculate the amount to pay (total amount + shipping cost)
  const calculateAmountToPay = () => {
    const totalAmount = parseInt(calculateTotalAmount(cart));
    const shippingCost = parseInt(calculateShippingCost());
    return (
      totalAmount +
      shippingCost -
      discount -
      (!isNaN(walletPaymentFinalPrice) && walletPaymentFinalPrice)
    );
  };

  return (
    <>
      {showPaymentGateway && (
        <MoveToPaymentGateway
          handleClose={handleClose}
          setPlaced={setPlaced}
          openPlaced={openPlaced}
          setOpenPlaced={setOpenPlaced}
        />
      )}
      {showFailedPayment && (
        <PaymentFailed
          setConfirmOverlay={setConfirmOverlay}
          calculateAmountToPay={calculateAmountToPay}
        />
      )}
      {showPendingPayment && (
        <PaymentPending
          setConfirmOverlay={setConfirmOverlay}
          calculateAmountToPay={calculateAmountToPay}
        />
      )}
      {!showPaymentGateway &&
        !showOrderConfirmation &&
        !showFailedPayment &&
        !showPendingPayment && (
          <div
            id="cashfree-payment"
            style={{ borderRadius: "10px" }}
            className="order-confirm-container"
          >
            <CloseSharpIcon className="close-svg" onClick={handleClose} />
          </div>
        )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleCloses}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {showOrderConfirmation && (
        <div className="order-confirm-container">
          <CloseSharpIcon className="close-svg" onClick={handleClose} />
          <div className="order-confirm-scroll-div">
            <div className="order-confirm-left-container">
              <div className="order-confirm-left-basic-detail-container">
                <img src={confirmOrderLogo} alt="" />
                <span className="thank-text">Thank You</span>
                <span className="order-confirm-confirm-text">
                  Your order is confirmed
                </span>
              </div>
              <div className="order-confirm-left-track-detail-container">
                <span className="order-confirm-order-code-date-container">
                  Order code <b>#{orderNo}</b> was placed on{" "}
                  <b>{`${new Date().getDate()}-${
                    new Date().getMonth() + 1
                  }-${new Date().getFullYear()}`}</b>{" "}
                  and is currently in progress
                </span>
                <div className="order-confirm-breadcrum-container">
                  {steps?.map((step, i) => (
                    <div
                      key={i}
                      className={`order-confirm-step-item ${
                        currentStep === i + 1 && "active"
                      } ${
                        (i + 1 < currentStep || complete) &&
                        "order-confirm-complete"
                      } `}
                      onClick={() => handleStepClick(i + 1)}
                    >
                      <div className="order-confirm-step">{step.icon}</div>
                      <p className="order-confirm-text-gray">{step.label}</p>
                    </div>
                  ))}
                </div>
                <span className="order-confirm-delivery-date-container">
                  Exprected delivery date:{" "}
                  <b>
                    {" "}
                    {new Date(
                      new Date().getTime() + 5 * 24 * 60 * 60 * 1000
                    ).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </b>
                </span>
              </div>
              <span className="order-confirm-email-notification-text">
                We will be sending you an email confirmation to{" "}
                <b>{userDetail && userDetail?.Email}</b>
              </span>
              <div className="order-confirm-bottom-continue-shopping-view-order">
                <div className="order-confirm-continue-shopping-btn-container">
                  <ChevronLeftSharpIcon />
                  <span className="order-confirm-continue-shopping-btn">
                    Continue to shopping
                  </span>
                </div>
                <div className="order-confirm-view-my-order-btn-container">
                  <RemoveRedEyeSharpIcon />
                  <span
                    className="order-confirm-view-my-order-btn"
                    onClick={() => navigate("/user/orders")}
                  >
                    View my orders
                  </span>
                </div>
              </div>
            </div>
            <div className="order-confirm-right-container">
              <img src={ccLogo} alt="" className="cc-logo" />
              <div className="order-confirm-cashback-container">
                <img
                  src={cashbackCard}
                  alt=""
                  className="order-confirm-cashback-img"
                />
                <span className="order-confirm-cashback-text">
                  You have received a cashback of ₹
                  {parseInt(tenPercentDiscount).toFixed(2)}
                </span>
                <span
                  className="order-confirm-cashback-btn"
                  onClick={() => navigate("/user/wallet")}
                >
                  View My Wallet
                </span>
              </div>
              <div className="order-confirm-order-summary-container">
                <h3>Order Summary</h3>
                <div className="order-confirm-sub-total-container">
                  <span className="order-confirm-sub-total-key">Sub Total</span>
                  <span className="order-confirm-sub-total-value">
                    ₹{formatWithCommasWithDecimal(calculateTotalAmount(cart))}
                  </span>
                </div>
                <div className="order-confirm-delivery-container">
                  <span className="order-confirm-delivery-key">Delivery</span>
                  <span className="order-confirm-delivery-value">
                    ₹{formatWithCommasWithDecimal(calculateShippingCost())}
                  </span>
                </div>

                <div className="order-confirm-delivery-container">
                  <span className="order-confirm-delivery-key">Discount</span>
                  <span className="order-confirm-delivery-value">
                    -₹{formatWithCommasWithDecimal(discount.toFixed(2))}
                  </span>
                </div>
                <div className="order-confirm-delivery-container">
                  <span className="order-confirm-delivery-key">Wallet</span>
                  <span className="order-confirm-delivery-value">
                    -₹
                    {formatWithCommasWithDecimal(
                      walletPaymentFinalPrice.toFixed(2)
                    )}
                  </span>
                </div>
                <div className="order-confirm-total-container">
                  <span className="order-confirm-total-key">Total</span>
                  <span className="order-confirm-total-value">
                    ₹
                    {formatWithCommasWithDecimal(
                      calculateAmountToPay().toFixed(2)
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MobileOrderConfirm;
