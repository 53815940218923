import React, { useEffect, useState } from 'react';
import "./MobilePromotion.css";
import { fetchAllHotDealsAsync, fetchAllRecommendedAsync, fetchAllWhatsNewAsync } from '../../../features/HomePage/homeSlice';
import { fetchAllCategoryProductsAsync } from '../../../features/listingPage/listingPageSlice';
import { useDispatch } from 'react-redux';
import MobileProduct from './MobileProduct';

function MobilePromotion() {
  const dispatch = useDispatch();
  const [promotionContent, setPromotionContent] = useState(1);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(()=>{
    dispatch(fetchAllWhatsNewAsync());
  },[dispatch]);

  useEffect(() => {
    // Function to change promotion tab automatically after 10 seconds
    const id = setInterval(() => {
      setPromotionContent((prevContent) => {
        const nextContent =
          prevContent === 4 ? 1 : prevContent + 1 === 3 ? 4 : prevContent + 1;
        dispatch(fetchAllCategoryProductsAsync(nextContent));
        return nextContent;
      });
    }, 10000); // Change tab every 10 seconds

    setIntervalId(id);

    return () => clearInterval(id); // Cleanup interval on component unmount
  }, [dispatch]);

  const handlePromotionContent = (value) => {
    clearInterval(intervalId);
    if(value === 1){
      dispatch(fetchAllWhatsNewAsync());
    }
    else if(value === 2){
      dispatch(fetchAllRecommendedAsync());
    }
    else{
      dispatch(fetchAllHotDealsAsync());
    }
    
    setPromotionContent(value);
  };

  const handleMouseEnter = () => {
    clearInterval(intervalId); // Pause the interval
  };

  const handleMouseLeave = () => {
    clearInterval(intervalId);
    const id = setInterval(() => {
      setPromotionContent((prevContent) => {
        const nextContent =
          prevContent === 4 ? 1 : prevContent + 1 === 3 ? 4 : prevContent + 1;
          if(nextContent === 1){
            dispatch(fetchAllWhatsNewAsync());
          }
          if(nextContent === 2){
            dispatch(fetchAllRecommendedAsync());
          }
          if(nextContent === 4){
            dispatch(fetchAllHotDealsAsync());
          }
        return nextContent;
      });
    }, 10000); // Change tab every 10 seconds

    setIntervalId(id);
  };
  return (
    <div className="mobile-promotion-container">
      <div className="mobile-promotion-navigation-container">
        <div
          className={`mobile-promotion-navigation-what-navigate ${
            promotionContent === 1 && "what-navigation-active"
          }`}
        >
          <span
            className={`mobile-promotion-navigation-what-navigate-span ${
              promotionContent === 1 && "what-navigate-active"
            }`}
            onClick={() => handlePromotionContent(1)}
          >
            What's New
          </span>
        </div>
        <div
          className={`mobile-promotion-navigation-recommend-navigate ${
            promotionContent === 2 && "recommend-navigation-active"
          }`}
        >
          <span
            className={`mobile-promotion-navigation-recommend-navigate-span ${
              promotionContent === 2 && "recommend-navigate-active"
            }`}
            onClick={() => handlePromotionContent(2)}
          >
            Recommended
          </span>
        </div>
        <div
          className={`mobile-promotion-navigation-hot-navigate ${
            promotionContent === 4 && "hot-navigation-active"
          }`}
        >
          <span
            className={`mobile-promotion-navigation-hot-navigate-span ${
              promotionContent === 4 && "hot-navigate-active"
            }`}
            onClick={() => handlePromotionContent(4)}
          >
            Hot Deals
          </span>
        </div>
      </div>
      <div className="mobile-promotion-product-sliders-container">
        <MobileProduct
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
        />
      </div>
      <div className="promotion-bottom-white-space"></div>
    </div>
  )
}

export default MobilePromotion