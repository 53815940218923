import React from 'react';
import "./MobileProfileComponent.css";
import { useParams } from 'react-router';
import MobileMyOrder from './MobileMyOrder';
import MobileSecurity from './MobileSecurity';
import MobileMyProfile from "./MobileMyProfile"
import MobileMyWallet from './MobileMyWallet';
import MobilePreOrders from './MobilePreOrders';

function MobileProfileComponent() {
    const url = useParams();
  return (
    <div className='mobile-profile-component-container'>
        <div className="mobile-profile-content">
            {url.profileUrl === "profile" && <MobileMyProfile/>}
            {url.profileUrl === "preorders" && <MobilePreOrders/>}
            {url.profileUrl === "orders" && <MobileMyOrder/>}
            {url.profileUrl === "security" && <MobileSecurity/>}
            {url.profileUrl === "wallet" && <MobileMyWallet/>}
        </div>
    </div>
  )
}

export default MobileProfileComponent