import React from "react";
import "./ProductCard.css";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import { connect } from "react-redux";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import { setCarouselIndex } from "../../store/slices/hotDealsSlice";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { FavoriteBorderOutlined } from "@mui/icons-material";
import {
  fetchAllCategoryProductsAsync,
  selectAllCategoryProducts,
} from "../../features/listingPage/listingPageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { selectUser } from "../../features/auth/authSlice";
import {
  selectAllWishlists,
  addToWishlistsAsync,
  removeFromWishlistAsync,
  fetchAllWishlistsAsync,
} from "../../features/wishlist/wishlistSlice";
import HoverSlider from "../ListingPage/ListingProduct/HoverSlider";
import { formatWithCommasWithDecimal } from "../../utils/utils";
function LikeProductCard() {
  const dispatch = useDispatch();
  const products = useSelector(selectAllCategoryProducts);
  const userDetail = useSelector(selectUser);
  const wishlist = useSelector(selectAllWishlists);


  useEffect(() => {
    dispatch(fetchAllWishlistsAsync(userDetail.UserID));
    dispatch(fetchAllCategoryProductsAsync(1));
  }, [dispatch, userDetail.UserID]);


  const addToCart = (id, e) => {
    // setItems((prevItems) =>
    //   prevItems.map((item) =>
    //     item.id === id ? { ...item, isAddeds: !item.isAddeds } : item
    //   )
    // );
    e.preventDefault();
  };

 

  const navigate = useNavigate();

  const handleNavigate = (prod_id) => {
    navigate(`/product/${prod_id}`);
  };

   // TO ADD PRODUCT INTO WISHLIST
   const handleAddToWishlist = (selectedProduct, event) => {
    let wishlistData = {
      userID: userDetail?.UserID,
      productId: selectedProduct.prod_ID,
      name: selectedProduct.prod_name,
      image: selectedProduct.images[0] || "",
      description: selectedProduct.prod_tech,
      mrp_price: selectedProduct.MRP_price,
      sale_price: selectedProduct.Sale_price,
      rating: selectedProduct.net_rating,
      qty: 10,
    };
    dispatch(addToWishlistsAsync(wishlistData)); 
    event.preventDefault(); // Add this line
  };
  
  const handleRemoveFromWishlist = (event, prodID) => {
    let wishlistData = {
      userID: userDetail?.UserID,
      productId: prodID,
    };
    dispatch(removeFromWishlistAsync(wishlistData));
    event.preventDefault(); // Add this line
  };
  

  // Function to check if a product is in the wishlist
  const isInWishlist = (productId) => {
    return (
      Array.isArray(wishlist) &&
      wishlist?.some((item) => item.productId === productId)
    );
  };


  return (
    <Wishlist>
      {(products?.product || []).slice(0, 8).map((data) => (
           <Card
           key={data.prod_ID}
           onClick={() => handleNavigate(data.prod_ID)}
         >
           <FavIcon>
                {isInWishlist(data.prod_ID) ? (
                  <FavoriteIcon
                    style={{
                      ...fontC,
                      color: "rgb(213, 48, 48)",
                      width: "25px",
                      height: "25px",
                    }}
                    onClick={(event) => {
                      handleRemoveFromWishlist(event, data.prod_ID);
                    }}
                  />
                ) : (
                  <FavoriteBorderOutlined
                    style={{
                      ...fontC,
                      color: "rgb(213, 48, 48)",
                      width: "25px",
                      height: "25px",
                    }}
                    onClick={(event) => {
                      handleAddToWishlist(data, event);
                    }}
                  />
                )}
              </FavIcon>
              <ItemImg>
              <HoverSlider images={data?.images && data.images}/>
              </ItemImg>
           <ItemDetail>
             <ItemRating>
               <Rating>{parseInt(data.net_rating).toFixed(1)}</Rating>
               <StarIcons style={fontS} />
               <Line>|</Line>
               <NoOfRating>313</NoOfRating>
               <AddCart onClick={(e) => addToCart(data.id, e)}>
                 {data.isAddeds ? (
                   <DoneOutlineOutlinedIcon
                     style={{
                       ...fontC,
                       background: "var(--color-green)",
                       color: "white",
                       padding: "4px",
                       marginTop: "5px",
                       borderRadius: "33px",
                       width: "25px",
                       height: "25px",
                     }}
                   />
                 ) : (
                   <ShoppingCartOutlinedIcon
                     style={{
                       ...fontC,
                       background: "var(--color-green)",
                       color: "white",
                       padding: "4px",
                       marginTop: "5px",
                       borderRadius: "33px",
                       width: "25px",
                       height: "25px",
                     }}
                   />
                 )}
               </AddCart>
             </ItemRating>
             <ItemName>
               {data.manufacture_name && data.manufacture_name}
             </ItemName>
             <ItemData>
               {data.prod_tech && data.prod_tech.slice(0, 45)}...
             </ItemData>
             <ItemPrice>
               <PriceDiv>
                 {data && data.Sale_price && (
                   <CurrentPrice>
                     Rs.{" "}
                     {formatWithCommasWithDecimal(
                       parseInt(
                         data.Sale_price > 99999
                           ? data.Sale_price / 10
                           : data.Sale_price
                       )
                     )}
                   </CurrentPrice>
                 )}
                 {data && data.MRP_price && (
                   <OriginalPrice>
                     Rs.{" "}
                     {formatWithCommasWithDecimal(
                       parseInt(
                         data.MRP_price > 99999
                           ? data.MRP_price / 10
                           : data.MRP_price
                       )
                     )}
                   </OriginalPrice>
                 )}
                 <Discount>20% OFF</Discount>
               </PriceDiv>
             </ItemPrice>
           </ItemDetail>
         </Card>
      ))}
    </Wishlist>
  );
}

const mapStateToProps = (state) => ({
  carouselIndex: state.hotDeals.carouselIndex,
});

const mapDispatchToProps = {
  setCarouselIndex,
};

export default connect(mapStateToProps, mapDispatchToProps)(LikeProductCard);

const Wishlist = styled.div`
width: 90%;
height: auto;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
flex-wrap: wrap;
padding-left: 1rem;
margin:auto;
`;

const Card = styled.div`
  position: relative;
  width: 17rem;
  height: 27rem;
  background: white;
  color: black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s all ease;
  border: 1px solid #a5a5a5;
  margin-right: 50px;
  margin-bottom:50px;
  &:hover {
    border-color: transparent;
    box-shadow: 0 0 15px 0 var(--border-color);

    transform: scale(1.01);
  }
`;
const ItemImg = styled.div`
  width: 100%;
  height: 68%;
  border-radius: 10px 10px 0 0;
`;
const FavIcon = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index:5;
  svg {
    width: 30px;
    height: 30px;
  }
`;
const ItemDetail = styled.div`
  width: 100%;
  height: 33%;
  display: flex;
  padding: 0 0.8rem 0 0.8rem;
  flex-direction: column;
  justify-content: flex-end;
`;
const ItemRating = styled.div`
  width: 85px;
  height: 25px;
  display: flex;
  align-items: center;
  background: #e9e3e3;
  // opacity: 0.9;
  padding: 4px 6px;
  border-radius: 20px;
  font-weight: 700;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  position: relative;
  &:hover {
    opacity: 1;
  }
`;
const Rating = styled.span`
  font-size: 12px;
`;
const StarIcons = styled(StarIcon)`
  color: gold;
`;
const Line = styled.span`
  margin-bottom: 0px;
  font-size: 0.6rem;
  font-weight: 900;
  margin: 0 2px;
`;
const NoOfRating = styled.span`
  font-size: 12px;
  margin-left: 2px;
`;
const ItemName = styled.span`
  font-size: 0.9rem;
  font-weight: 400;
  line-height: normal;
  color: black;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
  margin-bottom: 0.5rem;
  // font-family: "Orbitron", sans-serif;
  font-style: italic;
`;
const ItemData = styled.p`
  min-height: 30%;
  font-size: 1rem;
  font-weight: 700;
  margin: 0rem 0 0rem 0;
  color: var(--color-blue);
  text-transform: none;
`;
const ItemPrice = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0rem;
  margin-bottom: 0.8rem;
`;
const PriceDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const AddCart = styled.div`
  position: absolute;
  right: -10rem;
  top: -4px;
  svg {
    width: 30px;
    height: 30px;
  }
`;
const CurrentPrice = styled.p`
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
`;
const OriginalPrice = styled.p`
  text-decoration-line: line-through;
  margin: 0;
  color: gray;
  font-weight: 500;
  margin-left: 0;
  letter-spacing: 0.5px;
  font-size: 0.7rem;
`;

const Discount = styled.p`
  background: #ba3737;
  color: white;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  padding: 3px 5px;
`;

const fontS = {
  fontSize: "15",
};
const fontC = {
  fontSize: "20",
};
