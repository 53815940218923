import React, { useCallback, useEffect, useState } from "react";
import "./WalletPayment.css";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import WalletOutlinedIcon from "@mui/icons-material/WalletOutlined";
import { useDispatch, useSelector } from "react-redux";
import { selectAllCarts } from "../../features/cartPage/cartPageSlice";
import { selectSelectedAddress } from "../../features/cartPage/addressSlice";
import Swal from "sweetalert2";
import OrderConfirmed from "./OrderConfirmed";
import {
  fetchCouponAsync,
  getWalletAsync,
  selectAllCoupon,
  selectWallet,
} from "../../features/cartPage/walletSlice";
import {
  getWallet,
  tenPercentageDiscount,
  premiumUser,
  validCoupon,
  ccHundred,
} from "../../features/cartPage/walletAPI";
import { selectUser } from "../../features/auth/authSlice";
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ccLogo from "../../img/banners/Coupon-CC-Logo.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  calculateDealerPrice,
  calculateOfferPrice,
  calculateTotalAmount,
  calculateTotalAmountWithOutGST,
  fetchUserShipping,
  formatWithCommasWithDecimal,
} from "../../utils/utils";

function WalletPayment({
  confirmOverlay,
  setConfirmOverlay,
  handleConfirmOverlay,
  onCheckout,
  currentStep,
  setCurrentStep,
  handleGeneratePaymentURL,
  paymentSessionId,
  transactionId,
  generatePaymentUrl,
  setGeneratePaymentUrl,
}) {
  const dispatch = useDispatch();
  const walletAmount = useSelector(selectWallet)?.balance;
  const userDetail = useSelector(selectUser);
  // console.log(walletAmount);
  const [invalidCoupon, setInvalidCoupon] = useState(false);
  const [removedCoupon, setRemoveCoupon] = useState(false);
  const [addedCoupon, setAddedCoupon] = useState(false);
  const [openCoupon, setOpenCoupon] = useState(false);
  const [walletPaymentAmount, setWalletPaymentAmount] = useState();
  const [walletPaymentFinalPrice, setWalletPaymentFinalPrice] = useState(0);
  const [enteredCoupon, setEnteredCoupon] = useState("");
  const [tenPercentDiscount, setTenPercentDiscount] = useState(0);
  const [premUser, setPremUser] = useState(false);
  const [checkHundred, setCheckHundred] = useState(false);
  const [hundredPercentDiscount, setHundredPercentDiscount] = useState(0);
  const [errors, setErrors] = useState({});
  const [freeShipping, setFreeShipping] = useState(false);
  const [discount, setDiscount] = useState(0);
  const cart = useSelector(selectAllCarts);
  // console.log("CART",cart);
  const selectedAddress = useSelector(selectSelectedAddress);
  const [coupon, setCoupon] = useState("");
  const initialCoupons = useSelector(selectAllCoupon);
  // console.log(initialCoupons);

  useEffect(() => {
    dispatch(fetchCouponAsync());
    dispatch(getWalletAsync(userDetail?.UserID));
  }, [dispatch, userDetail?.UserID]);

  // Function to handle wallet payment input change
  const handleWalletPaymentChange = (event) => {
    const inputValue = parseInt(event.target.value);

    // Ensure inputValue is a valid number
    if (!isNaN(inputValue)) {
      if (
        inputValue > walletAmount ||
        inputValue > parseInt(calculateAmountToPay())
      ) {
        return;
      } else {
        setWalletPaymentAmount(inputValue);
      }
    } else {
      setWalletPaymentAmount(0);
    }
  };

  const handleWalletPayment = () => {
    // console.log(walletPaymentAmount);
    if (isNaN(walletPaymentAmount)) {
      return;
    } else {
      setWalletPaymentFinalPrice(walletPaymentAmount);
    }
  };

  useEffect(() => {
    const responsePromise = tenPercentageDiscount(userDetail?.UserID);
    responsePromise
      .then((response) => {
        // console.log(response); // Ensure response is as expected
        if (response && response.data && response.data.message) {
          setTenPercentDiscount(
            (calculateTotalAmountWithOutGST(cart) * 10) / 100
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error if necessary
      });
  }, []);

  const checkPremiumUser = useCallback(async () => {
    try {
      const response = await premiumUser(userDetail?.UserID);
      if (response && response.data && response.data.message) {
        setPremUser(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [userDetail?.UserID]);

  // Use useEffect to call the function
  useEffect(() => {
    checkPremiumUser();
  }, [checkPremiumUser]);

  const checkValidCoupon = (couponCode) => {
    let couponData = {
      userID: userDetail?.UserID,
      coupon: coupon,
    };
    const responsePromise = validCoupon(couponData);
    responsePromise.then((response) => {
      // console.log(response);
      if (response && response?.data && response?.data?.message) {
        return true;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          couponCode: "Already used twice(max. limit = 2)",
        }));
        return false;
      }
    });
  };

  const checkValidCCHundred = () => {
    return new Promise((resolve, reject) => {
      const responsePromise = ccHundred(userDetail?.UserID);
      responsePromise
        .then((response) => {
          // console.log(response);
          if (response && response?.data && response?.data?.message) {
            setCheckHundred(true);
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleCouponApply = async () => {
    const foundCoupon = initialCoupons?.find((c) => c?.code === coupon);
    const totalAmount = parseInt(calculateTotalAmountWithOutGST(cart));
    if (totalAmount && foundCoupon) {
      let discountAmount =
        totalAmount * (foundCoupon?.discount_percentage / 100);
      // if (discountAmount > foundCoupon?.UpTo) {
      //   discountAmount = foundCoupon?.UpTo;
      // }
      setDiscount(parseInt(discountAmount));
      setAddedCoupon(true);
      setOpenCoupon(false);
    }
    if (coupon === "CC100") {
      setDiscount(parseInt(calculateTotalAmount(cart) - 2));
      setAddedCoupon(true);
      setOpenCoupon(false);
    }
  };

  const fetchAndSetShipping = useCallback(async () => {
    if (userDetail?.UserID) {
      try {
        const userShipping = await fetchUserShipping(userDetail?.UserID);

        if (userShipping === "false") {
          setFreeShipping(true);
        } else {
          setFreeShipping(false);
        }
      } catch (error) {
        console.error("Error fetching user shipping info:", error);
      }
    }
  }, [userDetail?.UserID]);

  useEffect(() => {
    fetchAndSetShipping();
  }, [fetchAndSetShipping]);

  // Function to calculate the shipping cost for the selected shipping mode
  const calculateShippingCost = () => {
    let totalAmount = 0;
    cart &&
      cart?.forEach((product) => {
        totalAmount +=
          !product?.skuDetails?.SKU_ID?.startsWith("D") &&
          (product.skuDetails?.Offer_price
            ? calculateOfferPrice(
                parseInt(product?.skuDetails?.MRP_price),
                parseInt(product?.skuDetails?.Offer_price),
                parseInt(product?.skuDetails?.GST)
              )
            : calculateDealerPrice(
                parseInt(product?.skuDetails?.MRP_price),
                parseInt(product?.skuDetails?.NDP_Price),
                parseInt(product?.skuDetails?.GST)
              )) * product?.buy_quantity;
      });

    const amountAfterDiscount = totalAmount - (addedCoupon && discount);
    const amountToPay =
      amountAfterDiscount > walletPaymentFinalPrice
        ? amountAfterDiscount - walletPaymentFinalPrice
        : 0;

    if (amountToPay >= 3000 || premUser || freeShipping) {
      return 0;
    } else {
      return 250;
    }
  };

  // Function to calculate the amount to pay (total amount + shipping cost)
  const calculateAmountToPay = () => {
    const totalAmount = calculateTotalAmount(cart);
    const shippingCost = calculateShippingCost();
    checkValidCCHundred();
    const amountAfterDiscount =
      totalAmount +
      (checkHundred ? 0 : shippingCost) -
      (addedCoupon && discount);
    const amountToPay =
      amountAfterDiscount > walletPaymentFinalPrice
        ? amountAfterDiscount - walletPaymentFinalPrice
        : 0;
    return amountToPay;
  };

  const handleSave = (discount) => {
    const amount = parseInt(calculateTotalAmountWithOutGST(cart));
    // console.log(discount, amount);
    return (amount * discount) / 100;
  };

  useEffect(() => {
    if (generatePaymentUrl) {
      handleGeneratePaymentURL(parseInt(calculateAmountToPay()));
    }
  }, [generatePaymentUrl]);

  const handleCodeAdd = (data) => {
    const amount = parseInt(calculateTotalAmountWithOutGST(cart));
    const save = (amount * data?.discount_percentage) / 100;
    setCoupon(data?.code);
    setDiscount(save);
  };

  const handleCheckCoupon = () => {
    const foundCoupon = initialCoupons.find(
      (coupon) => coupon.code === enteredCoupon
    );
    if (foundCoupon) {
      // Select the found coupon
      const amount = parseInt(calculateTotalAmountWithOutGST(cart));
      const save = (amount * foundCoupon?.discount_percentage) / 100;
      setDiscount(save);
      setCoupon(enteredCoupon);
      setEnteredCoupon("");
      // Reset error state
      setInvalidCoupon(false);
    } else {
      setInvalidCoupon(true);
    }
  };

  return (
    <div className="wallet-payment-container">
      <div className="wallet-payment-main-container">
        <span className="wallet-payment-heading">Payment Summary</span>
        <div className="wallet-payment-details">
          <div className="wallet-payment-each-details">
            <span className="wallet-payment-each-key">Total Price</span>
            <hr className="wallet-payment-divider" />
            <span className="wallet-payment-each-value">
              ₹{formatWithCommasWithDecimal(calculateTotalAmount(cart))}
            </span>
          </div>
          <div className="wallet-payment-each-details">
            <span className="wallet-payment-each-key">Shipping Cost</span>
            <hr className="wallet-payment-divider" />
            <span className="wallet-payment-each-value">
              ₹{formatWithCommasWithDecimal(calculateShippingCost())}
            </span>
          </div>
          {discount !== 0 && addedCoupon && (
            <div className="wallet-payment-each-details">
              <span className="wallet-payment-each-key">Discount</span>
              <hr className="wallet-payment-divider" />
              <span className="wallet-payment-each-value">
                -₹{formatWithCommasWithDecimal(parseInt(discount))}
              </span>
            </div>
          )}
          {walletPaymentFinalPrice !== 0 && (
            <div className="wallet-payment-each-details">
              <span className="wallet-payment-each-key">Wallet</span>
              <hr className="wallet-payment-divider" />
              <span className="wallet-payment-each-value">
                -₹
                {walletPaymentFinalPrice
                  ? parseInt(walletPaymentFinalPrice).toFixed(2)
                  : "0.00"}
              </span>
            </div>
          )}
          <div
            className="wallet-payment-each-details"
            style={{
              borderTop: "2px solid rgba(0,0,0,0.1)",
              paddingTop: "1vh",
            }}
          >
            <span className="wallet-payment-each-key">Amount to pay</span>
            <hr className="wallet-payment-divider" />
            <span className="wallet-payment-each-value">
              ₹{formatWithCommasWithDecimal(parseInt(calculateAmountToPay()))}
            </span>
          </div>
          <span
            className="wallet-payment-hit-btn"
            onClick={() => {
              handleConfirmOverlay();
              setGeneratePaymentUrl(true);
            }}
          >
            Proceed to Payment <ArrowForwardOutlinedIcon />
          </span>
        </div>
      </div>
      <div className="wallet-payment-coupon-wallet-container">
        <div className="product-apply-coupon-code-container">
          <QrCode2OutlinedIcon
            className="product-apply-coupon-code-img"
            style={{ fontSize: "20px", color: "black" }}
          />
          <div className="product-apply-coupon-name-save-container">
            <span className="product-apply-coupon-name">Apply Coupon Code</span>
            {addedCoupon && (
              <span className="product-apply-coupon-save">
                You Saved ₹{formatWithCommasWithDecimal(discount)}
              </span>
            )}
          </div>
          <span
            className="product-apply-coupon-apply-btn"
            onClick={() => setOpenCoupon(true)}
          >
            {addedCoupon ? "Edit" : "Apply"}
          </span>
        </div>
        {openCoupon && (
          <>
            <div className="coupon-overlay"></div>
            <div className="coupon-overlay-selection-container">
              <div className="coupon-overlay-selection-heading-container">
                <span className="coupon-overlay-selection-span">
                  Apply Coupon Code
                </span>
                <CloseOutlinedIcon onClick={() => setOpenCoupon(false)} />
              </div>
              <div className="coupon-overlay-selection-input-container">
                <input
                  type="text"
                  placeholder="CCNEW"
                  className="coupon-overlay-selection-input"
                  value={enteredCoupon}
                  onChange={(e) =>
                    setEnteredCoupon(e.target.value.toUpperCase())
                  }
                />
                <span
                  className="coupon-overlay-selection-check-btn"
                  onClick={handleCheckCoupon}
                >
                  Check
                </span>
                {invalidCoupon && (
                  <span className="applied-coupon-invalid">
                    <ErrorOutlinedIcon />
                    Coupon code is invalid or expired
                  </span>
                )}
              </div>
              <div className="coupon-overlay-selection-coupon-select-container">
                {initialCoupons &&
                  initialCoupons?.map((data, index) => {
                    if (data?.code === coupon) {
                      return (
                        <div className="coupon-overlay-selection-coupon-select-each-container">
                          <input
                            type="radio"
                            className="coupon-overlay-selection-coupon-input"
                            unselectable={checkValidCoupon ? "true" : "false"}
                            checked={coupon === data?.code}
                            value={data?.code}
                            onChange={() => handleCodeAdd(data)}
                          />
                          <div className="coupon-overlay-selection-coupon-detail-container">
                            <div className="coupon-overlay-selection-coupon-top">
                              <LazyLoadImage
                                src={ccLogo}
                                alt=""
                                className="coupon-overlay-cc-logo"
                                effect="blur"
                                wrapperProps={{
                                  // If you need to, you can tweak the effect transition using the wrapper style.
                                  style: { transitionDelay: "1s" },
                                }}
                              />
                              <span className="coupon-overlay-selection-coupon">
                                {data?.code}
                              </span>
                            </div>
                            <span className="coupon-overlay-selection-save">
                              Save <br />
                              <b>
                                ₹
                                {formatWithCommasWithDecimal(
                                  parseInt(
                                    handleSave(data?.discount_percentage)
                                  )
                                )}
                              </b>
                            </span>
                            <span className="coupon-overlay-selection-expire">
                              Expires: <br /> <b>23.08.2024</b>
                            </span>
                            <span className="coupon-overlay-terms">
                              Terms & Conditions Apply
                            </span>
                          </div>
                          {errors[data?.code] && (
                            <span className="coupon-overlay-selection-error">
                              {errors[data?.code]}
                            </span>
                          )}
                        </div>
                      );
                    } else {
                      return null; // If data.code doesn't match coupon, don't render anything
                    }
                  })}
              </div>
              <div className="coupon-overlay-selection-bottom-container">
                <div className="coupon-overlay-selection-bottom-left-container">
                  <span className="coupon-overlay-selection-bottom-left-saving-head">
                    Maximum Savings:
                  </span>
                  <span className="coupon-overlay-selection-bottom-left-saving-price">
                    ₹{formatWithCommasWithDecimal(parseInt(discount))}
                  </span>
                </div>
                <span
                  className="coupon-overlay-selection-bottom-right-btn"
                  onClick={handleCouponApply}
                >
                  Apply
                </span>
              </div>
            </div>
          </>
        )}

        <div className="wallet-payment-wallet-balance-container">
          <div className="wallet-payment-balance-top-container">
            <WalletOutlinedIcon />
            <span className="wallet-payment-balance-top-span">
              Wallet Balance
            </span>
            <span className="wallet-payment-balance-top-price">
              ₹
              {formatWithCommasWithDecimal(
                walletAmount -
                  (!isNaN(walletPaymentAmount) && walletPaymentAmount)
              )}
            </span>
          </div>
          <div className="wallet-payment-balance-bottom-container">
            <input
              type="text"
              placeholder="Enter Amount to pay from wallet"
              value={walletPaymentAmount}
              onChange={handleWalletPaymentChange}
              className="wallet-payment-balance-bottom-input"
              onKeyPress={(event) => {
                // Check if the key pressed is not a digit
                if (!/\d/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <span
              className="wallet-payment-balance-bottom-pay-btn"
              onClick={handleWalletPayment}
            >
              Pay
            </span>
            {walletPaymentFinalPrice === walletPaymentAmount &&
              walletPaymentFinalPrice > 0 && (
                <span className="applied-wallet-removed">
                  <CheckCircleOutlineIcon />₹ {walletPaymentFinalPrice} used
                  from wallet
                </span>
              )}
          </div>
          {confirmOverlay && (
            <div className="overlay-container">
              <OrderConfirmed
                setConfirmOverlay={setConfirmOverlay}
                paymentSessionId={paymentSessionId}
                transactionId={transactionId}
                selectedAddress={selectedAddress}
                coupon={coupon}
                addedCoupon={addedCoupon}
                discount={discount}
                walletPaymentFinalPrice={walletPaymentFinalPrice}
                tenPercentDiscount={tenPercentDiscount}
                hundredPercentDiscount={hundredPercentDiscount}
                premUser={premUser}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                checkHundred={checkHundred}
                freeShipping={freeShipping}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WalletPayment;
