import React, { useState } from "react";
import styled from "styled-components";
import StarIcon from "@mui/icons-material/Star";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import { setCarouselIndex } from "../../store/slices/hotDealsSlice";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { FavoriteBorderOutlined } from "@mui/icons-material";
import {
  fetchAllCategoryProductsAsync,
  selectAllCategoryProducts,
} from "../../features/listingPage/listingPageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../features/auth/authSlice";
import {
  selectAllWishlists,
  addToWishlistsAsync,
  removeFromWishlistAsync,
  fetchAllWishlistsAsync,
} from "../../features/wishlist/wishlistSlice";
import {
  toggleOverlay,
  selectIsOverlayVisible,
} from "../../store/slices/headerSlice";
import {
  fetchAllCartsAsync,
  addToCartsAsync,
  removeFromCartsAsync,
  selectAllCarts,
} from "../../features/cartPage/cartPageSlice";
import HoverSlider from "../ListingPage/ListingProduct/HoverSlider";
import { IMAGE_BASE } from "../../config";
import SelectDesktopVariant from "../EndPages/SelectDesktopVariant";
import { calculateDiscountPercentage, formatWithCommasWithDecimal } from "../../utils/utils";

const SimilarProduct = ({
  setCarouselIndex,
  carouselIndex,
  isOverlayVisible,
  toggleOverlay,
}) => {
  const dispatch = useDispatch();
  const products = useSelector(selectAllCategoryProducts);
  const userDetail = useSelector(selectUser);
  const wishlist = useSelector(selectAllWishlists);
  const cart = useSelector(selectAllCarts); //TO FETCH ALL CARTS PRODUCTS
  const [openDesktopVariant, setOpenDesktopVariant] = useState(null);
  // console.log(wishlist);
  // console.log(cart);

  useEffect(() => {
    dispatch(fetchAllWishlistsAsync(userDetail.UserID));
    dispatch(fetchAllCategoryProductsAsync(1));
    dispatch(fetchAllCartsAsync(userDetail.UserID));
  }, [dispatch, userDetail.UserID]);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 2,
      slidesToSlide: 2,
    },
  };

  const handleLoginOverlay = (e) => {
    toggleOverlay();
    e.preventDefault();
  };

  const handleCarouselChange = (index) => {
    setCarouselIndex(index);
  };


  const navigate = useNavigate();

  const handleNavigate = (prod_id) => {
    navigate(`/product/${prod_id}`);
  };

  // TO ADD PRODUCT INTO CART
  const addToCart = (data, e) => {
    let cartData = {
      userID: userDetail?.UserID,
      productId: data?.prod_ID,
      SKU:data.SKU_ID,
      name: data?.prod_name,
      image: data?.images[0],
      manufacture_Image: data?.manufacture_images,
      manufacture_name: data?.manufacture_name,
      MRPPrice: data?.MRP_price,
      salePrice: data?.Sale_price,
      discountPrice: 200,
      buyQty: 1,
      qty: 10,
      size: "Medium",
      colour: "Red",
      variation: "Diamond",
    };
    dispatch(addToCartsAsync(cartData));
    e.preventDefault();
  };

  const removeFromCart = (data, e) => {
    let cartData = {
      userID: userDetail?.UserID,
      productId: data?.prod_ID,
    };
    dispatch(removeFromCartsAsync(cartData));
    dispatch(fetchAllCartsAsync(userDetail.UserID));
    e.preventDefault();
  };

  // TO ADD PRODUCT INTO WISHLIST
  const handleAddToWishlist = (selectedProduct, event) => {
    let wishlistData = {
      userID: userDetail?.UserID,
      productId: selectedProduct.prod_ID,
      name: selectedProduct.prod_name,
      image: selectedProduct.images[0] || "",
      description: selectedProduct.prod_name,
      mrp_price: selectedProduct.MRP_price,
      sale_price: selectedProduct.Sale_price,
      rating: selectedProduct.net_rating,
      qty: 10,
    };
    dispatch(addToWishlistsAsync(wishlistData)).then(() =>
      dispatch(fetchAllWishlistsAsync(userDetail.UserID))
    ); // Fetch updated wishlist
    event.preventDefault();
  };

  const handleRemoveFromWishlist = (event, prodID) => {
    let wishlistData = {
      userID: userDetail?.UserID,
      productId: prodID,
    };
    dispatch(removeFromWishlistAsync(wishlistData)).then(() =>
      dispatch(fetchAllWishlistsAsync(userDetail.UserID))
    ); // Fetch updated wishlist
    event.preventDefault();
  };

  // Function to check if a product is in the wishlist
  const isInWishlist = (productId) => {
    return (
      Array.isArray(wishlist) &&
      wishlist?.some((item) => item.productId === productId)
    );
  };

  // Function to check if a product is in the cart
  const isInCart = (productId) => {
    return (
      Array.isArray(cart) &&
      cart?.some((item) => (item.productId) === productId)
    );
  };

  
  const handleOpenDesktopVariant = (id, e) => {
    setOpenDesktopVariant(id);
    e.preventDefault();
  };

  return (
    <Container>
      <h3>Similar Product</h3>
      <StyledCarousel
        responsive={responsive}
        afterChange={handleCarouselChange}
        selectedIndex={carouselIndex}
      >
        {(products?.product || []).slice(0, 6).map((data) => (
          <Card key={data.prod_ID}>
            {/* <HotDeal src={h1} alt="hotdeal-icon"></HotDeal> */}
            <FavIcon
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {isInWishlist(data.prod_ID) ? (
                <FavoriteIcon
                  style={{
                    ...fontC,
                    color: "rgb(213, 48, 48)",
                    width: "25px",
                    height: "25px",
                  }}
                  onClick={(event) => {
                    handleRemoveFromWishlist(event, data.prod_ID);
                  }}
                />
              ) : (
                <FavoriteBorderOutlined
                  style={{
                    ...fontC,
                    color: "rgb(213, 48, 48)",
                    width: "25px",
                    height: "25px",
                  }}
                  onClick={(event) => {
                    userDetail && userDetail?.UserID
                      ? handleAddToWishlist(data, event)
                      : handleLoginOverlay(event);
                  }}
                />
              )}
            </FavIcon>
            <ItemImg onClick={() => handleNavigate(data.prod_ID)}>
              <HoverSlider images={data?.images && data.images} />
            </ItemImg>
            <ItemDetail>
              <ItemRating>
                <Rating>{parseInt(data.net_rating).toFixed(1)}</Rating>
                <StarIcons style={fontS} />
                <Line>|</Line>
                <NoOfRating>313</NoOfRating>
              </ItemRating>
              <AddCart
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {isInCart(data.prod_ID) ? (
                  <DoneOutlineOutlinedIcon
                    style={{
                      ...fontC,
                      background: "var(--color-green)",
                      color: "white",
                      padding: "4px",
                      marginTop: "5px",
                      borderRadius: "33px",
                      width: "25px",
                      height: "25px",
                    }}
                    onClick={(e) => removeFromCart(data, e)}
                  />
                ) : (
                  <ShoppingCartOutlinedIcon
                    style={{
                      ...fontC,
                      background: "var(--color-green)",
                      color: "white",
                      padding: "4px",
                      marginTop: "5px",
                      borderRadius: "33px",
                      width: "25px",
                      height: "25px",
                    }}
                    onClick={(e) => {
                      userDetail && userDetail?.UserID
                        ? handleOpenDesktopVariant(data.prod_ID, e)
                        : handleLoginOverlay(e);
                    }}
                  />
                )}
              </AddCart>
              {data && data?.manufacture_images ? (
                <IMG
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                    data && data?.manufacture_images
                  }`}
                  alt=""
                  className="product-container-detail-img"
                />
              ) : (
                <ItemName>
                  {data.manufacture_name && data.manufacture_name}
                </ItemName>
              )}
              <ItemNameMobile>
                {data.prod_name && data.prod_name.slice(0, 45)}
                {data.prod_name.length > 45 && "..."}
              </ItemNameMobile>
              <ItemData>
                {data.prod_name && data.prod_name.slice(0, 45)}
                {data.prod_name.length > 45 && "..."}
              </ItemData>
              <ItemPrice>
                <PriceDiv>
                  {data && data.Sale_price && (
                    <CurrentPrice>
                      Rs.{" "}
                      {formatWithCommasWithDecimal(
                        parseInt(
                          data.Sale_price > 99999
                            ? data.Sale_price / 10
                            : data.Sale_price
                        )
                      )}
                    </CurrentPrice>
                  )}
                  {data && data.MRP_price && (
                    <OriginalPrice>
                      Rs.{" "}
                      {formatWithCommasWithDecimal(
                        parseInt(
                          data.MRP_price > 99999
                            ? data.MRP_price / 10
                            : data.MRP_price
                        )
                      )}
                    </OriginalPrice>
                  )}
                  {calculateDiscountPercentage(
                    data?.MRP_price,
                    data?.Sale_price
                  ) > 0 && (
                    <Discount>
                      {calculateDiscountPercentage(
                        data?.MRP_price,
                        data?.Sale_price
                      )}
                      % OFF
                    </Discount>
                  )}
                </PriceDiv>
              </ItemPrice>
            </ItemDetail>
          </Card>
        ))}
      </StyledCarousel>
      <StyledCarousel
        responsive={responsive}
        afterChange={handleCarouselChange}
        selectedIndex={carouselIndex}
      >
        {(products?.product || []).slice(7).map((data) => (
          <Card key={data.prod_ID}>
            {/* <HotDeal src={h1} alt="hotdeal-icon"></HotDeal> */}
            <FavIcon
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {isInWishlist(data.prod_ID) ? (
                <FavoriteIcon
                  style={{
                    ...fontC,
                    color: "rgb(213, 48, 48)",
                    width: "25px",
                    height: "25px",
                  }}
                  onClick={(event) => {
                    handleRemoveFromWishlist(event, data.prod_ID);
                  }}
                />
              ) : (
                <FavoriteBorderOutlined
                  style={{
                    ...fontC,
                    color: "rgb(213, 48, 48)",
                    width: "25px",
                    height: "25px",
                  }}
                  onClick={(event) => {
                    userDetail && userDetail?.UserID
                      ? handleAddToWishlist(data, event)
                      : handleLoginOverlay(event);
                  }}
                />
              )}
            </FavIcon>
            <ItemImg onClick={() => handleNavigate(data.prod_ID)}>
              <HoverSlider images={data?.images && data.images} />
            </ItemImg>
            <ItemDetail>
              <ItemRating>
                <Rating>{parseInt(data.net_rating).toFixed(1)}</Rating>
                <StarIcons style={fontS} />
                <Line>|</Line>
                <NoOfRating>313</NoOfRating>
              </ItemRating>
              <AddCart
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {isInCart(data.prod_ID) ? (
                  <DoneOutlineOutlinedIcon
                    style={{
                      ...fontC,
                      background: "var(--color-green)",
                      color: "white",
                      padding: "4px",
                      marginTop: "5px",
                      borderRadius: "33px",
                      width: "25px",
                      height: "25px",
                    }}
                    onClick={(e) => removeFromCart(data, e)}
                  />
                ) : (
                  <ShoppingCartOutlinedIcon
                    style={{
                      ...fontC,
                      background: "var(--color-green)",
                      color: "white",
                      padding: "4px",
                      marginTop: "5px",
                      borderRadius: "33px",
                      width: "25px",
                      height: "25px",
                    }}
                    onClick={(e) => {
                      userDetail && userDetail?.UserID
                        ? handleOpenDesktopVariant(data.prod_ID, e)
                        : handleLoginOverlay(e);
                    }}
                  />
                )}
              </AddCart>
              {data && data?.manufacture_images ? (
                <IMG
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                    data && data?.manufacture_images
                  }`}
                  alt=""
                  className="product-container-detail-img"
                />
              ) : (
                <ItemName>
                  {data.manufacture_name && data.manufacture_name}
                </ItemName>
              )}
              <ItemNameMobile>
                {data.prod_name && data.prod_name.slice(0, 45)}
                {data.prod_name.length > 45 && "..."}
              </ItemNameMobile>
              <ItemData>
                {data.prod_name && data.prod_name.slice(0, 45)}
                {data.prod_name.length > 45 && "..."}
              </ItemData>
              <ItemPrice>
                <PriceDiv>
                  {data && data.Sale_price && (
                    <CurrentPrice>
                      Rs.{" "}
                      {formatWithCommasWithDecimal(
                        parseInt(
                          data.Sale_price > 99999
                            ? data.Sale_price / 10
                            : data.Sale_price
                        )
                      )}
                    </CurrentPrice>
                  )}
                  {data && data.MRP_price && (
                    <OriginalPrice>
                      Rs.{" "}
                      {formatWithCommasWithDecimal(
                        parseInt(
                          data.MRP_price > 99999
                            ? data.MRP_price / 10
                            : data.MRP_price
                        )
                      )}
                    </OriginalPrice>
                  )}
                  {calculateDiscountPercentage(
                    data?.MRP_price,
                    data?.Sale_price
                  ) > 0 && (
                    <Discount>
                      {calculateDiscountPercentage(
                        data?.MRP_price,
                        data?.Sale_price
                      )}
                      % OFF
                    </Discount>
                  )}
                </PriceDiv>
              </ItemPrice>
            </ItemDetail>
          </Card>
        ))}
      </StyledCarousel>
      {openDesktopVariant !== null && (
        <SelectDesktopVariant
          setOpenDesktopVariant={setOpenDesktopVariant}
          product={products?.product.find(
            (item) => item.prod_ID === openDesktopVariant
          )}
        />
      )}
      <MobileViewContainer>
        {(products?.product || []).slice(0, 8).map((data) => (
          <MobileCard
            key={data.prod_ID}
            onClick={() => handleNavigate(data.prod_ID)}
          >
            {/* <HotDeal src={h1} alt="hotdeal-icon"></HotDeal> */}
            {/* <MobileFavIcon>
                {isInWishlist(data.prod_ID) ? (
                  <FavoriteIcon
                    style={{
                      ...fontC,
                      color: "rgb(213, 48, 48)",
                      width: "25px",
                      height: "25px",
                    }}
                    onClick={(event) => {
                      handleRemoveFromWishlist(event, data.prod_ID);
                    }}
                  />
                ) : (
                  <FavoriteBorderOutlined
                    style={{
                      ...fontC,
                      color: "rgb(213, 48, 48)",
                      width: "25px",
                      height: "25px",
                    }}
                    onClick={(e) => {
                      handleAddToWishlist(data, e);
                    }}
                  />
                )}
              </MobileFavIcon> */}
            <MobileImgDetail>
              <MobileItemImg>
                <HoverSlider images={data?.images && data.images} />
              </MobileItemImg>
              <MobileItemDetail>
                {data && data?.manufacture_images ? (
                  <MobileIMG
                    src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                      data && data?.manufacture_images
                    }`}
                    alt=""
                    className="product-container-detail-img"
                  />
                ) : (
                  <MobileItemName>
                    {data.manufacture_name && data.manufacture_name}
                  </MobileItemName>
                )}
                <MobileItemData>
                  {data.prod_name && data.prod_name.slice(0, 45)}
                  {data.prod_name.length > 45 && "..."}
                </MobileItemData>
                <MobileItemRating>
                  <MobileRating>
                    {parseInt(data.net_rating).toFixed(1)}
                  </MobileRating>
                  <MobileStarIcons style={fontS} />
                  <MobileLine>|</MobileLine>
                  <MobileNoOfRating>313</MobileNoOfRating>
                </MobileItemRating>

                <MobileItemPrice>
                  <MobilePriceDiv>
                    {data && data.Sale_price && (
                      <MobileCurrentPrice>
                        Rs.{" "}
                        {formatWithCommasWithDecimal(
                          parseInt(
                            data.Sale_price > 99999
                              ? data.Sale_price / 10
                              : data.Sale_price
                          )
                        )}
                      </MobileCurrentPrice>
                    )}
                    {data && data.MRP_price && (
                      <MobileOriginalPrice>
                        Rs.{" "}
                        {formatWithCommasWithDecimal(
                          parseInt(
                            data.MRP_price > 99999
                              ? data.MRP_price / 10
                              : data.MRP_price
                          )
                        )}
                      </MobileOriginalPrice>
                    )}
                    {calculateDiscountPercentage(
                      data?.MRP_price,
                      data?.Sale_price
                    ) > 0 && (
                      <MobileDiscount>
                        {calculateDiscountPercentage(
                          data?.MRP_price,
                          data?.Sale_price
                        )}
                        % OFF
                      </MobileDiscount>
                    )}
                  </MobilePriceDiv>
                </MobileItemPrice>
              </MobileItemDetail>
            </MobileImgDetail>
            <MobilePurchase>
              <MobileAddToCart>
                {isInCart(data.prod_ID) ? (
                  <>
                    <DoneOutlineOutlinedIcon
                      style={{
                        ...fontC,
                        color: "white",
                        padding: "4px",
                        width: "28px",
                        height: "28px",
                      }}
                      onClick={(e) => removeFromCart(data, e)}
                    />{" "}
                    <MobileAddToCartSpan>Added</MobileAddToCartSpan>
                  </>
                ) : (
                  <>
                    <ShoppingCartOutlinedIcon
                      style={{
                        ...fontC,
                        color: "var(--color-blue)",
                        padding: "4px",
                        width: "28px",
                        height: "28px",
                      }}
                      onClick={(e) => addToCart(data, e)}
                    />
                    <MobileAddToCartSpan>Add to cart</MobileAddToCartSpan>
                  </>
                )}
              </MobileAddToCart>
              <MobileBuyNow>Buy Now</MobileBuyNow>
            </MobilePurchase>
          </MobileCard>
        ))}
      </MobileViewContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  carouselIndex: state.hotDeals.carouselIndex,
  isOverlayVisible: selectIsOverlayVisible(state),
});

const mapDispatchToProps = {
  setCarouselIndex,
  toggleOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(SimilarProduct);

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 0.75rem 7.5rem;
  background: #d1d4d4;
  // overflow: hidden;
  margin: auto;
  h3 {
    font-size: 2.2rem;
    font-weight: 700;
    line-height: normal;
    text-transform: Capitalize;
    letter-spacing: 0.1rem;
    margin-bottom: 5vh;
    text-align: center;
    padding: 1.5rem 0 1rem 0;
    color: black;
  }
  @media (max-width: 500px) {
    padding: 0.75rem 0rem;
    h3 {
      font-size: 1.5rem;
      margin: 0;
      margin-bottom: 1vh;
      text-align: center;
      padding: 0.2rem 0 0.5rem 0;
      color: black;
    }
  }
`;
const StyledCarousel = styled(Carousel)`
  margin-bottom: 3rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  a {
    width: 230px;
    height: 340px;
    margin: 0;
    padding: 0;
  }
  button {
    background: #04477e;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0;
    margin-right: -1.6rem;
    margin-left: -3.2rem;
    z-index: 10;
    font-weight: bold;
    &:hover {
      background: #003765;
    }
  }
  @media (max-width: 500px) {
    margin: 0;
    margin-left: 1rem;
    padding: 0.75rem 0rem;
    button {
      width: 1rem;
      height: 1rem;
      margin-right: -0.2rem;
      margin-left: -1rem;
    }
    display: none;
  }
`;
const Card = styled.div`
  position: relative;
  width: 230px;
  height: 340px;
  background: white;
  color: black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s all ease;
  border: 1px solid #a5a5a5;
  @media (max-width: 500px) {
    border-radius: 5px;
    margin-bottom: 0;
    width: 11rem;
    height: 18rem;
  }
`;
// const ItemImg = styled.img`
//   width: 100%;
//   height: 66%;
//   border-radius: 10px 10px 0 0;
//   @media (max-width: 500px) {
//     height: 78%; /* Adjusted height for mobile view */
//     border-radius: 5px 5px 0 0;
//   }
// `;

const ItemImg = styled.div`
  width: 100%;
  height: 68%;
  border-radius: 10px 10px 0 0;
  @media (max-width: 500px) {
    height: 78%;
    border-radius: 5px 5px 0 0;
  }
`;
const FavIcon = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 5;
  svg {
    width: 30px;
    height: 30px;
  }
  @media (max-width: 500px) {
    top: 0.5rem;
    right: 0.5rem;
  }
`;
const ItemDetail = styled.div`
  width: 100%;
  height: 32%;
  display: flex;
  padding: 0.8rem;
  padding-bottom: 1rem;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  @media (max-width: 500px) {
    padding: 0 0.5rem 0.5rem 0.5rem;
    height: 22%; /* Adjusted height for mobile view */
  }
`;
const ItemRating = styled.div`
  width: 80px;
  height: 22px;
  display: flex;
  align-items: center;
  background: #e9e3e3;
  // opacity: 0.9;
  padding: 4px 6px;
  border-radius: 20px;
  font-weight: 700;
  margin-bottom: 0rem;
  justify-content: space-between;
  position: absolute;
  top: -11px;
  left: 0.8rem;
  &:hover {
    opacity: 1;
  }
  @media (max-width: 500px) {
    position: static;
    margin-bottom: 0.4rem;
    width: 70px;
    height: 20px;
  }
`;
const Rating = styled.span`
  font-size: 10px;
  @media (max-width: 500px) {
    font-size: 10px;
  }
`;
const StarIcons = styled(StarIcon)`
  color: gold;
`;
const Line = styled.span`
  margin-bottom: 0px;
  font-size: 0.5rem;
  font-weight: 900;
  margin: 0 2px;
  @media (max-width: 500px) {
    font-size: 0.5rem;
    font-weight: 900;
    margin: 0 1.5px;
  }
`;
const NoOfRating = styled.span`
  font-size: 10px;
  margin-left: 2px;
  @media (max-width: 500px) {
    font-size: 10px;
  }
`;
const ItemName = styled.span`
  font-size: 0.9rem;
  font-weight: 400;
  line-height: normal;
  color: black;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
  margin-bottom: 0rem;
  // font-family: "Orbitron", sans-serif;
  font-style: italic;
  @media (max-width: 500px) {
    display: none;
    font-size: 0.8rem;
    margin-bottom: 0rem;
  }
`;

const IMG = styled.img`
  width: 80px;
  height: 20px;
`;
const ItemNameMobile = styled.span`
  display: none;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: normal;
  color: black;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
  // font-family: "Orbitron", sans-serif;
  font-style: italic;
  @media (max-width: 500px) {
    display: block;
    font-size: 0.8rem;
    margin: 0.3rem 0;
  }
`;
const ItemData = styled.p`
  // min-height: 30%;
  font-size: 0.85rem;
  font-weight: 700;
  color: var(--color-blue);
  text-transform: none;
  margin: 0;
  @media (max-width: 500px) {
    min-height: 25%;
    font-size: 0.9rem;
    display: none;
  }
`;
const ItemPrice = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 500px) {
    margin-bottom: 0rem;
  }
`;
const PriceDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;
const AddCart = styled.div`
  position: absolute;
  right: 1rem;
  top: -1rem;
  svg {
    width: 25px;
    height: 25px;
  }
  @media (max-width: 500px) {
    right: -5.3rem;
  }
`;
const CurrentPrice = styled.p`
  margin: 0;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: capitalize;
  @media (max-width: 500px) {
    font-size: 0.8rem;
  }
`;
const OriginalPrice = styled.p`
  text-decoration-line: line-through;
  margin: 0;
  color: gray;
  font-weight: 500;
  margin-left: 1rem;
  letter-spacing: 0.5px;
  font-size: 0.7rem;
  @media (max-width: 500px) {
    font-size: 0.5rem;
  }
`;
const Discount = styled.div`
  background: #ba3737;
  color: white;
  border-radius: 5px;
  font-weight: 600;
  font-size: 10px;
  padding: 3px 5px;
  position: absolute;
  right: 0;
  @media (max-width: 500px) {
    font-size: 8px;
  }
`;

/**  Mobile View */

const MobileViewContainer = styled.div`
  display: none;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
  }
`;

const MobileCard = styled.div`
  display: none;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: white;
    position: relative;
    width: 100%;
    height: 200px;
    margin: 5px 0;
    padding: 10px;
  }
`;
const MobileImgDetail = styled.div`
  @media (max-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    position: relative;
    width: 100%;
    height: 60%;
    margin: 10px 0;
    padding: 0 10px;
  }
`;
const MobileHotDeal = styled.img``;

const MobileItemImg = styled.div`
  display: none;
  @media (max-width: 500px) {
    display: flex;
    width: 30%;
    height: 100%;
    border-radius: 5px;
  }
`;
const MobileFavIcon = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 5;
  svg {
    width: 30px;
    height: 30px;
  }
  @media (max-width: 500px) {
    top: 0.5rem;
    right: 0.5rem;
  }
`;
const MobileItemDetail = styled.div`
  display: none;
  @media (max-width: 500px) {
    width: 70%;
    height: 100%;
    display: flex;
    padding: 0 8px 0 8px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`;
const MobileItemRating = styled.div`
  @media (max-width: 500px) {
    width: 75px;
    height: 20px;
    display: flex;
    align-items: center;
    background: #e9e3e3;
    padding: 4px 6px;
    border-radius: 20px;
    font-weight: 700;
    justify-content: space-between;
    position: relative;
    color: black;
    margin-bottom: 0.4rem;
  }
`;
const MobileRating = styled.span`
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;
const MobileStarIcons = styled(StarIcon)`
  color: gold;
`;
const MobileLine = styled.span`
  @media (max-width: 500px) {
    font-size: 0.5rem;
    font-weight: 900;
    margin: 0 1.5px;
  }
`;
const MobileNoOfRating = styled.span`
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;
const MobileItemName = styled.span`
  font-size: 0.95rem;
  font-weight: 400;
  line-height: normal;
  color: black;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
  margin-bottom: 0.5rem;
  // font-family: "Orbitron", sans-serif;
  font-style: italic;
  @media (max-width: 500px) {
    font-size: 0.8rem;
    margin-bottom: 0rem;
  }
`;

const MobileIMG = styled.img`
  width: 80px;
  height: 20px;
`;
const MobileItemData = styled.p`
  @media (max-width: 500px) {
    color: var(--color-blue);
    text-transform: capitalize;
    width: 100%;
    min-height: 20%;
    font-size: 0.95rem;
    font-weight: 700;
    margin: 0;
  }
`;
const MobileItemPrice = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0rem;
  margin-bottom: 0.8rem;
  @media (max-width: 500px) {
    margin-bottom: 0rem;
  }
`;
const MobilePriceDiv = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;
const MobileCurrentPrice = styled.p`
  @media (max-width: 500px) {
    margin: 0;
    font-size: 0.95rem;
    font-weight: 700;
    text-transform: capitalize;
    color: black;
  }
`;
const MobileOriginalPrice = styled.p`
  @media (max-width: 500px) {
    text-decoration-line: line-through;
    margin: 0;
    color: gray;
    font-weight: 500;
    margin-left: 0;
    letter-spacing: 0.5px;
    margin: 0 0.5rem;
    font-size: 0.85rem;
  }
`;

const MobileDiscount = styled.p`
  @media (max-width: 500px) {
    background: #ba3737;
    color: white;
    border-radius: 5px;
    font-weight: 600;
    font-size: 10px;
    padding: 4px 5px;
    position: absolute;
    right: 0;
  }
`;

const MobilePurchase = styled.div`
  @media (max-width: 500px) {
    width:100%;
    position:absolute:
    bottom:10px;
    left:0;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:0 10px;
  }
`;
const MobileAddToCart = styled.div`
  @media (max-width: 500px) {
    width: 160px;
    height: 40px;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    border: 2px solid var(--color-blue);
    color: var(--color-blue);
    // background: var(--color-blue);
    // color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 15px;
  }
`;

const MobileAddToCartSpan = styled.span``;
const MobileBuyNow = styled.div`
  @media (max-width: 500px) {
    width: 160px;
    height: 40px;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    background: var(--color-blue);
    color: white;
    border-radius: 5px;
  }
`;

const fontS = {
  fontSize: "15",
};
const fontC = {
  fontSize: "20",
};
