import React, { useEffect, useState } from "react";
import "./MyOrder.css";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { IMAGE_BASE } from "../../config";
import {
  getAllOrderAsync,
  selectAllOrders,
} from "../../features/cartPage/OrderSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { WriteReviews } from "../ProductDescription/WriteReviews";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { formatWithCommasWithDecimal } from "../../utils/utils";

function MyOrder() {
  const dispatch = useDispatch();
  const orders = useSelector(selectAllOrders);
  // console.log("orders", orders);
  const userDetail = useSelector(selectUser);
  const navigate = useNavigate();
  const [showAllProducts, setShowAllProducts] = useState(null);

  // Define state variables to manage the visibility of different order statuses
  const [showAllProcessing, setShowAllProcessing] = useState(true);
  const [showAllDelivered, setShowAllDelivered] = useState(true);
  const [showAllCancelled, setShowAllCancelled] = useState(true);
  const [showAllPacked, setShowAllPacked] = useState(true);
  const [showAllPartConfirm, setShowAllPartConfirm] = useState(true);
  const [showAllConfirm, setShowAllConfirm] = useState(true);

  const [changeColorBtn, setChangeColorBtn] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  // Function to toggle visibility of processing orders
  const toggleProcessingOrders = () => {
    if (showAllProcessing === true && changeColorBtn !== "processing") {
      setChangeColorBtn("processing");
      setShowAllDelivered(false);
      setShowAllCancelled(false);
    } else if (changeColorBtn === "processing") {
      setChangeColorBtn("");
      setShowAllDelivered(true);
      setShowAllCancelled(true);
    }
  };

  // Function to toggle visibility of delivered orders
  const toggleDeliveredOrders = () => {
    if (showAllDelivered === true && changeColorBtn !== "delivered") {
      setChangeColorBtn("delivered");
      setShowAllProcessing(false);
      setShowAllCancelled(false);
    } else if (changeColorBtn === "delivered") {
      setChangeColorBtn("");
      setShowAllProcessing(true);
      setShowAllCancelled(true);
    }
  };

  // Function to toggle visibility of cancelled orders
  const toggleCancelledOrders = () => {
    if (showAllCancelled === true && changeColorBtn !== "cancelled") {
      setChangeColorBtn("cancelled");
      setShowAllDelivered(false);
      setShowAllProcessing(false);
    } else if (changeColorBtn === "cancelled") {
      setChangeColorBtn("");
      setShowAllProcessing(true);
      setShowAllDelivered(true);
    }
  };

  const handleDownloadInvoice = async (order) => {
    try {
      // Construct the URL for downloading the PDF invoice
      const invoiceUrl = `https://cyclecircle.blr1.digitaloceanspaces.com/${IMAGE_BASE}_INVOICE/${order?.order_no}.pdf`;

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = invoiceUrl;
      link.setAttribute("download", "invoice.pdf");

      // Simulate a click event to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up resources
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading invoice:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (Object.keys(userDetail).length) {
        handleOpen();
        await dispatch(getAllOrderAsync(userDetail?.UserID));
        handleClose();
      }
    };
    fetchData();
    return () => clearTimeout(2000);
  }, [dispatch, userDetail]);

  // Filter orders based on the entered order code
  useEffect(() => {
    const capitalizedSearchTerm = searchTerm.toUpperCase();

    setFilteredOrders(
      orders.orderResults?.filter((order) =>
        order.order_no.includes(capitalizedSearchTerm)
      ) || []
    );
  }, [orders.orderResults, searchTerm]);

  useEffect(() => {
    if (selectedMonth !== "") {
      const currentDate = new Date();
      const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - selectedMonth,
        1
      );

      setFilteredOrders(
        orders.orderResults?.filter((order) => {
          const orderDate = new Date(order.order_date);
          return orderDate >= startDate;
        }) || []
      );
    }
  }, [orders.orderResults, selectedMonth]);

  const handleMonthChange = (e) => {
    setSelectedMonth(parseInt(e.target.value));
  };

  const handleViewOrder = (order_ID) => {
    navigate(`/user/orders/view_order/${order_ID}`);
  };

  const handleFilterOrders = (status) => {
    if (
      status === "processing" ||
      status === "delivered" ||
      status === "cancelled"
    ) {
      if (searchTerm.trim() !== "") {
        // If search term is entered, filter based on search term and status
        setFilteredOrders(
          orders.orderResults?.filter(
            (order) =>
              order.order_no.includes(searchTerm) &&
              order.orderStatus === status
          ) || []
        );
      } else {
        // If no search term, filter based on status only
        setFilteredOrders(
          orders.orderResults?.filter(
            (order) => order.orderStatus === status
          ) || []
        );
      }
    } else {
      // If "All" button is clicked, reset filtered orders to all orders
      setSearchTerm("");
      setFilteredOrders(orders.orderResults || []);
    }
  };

  

  const toggleShowProducts = (value) => {
    if (showAllProducts === null) {
      setShowAllProducts(value);
    } else {
      setShowAllProducts(null);
    }
    if (!showAllProducts) {
      // Scroll to the "Show more" button when showing all products
      const showMoreButton = document.getElementById(
        `show-more-${value}-button`
      );
      showMoreButton.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  // Sort the filteredOrders array by date and time in descending order
  const sortedOrders = [...filteredOrders].sort((a, b) => {
    const dateA = new Date(a.order_date);
    const dateB = new Date(b.order_date);
    return dateB - dateA;
  });

  // console.log(sortedOrders);

  // Function to filter orders based on the selected filter
  const filterOrders = (order) => {
    // Filter out orders with null status
    if (!order?.orderStatus?.status) {
      return false;
    }

    if (!changeColorBtn) {
      // If no filter is selected, show all orders
      return true;
    }

    if (changeColorBtn === "processing") {
      // If "processing" button is active, include all statuses except "cancelled"
      return (
        order?.orderStatus?.status !== "cancelled" &&
        order?.orderStatus?.status !== "delivered"
      );
    }

    // Filter orders based on the selected status
    return order?.orderStatus?.status === changeColorBtn;
  };

  // REVIEW FUNCTIONALITY

  const [showReviewPopup, setShowReviewPopup] = useState(false);
  const closeReviewPopup = () => {
    setShowReviewPopup(false);
  };

  const toggleReviewPopup = () => {
    setShowReviewPopup(!showReviewPopup);
  };

  return (
    <div className="my-order-page-container">
      {showReviewPopup && <div className="overlay" />}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open} // Show backdrop when either open or filtering state is true
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <span className="my-order-total-order">
        My Orders <b>{sortedOrders && sortedOrders.length}</b>
      </span>
      <div className="my-order-page-header-container">
        <div className="my-order-page-left-header-div">
          <span
            className={`my-order-page-delivery ${
              changeColorBtn === "delivered" ? "active-order-sorting-btn" : ""
            }`}
            onClick={toggleDeliveredOrders}
          >
            Delivered
          </span>
          <span
            className={`my-order-page-processing ${
              changeColorBtn === "processing" ? "active-order-sorting-btn" : ""
            }`}
            onClick={toggleProcessingOrders}
          >
            Confirmed
          </span>
          <span
            className={`my-order-page-cancelled ${
              changeColorBtn === "cancelled" ? "active-order-sorting-btn" : ""
            }`}
            onClick={toggleCancelledOrders}
          >
            Cancelled
          </span>
        </div>
        <div className="my-order-page-right-header-container">
          <div className="my-order-page-search-div">
            <SearchOutlinedIcon />
            <input
              type="text"
              value={searchTerm}
              placeholder="Enter Order Code"
              onChange={(e) => setSearchTerm(e.target.value.toUpperCase())}
            />
          </div>
          <div className="my-order-page-monthly-order-div">
            <select name="month" id="month" onChange={handleMonthChange}>
              <option value="" disabled selected>
                Select Month
              </option>
              <option value="1">Last 1 Month</option>
              <option value="2">Last 2 Months</option>
              <option value="3">Last 3 Months</option>
              <option value="4">Last 4 Months</option>
              <option value="5">Last 5 Months</option>
              <option value="6">Last 6 Months</option>
              <option value="12">Last 1 Year</option>
              <option value="24">Last 2 Years</option>
            </select>
          </div>
        </div>
      </div>
      {sortedOrders &&
        sortedOrders.map(
          (order, index) =>
            filterOrders(order) && (
              <div className="my-order-page-main-order-by-date-container">
                <div className="my-order-page-date-order-header-div">
                  <div className="my-order-page-date-order-header-left-div">
                    <div className="my-order-page-date-header-left-orderID-div">
                      <span className="left-orderID-heading">Order #</span>
                      <span className="left-orderID-value">
                        {order?.order_no}
                      </span>
                    </div>
                    <div className="my-order-page-date-header-left-date-div">
                      <span className="left-date-heading">Order Date</span>
                      <span className="left-date-value">
                        {new Date(order?.order_date).toLocaleDateString(
                          "en-US",
                          {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          }
                        )}
                      </span>
                    </div>
                    <div className="my-order-page-date-header-left-status-div">
                      <span className="left-status-heading">Status</span>
                      <div className="left-status-value-processing">
                        <p></p>
                        <span>
                          {order?.orderStatus?.status === "pending"
                            ? "Confirmed"
                            : order?.orderStatus?.status}
                        </span>
                      </div>
                    </div>
                    <div className="my-order-page-date-header-left-date-div">
                      <span className="left-date-heading">ETA</span>
                      <span className="left-date-value">
                        {new Date(
                          new Date(order?.order_date).getTime() +
                            5 * 24 * 60 * 60 * 1000
                        ).toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })}
                      </span>
                    </div>
                  </div>
                  <div className="my-order-page-date-order-header-right-div">
                    <span className="my-order-page-date-order-header-order-again">
                      Order Again
                    </span>
                    <span
                      className="my-order-page-date-order-header-view-order"
                      onClick={() => handleViewOrder(order?.order_no)}
                    >
                      View Order
                    </span>
                  </div>
                </div>
                {order?.orderItems &&
                  order?.orderItems?.map((product, index) => (
                    <div
                      key={product?.item_no_SKU}
                      className={`my-order-page-date-order-each-order-div ${
                        index > 2 && !showAllProducts ? "hidden" : ""
                      }`}
                    >
                      <div className="my-order-page-date-order-each-order-left-div">
                        <LazyLoadImage
                          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${product.prodimage}`}
                          alt=""
                          className="my-order-page-date-order-each-order-left-img"
                          effect="blur"
                          wrapperProps={{
                            // If you need to, you can tweak the effect transition using the wrapper style.
                            style: { transitionDelay: "1s" },
                          }}
                        />
                        <div className="my-order-page-date-order-each-order-left-detail-div">
                          <LazyLoadImage
                            src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                              product && product?.manufacture_images
                            }`}
                            alt=""
                            className="my-order-page-date-order-each-order-left-detail-brands-logo"
                            effect="blur"
                            wrapperProps={{
                              // If you need to, you can tweak the effect transition using the wrapper style.
                              style: { transitionDelay: "1s" },
                            }}
                          />
                          <span className="my-order-page-date-order-each-order-left-product-name">
                            {product?.prod_name}
                          </span>
                          <span className="my-order-page-date-order-each-order-left-product-tech">
                            {product?.prodTech &&
                              product?.prodTech
                                .slice(0, 1)
                                .map((tech, index) => <span>{tech} |</span>)}
                          </span>
                        </div>
                      </div>
                      <div className="my-order-page-date-order-each-order-left-product-variant-qty-div">
                        <span className="my-order-page-date-order-each-order-left-product-qty">
                          Qty: <b>{product?.quantity}</b>
                        </span>
                        <div className="my-order-page-date-order-each-order-left-product-variant-detail">
                          {product?.size_name && (
                            <span className="my-order-page-date-order-each-order-left-product-variant-size-div">
                              Size:{" "}
                              <b>{product?.size_name && product?.size_name}</b>
                            </span>
                          )}
                          {product?.colour_name && (
                            <span className="my-order-page-date-order-each-order-left-product-variant-color-div">
                              Color:{" "}
                              <b>
                                {product?.colour_name && product?.colour_name}
                              </b>
                            </span>
                          )}
                          {product?.variant_name && (
                            <span className="my-order-page-date-order-each-order-left-product-variant-variant-div">
                              Variant:{" "}
                              <b>
                                {product?.variant_name && product?.variant_name}
                              </b>
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="my-order-page-date-order-each-order-right-div">
                        <span className="my-order-page-date-order-each-order-right-write-review">
                          Write a review
                        </span>
                        {showReviewPopup && (
                          <WriteReviews onClose={closeReviewPopup} />
                        )}
                        <span className="my-order-page-date-order-each-order-right-buy-again-it">
                          Return this Item
                        </span>
                      </div>
                    </div>
                  ))}
                {!showAllProducts && order.orderItems.length > 3 && (
                  <span
                    id={`show-more-${order.orderStatus}-button`}
                    className="show-more-button"
                    onClick={() => toggleShowProducts(order.orderStatus)}
                  >
                    {order.orderItems.length - 3} more items{" "}
                    <KeyboardArrowDownIcon />
                  </span>
                )}
                {/* Show less button */}
                {showAllProducts === order.orderStatus &&
                  order.orderItems.length > 3 && (
                    <span
                      id={`show-less-${order.orderStatus}-button`}
                      className="show-less-button"
                      onClick={() => toggleShowProducts(order.orderStatus)}
                    >
                      Show less <KeyboardArrowUpIcon />
                    </span>
                  )}
                <div className="my-order-page-main-order-by-date-total-price-invoice-div">
                  <div className="my-order-page-main-order-by-date-total-price-div">
                    <span className="my-order-total-price-key">
                      Order Total:
                    </span>
                    <span className="my-order-total-price-value">
                      ₹
                      {formatWithCommasWithDecimal(
                        order?.TotalAmount && parseInt(order?.TotalAmount)
                      )}
                    </span>
                  </div>
                  {order &&
                    order?.orderStatus &&
                    order?.orderStatus?.tracking_no && (
                      <div
                        className="my-order-page-main-order-invoice-div"
                        onClick={() => handleDownloadInvoice(order)}
                      >
                        <ReceiptIcon />
                        <span>Invoice</span>
                      </div>
                    )}
                </div>
              </div>
            )
        )}
    </div>
  );
}

export default MyOrder;
