import React, { useState } from "react";
import "./CartsAllProduct.css";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  fetchAllCartsAsync,
  selectAllCarts,
  removeAllFromCartsAsync,
  changeQtyOfProductCartsAsync,
  removeFromCartsAsync,
  addToCartsAsync,
} from "../../features/cartPage/cartPageSlice";
import { IMAGE_BASE } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { useEffect } from "react";
import NoCartItem from "./NoCartItem";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import WantDeleteProduct from "./WantDeleteProduct";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  calculateDealerPrice,
  calculateDiscountPercentage,
  calculateOfferPrice,
  formatWithCommasWithDecimal,
} from "../../utils/utils";
import { useNavigate } from "react-router";

function CartsAllProduct() {
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const navigate = useNavigate();
  const cart = useSelector(selectAllCarts);
  // console.log(cart);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail && userDetail?.UserID)
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
  }, [dispatch, userDetail]);

  const handleRemoveAllFromCarts = () => {
    dispatch(removeAllFromCartsAsync(userDetail.UserID)).then(() => {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
    });
  };

  useEffect(() => {
    // Function to handle adjusting quantity
    const fetchData = async () => {
      handleOpen();
      const adjustQuantityToAvailable = () => {
        cart?.forEach((item) => {
          const availableQuantity = item?.skuDetails?.qty || 0;

          // Remove product if available quantity is 0
          if (availableQuantity < parseInt(item?.skuDetails?.Bundle)) {
            handleRemoveFromCarts(item?.skuDetails?.SKU_ID);
            return; // Skip processing further for this item
          }

          const updatedQty = Math.min(item?.buy_quantity, availableQuantity);
          const newQtys =
            (parseInt(availableQuantity) / parseInt(item?.skuDetails?.Bundle)) *
            parseInt(item?.skuDetails?.Bundle);

          // Update quantity only if buy_quantity exceeds available quantity
          if (item.buy_quantity !== updatedQty) {
            // const decrementCount = Math.max(0, item?.buy_quantity - updatedQty);
            handleChangeQtyOfProductCarts(item, newQtys, "decrement");
          }
        });
      };
      // Call the function to adjust quantities when component mounts
      adjustQuantityToAvailable();
      handleClose();
    };
    fetchData();
    return () => clearTimeout(2000);
  }, []); // Empty dependency array ensures this runs only once when component mounts

  const handleRemoveFromCarts = (SKU_id) => {
    let cartData = {
      userID: userDetail?.UserID,
      SKU: SKU_id,
    };
    dispatch(removeFromCartsAsync(cartData)).then(() => {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
    });
  };

  const handleChangeQtyOfProductCarts = (data, newQty, Action) => {
    let cartData = {
      userID: userDetail?.UserID,
      SKU: data?.skuDetails?.SKU_ID,
      buyQty: newQty,
    };
    if (newQty > 0 && newQty <= data?.skuDetails?.qty) {
      dispatch(addToCartsAsync(cartData)).then(() => {
        dispatch(fetchAllCartsAsync(userDetail?.UserID));
      });
    }
  };


  const [productToRemove, setProductToRemove] = useState(null);

  // Function to handle setting the product to be removed
  const handleSetProductToRemove = (skuId) => {
    setProductToRemove(skuId);
  };

  return (
    <>
      <div className="carts-all-product-container">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="continue-remove-items-div">
          {cart && cart?.length > 0 && (
            <button
              className="remove-all-items-btn"
              onClick={handleRemoveAllFromCarts}
            >
              Remove all items
            </button>
          )}
        </div>
        <div className="main-carts-products-div-container">
          {cart && cart.length > 0 ? (
            <>
              <div className="main-carts-products-details-align-key">
                <span className="product-name-img">Product</span>
                <span className="product-carts-quantity">Qty</span>
                <span className="product-carts-price-per-unit">
                  Price per unit
                </span>
                <span className="product-carts-price">
                  {" "}
                  Total Price <b>(Incl. GST)</b>
                </span>
              </div>
              <div className="main-carts-products-contain-div">
                {cart?.map((data, index) => (
                  <div
                    className="main-carts-product-div-each-container"
                    key={index}
                  >
                    {productToRemove === data?.skuDetails?.SKU_ID ? (
                      <WantDeleteProduct
                        productData={data}
                        setProductToRemove={setProductToRemove}
                      />
                    ) : (
                      <>
                        <div className="main-carts-product-div-name-img" >
                          <div
                            className="main-carts-product-img"
                            onClick={() =>
                              navigate(
                                `/product/${
                                  data?.skuDetails?.prod_ID &&
                                  data?.skuDetails?.prod_ID
                                }`
                              )
                            }
                          >
                            <LazyLoadImage
                              src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                                data?.images && data.images
                              }`}
                              alt=""
                              effect="blur"
                              wrapperProps={{
                                // If you need to, you can tweak the effect transition using the wrapper style.
                                style: { transitionDelay: "1s" },
                              }}
                            />
                          </div>
                          <div className="main-carts-product-name-desc">
                            <div
                              className="main-carts-product-name-desc-only"
                              style={{ position: "relative" }}
                            >
                              {data && data?.skuDetails?.manufacture_images ? (
                                <LazyLoadImage
                                  className="main-carts-product-brand-img"
                                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                                    data && data?.skuDetails?.manufacture_images
                                  }`}
                                  alt=""
                                  effect="blur"
                                  wrapperProps={{
                                    // If you need to, you can tweak the effect transition using the wrapper style.
                                    style: { transitionDelay: "1s" },
                                  }}
                                />
                              ) : (
                                <h3 className="main-parts-product-name">
                                  {data?.skuDetails?.manufacture_name &&
                                    data?.skuDetails?.manufacture_name}
                                </h3>
                              )}
                              {data?.skuDetails?.SKU_ID?.startsWith("D") && (
                                <span
                                  className="main-carts-product-not-available"
                                  style={{
                                    color: "var(--color-peach)",
                                    fontSize: "12px",
                                    fontWeight: "700",
                                    position: "absolute",
                                    top: "5px",
                                    right: "0",
                                  }}
                                >
                                  Not Available
                                </span>
                              )}

                              <span className="main-parts-product-desc">
                                {(data?.skuDetails?.prod_name).slice(0, 65)}
                              </span>
                              <div className="main-cart-product-properties-main-container">
                                {data?.skuDetails?.size_name && (
                                  <div className="main-cart-product-size-container">
                                    <span className="main-cart-product-size">
                                      {data?.skuDetails?.size_name &&
                                        (data?.skuDetails?.size_name).slice(
                                          0,
                                          10
                                        )}
                                    </span>
                                    <CloseIcon />
                                  </div>
                                )}
                                {data?.skuDetails?.colour_name && (
                                  <div className="main-cart-product-size-container">
                                    <span className="main-cart-product-size">
                                      {data?.skuDetails?.colour_name &&
                                        (data?.skuDetails?.colour_name).slice(
                                          0,
                                          15
                                        )}
                                    </span>
                                    <CloseIcon />
                                  </div>
                                )}
                                {data?.skuDetails?.varient_name && (
                                  <div className="main-cart-product-size-container">
                                    <span className="main-cart-product-size">
                                      {data?.skuDetails?.varient_name &&
                                        (data?.skuDetails?.varient_name).slice(
                                          0,
                                          15
                                        )}
                                    </span>
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="main-carts-product-quantity-div">
                          <RemoveSharpIcon
                            onClick={() => {
                              const bundleSize = parseInt(
                                data?.skuDetails?.Bundle
                              );
                              const currentQuantity = parseInt(
                                data?.buy_quantity
                              );

                              if (currentQuantity === bundleSize) {
                                handleSetProductToRemove(data.SKU_ID);
                              } else {
                                const newQuantity = Math.max(
                                  currentQuantity - bundleSize,
                                  0
                                ); // Ensure the quantity doesn't go below 0
                                handleChangeQtyOfProductCarts(
                                  data,
                                  newQuantity,
                                  "decrement"
                                );
                              }
                            }}

                            // className={data?.buy_quantity === 1 ? "disabled" : ""}
                          />
                          <span>{data?.buy_quantity}</span>
                          <AddSharpIcon
                            onClick={() => {
                              const bundleSize = parseInt(
                                data?.skuDetails?.Bundle
                              );
                              const currentQuantity = parseInt(
                                data?.buy_quantity
                              );

                              const newQuantity = currentQuantity + bundleSize;

                              handleChangeQtyOfProductCarts(
                                data,
                                newQuantity,
                                "increment"
                              );
                            }}
                            className={
                              data?.buy_quantity === data?.skuDetails?.qty
                                ? "disabled"
                                : ""
                            }
                          />
                        </div>
                        <div className="main-carts-product-pricing-container">
                          <div className="main-carts-product-ndp-offer-price">
                            {data?.skuDetails?.NDP_Price && (
                              <span className="main-carts-product-npd-price-container">
                                Dealer Price -{" "}
                                <b
                                  style={{
                                    textDecoration:
                                      data?.skuDetails?.Offer_price !== null &&
                                      "line-through",
                                    fontSize:
                                      data?.skuDetails?.Offer_price !== null &&
                                      "10px",
                                  }}
                                >
                                  {" "}
                                  ₹
                                  {formatWithCommasWithDecimal(
                                    calculateDealerPrice(
                                      parseInt(data.skuDetails?.MRP_price),
                                      parseInt(data.skuDetails?.NDP_Price),
                                      data?.skuDetails?.GST
                                    )
                                  )}
                                </b>
                              </span>
                            )}
                            {data?.skuDetails?.Offer_price !== null && (
                              <span className="main-carts-product-offer-price-span">
                                ₹
                                {formatWithCommasWithDecimal(
                                  calculateOfferPrice(
                                    parseInt(data.skuDetails?.MRP_price),
                                    parseInt(data.skuDetails?.Offer_price),
                                    data?.skuDetails?.GST
                                  )
                                )}
                              </span>
                            )}{" "}
                          </div>
                          <span className="main-carts-product-mrp-price-span">
                            Product MRP - ₹
                            {formatWithCommasWithDecimal(
                              parseInt(data.skuDetails?.MRP_price)
                            )}
                          </span>
                        </div>
                        <div className="main-carts-product-price-div">
                          <div className="main-carts-product-price-main-container">
                            ₹
                            {formatWithCommasWithDecimal(
                              (data?.skuDetails?.Offer_price
                                ? calculateOfferPrice(
                                    parseInt(data.skuDetails?.MRP_price),
                                    parseInt(data.skuDetails?.Offer_price),
                                    data?.skuDetails?.GST
                                  )
                                : calculateDealerPrice(
                                    parseInt(data.skuDetails?.MRP_price),
                                    parseInt(data.skuDetails?.NDP_Price),
                                    data?.skuDetails?.GST
                                  )) * data?.buy_quantity
                            )}
                          </div>
                          {calculateDiscountPercentage(
                            data?.skuDetails?.MRP_price,
                            data?.skuDetails?.NDP_Price
                          ) > 0 && (
                            <div className="main-carts-product-dealer-margin-span">
                              <LocalOfferOutlinedIcon />{" "}
                              <span
                                style={{
                                  textDecoration:
                                    data?.skuDetails?.Offer_price !== null &&
                                    "line-through",
                                  fontSize:
                                    data?.skuDetails?.Offer_price !== null &&
                                    "10px",
                                  margin: "0 0.2vw",
                                }}
                              >
                                {" "}
                                {calculateDiscountPercentage(
                                  data?.skuDetails?.MRP_price,
                                  calculateDealerPrice(
                                    parseInt(data.skuDetails?.MRP_price),
                                    parseInt(data.skuDetails?.NDP_Price),
                                    data?.skuDetails?.GST
                                  )
                                )}
                                %
                              </span>
                              {data?.skuDetails?.Offer_price !== null && (
                                <span className="main-carts-product-dealer-margin-offer-ribbon">
                                  {calculateDiscountPercentage(
                                    data?.skuDetails?.MRP_price,
                                    calculateOfferPrice(
                                      parseInt(data.skuDetails?.MRP_price),
                                      parseInt(data.skuDetails?.Offer_price),
                                      data?.skuDetails?.GST
                                    )
                                  )}
                                  %
                                </span>
                              )}{" "}
                              Dealer Margin
                            </div>
                          )}
                        </div>
                        <div className="remove-product-cross">
                          <CloseOutlinedIcon
                            onClick={() =>
                              handleSetProductToRemove(data?.skuDetails?.SKU_ID)
                            }
                            // onClick={() => handleRemoveFromCarts(data)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <NoCartItem text={"Carts"} />
          )}
        </div>
      </div>
    </>
  );
}

export default CartsAllProduct;
