import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllTopSearch,
  fetchAllRecommendSearch
} from "./searchAPI";

const initialState = {
  topSearch: [],
  recentSearch: [],
  recommendSearch:[],
  status: "idle",
};

export const fetchAllTopSearchAsync = createAsyncThunk(
  "product/fetchAllTopSearch",
  async () => {
    const response = await fetchAllTopSearch();
    return response.data;
  }
);
export const fetchAllRecommendSearchAsync = createAsyncThunk(
  "product/fetchAllRecommendSearch",
  async (term) => {
    const response = await fetchAllRecommendSearch(term);
    return response.data;
  }
);

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTopSearchAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllTopSearchAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.topSearch = action.payload;
      })
      .addCase(fetchAllRecommendSearchAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllRecommendSearchAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.recommendSearch = action.payload;
      });
  },
});

export const selectAllTopSearch = (state) => state.search.topSearch;
export const selectAllRecommendSearch = (state) => state.search.recommendSearch;

export default searchSlice.reducer;
