import React, { useRef, useState } from "react";
import "./UploadDocument.css";
import upload_logo from "../../../../img/upload_layer.svg";
import pdfLogo from "../../../../icons/pdf.png";
import CloseIcon from "@mui/icons-material/Close";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from "react-lazy-load-image-component";

function UploadDocument({ updateFinalData, finalData }) {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateFinalData({ [name]: value });
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check if the selected file is a PDF
      if (file.type === "application/pdf") {
        setFileName(file.name);
        updateFinalData({ Document_IMAGE: file });
      } else {
        // If the selected file is not a PDF, alert the user
        // console.log("Please select a PDF file.");
        // Clear the file input value
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    }
  };
  

  const handleRemoveFile = () => {
    setFileName(null);
    updateFinalData({ Document_IMAGE: null });
    // Clear the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div className="uploads-document-container">
      <span className="uploads-documents-heading">
        Upload Business Document
      </span>
      <span className="uploads-documents-desc">
        We require any of these documents for verification purposes.
      </span>

      <div className="upload-documents-main-upload-container">
        <fieldset className="input-fieldset">
          <select
            className=" select-state-select upload-state"
            id="business_document"
            name="business_document"
            onChange={handleInputChange}
            value={finalData?.business_document || ""}
            required
          >
            <option className="select-upload-option" value="">
              Business Document
            </option>
            <option className="select-upload-option" value="ROC Certificate">
              Registration Certificate from ROC(Registrar of Company for
              Jurisdiction)
            </option>
            <option className="select-upload-option" value="Tax Return">
              Sales Tax & Income Tax Return IMSME
            </option>
            <option className="select-upload-option" value="Shop License">
              Certificate/License issued under Shops & Establishment act and /
              or any other acts as applicable to the firm.
            </option>
          </select>
        </fieldset>
        <div className="upload-documents-upload-and-detail">
          <div className="upload-documents-img-text-div">
            <input
              type="file"
              className="upload-input"
              name="Document_IMAGE"
              id="Document_IMAGE"
              ref={fileInputRef}
              onChange={handleFileSelect}
              accept=".pdf"
            />
            <LazyLoadImage src={upload_logo} alt="" className="upload-documents-img" effect="blur"
                wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
                }}/>
            <span className="upload-documents-text">Upload Documents</span>
          </div>
          {fileName && (
            <div className="upload-document-name">
              <LazyLoadImage className="upload-document-logo" src={pdfLogo} alt="" effect="blur"
                wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
                }}/>
              <span className="upload-document-span">{fileName.slice(0, 16)}.pdf</span>
              <CloseIcon onClick={handleRemoveFile}/>
            </div>
          )}
          <div className="upload-documents-size-details">
            <ul className="upload-documents-ul">
              <li>Max File size: 10MB</li>
              <li>File Type .PDF</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadDocument;
