import React, { useState, useEffect, useRef } from "react";
import "./MyProfile.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import addressHome from "../../img/address-home.png";
import addressTick from "../../img/address-ticks.png";
import addressDefault from "../../img/address-default.png";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import {
  fetchAllAddressAsync,
  selectAllAddress,
  removeAddressAsync,
  setSelectedAddress,
  selectSelectedAddress,
} from "../../features/cartPage/addressSlice";
import { selectUser } from "../../features/auth/authSlice";

import { IMAGE_BASE } from "../../config";
import EditBasicProfileDetail from "./EditBasicProfileDetail/EditBasicProfileDetail";
import AddNewAddress from "./SaveAddress/AddNewAddress";
import aadharLogo from "../../img/banners/aadhar-logo.png";
import panLogo from "../../img/banners/pan-card-logo.png";
import gstLogo from "../../img/banners/gst-logo.png";
import EditAddressDetail from "./SaveAddress/EditAddressDetail";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function MyProfile() {
  const dispatch = useDispatch();
  const addresses = useSelector(selectAllAddress);
  const selectedAddress = useSelector(selectSelectedAddress);
  const userDetail = useSelector(selectUser);
  const [address, setAddress] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [openedAddressIndex, setOpenedAddressIndex] = useState(null);
  const [isDefault, setIsDefault] = useState(false);
  const [openClick, setOpenClick] = useState(false);
  const [openOverlay, setOpenOverlay] = useState(false);
  // const [selectedAddress, setSelectedAddress] = useState(null);
  const openClickRef = useRef(null);
  const [sendAddress, setSendAddress] = useState(null);

  const [editAddressIndex, setEditAddressIndex] = useState(null);

  const [editBasicProfile, setEditBasicProfile] = useState(false);
  const [addNewProfileAddress, setAddNewProfileAddress] = useState(false);
  const [editAddressDetail, setEditAddressDetail] = useState(false);
  const [finalData, setFinalData] = useState({
    FirstName: (userDetail && userDetail?.FirstName) || null,
    LastName: (userDetail && userDetail?.LastName) || null,
    Email: (userDetail && userDetail?.Email) || null,
    PhoneNumber: (userDetail && userDetail?.PhoneNumber) || null,
    DOB: (userDetail && userDetail?.DOB) || null,
    Gender: (userDetail && userDetail?.Gender) || null,
    Password: (userDetail && userDetail?.Password) || null,
    USER_IMAGE: (userDetail && userDetail?.UserImage) || null,
    dealership_name: (userDetail && userDetail?.dealership_name) || null,
    address_line_1: (userDetail && userDetail?.address_line_1) || null,
    address_line_2: (userDetail && userDetail?.address_line_2) || null,
    pin_code: (userDetail && userDetail?.pin_code) || null,
    location: (userDetail && userDetail?.location) || null,
    district: (userDetail && userDetail?.district) || null,
    state_id: (userDetail && userDetail?.state_id) || null,
    GST: (userDetail && userDetail?.GST) || null,
    aadhar: (userDetail && userDetail?.aadhar) || null,
    pan_card: (userDetail && userDetail?.pan_card) || null,
    PAN_IMAGE: (userDetail && userDetail?.pan_card_img) || null,
    AADHAR_FRONT: (userDetail && userDetail?.aadhar_front) || null,
    AADHAR_BACK: (userDetail && userDetail?.aadhar_back) || null,
  });

  const handleEditBasicProfile = () => {
    setOpenOverlay(!openOverlay);
    setEditBasicProfile(!editBasicProfile);
  };

  const handleAddNewAddress = () => {
    setOpenOverlay(!openOverlay);
    setAddNewProfileAddress(!addNewProfileAddress);
  };

  const handleEditAddressDetail = () => {
    setOpenOverlay(!openOverlay);
    setEditAddressDetail(!editAddressDetail);
  };

  const handleEditAddress = (index, address) => {
    setEditAddressIndex(index);
    setSendAddress(address);
  };

  useEffect(() => {
    if (userDetail?.UserID) {
      dispatch(fetchAllAddressAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail]);

  useEffect(() => {
    if (addresses && addresses.length > 0) {
      const defaultAddress = addresses.find((addr) => addr.is_default === 1);
      dispatch(
        setSelectedAddress(defaultAddress ? defaultAddress.shippingID : null)
      );
    }
  }, [addresses]);

  // Function to find state name by state_id
  const getStateNameById = (stateId) => {
    switch (stateId) {
      case "1":
        return "Andhra Pradesh";
      case "2":
        return "Arunachal Pradesh";
      case "3":
        return "Assam";
      case "4":
        return "Bihar";
      case "5":
        return "Chhattisgarh";
      case "6":
        return "Goa";
      case "7":
        return "Gujarat";
      case "8":
        return "Haryana";
      case "9":
        return "Himachal Pradesh";
      case "10":
        return "Jharkhand";
      case "11":
        return "Karnataka";
      case "12":
        return "Kerala";
      case "13":
        return "Madhya Pradesh";
      case "14":
        return "Maharashtra";
      case "15":
        return "Manipur";
      case "16":
        return "Meghalaya";
      case "17":
        return "Mizoram";
      case "18":
        return "Nagaland";
      case "19":
        return "Odisha";
      case "20":
        return "Punjab";
      case "21":
        return "Rajasthan";
      case "22":
        return "Sikkim";
      case "23":
        return "Tamil Nadu";
      case "24":
        return "Telangana";
      case "25":
        return "Tripura";
      case "26":
        return "Uttar Pradesh";
      case "27":
        return "Uttarakhand";
      case "28":
        return "West Bengal";
      default:
        return ""; // Return empty string for unknown state_id
    }
  };

  const handleAddAddress = () => {
    setAddAddress(true);
  };
  const handleRemoveAddress = (shippingID) => {
    dispatch(removeAddressAsync(shippingID)).then(() => {
      dispatch(fetchAllAddressAsync(userDetail?.UserID));
    });
  };

  const handleAddress = () => {
    setAddress(true);
    setAddAddress(true);
  };
  const handleOutsideClick = (event) => {
    if (openClickRef.current && !openClickRef.current.contains(event.target)) {
      setOpenClick(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOpenThreeDots = (index) => {
    if (openedAddressIndex === index) {
      setOpenedAddressIndex(null); // Close the opened container if clicked again
    } else {
      setOpenedAddressIndex(index); // Open the clicked container
    }
  };

  const handleSelectAddress = (shippingID) => {
    dispatch(setSelectedAddress(shippingID));
  };

  const formatAadharNumber = (aadharNumber) => {
    // Check if aadharNumber is provided and has 12 characters
    if (aadharNumber && aadharNumber.length === 12) {
      // Replace first 8 characters with 'X' and split the Aadhar number into groups of 4 characters
      const formattedAadharNumber = "XXXX-XXXX-" + aadharNumber.slice(-4);
      return formattedAadharNumber;
    } else {
      return aadharNumber; // Return original number if not valid
    }
  };
  const formatPanCardNumber = (panCardNumber) => {
    if (!panCardNumber) return panCardNumber;

    // Mask all characters except the last four with 'X'
    const maskedPanCardNumber = panCardNumber.slice(0, -4).replace(/./g, "X");

    // Return the masked PAN card number along with the last four digits, separated by space
    return (
      maskedPanCardNumber.slice(0, 5) +
      " " +
      maskedPanCardNumber.slice(5) +
      panCardNumber.slice(-4, -1) +
      " " +
      panCardNumber.slice(-1)
    );
  };
  const formatGSTNumber = (gstNumber) => {
    if (!gstNumber) return gstNumber;

    // Mask all characters except the last four with 'X'
    const maskedGSTNumber = gstNumber.slice(0, -4).replace(/./g, "X");

    // Return the masked GST number along with the last four digits, separated by space
    return (
      maskedGSTNumber.slice(0, 2) +
      " " +
      maskedGSTNumber.slice(2, 7) +
      " " +
      maskedGSTNumber.slice(7, 11) +
      " " +
      gstNumber.slice(-4, -2) +
      " " +
      gstNumber.slice(-2)
    );
  };

  return (
    <div className="my-profile-container-div">
      <div className="my-profile-basic-profile-container">
        <div
          className="my-profile-basic-profile-details-edit-btn"
          onClick={handleEditBasicProfile}
        >
          <BorderColorIcon />
          <span>Edit</span>
        </div>
        <LazyLoadImage
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail && userDetail?.UserImage
          }`}
          alt=""
          className="my-profile-img-parts"
          effect="blur"
          wrapperProps={{
            // If you need to, you can tweak the effect transition using the wrapper style.
            style: { transitionDelay: "1s" },
          }}
        />
        <div className="my-profile-basic-profile-basic-details-container">
          <div className="my-profile-basic-profile-details-user-name">
            {userDetail &&
              userDetail?.FirstName &&
              userDetail?.FirstName.charAt(0).toUpperCase() +
                userDetail?.FirstName.slice(1)}{" "}
            {userDetail &&
              userDetail?.LastName &&
              userDetail?.LastName.charAt(0).toUpperCase() +
                userDetail?.LastName.slice(1)}
          </div>

          <span className="my-profile-basic-profile-details-dob">
            <CalendarMonthIcon />
            <span>{userDetail && userDetail?.DOB}</span>
          </span>
          <div className="my-profile-basic-profile-details-gender">
            <AcUnitIcon />
            <span>{userDetail && userDetail?.Gender}</span>
          </div>
          <div className="my-profile-basic-profile-details-phone-number">
            <LocalPhoneIcon />
            <span>{userDetail && userDetail?.PhoneNumber}</span>
            <CheckCircleIcon className="my-profile-phone-number-verified" />
          </div>
          <div className="my-profile-basic-profile-details-email">
            <EmailIcon />
            <span>{userDetail && userDetail?.Email}</span>
          </div>
        </div>
      </div>
      <div className="my-profile-my-address-container">
        <h3>Address</h3>
        <div className="my-profile-saved-addresses-container">
          {addresses &&
            addresses.length > 0 &&
            addresses?.map((address) => (
              <div
                className={`my-profile-saved-address-div-container ${
                  address.shippingID === 1 && "address-default-active"
                } ${
                  selectedAddress === address.shippingID &&
                  "address-selected-active"
                }`}
                key={address.shippingID}
              >
                <div className="my-profile-make-default-radio">
                  <input
                    type="radio"
                    value={address.shippingID}
                    onChange={() => handleSelectAddress(address.shippingID)}
                    checked={selectedAddress === address.shippingID}
                  />
                </div>
                <div className="my-profile-address-three-dots">
                  <MoreHorizOutlinedIcon
                    onClick={() => handleOpenThreeDots(address.shippingID)}
                  />
                  {openedAddressIndex === address.shippingID && (
                    <div className="my-profile-three-dots-click-open-div">
                      <div className="my-profile-three-dots-edit-div">
                        <BorderColorOutlinedIcon
                          onClick={() => {
                            handleOpenThreeDots(address.shippingID);
                            handleEditAddress(address.shippingID, address);
                            handleEditAddressDetail();
                          }}
                        />
                        <span
                          onClick={() => {
                            handleOpenThreeDots(address.shippingID);
                            handleEditAddress(address.shippingID, address);
                            handleEditAddressDetail();
                          }}
                        >
                          Edit
                        </span>
                      </div>
                      <div className="my-profile-three-dots-delete-div">
                        <DeleteIcon
                          onClick={() => {
                            handleRemoveAddress(address.shippingID);
                            handleOpenThreeDots(address.shippingID);
                          }}
                        />
                        <span
                          onClick={() => {
                            handleRemoveAddress(address.shippingID);
                            handleOpenThreeDots(address.shippingID);
                          }}
                        >
                          Delete
                        </span>
                      </div>
                      <div className="my-profile-three-dots-default-div">
                        <span>Default</span>
                        <FormControlLabel
                          value="default"
                          control={
                            <Switch
                              color="primary"
                              checked={address.is_default === 1}
                              onChange={() => setIsDefault(!isDefault)}
                            />
                          }
                          labelPlacement="start"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="my-profile-address-level-1">
                  <div className="my-profile-address-level-1-img-div">
                    <img
                      className={`my-profile-address-home ${
                        address.shippingID === 1 && "address-default-active"
                      } ${
                        selectedAddress === address.shippingID &&
                        "address-selected-active"
                      }`}
                      src={addressHome}
                      alt=""
                    />
                    {address.is_default === 1 && (
                      <img
                        className="my-profile-address-tick"
                        src={addressTick}
                        alt=""
                      />
                    )}
                  </div>
                  <span className="my-profile-address-type">
                    My {address.save_address}
                  </span>
                  {address.is_default === 1 && (
                    <div className="my-profile-address-default">
                      <img src={addressDefault} alt="" />
                      <span>Default</span>
                    </div>
                  )}
                </div>
                <div className="my-profile-address-level-2">
                  <p className="my-profile-address-local-address">
                    {address.FirstName} {address.LastName}, {address.address_1}{" "}
                    {address.address_2},
                  </p>
                  <p className="my-profile-address-city-location">
                    {address.city} {address.district} {address.pin_code},
                  </p>
                  <p className="my-profile-address-state">
                    {getStateNameById(address.state_id)}
                  </p>
                  <p className="my-profile-address-mob-no">
                    +91-{address.PhoneNumber}
                  </p>
                </div>
              </div>
            ))}
          <div
            className="my-profile-new-address-add-container"
            onClick={handleAddNewAddress}
          >
            <AddIcon />
            <span className="my-profile-new-address-add-span">
              Add new address
            </span>
          </div>
        </div>
      </div>
      <div className="my-profile-my-documents-container">
        <h3>Documents</h3>
        <div className="my-profile-my-documents-main-container">
          <div className="my-profile-my-document-aadhar-card">
            <img
              src={aadharLogo}
              alt=""
              className="my-profile-my-document-img"
            />
            <span className="my-profile-my-document-aadhar-card-name">
              Aadhar Card
            </span>
            <span className="my-profile-my-document-aadhar-card-number">
              {userDetail && formatAadharNumber(userDetail?.aadhar)}
            </span>
          </div>
          <div className="my-profile-my-document-pan-card">
            <img src={panLogo} alt="" className="my-profile-my-document-img" />
            <span className="my-profile-my-document-pan-card-name">
              Pan Card
            </span>
            <span className="my-profile-my-document-pan-card-number">
              {userDetail && formatPanCardNumber(userDetail?.pan_card)}
            </span>
          </div>
          <div className="my-profile-my-document-gst">
            <img src={gstLogo} alt="" className="my-profile-my-document-img" />
            <span className="my-profile-my-document-gst-name">GST</span>
            <span className="my-profile-my-document-gst-number">
              {userDetail && formatGSTNumber(userDetail?.GST)}
            </span>
          </div>
        </div>
      </div>
      {/* <div className="my-profile-my-review-container">
        <h3>My Reviews</h3>
        <div className="my-profile-my-review-main-container">
          <div className="my-profile-my-each-review-div">
            <div className="my-profile-my-each-review-upper-level-detail">
              <img
                src="https://www.jiomart.com/images/product/original/rv5pxpyj7m/manogyam-security-guard-against-theft-smart-e-bike-steel-wire-cable-lock-cycle-accessorie-cycle-lock-product-images-orv5pxpyj7m-p598992312-0-202303021944.jpg?im=Resize=(420,420)"
                alt=""
                className="my-profile-my-each-review-upper-level-detail-img"
              />
              <div className="my-profile-my-each-review-upper-level-main-detail">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Salsa_Logo.svg/1200px-Salsa_Logo.svg.png"
                  alt=""
                  className="my-profile-my-each-review-upper-level-main-detail-brand-logo"
                />
                <span className="my-profile-my-each-review-upper-level-detail-product-name">
                  Cycling Lock-On Anti-Slip Bicycle Handle Grip
                </span>
                <span className="my-profile-my-each-review-upper-level-detail-product-price">
                  $999.00
                </span>
              </div>
            </div>
            <div className="my-profile-my-each-review-bottom-level-main-detail">
              <div className="my-profile-my-each-review-bottom-level-review-star-div">
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <span className="my-profile-my-each-review-bottom-level-review-total-number">
                  (5.0)
                </span>
              </div>
              <span className="my-profile-my-each-review-bottom-level-review-date">
                3 days ago
              </span>
              <span className="my-profile-my-each-review-bottom-level-review-text">
                Awesome Product!
              </span>
            </div>
          </div>
          <div className="my-profile-my-each-review-div">
            <div className="my-profile-my-each-review-upper-level-detail">
              <img
                src="https://www.jiomart.com/images/product/original/rv5pxpyj7m/manogyam-security-guard-against-theft-smart-e-bike-steel-wire-cable-lock-cycle-accessorie-cycle-lock-product-images-orv5pxpyj7m-p598992312-0-202303021944.jpg?im=Resize=(420,420)"
                alt=""
                className="my-profile-my-each-review-upper-level-detail-img"
              />
              <div className="my-profile-my-each-review-upper-level-main-detail">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Salsa_Logo.svg/1200px-Salsa_Logo.svg.png"
                  alt=""
                  className="my-profile-my-each-review-upper-level-main-detail-brand-logo"
                />
                <span className="my-profile-my-each-review-upper-level-detail-product-name">
                  Cycling Lock-On Anti-Slip Bicycle Handle Grip
                </span>
                <span className="my-profile-my-each-review-upper-level-detail-product-price">
                  $999.00
                </span>
              </div>
            </div>
            <div className="my-profile-my-each-review-bottom-level-main-detail">
              <div className="my-profile-my-each-review-bottom-level-review-star-div">
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <span className="my-profile-my-each-review-bottom-level-review-total-number">
                  (5.0)
                </span>
              </div>
              <span className="my-profile-my-each-review-bottom-level-review-date">
                3 days ago
              </span>
              <span className="my-profile-my-each-review-bottom-level-review-text">
                Awesome Product!
              </span>
            </div>
          </div>
          <div className="my-profile-my-each-review-div">
            <div className="my-profile-my-each-review-upper-level-detail">
              <img
                src="https://www.jiomart.com/images/product/original/rv5pxpyj7m/manogyam-security-guard-against-theft-smart-e-bike-steel-wire-cable-lock-cycle-accessorie-cycle-lock-product-images-orv5pxpyj7m-p598992312-0-202303021944.jpg?im=Resize=(420,420)"
                alt=""
                className="my-profile-my-each-review-upper-level-detail-img"
              />
              <div className="my-profile-my-each-review-upper-level-main-detail">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Salsa_Logo.svg/1200px-Salsa_Logo.svg.png"
                  alt=""
                  className="my-profile-my-each-review-upper-level-main-detail-brand-logo"
                />
                <span className="my-profile-my-each-review-upper-level-detail-product-name">
                  Cycling Lock-On Anti-Slip Bicycle Handle Grip
                </span>
                <span className="my-profile-my-each-review-upper-level-detail-product-price">
                  $999.00
                </span>
              </div>
            </div>
            <div className="my-profile-my-each-review-bottom-level-main-detail">
              <div className="my-profile-my-each-review-bottom-level-review-star-div">
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <span className="my-profile-my-each-review-bottom-level-review-total-number">
                  (5.0)
                </span>
              </div>
              <span className="my-profile-my-each-review-bottom-level-review-date">
                3 days ago
              </span>
              <span className="my-profile-my-each-review-bottom-level-review-text">
                Awesome Product!
              </span>
            </div>
          </div>
          <div className="my-profile-my-each-review-div">
            <div className="my-profile-my-each-review-upper-level-detail">
              <img
                src="https://www.jiomart.com/images/product/original/rv5pxpyj7m/manogyam-security-guard-against-theft-smart-e-bike-steel-wire-cable-lock-cycle-accessorie-cycle-lock-product-images-orv5pxpyj7m-p598992312-0-202303021944.jpg?im=Resize=(420,420)"
                alt=""
                className="my-profile-my-each-review-upper-level-detail-img"
              />
              <div className="my-profile-my-each-review-upper-level-main-detail">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Salsa_Logo.svg/1200px-Salsa_Logo.svg.png"
                  alt=""
                  className="my-profile-my-each-review-upper-level-main-detail-brand-logo"
                />
                <span className="my-profile-my-each-review-upper-level-detail-product-name">
                  Cycling Lock-On Anti-Slip Bicycle Handle Grip
                </span>
                <span className="my-profile-my-each-review-upper-level-detail-product-price">
                  $999.00
                </span>
              </div>
            </div>
            <div className="my-profile-my-each-review-bottom-level-main-detail">
              <div className="my-profile-my-each-review-bottom-level-review-star-div">
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <StarRateRoundedIcon />
                <span className="my-profile-my-each-review-bottom-level-review-total-number">
                  (5.0)
                </span>
              </div>
              <span className="my-profile-my-each-review-bottom-level-review-date">
                3 days ago
              </span>
              <span className="my-profile-my-each-review-bottom-level-review-text">
                Awesome Product!
              </span>
            </div>
          </div>
        </div>
      </div> */}

      {/* OVERLAY */}
      {openOverlay && (
        <>
          <div className="overlay"></div>
          <div className="my-profile-overlay-container">
            {editBasicProfile && (
              <EditBasicProfileDetail
                handleEditBasicProfile={handleEditBasicProfile}
              />
            )}
            {addNewProfileAddress && (
              <AddNewAddress handleAddNewAddress={handleAddNewAddress} />
            )}
            {editAddressDetail && (
              <EditAddressDetail
                handleEditAddressDetail={handleEditAddressDetail}
                sendAddress={sendAddress}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default MyProfile;
