import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getWallet,applyCoupon, debitWallet, fetchCoupon,getWalletDetail } from "./walletAPI";

const initialState = {
  wallet: null,
  walletDetail:[],
  coupon:[],
  status: "idle",
};

export const getWalletAsync = createAsyncThunk(
  "wallet/getWallet",
  async (userID) => {
    try {
      const response = await getWallet(userID);
      return response.data;
    } catch (error) {
      throw Error("Failed to get order status");
    }
  }
);
export const getWalletDetailAsync = createAsyncThunk(
  "wallet/getWalletDetail",
  async (userID) => {
    try {
      const response = await getWalletDetail(userID);
      return response.data;
    } catch (error) {
      throw Error("Failed to get order status");
    }
  }
);
export const fetchCouponAsync = createAsyncThunk(
    "wallet/fetchCoupon",
    async () => {
      try {
        const response = await fetchCoupon();
        return response.data;
      } catch (error) {
        throw Error("Failed to get order status");
      }
    }
  );
export const debitWalletAsync = createAsyncThunk(
    "wallet/debitWallet",
    async (walletData) => {
      try {
        const response = await debitWallet(walletData);
        return response.data;
      } catch (error) {
        throw Error("Failed to get order status");
      }
    }
  );

export const applyCouponAsync = createAsyncThunk(
  "wallet/applyCoupon",
  async (couponData) => {
    try {
      const response = await applyCoupon(couponData);
      // console.log(response);
      return response;
    } catch (error) {
      throw Error("Failed to create order");
    }
  }
);

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWalletAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getWalletAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.wallet = action.payload;
      })
      .addCase(applyCouponAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(applyCouponAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // state.inventory = action.payload;
      })
      .addCase(debitWalletAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(debitWalletAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // state.wallet = action.payload;
      })
      .addCase(fetchCouponAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCouponAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.coupon = action.payload;
      })
      .addCase(getWalletDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getWalletDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.walletDetail = action.payload;
      });
  },
});

export const selectWallet = (state) => state.wallet.wallet;
export const selectAllCoupon = (state) =>state.wallet.coupon;
export const selectWalletDetail = (state) => state.wallet.walletDetail;


export default walletSlice.reducer;
