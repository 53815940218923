import React, { useEffect, useState } from "react";
import "./MobileBigNavigation.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PartsIcon from "../../img/navigation/parts.png";
import accessoriesIcon from "../../img/navigation/accessories.png";
import clothingIcon from "../../img/navigation/clothing.png";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllNavigationsAsync,
  selectAllNavigations,
} from "../../features/HomePage/homeSlice";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";
import { useNavigate } from "react-router";
import { IMAGE_BASE } from "../../config";
import { Slide } from "react-reveal";

function MobileBigNavigation({ setShowNavigation }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const verticalDetail = useSelector(selectAllNavigations);
  const [nav, setNav] = useState(1);

  useEffect(() => {
    dispatch(fetchAllNavigationsAsync(1));
  }, []);

  const handleNavigations = (vertical) => {
    if (nav === vertical) {
      setNav(null);
    } else {
      dispatch(fetchAllNavigationsAsync(vertical));
      setNav(vertical);
    }
  };
  const handleBrandNavigate = (value) => {
    const lowercaseValue = value.toLowerCase();
    navigate(`/listing-page/${lowercaseValue}`);
    setShowNavigation(false);
  };

  const handelCategoryNavigate = (catID, catName) => {
    navigate(`/listing-page/${catID}/${catName}`);
    dispatch(setSelectedSubcategory(null));
    // dispatch(fetchAllCategoryProductsAsync(catID));
    setShowNavigation(false);
  };
  return (
    <Slide right>
      <div className="mobile-big-navigation-container">
        <div className="mobile-big-navigation-header-container">
          <ChevronLeftIcon
            id="mobile-big-back-icon"
            onClick={() => setShowNavigation(false)}
          />
          <span className="mobile-big-navigation-heading">All Categories</span>
        </div>
        <div className="mobile-big-navigation-tabs">
          <div
            className={`mobile-big-each-navigation-tab ${
              nav === 1 && "nav-active"
            }`}
            onClick={() => handleNavigations(1)}
          >
            <img
              src={PartsIcon}
              alt=""
              className="mobile-big-each-navigation-tab-icon"
            />
            <span className="mobile-big-each-navigation-tab-name">Parts</span>
          </div>
          <div
            className={`mobile-big-each-navigation-tab ${
              nav === 2 && "nav-active"
            }`}
            onClick={() => handleNavigations(2)}
          >
            <img
              src={accessoriesIcon}
              alt=""
              className="mobile-big-each-navigation-tab-icon"
            />
            <span className="mobile-big-each-navigation-tab-name">
              Accessories
            </span>
          </div>
          <div
            className={`mobile-big-each-navigation-tab ${
              nav === 3 && "nav-active"
            }`}
            onClick={() => handleNavigations(3)}
          >
            <img
              src={clothingIcon}
              alt=""
              className="mobile-big-each-navigation-tab-icon"
            />
            <span className="mobile-big-each-navigation-tab-name">
              Maintenance
            </span>
          </div>
        </div>
        <div className="mobile-big-navigation-ctg-container">
          {verticalDetail?.categories &&
            verticalDetail?.categories.map((category, index) => (
              <div
                className="mobile-big-navigation-each-ctg-container"
                key={index}
                onClick={() =>
                  handelCategoryNavigate(
                    category?.CategoryID,
                    category?.categoryName
                  )
                }
              >
                <img
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_CATEGORY_IMAGE/${
                    category?.image && category?.image
                  }`}
                  alt=""
                  className="mobile-big-navigation-each-ctg-icon"
                />
                <span className="mobile-big-navigation-each-ctg-name">
                  {category.categoryName && category?.categoryName}
                </span>
              </div>
            ))}
        </div>
        <div className="mobile-big-navigation-brand-container">
          <span className="mobile-navigation-brand-heading">Top Brands</span>
          <div className="mobile-big-navigation-brand-main-container">
            {verticalDetail?.manufacturers &&
              verticalDetail?.manufacturers.map((brand, index) => (
                <div className="mobile-big-navigation-each-brand" key={index}>
                  <img
                    src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                      brand?.manufacture_images && brand?.manufacture_images
                    }`}
                    alt=""
                    className="mobile-big-navigation-each-brand-logo"
                    onClick={() =>
                      handleBrandNavigate(
                        brand?.manufacture_name && brand?.manufacture_name
                      )
                    }
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </Slide>
  );
}

export default MobileBigNavigation;
