import React, { useState, useEffect, useRef, useCallback } from "react";
import "./MobilePriceDetail.css";
import { API_BASE_PAYMENT_URL } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import EastSharpIcon from "@mui/icons-material/EastSharp";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import WalletOutlinedIcon from "@mui/icons-material/WalletOutlined";
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  fetchAllCartsAsync,
  selectAllCarts,
} from "../../features/cartPage/cartPageSlice";
import Swal from "sweetalert2";
import { selectUser } from "../../features/auth/authSlice";
import { selectSelectedAddress } from "../../features/cartPage/addressSlice";
import MobileOrderConfirm from "./MobileOrderConfirm";
import {
  fetchCouponAsync,
  getWalletAsync,
  selectAllCoupon,
  selectWallet,
} from "../../features/cartPage/walletSlice";
import {
  premiumUser,
  tenPercentageDiscount,
  validCoupon,
} from "../../features/cartPage/walletAPI";
import ccLogo from "../../img/banners/Coupon-CC-Logo.png";
import { Slide } from "react-reveal";
import {
  calculateDealerPrice,
  calculateOfferPrice,
  calculateTotalAmount,
  calculateTotalAmountWithOutGST,
  fetchUserShipping,
  formatWithCommasWithDecimal,
} from "../../utils/utils";

function MobilePriceDetail({ onCheckout, currentStep, setCurrentStep }) {
  const dispatch = useDispatch();
  const cart = useSelector(selectAllCarts);
  const selectedAddress = useSelector(selectSelectedAddress);
  const userDetail = useSelector(selectUser);
  const walletAmount = useSelector(selectWallet)?.balance;
  const [invalidCoupon, setInvalidCoupon] = useState(false);
  const [addedCoupon, setAddedCoupon] = useState(false);
  const [enteredCoupon, setEnteredCoupon] = useState("");
  const [walletPaymentAmount, setWalletPaymentAmount] = useState();
  const [walletPaymentFinalPrice, setWalletPaymentFinalPrice] = useState(0);
  const [tenPercentDiscount, setTenPercentDiscount] = useState(0);
  const [premUser, setPremUser] = useState(false);
  const [errors, setErrors] = useState({});
  const [discount, setDiscount] = useState(0);
  const [coupon, setCoupon] = useState("");
  const initialCoupons = useSelector(selectAllCoupon);
  const [shippingOpen, setShippingOpen] = useState(false);
  const [confirmOverlay, setConfirmOverlay] = useState(false);
  const [paymentSessionId, setPaymentSessionId] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [openCoupon, setOpenCoupon] = useState(false);
  const [freeShipping, setFreeShipping] = useState(false);
  // console.log(initialCoupons);

  useEffect(() => {
    dispatch(fetchCouponAsync());
    dispatch(getWalletAsync(userDetail?.UserID));
  }, [dispatch, userDetail?.UserID]);

  // Function to handle wallet payment input change
  const handleWalletPaymentChange = (event) => {
    const inputValue = parseInt(event.target.value);

    // Ensure inputValue is a valid number
    if (!isNaN(inputValue)) {
      if (inputValue > walletAmount) {
        return;
      } else {
        setWalletPaymentAmount(inputValue);
      }
    } else {
      setWalletPaymentAmount(0);
    }
  };

  const handleWalletPayment = () => {
    // console.log(walletPaymentAmount);
    if (isNaN(walletPaymentAmount)) {
      return;
    } else {
      setWalletPaymentFinalPrice(walletPaymentAmount);
    }
  };

  useEffect(() => {
    const responsePromise = tenPercentageDiscount(userDetail?.UserID);
    responsePromise
      .then((response) => {
        // console.log(response);
        if (response && response.data && response.data.message) {
          setTenPercentDiscount(
            (calculateTotalAmountWithOutGST(cart) * 10) / 100
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error if necessary
      });
  }, []);

  const checkPremiumUser = useCallback(async () => {
    try {
      const response = await premiumUser(userDetail?.UserID);
      if (response && response.data && response.data.message) {
        setPremUser(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [userDetail?.UserID]);

  // Use useEffect to call the function
  useEffect(() => {
    checkPremiumUser();
  }, [checkPremiumUser]);

  const handleConfirmOverlay = () => {
    setConfirmOverlay(true);
  };

  useEffect(() => {
    if (userDetail && userDetail?.UserID)
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
  }, [dispatch, userDetail]);

  const infoRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (infoRef.current && !infoRef.current.contains(event.target)) {
        // Clicked outside the component, you can close it here
        setShippingOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchAndSetShipping = useCallback(async () => {
    if (userDetail?.UserID) {
      try {
        const userShipping = await fetchUserShipping(userDetail?.UserID);

        if (userShipping === "false") {
          setFreeShipping(true);
        } else {
          setFreeShipping(false);
        }
      } catch (error) {
        console.error("Error fetching user shipping info:", error);
      }
    }
  }, [userDetail?.UserID]);

  useEffect(() => {
    fetchAndSetShipping();
  }, [fetchAndSetShipping]);

  // Function to calculate the shipping cost for the selected shipping mode
  const calculateShippingCost = () => {
    let totalAmount = 0;
    cart &&
      cart?.forEach((product) => {
        totalAmount +=
          !product?.skuDetails?.SKU_ID?.startsWith("D") &&
          (product.skuDetails?.Offer_price
            ? calculateOfferPrice(
                parseInt(product.skuDetails?.MRP_price),
                parseInt(product.skuDetails?.Offer_price),
                product?.skuDetails?.GST
              )
            : calculateDealerPrice(
                parseInt(product.skuDetails?.MRP_price),
                parseInt(product.skuDetails?.NDP_Price),
                product?.skuDetails?.GST
              )) * product?.buy_quantity;
      });

    const amountAfterDiscount = totalAmount - (addedCoupon && discount);
    const amountToPay =
      amountAfterDiscount > walletPaymentFinalPrice
        ? amountAfterDiscount - walletPaymentFinalPrice
        : 0;

    if (amountToPay >= 3000 || premUser || freeShipping) {
      return 0;
    } else {
      return 250;
    }
  };

  // Function to calculate the amount to pay (total amount + shipping cost)
  const calculateAmountToPay = () => {
    const totalAmount = parseInt(calculateTotalAmount(cart));
    const shippingCost = parseInt(calculateShippingCost());
    const amountAfterDiscount = totalAmount + shippingCost - discount;
    const amountToPay =
      amountAfterDiscount > walletPaymentFinalPrice
        ? amountAfterDiscount -
          (!isNaN(walletPaymentFinalPrice) && walletPaymentFinalPrice)
        : 0;
    return parseInt(amountToPay);
  };

  const handleGeneratePaymentURL = async (price) => {
    // console.log(price);
    try {
      const response = await fetch(
        `${API_BASE_PAYMENT_URL}${userDetail?.UserID}/${price}`
      );

      const data = await response.json();
      setPaymentSessionId(data?.Payment_Session_ID);
      setTransactionId(data?.LinkID);
      // console.log(data);
    } catch (error) {
      console.error("An error occurred during the API request:", error.message);
    }
  };

  const handleCheckCoupon = () => {
    const foundCoupon = initialCoupons.find(
      (coupon) => coupon.code === enteredCoupon
    );
    if (foundCoupon) {
      // Select the found coupon
      const amount = parseInt(calculateTotalAmountWithOutGST(cart));
      const save = (amount * foundCoupon?.discount_percentage) / 100;
      setDiscount(save);
      setCoupon(enteredCoupon);
      setEnteredCoupon("");
      // Reset error state
      setInvalidCoupon(false);
    } else {
      setInvalidCoupon(true);
    }
  };

  const checkValidCoupon = (couponCode) => {
    let couponData = {
      userID: userDetail?.UserID,
      coupon: coupon,
    };
    const responsePromise = validCoupon(couponData);
    responsePromise.then((response) => {
      // console.log(response);
      if (response && response?.data && response?.data?.message) {
        return true;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          couponCode: "Already used twice(max. limit = 2)",
        }));
        return false;
      }
    });
  };
  const handleCodeAdd = (data) => {
    const amount = parseInt(calculateTotalAmountWithOutGST(cart));
    const save = (amount * data?.discount_percentage) / 100;
    setCoupon(data?.code);
    setDiscount(save);
  };

  const handleSave = (discount) => {
    const amount = parseInt(calculateTotalAmountWithOutGST(cart));
    // console.log(discount, amount);
    return (amount * discount) / 100;
  };

  const handleCouponApply = async () => {
    const foundCoupon = initialCoupons?.find((c) => c?.code === coupon);
    const totalAmount = parseInt(calculateTotalAmountWithOutGST(cart));
    if (totalAmount && foundCoupon) {
      let discountAmount =
        totalAmount * (foundCoupon?.discount_percentage / 100);
      // if (discountAmount > foundCoupon?.UpTo) {
      //   discountAmount = foundCoupon?.UpTo;
      // }
      setDiscount(parseInt(discountAmount));
      setAddedCoupon(true);
      setOpenCoupon(false);
    }
    if (coupon === "CC100") {
      setDiscount(parseInt(calculateTotalAmount(cart) - 2));
      setAddedCoupon(true);
      setOpenCoupon(false);
    }
  };
  return (
    <>
      {cart && cart?.length > 0 && (
        <div
          className="mobile-priceDetail-container"
          style={{ minHeight: currentStep === 3 && "78vh" }}
        >
          <h3 className="mobile-priceDetail-heading">Payment Summary</h3>
          <div className="mobile-product-cart-pricing-details">
            <div className="mobile-product-cart-pricing-div">
              <p>Total Price</p>
              <hr />
              <span>
                ₹{formatWithCommasWithDecimal(calculateTotalAmount(cart))}
              </span>
            </div>

            <div className="mobile-product-cart-pricing-div">
              <p>Shipping Cost</p>
              <hr />
              <span>
                ₹{formatWithCommasWithDecimal(calculateShippingCost())}
              </span>
            </div>
            {discount !== 0 && addedCoupon && (
              <div className="mobile-product-cart-pricing-div">
                <p>Discount</p>
                <hr />
                <span>-₹{discount.toFixed(2)}</span>
              </div>
            )}
            {walletPaymentFinalPrice !== 0 && (
              <div className="mobile-product-cart-pricing-div">
                <p>Wallet</p>
                <hr />
                <span>
                  -₹
                  {walletPaymentFinalPrice
                    ? walletPaymentFinalPrice.toFixed(2)
                    : "0.00"}
                </span>
              </div>
            )}
            <div className="mobile-product-cart-pricing-div">
              <p className="mobile-total-cart-price-text">Amount to pay</p>
              <hr />
              <span className="mobile-total-cart-price-text">
                ₹{formatWithCommasWithDecimal(calculateAmountToPay())}
              </span>
            </div>
            <div className="mobile-product-cart-bottom-fixed-div">
              <div className="mobile-product-cart-left-container">
                <span className="mobile-product-cart-left-span">
                  Total Amount
                </span>
                <span className="mobile-product-cart-left-price">
                  ₹{formatWithCommasWithDecimal(calculateAmountToPay())}
                </span>
              </div>
              <span
                className="mobile-proceed-btn"
                onClick={() => {
                  setCurrentStep((prev) => {
                    let nextStep = prev;
                    if (prev < 2) {
                      nextStep = prev + 1;
                    } else if (prev === 2 && selectedAddress !== null) {
                      nextStep = 3;
                    } else if (prev === 2 && selectedAddress === null) {
                      // Display SweetAlert if selectedAddress is null
                      Swal.fire({
                        icon: "warning",
                        title: "Please add an address",
                        text: "You need to add an address before proceeding.",
                        confirmButtonText: "OK",
                      });
                    } else if (prev === 3) {
                      handleConfirmOverlay();
                      handleGeneratePaymentURL(calculateAmountToPay());
                    }
                    return nextStep;
                  });
                }}
              >
                {currentStep !== 1
                  ? "Proceed to Payment"
                  : "Proceed to Checkout"}
                <EastSharpIcon />
              </span>
            </div>

            {currentStep === 3 && (
              <div className="product-apply-coupon-code-container">
                <QrCode2OutlinedIcon
                  className="product-apply-coupon-code-img"
                  style={{ fontSize: "6.5vw", color: "black" }}
                />
                <div className="product-apply-coupon-name-save-container">
                  <span className="product-apply-coupon-name">
                    Apply Coupon Code
                  </span>
                  {addedCoupon && (
                    <span className="product-apply-coupon-save">
                      You Saved ₹{discount.toFixed(2)}
                    </span>
                  )}
                </div>
                <span
                  className="product-apply-coupon-apply-btn"
                  onClick={() => setOpenCoupon(true)}
                >
                  {addedCoupon ? "Edit" : "Apply"}
                </span>
              </div>
            )}

            {openCoupon && (
              <Slide right>
                {/* <div className="coupon-overlay"></div> */}
                <div className="coupon-overlay-selection-container">
                  <div className="coupon-overlay-selection-heading-container">
                    <KeyboardArrowLeftIcon
                      onClick={() => setOpenCoupon(false)}
                    />
                    <span className="coupon-overlay-selection-span">
                      Apply Coupon Code
                    </span>
                  </div>
                  <div className="coupon-overlay-selection-input-container">
                    <input
                      type="text"
                      placeholder="CCNEW"
                      className="coupon-overlay-selection-input"
                      value={enteredCoupon}
                      onChange={(e) =>
                        setEnteredCoupon(e.target.value.toUpperCase())
                      }
                    />
                    <span
                      className="coupon-overlay-selection-check-btn"
                      onClick={handleCheckCoupon}
                    >
                      Check
                    </span>
                    {invalidCoupon && (
                      <span className="applied-coupon-invalid">
                        <ErrorOutlinedIcon />
                        Coupon code is invalid or expired
                      </span>
                    )}
                  </div>
                  <div className="coupon-overlay-selection-coupon-select-container">
                    {initialCoupons &&
                      initialCoupons?.map((data, index) => {
                        if (data?.code === coupon) {
                          return (
                            <div className="coupon-overlay-selection-coupon-select-each-container">
                              <input
                                type="radio"
                                className="coupon-overlay-selection-coupon-input"
                                unselectable={
                                  checkValidCoupon ? "true" : "false"
                                }
                                checked={coupon === data.code}
                                value={data?.code}
                                onChange={() => handleCodeAdd(data)}
                              />
                              <div className="coupon-overlay-selection-coupon-detail-container">
                                <div className="coupon-overlay-selection-coupon-top">
                                  <img
                                    src={ccLogo}
                                    alt=""
                                    className="coupon-overlay-cc-logo"
                                  />
                                  <span className="coupon-overlay-selection-coupon">
                                    {data?.code}
                                  </span>
                                </div>
                                <span className="coupon-overlay-selection-save">
                                  Save <br />
                                  <b>
                                    ₹
                                    {parseInt(
                                      handleSave(data?.discount_percentage)
                                    ).toFixed(2)}
                                  </b>
                                </span>
                                <span className="coupon-overlay-selection-expire">
                                  Expires: <br /> <b>23.08.2024</b>
                                </span>
                                <span className="coupon-overlay-terms">
                                  Terms & Conditions Apply
                                </span>
                              </div>
                              {errors[data?.code] && (
                                <span className="coupon-overlay-selection-error">
                                  {errors[data?.code]}
                                </span>
                              )}
                            </div>
                          );
                        } else {
                          return null; // If data.code doesn't match coupon, don't render anything
                        }
                      })}
                  </div>
                  <div className="coupon-overlay-selection-bottom-container">
                    <div className="coupon-overlay-selection-bottom-left-container">
                      <span className="coupon-overlay-selection-bottom-left-saving-head">
                        Maximum Savings:
                      </span>
                      <span className="coupon-overlay-selection-bottom-left-saving-price">
                        ₹{parseInt(discount).toFixed(2)}
                      </span>
                    </div>
                    <span
                      className="coupon-overlay-selection-bottom-right-btn"
                      onClick={handleCouponApply}
                    >
                      Apply
                    </span>
                  </div>
                </div>
              </Slide>
            )}

            {currentStep === 3 && (
              <div className="wallet-payment-wallet-balance-container">
                <div className="wallet-payment-balance-top-container">
                  <WalletOutlinedIcon />
                  <span className="wallet-payment-balance-heading">
                    Wallet Balance
                  </span>
                  <div className="wallet-payment-balance-right-container">
                    <div className="wallet-payment-balance-right-details">
                      <span className="wallet-payment-balance-right-details-price">
                        ₹
                        {walletAmount -
                          (!isNaN(walletPaymentAmount) && walletPaymentAmount)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="wallet-payment-balance-bottom-container">
                  <input
                    type="text"
                    placeholder="Enter Amount to pay from wallet"
                    value={walletPaymentAmount}
                    onChange={handleWalletPaymentChange}
                    className="wallet-payment-balance-bottom-input"
                    onKeyPress={(event) => {
                      // Check if the key pressed is not a digit
                      if (!/\d/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <span
                    className="wallet-payment-balance-bottom-pay-btn"
                    onClick={handleWalletPayment}
                  >
                    Pay
                  </span>
                  {walletPaymentFinalPrice !== 0 &&
                    walletPaymentAmount !== 0 && (
                      <span className="applied-wallet-removed">
                        <CheckCircleOutlineIcon />₹ {walletPaymentFinalPrice}{" "}
                        used from wallet
                      </span>
                    )}
                </div>
                {confirmOverlay && (
                  <div className="overlay-container">
                    <MobileOrderConfirm
                      setConfirmOverlay={setConfirmOverlay}
                      paymentSessionId={paymentSessionId}
                      transactionId={transactionId}
                      selectedAddress={selectedAddress}
                      coupon={coupon}
                      addedCoupon={addedCoupon}
                      discount={discount}
                      walletPaymentFinalPrice={walletPaymentFinalPrice}
                      tenPercentDiscount={tenPercentDiscount}
                      premUser={premUser}
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      freeShipping={freeShipping}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default MobilePriceDetail;
