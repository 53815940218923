import { API_BASE_URL } from "../../config";
export function fetchAllWishlists(UserID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}wishlist/${UserID}`);
    const data = await response.json();
    // console.log("from API",data)
    resolve({ data });
  });
}

export function addToWishlists(wishlistData) {
  const UserID = wishlistData.UserID;
  const prodID = wishlistData.prodID;
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}wishlist/add/${UserID}/${prodID}`);
    const data = await response.json();
    resolve({ data });
  });
}

export function removeFromWishlists(wishlistData) {
  const UserID = wishlistData.UserID;
  const prodID = wishlistData.prodID;
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}wishlist/remove/${UserID}/${prodID}`);
    const data = await response.json();
    resolve({ data });
  });
}

export function removeAllFromWishlists(UserID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}wishlist/removeAll/${UserID}`);
    const data = await response.json();
    resolve({ data });
  });
}
