import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllCarts,
  addToCarts,
  removeFromCarts,
  removeAllFromCarts,
  changeQtyOfProductCarts,
  fetchAllPreOrderCarts,
  removeAllFromPreorderCarts,
  addToPreOrderCarts,
  removeFromPreOrderCarts,
  fetchAllPreOrderUserDetails,
  fetchAllAdminUsers,
  fetchAllAdminUsersOrders,
  fetchAllAdminUsersCarts,
  fetchAllUserCarts,
  fetchAllUsersRole,
  fetchUserRoleById,
  updateUserRole,
  fetchAllUsersShipping,
  fetchUserShippingById,
  updateUserShipping,
} from "./cartPageAPI";

const initialState = {
  carts: [],
  preorder: [],
  orders: [],
  ordersUser: [],
  cartsUser: [],
  userCarts: [],
  preOrderUser: [],
  userRole: [],
  userShipping: [],
  status: "idle", // Include the 'status' property in the initialState
};

export const fetchAllCartsAsync = createAsyncThunk(
  "carts/fetchAllCarts",
  async (UserID) => {
    const response = await fetchAllCarts(UserID);
    // console.log(response)
    return response.data.cartItems;
  }
);
export const removeAllFromCartsAsync = createAsyncThunk(
  "carts/removeAllFromCarts",
  async (UserID) => {
    const response = await removeAllFromCarts(UserID);
    // console.log(response)
    return response;
  }
);
export const changeQtyOfProductCartsAsync = createAsyncThunk(
  "carts/changeQtyOfProductCarts",
  async (cartData) => {
    const response = await changeQtyOfProductCarts(cartData);
    // console.log(response)
    return response;
  }
);

export const addToCartsAsync = createAsyncThunk(
  "carts/addToCarts",
  async (cartData) => {
    const response = await addToCarts(cartData);
    // console.log(response);
    return response;
  }
);
export const removeFromCartsAsync = createAsyncThunk(
  "carts/removeFromCarts",
  async (cartData) => {
    const response = await removeFromCarts(cartData);
    // console.log(response);
    return response;
  }
);

export const addToPreorderCartsAsync = createAsyncThunk(
  "carts/addToPreorderCarts",
  async (preOrderData) => {
    const response = await addToPreOrderCarts(preOrderData);
    // console.log(response);
    return response;
  }
);
export const removeFromPreorderCartsAsync = createAsyncThunk(
  "carts/removeFromPreorderCarts",
  async (SKU_ID) => {
    const response = await removeFromPreOrderCarts(SKU_ID);
    // console.log(response);
    return response;
  }
);

export const fetchAllPreorderCartsAsync = createAsyncThunk(
  "carts/fetchAllPreorderCarts",
  async (UserID) => {
    const response = await fetchAllPreOrderCarts(UserID);
    // console.log(response)
    return response.data;
  }
);
export const removeAllFromPreorderCartsAsync = createAsyncThunk(
  "carts/removeAllFromPreorderCarts",
  async (UserID) => {
    const response = await removeAllFromPreorderCarts(UserID);
    // console.log(response)
    return response;
  }
);

export const fetchAllPreOrderUserDetailsAsync = createAsyncThunk(
  "carts/fetchAllPreOrderUserDetails",
  async () => {
    const response = await fetchAllPreOrderUserDetails();
    // console.log(response)
    return response.data;
  }
);

export const fetchAllAdminUsersAsync = createAsyncThunk(
  "carts/fetchAllAdminUsers",
  async () => {
    const response = await fetchAllAdminUsers();
    // console.log(response)
    return response.data;
  }
);
export const fetchAllAdminUsersCartsAsync = createAsyncThunk(
  "carts/fetchAllAdminUsersCarts",
  async () => {
    const response = await fetchAllAdminUsersCarts();
    // console.log(response)
    return response.data;
  }
);
export const fetchAllAdminUsersOrdersAsync = createAsyncThunk(
  "carts/fetchAllAdminUsersOrders",
  async (UserID) => {
    const response = await fetchAllAdminUsersOrders(UserID);
    // console.log(response)
    return response.data;
  }
);

export const fetchAllUserCartsAsync = createAsyncThunk(
  "carts/fetchAllUserCarts",
  async (UserID) => {
    const response = await fetchAllUserCarts(UserID);
    // console.log(response)
    return response.data.cartItems;
  }
);

export const fetchAllUsersRoleAsync = createAsyncThunk(
  "carts/fetchAllUsersRole",
  async () => {
    const response = await fetchAllUsersRole();
    // console.log(response)
    return response.data;
  }
);

export const fetchUserRoleByIdAsync = createAsyncThunk(
  "carts/fetchUserRoleById",
  async (UserID) => {
    const response = await fetchUserRoleById(UserID);
    // console.log(response)
    return response.data;
  }
);

export const updateUserRoleAsync = createAsyncThunk(
  "carts/updateUserRole",
  async (UserID) => {
    const response = await updateUserRole(UserID);
    // console.log(response)
    return response.data;
  }
);

export const fetchAllUsersShippingAsync = createAsyncThunk(
  "carts/fetchAllUsersShipping",
  async () => {
    const response = await fetchAllUsersShipping();
    // console.log(response)
    return response.data;
  }
);

export const fetchUserShippingByIdAsync = createAsyncThunk(
  "carts/fetchUserShippingById",
  async (UserID) => {
    const response = await fetchUserShippingById(UserID);
    // console.log(response)
    return response.data;
  }
);

export const updateUserShippingAsync = createAsyncThunk(
  "carts/updateUserShipping",
  async (UserID) => {
    const response = await updateUserShipping(UserID);
    // console.log(response)
    return response.data;
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCartsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCartsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.carts = action.payload;
      })
      .addCase(fetchAllUserCartsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllUserCartsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.userCarts = action.payload;
      })
      .addCase(addToCartsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addToCartsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // state.carts = action.payload;
      })
      .addCase(removeFromCartsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeFromCartsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload);
        // state.carts = action.payload;
      })
      .addCase(removeAllFromCartsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeAllFromCartsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload);
        // state.carts = action.payload;
      })
      .addCase(changeQtyOfProductCartsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(changeQtyOfProductCartsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload);
        // state.carts = action.payload;
      })
      .addCase(fetchAllPreorderCartsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllPreorderCartsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.preorder = action.payload;
      })
      .addCase(fetchAllPreOrderUserDetailsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllPreOrderUserDetailsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.preOrderUser = action.payload;
      })
      .addCase(fetchAllAdminUsersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllAdminUsersAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.ordersUser = action.payload;
      })
      .addCase(fetchAllAdminUsersCartsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllAdminUsersCartsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.cartsUser = action.payload;
      })
      .addCase(fetchAllAdminUsersOrdersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllAdminUsersOrdersAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.orders = action.payload;
      })
      .addCase(addToPreorderCartsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addToPreorderCartsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // state.preorder = action.payload;
      })
      .addCase(removeFromPreorderCartsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeFromPreorderCartsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload);
        // state.preorder = action.payload;
      })
      .addCase(removeAllFromPreorderCartsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeAllFromPreorderCartsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload);
        // state.preorder = action.payload;
      })
      .addCase(fetchAllUsersRoleAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllUsersRoleAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload);
        state.userRole = action.payload;
      })
      .addCase(fetchUserRoleByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserRoleByIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload);
        // state.preorder = action.payload;
      })
      .addCase(updateUserRoleAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserRoleAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload);
        // state.preorder = action.payload;
      })
      .addCase(fetchAllUsersShippingAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllUsersShippingAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload);
        state.userShipping = action.payload;
      })
      .addCase(fetchUserShippingByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserShippingByIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload);
        // state.preorder = action.payload;
      })
      .addCase(updateUserShippingAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserShippingAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload);
        // state.preorder = action.payload;
      });
  },
});

export const selectAllCarts = (state) => state.cart.carts;
export const selectAllPreorders = (state) => state.cart.preorder;
export const selectAllPreordersUser = (state) => state.cart.preOrderUser;
export const selectAllAdminUser = (state) => state.cart.ordersUser;
export const selectAllAdminUserCart = (state) => state.cart.cartsUser;
export const selectAllAdminUserOrders = (state) => state.cart.orders;
export const selectAllAdminUserCarts = (state) => state.cart.userCarts;
export const selectAllUsersRole = (state) => state.cart.userRole;
export const selectAllUsersShipping = (state) => state.cart.userShipping;

export default cartSlice.reducer;
