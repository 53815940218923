import React, { useState, useMemo, useEffect, useRef } from "react";
import "./SelectDesktopVariant.css";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { ADMIN_USER, IMAGE_BASE } from "../../config";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import {
  setProductID,
  setSalePrice,
  setQuantity,
  selectVarient,
} from "../../store/slices/selectVarient";
import { useDispatch, useSelector } from "react-redux";
import { Slide } from "react-reveal";
import { selectUser } from "../../features/auth/authSlice";
import {
  addToCartsAsync,
  addToPreorderCartsAsync,
  fetchAllAdminUsersCartsAsync,
  fetchAllCartsAsync,
  fetchAllPreorderCartsAsync,
  fetchAllUserCartsAsync,
  removeFromCartsAsync,
  removeFromPreorderCartsAsync,
  selectAllAdminUserCart,
  selectAllAdminUserCarts,
  selectAllCarts,
  selectAllPreorders,
} from "../../features/cartPage/cartPageSlice";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { useNavigate } from "react-router";
import { findqty } from "../../data";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import commingSoonLogo from "../../icons/badges/comming-soon.png";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  calculateDealerPrice,
  calculateDiscountPercentage,
  calculateOfferPrice,
  colourNameToHex,
  formatWithCommasWithDecimal,
  formatWithCommasWithoutDecimal,
} from "../../utils/utils";

function SelectDesktopVariant({
  setOpenDesktopVariant,
  openDesktopVariant,
  product,
  handleMouseEnter,
  handleMouseLeave,
  qtys,
}) {
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectColor, setSelectColor] = useState(null);
  const cart = useSelector(selectAllCarts);
  const preOrder = useSelector(selectAllPreorders);
  // console.log(cart);
  const [qty, setQty] = useState(0); // Initialize quantity to 1
  const [inputQty, setInputQty] = useState("0"); // Input field value
  const variations = useMemo(() => product?.skuDetails || [], [product]);
  const [selectedProduct, setSelectedProduct] = useState(
    [product?.skuDetails[0]] || []
  );
  // console.log(selectedProduct);
  // console.log(product, qtys);
  const [isCartValueZero, setIsCartValueZero] = useState(false);
  const mainQty = useSelector(selectVarient).quantity;
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  // console.log(qtys);
  // console.log(useSelector(selectVarient));
  // console.log(selectedProduct);
  // console.log(selectedProduct[0]);

  // For ADMIN ONLY
  const allCartsUser = useSelector(selectAllAdminUserCart);
  const allUserCartsItems = useSelector(selectAllAdminUserCarts);
  const [selectedUser, setSelectedUser] = useState(userDetail?.UserID || null);

  const handleSelectChange = (event) => {
    handleOpen();
    setSelectedUser(event.target.value);
  };
  // console.log(
  //   "ALL_CARTS_USER",
  //   allCartsUser,
  //   "ALL_USERS_CARTS_ITEMS",
  //   allUserCartsItems
  // );

  useEffect(() => {
    if (userDetail?.UserID && ADMIN_USER?.includes(userDetail?.UserID)) {
      handleOpen();
      dispatch(fetchAllAdminUsersCartsAsync()).then(() => {
        handleClose();
      });
    }
  }, [dispatch, userDetail?.UserID]);

  // Use useEffect to trigger the function when selectedUser changes
  useEffect(() => {
    const fetchOrders = async () => {
      if (selectedUser) {
        await dispatch(fetchAllUserCartsAsync(selectedUser)).then(() => {
          handleClose();
        });
      }
    };
    fetchOrders();
  }, [dispatch, selectedUser]);

  useEffect(() => {
    setSelectedProduct([product?.skuDetails[0]] || []);
  }, [product, setSelectedProduct]);

  useEffect(() => {
    if (userDetail) {
      dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail]);

  const handleIncreaseQty = () => {
    // console.log(selectedProduct[0]?.Bundle);
    const bundleSize = parseInt(product && product?.Bundle);
    if (product && product?.manufacture_name === "ESI") {
      setQty(qty + bundleSize);
      setInputQty(String(qty + bundleSize));
    } else {
      const updateqty = parseInt(
        mainQty % parseInt(product && product?.Bundle)
      );
      const newQuantity = Math.min(qty + bundleSize, mainQty - updateqty || 0);
      setQty(newQuantity);
      setInputQty(String(newQuantity));
    }
  };

  const handleDecreaseQty = () => {
    const bundleSize = parseInt(product && product?.Bundle);
    const newQuantity = Math.max(qty - bundleSize, 0);
    setQty(newQuantity);
    setInputQty(String(newQuantity));
  };

  const handleInputChange = (event) => {
    const input = event.target.value;
    setInputQty(input);

    if (product && product?.manufacture_name === "ESI") {
      setQty(input);
    } else {
      // Validate and update quantity based on the product quantity
      const updateqty = parseInt(
        mainQty % parseInt(product && product?.Bundle)
      );
      const newQuantity = Math.min(
        parseInt(input) || 0,
        mainQty - updateqty || 0
      );
      setQty(newQuantity);
    }
  };

  const handleInputBlur = () => {
    if (product && product?.manufacture_name === "ESI") {
      const bundleSize = parseInt(product && product?.Bundle);
      let newQuantity = qty || 0;
      // Adjust new quantity to be a multiple of the bundle size
      newQuantity = Math.floor(newQuantity / bundleSize) * bundleSize;
      setQty(newQuantity);
      setInputQty(String(newQuantity));
    } else {
      // If the user enters a quantity beyond the product quantity, adjust it

      const bundleSize = parseInt(product && product?.Bundle);
      let newQuantity = Math.min(qty, mainQty || 0);
      // Adjust new quantity to be a multiple of the bundle size
      newQuantity = Math.floor(newQuantity / bundleSize) * bundleSize;
      setQty(newQuantity);
      setInputQty(String(newQuantity));
    }
  };

  // Initialize selectedSize and selectColor with the most available size and color
  useEffect(() => {
    const mostAvailableSize = variations.reduce((acc, variant) => {
      if (!acc || findqty(variant.SKU_ID, qtys) > findqty(acc.SKU_ID, qtys)) {
        return variant.size_name;
      }
      return acc;
    }, null);

    const mostAvailableColor = variations.reduce((acc, variant) => {
      if (!acc || findqty(variant.SKU_ID, qtys) > findqty(acc.SKU_ID, qtys)) {
        return variant.colour_name;
      }
      return acc;
    }, null);

    // console.log(mostAvailableColor,mostAvailableSize);
    // setSelectedSize(mostAvailableSize);
    // setSelectColor(mostAvailableColor);
  }, [variations, qtys]);

  useEffect(() => {
    if (product && product.prod_ID !== null) {
      dispatch(setSalePrice(product.skuDetails[0].NDP_Price));
      dispatch(setQuantity(findqty(product.skuDetails[0].SKU_ID, qtys)));
      dispatch(setProductID(product.prod_ID));
    }
    if (product && product?.skuDetails?.length === 1) {
      setSelectedSize(product?.skuDetails[0]?.size_name);
      setSelectColor(product?.skuDetails[0]?.colour_name);
    }
  }, [dispatch, product]);

  useEffect(() => {
    // console.log("Entering useEffect");
    const sizes = [...new Set(variations.map((variant) => variant.size_name))];
    const colors = [
      ...new Set(variations.map((variant) => variant.colour_name)),
    ];

    // console.log("Setting available sizes, colors, variants");
    setAvailableSizes(sizes);
    setAvailableColors(colors);
  }, [variations]);

  useEffect(() => {
    let filteredSizes = [];
    let filteredColors = [];

    if (!selectedSize && !selectColor) {
      filteredSizes = [
        ...new Set(variations.map((variant) => variant.size_name)),
      ];
      filteredColors = [
        ...new Set(variations.map((variant) => variant.colour_name)),
      ];
      setSelectedProduct([variations[0]]);
    } else {
      if (selectedSize && selectColor) {
        const selectedSku = variations.find(
          (variant) =>
            variant.size_name === selectedSize &&
            variant.colour_name === selectColor
        );

        if (selectedSku) {
          dispatch(setSalePrice(selectedSku?.Sale_Price));
          dispatch(setQuantity(findqty(selectedSku?.SKU_ID, qtys)));
          const selectProduct = variations.filter(
            (variant) =>
              variant.size_name === selectedSize &&
              variant.colour_name === selectColor
          );
          setSelectedProduct(selectProduct);
          if (qty > findqty(selectedSku?.SKU_ID, qtys)) {
            setQty(findqty(selectedSku?.SKU_ID, qtys));
          }
        }
      } else if (selectedSize && !selectColor) {
        const selectedSku = variations.find(
          (variant) => variant.size_name === selectedSize
        );

        if (selectedSku) {
          dispatch(setSalePrice(selectedSku?.Sale_Price));
          dispatch(setQuantity(findqty(selectedSku?.SKU_ID, qtys)));
          const selectProduct = variations.filter(
            (variant) => variant.size_name === selectedSize
          );
          setSelectedProduct(selectProduct);
          if (qty > findqty(selectedSku?.SKU_ID, qtys)) {
            setQty(findqty(selectedSku?.SKU_ID, qtys));
          }
        }
      } else if (!selectedSize && selectColor) {
        const selectedSku = variations.find(
          (variant) => variant.colour_name === selectColor
        );

        if (selectedSku) {
          dispatch(setSalePrice(selectedSku?.Sale_Price));
          dispatch(setQuantity(findqty(selectedSku?.SKU_ID, qtys)));
          const selectProduct = variations.filter(
            (variant) => variant.colour_name === selectColor
          );
          setSelectedProduct(selectProduct);
          if (qty > findqty(selectedSku?.SKU_ID, qtys)) {
            setQty(findqty(selectedSku?.SKU_ID, qtys));
          }
        }
      }

      filteredSizes = availableSizes;
      filteredColors = availableColors;

      if (selectedSize) {
        filteredColors = variations
          .filter((variant) => variant.size_name === selectedSize)
          .map((variant) => variant.colour_name);
      }

      if (selectColor) {
        filteredSizes = variations
          .filter((variant) => variant.colour_name === selectColor)
          .map((variant) => variant.size_name);
      }
    }

    setAvailableSizes([...new Set(filteredSizes)]);
    setAvailableColors([...new Set(filteredColors)]);
  }, [selectedSize, selectColor, variations]);

  const handleSelectSize = (size) => {
    setSelectedSize(size);
  };

  const handleSelectColor = (color) => {
    setSelectColor(color);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the container
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setOpenDesktopVariant(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (qty > 0) {
      setIsCartValueZero(false);
    }
  }, [qty]);

  // ADD TO CART
  const addToCart = (data, selectedQty, e) => {
    e.preventDefault();
    if (
      availableSizes &&
      availableSizes[0] !== null &&
      availableColors &&
      availableColors[0] !== null
    ) {
      if (selectedSize && selectColor) {
        handleOpen();
        let cartData = {
          userID:
            userDetail?.UserID && ADMIN_USER?.includes(userDetail?.UserID) && selectedUser !== null
              ? selectedUser
              : userDetail?.UserID,
          SKU: selectedProduct[0].SKU_ID,
          buyQty: selectedQty === 0 ? product?.Bundle : selectedQty,
        };
        if (selectedQty >= 1) {
          dispatch(addToCartsAsync(cartData)).then(() => {
            dispatch(fetchAllCartsAsync(userDetail?.UserID)).then(() => {
              if (userDetail?.UserID && ADMIN_USER?.includes(userDetail?.UserID) && selectedUser !== null) {
                dispatch(fetchAllUserCartsAsync(selectedUser));
              }
              handleClose();
            });
          });
        } else if (selectedQty === 0) {
          setIsCartValueZero(true);
          handleClose();
        } else {
          handleClose();
        }
      } else {
        return;
      }
    } else if (
      availableSizes &&
      availableSizes[0] !== null &&
      availableColors[0] === null
    ) {
      if (selectedSize !== null) {
        handleOpen();
        let cartData = {
          userID:
            userDetail?.UserID && ADMIN_USER?.includes(userDetail?.UserID) && selectedUser !== null
              ? selectedUser
              : userDetail?.UserID,
          SKU: selectedProduct[0].SKU_ID,
          buyQty: selectedQty === 0 ? product?.Bundle : selectedQty,
        };
        if (selectedQty >= 1) {
          dispatch(addToCartsAsync(cartData)).then(() => {
            dispatch(fetchAllCartsAsync(userDetail?.UserID)).then(() => {
              if (userDetail?.UserID && ADMIN_USER?.includes(userDetail?.UserID) && selectedUser !== null) {
                dispatch(fetchAllUserCartsAsync(selectedUser));
              }
              handleClose();
            });
          });
        } else if (selectedQty === 0) {
          setIsCartValueZero(true);
          handleClose();
        } else {
          handleClose();
        }
      }
    } else if (
      availableSizes &&
      availableSizes[0] === null &&
      availableColors &&
      availableColors[0] !== null
    ) {
      if (selectColor) {
        handleOpen();
        let cartData = {
          userID:
            userDetail?.UserID && ADMIN_USER?.includes(userDetail?.UserID) && selectedUser !== null
              ? selectedUser
              : userDetail?.UserID,
          SKU: selectedProduct[0].SKU_ID,
          buyQty: selectedQty === 0 ? product?.Bundle : selectedQty,
        };
        if (selectedQty >= 1) {
          dispatch(addToCartsAsync(cartData)).then(() => {
            dispatch(fetchAllCartsAsync(userDetail?.UserID)).then(() => {
              if (userDetail?.UserID && ADMIN_USER?.includes(userDetail?.UserID) && selectedUser !== null) {
                dispatch(fetchAllUserCartsAsync(selectedUser));
              }
              handleClose();
            });
          });
        } else if (selectedQty === 0) {
          setIsCartValueZero(true);
          handleClose();
        } else {
          handleClose();
        }
      }
    } else {
      return;
    }
  };

  const handleBuyNow = (data, selectedQty, e) => {
    if (isInCart()) {
      navigate("/carts");
    } else {
      e.preventDefault();

      if (
        availableSizes &&
        availableSizes[0] !== null &&
        availableColors &&
        availableColors[0] !== null
      ) {
        if (selectedSize && selectColor) {
          handleOpen();
          let cartData = {
            userID: userDetail?.UserID,
            SKU: selectedProduct[0].SKU_ID,
            buyQty: selectedQty === 0 ? product?.Bundle : selectedQty,
          };
          if (selectedQty >= 1) {
            dispatch(addToCartsAsync(cartData)).then(() => {
              dispatch(fetchAllCartsAsync(userDetail?.UserID)).then(() => {
                handleClose();
                navigate("/carts");
              });
            });
          } else if (selectedQty === 0) {
            setIsCartValueZero(true);
            handleClose();
          } else {
            handleClose();
          }
        } else {
          return;
        }
      } else if (
        availableSizes &&
        availableSizes[0] !== null &&
        availableColors[0] === null
      ) {
        if (selectedSize !== null) {
          handleOpen();
          let cartData = {
            userID: userDetail?.UserID,
            SKU: selectedProduct[0].SKU_ID,
            buyQty: selectedQty === 0 ? product?.Bundle : selectedQty,
          };
          if (selectedQty >= 1) {
            dispatch(addToCartsAsync(cartData)).then(() => {
              dispatch(fetchAllCartsAsync(userDetail?.UserID)).then(() => {
                handleClose();
                navigate("/carts");
              });
            });
          } else if (selectedQty === 0) {
            setIsCartValueZero(true);
            handleClose();
          } else {
            handleClose();
          }
        }
      } else if (
        availableSizes &&
        availableSizes[0] === null &&
        availableColors &&
        availableColors[0] !== null
      ) {
        if (selectColor) {
          handleOpen();
          let cartData = {
            userID: userDetail?.UserID,
            SKU: selectedProduct[0].SKU_ID,
            buyQty: selectedQty === 0 ? product?.Bundle : selectedQty,
          };
          if (selectedQty >= 1) {
            dispatch(addToCartsAsync(cartData)).then(() => {
              dispatch(fetchAllCartsAsync(userDetail?.UserID)).then(() => {
                handleClose();
                navigate("/carts");
              });
            });
          } else if (selectedQty === 0) {
            setIsCartValueZero(true);
            handleClose();
          } else {
            handleClose();
          }
        }
      } else {
        return;
      }
    }
  };

  // Function to check if a product is in the cart
  const isInCart = () => {
    // console.log(cart,selectedProduct[0]?.SKU_ID)
    if (userDetail?.UserID && ADMIN_USER?.includes(userDetail?.UserID)) {
      return (
        Array.isArray(allUserCartsItems) &&
        allUserCartsItems?.some(
          (item) => item?.SKU_ID === selectedProduct[0]?.SKU_ID
        )
      );
    } else {
      return (
        Array.isArray(cart) &&
        cart?.some(
          (item) => item?.skuDetails?.SKU_ID === selectedProduct[0]?.SKU_ID
        )
      );
    }
  };

  // Function to check if a product is in the pre order
  const isInPreOrder = () => {
    return (
      Array.isArray(preOrder) &&
      preOrder?.some(
        (item) =>
          parseInt(item?.SKU_ID) === parseInt(selectedProduct[0]?.SKU_ID)
      )
    );
  };

  // REMOVE FROM CART
  const removeFromCart = () => {
    handleOpen();
    let cartData = {
      userID:
        userDetail?.UserID && ADMIN_USER?.includes(userDetail?.UserID) && selectedUser !== null
          ? selectedUser
          : userDetail?.UserID,
      SKU: selectedProduct[0]?.SKU_ID,
    };
    dispatch(removeFromCartsAsync(cartData)).then(() => {
      dispatch(fetchAllCartsAsync(userDetail?.UserID)).then(() => {
        if (userDetail?.UserID && ADMIN_USER?.includes(userDetail?.UserID) && selectedUser !== null) {
          dispatch(fetchAllUserCartsAsync(selectedUser));
        }
        handleClose();
      });
    });
  };

  const addToPreBook = (data, selectedQty, e) => {
    e.preventDefault();
    if (
      availableSizes &&
      availableSizes[0] !== null &&
      availableColors &&
      availableColors[0] !== null
    ) {
      if (selectedSize && selectColor) {
        handleOpen();
        let preOrderData = {
          UserID: userDetail?.UserID,
          SKU_ID: selectedProduct[0].SKU_ID,
          quantity: selectedQty === 0 ? product?.Bundle : selectedQty,
        };
        if (selectedQty >= 1) {
          dispatch(addToPreorderCartsAsync(preOrderData)).then(() => {
            dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID)).then(
              () => {
                handleClose();
              }
            );
          });
        } else if (selectedQty === 0) {
          setIsCartValueZero(true);
          handleClose();
        } else {
          handleClose();
        }
      } else {
        return;
      }
    } else if (
      availableSizes &&
      availableSizes[0] !== null &&
      availableColors[0] === null
    ) {
      if (selectedSize !== null) {
        handleOpen();
        let preOrderData = {
          UserID: userDetail?.UserID,
          SKU_ID: selectedProduct[0].SKU_ID,
          quantity: selectedQty === 0 ? product?.Bundle : selectedQty,
        };
        if (selectedQty >= 1) {
          dispatch(addToPreorderCartsAsync(preOrderData)).then(() => {
            dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID)).then(
              () => {
                handleClose();
              }
            );
          });
        } else if (selectedQty === 0) {
          setIsCartValueZero(true);
          handleClose();
        } else {
          handleClose();
        }
      }
    } else if (
      availableSizes &&
      availableSizes[0] === null &&
      availableColors &&
      availableColors[0] !== null
    ) {
      if (selectColor) {
        handleOpen();
        let preOrderData = {
          UserID: userDetail?.UserID,
          SKU_ID: selectedProduct[0].SKU_ID,
          quantity: selectedQty === 0 ? product?.Bundle : selectedQty,
        };
        if (selectedQty >= 1) {
          dispatch(addToPreorderCartsAsync(preOrderData)).then(() => {
            dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID)).then(
              () => {
                handleClose();
              }
            );
          });
        } else if (selectedQty === 0) {
          setIsCartValueZero(true);
          handleClose();
        } else {
          handleClose();
        }
      }
    } else {
      return;
    }
  };
  const removeFromPreBook = () => {
    handleOpen();
    dispatch(removeFromPreorderCartsAsync(selectedProduct[0]?.SKU_ID)).then(
      () => {
        dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID)).then(() => {
          handleClose();
        });
      }
    );
  };

  const handlenavigate = (prod_id) => {
    navigate(`/product/${prod_id}`);
  };
  // console.log(product);

  useEffect(() => {
    handleMouseEnter();
  }, [openDesktopVariant, handleMouseEnter]);

  return (
    <div className="open-desktop-variant-container" ref={containerRef}>
      <div className="overlay"></div>
      <Slide right>
        <div
          className={`desktop-select-variant-bottom-container ${
            openDesktopVariant && "variant-active"
          }`}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open} // Show backdrop when either open or filtering state is true
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="desktop-select-variant-bottom-header-div">
            <span>Select Variant</span>
            <CancelIcon onClick={() => setOpenDesktopVariant(null)} />
          </div>
          <div className="desktop-select-variant-bottom-product-details-container">
            <div
              className="desktop-select-variant-bottom-product-img-div"
              onClick={() => handlenavigate(product.prod_ID)}
            >
              <LazyLoadImage
                src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                  product?.images[0] && product?.images[0]
                }`}
                alt=""
                effect="blur"
                wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
                }}
              />
            </div>
            <div className="desktop-select-variant-bottom-product-details-div">
              {product && product?.manufacture_images ? (
                <LazyLoadImage
                  className="desktop-select-variant-bottom-brand-img"
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                    product && product?.manufacture_images
                  }`}
                  alt=""
                  effect="blur"
                  wrapperProps={{
                    // If you need to, you can tweak the effect transition using the wrapper style.
                    style: { transitionDelay: "1s" },
                  }}
                />
              ) : (
                <span className="desktop-select-variant-bottom-brand-name">
                  By: {product?.manufacture_name && product?.manufacture_name}
                </span>
              )}
              {product?.manufacture_name === "ESI" && (
                <LazyLoadImage
                  src={commingSoonLogo}
                  alt=""
                  className="desktop-select-variant-comming-soon"
                  effect="blur"
                  wrapperProps={{
                    // If you need to, you can tweak the effect transition using the wrapper style.
                    style: { transitionDelay: "1s" },
                  }}
                />
              )}
              <span className="desktop-select-variant-bottom-product-name">
                {selectedProduct[0] &&
                  selectedProduct[0]?.prod_name &&
                  selectedProduct[0]?.prod_name}
              </span>
              <div className="desktop-select-variant-bottom-price-container">
                <div className="desktop-select-variant-bottom-mrp-discount-price-div">
                  <span className="desktop-select-variant-bottom-sale-price">
                    Dealer Price -{" "}
                    <b
                      style={{
                        textDecoration:
                          selectedProduct[0]?.Offer_price &&
                          selectedProduct[0]?.Offer_price !== null &&
                          "line-through",
                        fontSize:
                          selectedProduct[0]?.Offer_price &&
                          selectedProduct[0]?.Offer_price !== null &&
                          "10px",
                      }}
                    >
                      ₹
                      {selectedProduct[0] &&
                        selectedProduct[0]?.NDP_Price &&
                        formatWithCommasWithoutDecimal(
                          calculateDealerPrice(
                            parseInt(
                              selectedProduct[0] &&
                                selectedProduct[0]?.MRP_price
                            ),
                            parseInt(
                              selectedProduct[0] &&
                                selectedProduct[0]?.NDP_Price
                            ),
                            product && product?.GST
                          )
                        )}
                    </b>
                  </span>
                  {selectedProduct[0]?.Offer_price &&
                    selectedProduct[0].Offer_price !== null && (
                      <span className="desktop-select-variant-bottom-offer-price">
                        ₹
                        {formatWithCommasWithoutDecimal(
                          calculateOfferPrice(
                            parseInt(
                              selectedProduct[0] &&
                                selectedProduct[0]?.MRP_price
                            ),
                            parseInt(
                              selectedProduct[0] &&
                                selectedProduct[0]?.Offer_price
                            ),
                            product && product?.GST
                          )
                        )}
                      </span>
                    )}
                </div>
                <span className="desktop-select-variant-bottom-mrp-price">
                  Product MRP - ₹
                  {selectedProduct[0] &&
                    selectedProduct[0]?.MRP_price &&
                    formatWithCommasWithDecimal(
                      selectedProduct[0] && selectedProduct[0]?.MRP_price
                    )}
                </span>
                {selectedProduct[0]?.Offer_price &&
                selectedProduct[0].Offer_price !== null ? (
                  <span className="desktop-select-variant-bottom-discount-percentage">
                    {calculateDiscountPercentage(
                      selectedProduct[0] && selectedProduct[0]?.MRP_price,
                      calculateOfferPrice(
                        parseInt(
                          selectedProduct[0] && selectedProduct[0]?.MRP_price
                        ),
                        parseInt(
                          selectedProduct[0] && selectedProduct[0]?.Offer_price
                        ),
                        product && product?.GST
                      )
                    )}
                    % Dealer Margin
                  </span>
                ) : (
                  <span className="desktop-select-variant-bottom-discount-percentage">
                    {calculateDiscountPercentage(
                      selectedProduct[0] && selectedProduct[0]?.MRP_price,
                      calculateOfferPrice(
                        parseInt(
                          selectedProduct[0] && selectedProduct[0]?.MRP_price
                        ),
                        parseInt(
                          selectedProduct[0] && selectedProduct[0]?.NDP_Price
                        ),
                        product && product?.GST
                      )
                    )}
                    % Dealer Margin
                  </span>
                )}
              </div>
              <div className="desktop-select-variant-bottom-product-qty-div">
                <div
                  className="desktop-select-variant-bottom-qty-decrease-btn"
                  style={{
                    background:
                      inputQty <= product?.Bundle
                        ? "rgb(165, 157, 159)"
                        : "var(--color-blue)",
                  }}
                  onClick={handleDecreaseQty}
                >
                  <RemoveIcon />
                </div>
                <input
                  type="text"
                  value={inputQty}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  onKeyPress={(e) => {
                    // Allow only numbers (0-9) and backspace (8)
                    if (!/[0-9\b]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  style={{ border: isCartValueZero ? "1px solid red" : "" }}
                  className="desktop-select-variant-bottom-qty-amount"
                />

                <div
                  className="desktop-select-variant-bottom-qty-increase-btn"
                  style={{
                    background:
                      inputQty >= findqty(product.skuDetails[0].SKU_ID, qtys) &&
                      product?.manufacture_name !== "ESI"
                        ? "rgb(165, 157, 159)"
                        : "var(--color-blue)",
                  }}
                  onClick={handleIncreaseQty}
                >
                  <AddIcon />
                </div>

                {findqty(product.skuDetails[0].SKU_ID, qtys) <=
                  product?.Bundle && (
                  <span className="desktop-variant-out-of-stock">
                    Out of Stock
                  </span>
                )}
              </div>
            </div>
          </div>

          {product && product?.Advantages && (
            <div className="desktop-select-variant-bottom-advantage-div">
              <div className="adv-user-wrapper">
                <span className="desktop-select-variant-bottom-adv-heading">
                  Advantages
                </span>
                {userDetail?.UserID && ADMIN_USER?.includes(userDetail?.UserID) && (
                  <select
                    className="admin-select-user"
                    name="select-user"
                    id="select-user"
                    onChange={handleSelectChange}
                  >
                    {allCartsUser &&
                      Array?.isArray(allCartsUser) &&
                      allCartsUser?.map((user) => (
                        <option
                          value={user?.UserID}
                          key={user?.UserID}
                          selected={userDetail?.UserID === user?.UserID}
                        >
                          {user?.business_name
                            ? user?.business_name
                            : user?.FirstName}
                        </option>
                      ))}
                  </select>
                )}
              </div>
              <div className="desktop-select-variant-bottom-adv-main-container">
                {product?.Advantages?.Advantage1 && (
                  <span className="desktop-select-variant-bottom-adv-each-div">
                    <LazyLoadImage
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_ADVANTAGE/${product?.Advantages?.Image1}`}
                      alt=""
                      effect="blur"
                      wrapperProps={{
                        // If you need to, you can tweak the effect transition using the wrapper style.
                        style: { transitionDelay: "1s" },
                      }}
                    />
                    <span>{product?.Advantages?.Advantage1}</span>
                  </span>
                )}
                {product?.Advantages?.Advantage2 && (
                  <span className="desktop-select-variant-bottom-adv-each-div">
                    <LazyLoadImage
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_ADVANTAGE/${product?.Advantages?.Image2}`}
                      alt=""
                      effect="blur"
                      wrapperProps={{
                        // If you need to, you can tweak the effect transition using the wrapper style.
                        style: { transitionDelay: "1s" },
                      }}
                    />
                    <span>{product?.Advantages?.Advantage2}</span>
                  </span>
                )}
                {product?.Advantages?.Advantage3 && (
                  <span className="desktop-select-variant-bottom-adv-each-div">
                    <LazyLoadImage
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_ADVANTAGE/${product?.Advantages?.Image3}`}
                      alt=""
                      effect="blur"
                      wrapperProps={{
                        // If you need to, you can tweak the effect transition using the wrapper style.
                        style: { transitionDelay: "1s" },
                      }}
                    />
                    <span>{product?.Advantages?.Advantage3}</span>
                  </span>
                )}
                {product?.Advantages?.Advantage4 && (
                  <span className="desktop-select-variant-bottom-adv-each-div">
                    <LazyLoadImage
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_ADVANTAGE/${product?.Advantages?.Image4}`}
                      alt=""
                      effect="blur"
                      wrapperProps={{
                        // If you need to, you can tweak the effect transition using the wrapper style.
                        style: { transitionDelay: "1s" },
                      }}
                    />
                    <span>{product?.Advantages?.Advantage4}</span>
                  </span>
                )}
              </div>
            </div>
          )}

          <div className="desktop-select-variant-bottom-product-variant-container">
            {availableSizes &&
              availableSizes[0] !== null &&
              availableSizes?.length > 0 && (
                <div className="desktop-select-variant-bottom-product-variant-select-size-div">
                  <span className="desktop-select-variant-bottom-product-variant-select-size-heading">
                    Choose Size
                  </span>
                  {availableSizes && availableSizes[0] !== undefined && (
                    <div className="desktop-selct-variant-bottom-product-variant-select-size-option-div">
                      {availableSizes &&
                        availableSizes?.map((size) => (
                          <span
                            key={size}
                            onClick={() => handleSelectSize(size)}
                            className={`desktop-select-variant-bottom-product-variant-select-size-option-span ${
                              selectedSize === size
                                ? "variant-option-selected"
                                : ""
                            }`}
                          >
                            {size}
                          </span>
                        ))}
                    </div>
                  )}
                </div>
              )}
            {availableColors &&
              availableColors[0] !== null &&
              availableColors?.length > 0 && (
                <div className="desktop-select-variant-bottom-product-variant-select-color-div">
                  <span className="desktop-select-variant-bottom-product-variant-select-color-heading">
                    Choose Colour
                  </span>
                  {availableColors && availableColors[0] !== undefined && (
                    <div className="desktop-selct-variant-bottom-product-variant-select-color-option-div">
                      {/* {availableColors.map((color) => (
                      <span
                        key={color}
                        onClick={() => handleSelectColor(color)}
                        className={`desktop-select-variant-bottom-product-variant-select-color-option-span ${
                          color === selectColor ? "variant-option-selected" : ""
                        }`}
                      >
                        Grey
                      </span>
                    ))} */}
                      {availableColors.map((color) => (
                        <div
                          key={color}
                          className={`desktop-select-variant-bottom-product-color-div ${
                            color === selectColor ? "active" : ""
                          }`}
                          onClick={() => {
                            handleSelectColor(color);
                          }}
                        >
                          <button
                            className={color === selectColor ? "active" : ""}
                            style={{ background: colourNameToHex(color) }}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
          </div>

          <div className="desktop-select-variant-bottom-product-variant-select-continue-buy-btn">
            {(inputQty <= 0 ||
              (availableSizes && availableSizes[0] !== null && !selectedSize) ||
              (availableColors &&
                availableColors[0] !== null &&
                !selectColor)) && (
              <div className="desktop-show-message-for-variant-part">
                <sub style={{ marginTop: "-6px" }}>★</sub>Please select{" "}
                {inputQty <= 0 && <span>&nbsp;quantity</span>}{" "}
                {availableSizes &&
                  availableSizes[0] !== null &&
                  !selectedSize && <span>, size</span>}{" "}
                {availableColors &&
                  availableColors[0] !== null &&
                  !selectColor && <span>, color</span>}
              </div>
            )}
            {product?.manufacture_name === "ESI" ? (
              <div className="desktop-prebook-btn-container">
                {isInPreOrder() ? (
                  <span className="desktop-prebook-remove-btn">
                    <AddTaskOutlinedIcon />
                    Pre-Booked
                  </span>
                ) : (
                  <span
                    className="desktop-prebook-book-btn"
                    onClick={(e) => addToPreBook(product, qty, e)}
                  >
                    Pre-Book - At no advance cost
                  </span>
                )}
              </div>
            ) : (
              <>
                <div
                  className="desktop-select-variant-bottom-product-variant-select-continue-btn"
                  onClick={(e) =>
                    isInCart() ? removeFromCart() : addToCart(product, qty, e)
                  }
                >
                  {isInCart() ? (
                    <span>Added</span>
                  ) : (
                    <div
                    // onClick={(e) => {
                    //   addToCart(product, qty, e);
                    //   // setOpenDesktopVariant(null);
                    // }}
                    >
                      <ShoppingCartOutlinedIcon />
                      <span>Add to Cart</span>
                    </div>
                  )}
                </div>
                <div
                  className="desktop-select-variant-bottom-product-variant-select-buy-now-btn"
                  onClick={(e) => {
                    handleBuyNow(product, qty, e);
                  }}
                >
                  <ShoppingBagOutlinedIcon />
                  <span>Buy Now</span>
                </div>
              </>
            )}
          </div>
        </div>
      </Slide>
    </div>
  );
}

export default SelectDesktopVariant;
