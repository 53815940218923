import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./MobileBottomNavigation.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ViewStreamOutlinedIcon from "@mui/icons-material/ViewStreamOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import { connect, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import verticalIcon from "../../img/mobileIcon/Button-Category.png";
import {
  selectIsOverlayVisible,
  toggleOverlay,
} from "../../store/slices/headerSlice";
import MobileSideBar from "./MobileSideBar";
import MobileBigSearch from "./MobileBigSearch";
import MobileBigNavigation from "./MobileBigNavigation";

function MobileBottomNavigation({ isOverlayVisible, toggleOverlay }) {
  const [openBurger, setOpenBurger] = useState(false);
  const [showNavigation, setShowNavigation] = useState(null);
  const [bigSearch, setBigSearch] = useState(false);
  const userDetail = useSelector(selectUser);
  const navigate = useNavigate();
  const location = useLocation();

  const isCurrentPath = (path) => {
    return location.pathname === path;
  };
  const handleHome = (path) => {
    navigate(`/`);
  };
  const handleCart = (path) => {
    navigate(`/${path}`);
  };
  const handleNavigate = (path) => {
    if(path === "search"){
      return;
    }
    navigate(`/user/${path}`);
  };
  return (
    <div className="mobile-bottom-navigation-container">
      <div
        className="mobile-bottom-each-navigation"
        onClick={() => handleHome("/")}
      >
        <HomeOutlinedIcon
          style={{
            color: isCurrentPath("/")
              ? "var(--color-peach)"
              : "rgba(0,0,0,0.9)",
          }}
        />
        <span
          className="mobile-bottom-each-nav-name"
          style={{
            color: isCurrentPath("/")
              ? "var(--color-peach)"
              : "rgba(0,0,0,0.9)",
          }}
        >
          Home
        </span>
      </div>
      <div
        className="mobile-bottom-each-navigation"
        onClick={() =>
          Object.keys(userDetail).length
            ? handleNavigate("orders")
            : toggleOverlay()
        }
      >
        <ViewStreamOutlinedIcon
          style={{
            color: isCurrentPath("/user/orders")
              ? "var(--color-peach)"
              : "rgba(0,0,0,0.9)",
          }}
        />
        <span
          className="mobile-bottom-each-nav-name"
          style={{
            color: isCurrentPath("/user/orders")
              ? "var(--color-peach)"
              : "rgba(0,0,0,0.9)",
          }}
        >
          Orders
        </span>
      </div>
      {/* <div
        className="mobile-bottom-each-navigation"
        onClick={() => {
          Object.keys(userDetail).length
            ? handleNavigate("search")
            : toggleOverlay();
          setBigSearch(true);
        }}
      >
        <SearchSharpIcon
          style={{
            color: isCurrentPath("/user/orders")
              ? "var(--color-peach)"
              : "rgba(0,0,0,0.9)",
          }}
        />
        <span
          className="mobile-bottom-each-nav-name"
          style={{
            color: isCurrentPath("/user/orders")
              ? "var(--color-peach)"
              : "rgba(0,0,0,0.9)",
          }}
        >
          Search
        </span>
        {bigSearch && (
          <MobileBigSearch setBigSearch={setBigSearch} bigSearch={bigSearch} />
        )}
      </div> */}
      {showNavigation && <MobileBigNavigation setShowNavigation={setShowNavigation}/>}
      <img
          src={verticalIcon}
          alt=""
          className="mobile-header-vertical-icon"
          onClick={() => setShowNavigation(true)}
        />
      <div
        className="mobile-bottom-each-navigation"
        onClick={() =>
          Object.keys(userDetail).length ? handleCart("carts") : toggleOverlay()
        }
      >
        <ShoppingCartOutlinedIcon
          style={{
            color: isCurrentPath("/carts")
              ? "var(--color-peach)"
              : "rgba(0,0,0,0.9)",
          }}
        />
        <span
          className="mobile-bottom-each-nav-name"
          style={{
            color: isCurrentPath("/carts")
              ? "var(--color-peach)"
              : "rgba(0,0,0,0.9)",
          }}
        >
          Cart
        </span>
      </div>
      <div
        className="mobile-bottom-each-navigation"
        onClick={() => setOpenBurger(true)}
      >
        <AccountCircleOutlinedIcon
          style={{
            color: openBurger ? "var(--color-peach)" : "rgba(0,0,0,0.9)",
          }}
        />
        <span
          className="mobile-bottom-each-nav-name"
          style={{
            color: openBurger ? "var(--color-peach)" : "rgba(0,0,0,0.9)",
          }}
        >
          Profile
        </span>
      </div>
      {openBurger && (
        <MobileSideBar setOpenBurger={setOpenBurger} openBurger={openBurger} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});
const mapDispatchToProps = {
  toggleOverlay,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileBottomNavigation);
