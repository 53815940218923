import React, { useEffect, useState } from "react";
import "./BusinessRegistration.css";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API_BASE_URL } from "../../../../config";
import CheckIcon from "@mui/icons-material/Check";
import DoneIcon from "@mui/icons-material/Done";

function BusinessRegistration({
  updateFinalData,
  finalData,
  GSTVerify,
  setGSTVerify,
}) {
  const [errors, setErrors] = useState({});
  const [gstExists, setGstExists] = useState(false);
  const [verify, setVerify] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    updateFinalData({ [name]: value });
  };



  useEffect(()=>{
    if(finalData?.GST?.length < 15){
      setGSTVerify(false);
      updateFinalData({ ["GST_DATA"]: null });
    }
  },[finalData?.GST?.length]);

  const handleVerifyGST = async () => {
    handleOpen();
    if (!finalData.GST || finalData.GST.length < 15) {
      handleClose();
      Swal.fire({
        title: "Enter GSTIN correctly",
        text: "Please Enter GSTIN correctly",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}gst_verify/${finalData.GST}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (data.GST.message === "GSTIN Exists") {
          setGSTVerify(true);
          setVerify(true);
          updateFinalData({ GST_DATA: data.GST });
          handleClose();
          // console.log(data);
        } else {
          handleClose();
          Swal.fire({
            title: "GSTIN Doesn't Exist",
            text: "Please check and try again",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        handleClose();
        Swal.fire({
          title: "GSTIN Doesn't Exist",
          text: "Please check and try again",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error(
          "An error occurred during the API request:",
          error.message
        );
      }
    }
  };

  function validateGSTIN(gstin) {
    const regex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    return regex.test(gstin);
  }

  const handleGSTBlur = async (e) => {
    const gst = e.target.value;
    if (!validateGSTIN(gst)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        GST: "Please enter a valid 15-digit GST number",
      }));
      return;
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}singup_gst_verify/${gst}`
      );
      const data = await response.json();

      if (data.status === "error") {
        setGstExists(true);
        setErrors((prevErrors) => ({
          ...prevErrors,
          GST: "GST number already registered with Cyclecircle",
        }));
      } else {
        setGstExists(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          GST: "",
        }));
      }
    } catch (error) {
      console.error("Error checking GST number existence:", error);
    }
  };

  const handleVerifyGSTClose = () => {
    setVerify(false);
  };

  return (
    <div className="business-registration-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <span className="business-registration-heading">
        Business Registration Details
      </span>
      <span className="business-registration-desc">
        We require this information to verify your business
      </span>
      <fieldset className="input-fieldset">
        <legend className="input-legend">Business Registered Name&nbsp;<b>*</b></legend>
        <input
          type="text"
          name="business_name"
          value={finalData?.business_name || ""}
          onChange={handleInputChange}
          placeholder="Ente your business name"
          className="input-type-write"
          required
        />
      </fieldset>
      <div className="basic-detail-form-2-gender">
        <fieldset className="input-fieldset">
          <legend className="input-legend" style={{ marginLeft: "-15px" }}>
            GSTIN&nbsp;<b>*</b>
          </legend>
          <div className="type-radio-each-btn">
            <input
              type="radio"
              name="Have_GSTIN"
              id="Have_GSTIN"
              value="yes"
              checked={finalData?.Have_GSTIN === "yes"}
              onChange={handleInputChange}
            />
            <span className="type-radio-span">I have GSTIN</span>
          </div>
          <div className="type-radio-each-btn">
            <input
              type="radio"
              name="Have_GSTIN"
              id="Have_GSTIN"
              value="no"
              checked={finalData?.Have_GSTIN === "no"}
              onChange={handleInputChange}
            />
            <span className="type-radio-span">I don't have GSTIN</span>
          </div>
        </fieldset>
      </div>
      {finalData?.Have_GSTIN && finalData?.Have_GSTIN === "yes" && (
        <fieldset
          className={`input-fieldset ${
            !errors["GST"] &&
            !gstExists &&
            finalData.GST &&
            validateGSTIN(finalData.GST)
              ? "valid"
              : (errors["GST"] || gstExists) && validateGSTIN(finalData.GST)
              ? "error"
              : ""
          }`}
        >
          <legend className="input-legend">Enter GSTIN Number</legend>
          <input
            type="text"
            name="GST"
            id="GST"
            onBlur={handleGSTBlur}
            value={finalData.GST || ""}
            placeholder="Ex:55XE15VD9823EF5"
            onChange={handleInputChange}
            maxLength="15"
            className="input-type-write"
          />
        </fieldset>
      )}

      {errors["GST"] && finalData?.GST && (
        <span className="error-message">{errors["GST"]}</span>
      )}
      {!GSTVerify && !errors["GST"] &&
        finalData?.GST &&
        finalData?.GST?.length === 15 && (
          <div className="email-verification-container">
            <span
              className="email-verification-verify-btn"
              onClick={handleVerifyGST}
            >
              Verify
            </span>
            <div className="email-verification-text-div">
              <span className="verify-symbol">!</span>
              <span className="email-verification-text">
              Please verify to proceed
              </span>
            </div>
          </div>
        )}
      {GSTVerify && (
        <div className="phone-number-verified-check">
          <CheckIcon />
          <span className="phone-number-verified-span">Verified</span>
        </div>
      )}
      <div className="make-address-default-container" style={{marginTop:"5vh"}}>
        <span className="make-address-default-heading">
          Choose which ever is applicable
        </span>
        <div className="make-detail-default-address-div">
          <input
          type="radio"
          name="applicable"
          id="applicable"
          value="1"
          checked={finalData?.applicable === "1"}
          onChange={handleInputChange}
          />
          My annual turnover is less than GST prescribed limit.
        </div>
        <div className="make-detail-default-address-div">
          <input
           type="radio"
           name="applicable"
           id="applicable"
           value="2"
           checked={finalData?.applicable === "2"}
           onChange={handleInputChange}
            defaultChecked
          />
          My business category is exempted from GST
        </div>
      </div>
     

      {/* {verify && <div className="signup-overlay"></div>} */}
      {GSTVerify && (
        <div className="show-GST-details-after-verify">
          {/* <DoneIcon /> */}
          <h3 className="show-GST-details-after-verify-heading">
            GSTIN is Valid
          </h3>
          <div className="show-GST-details-after-verify-main-container">
            <div className="show-GST-details-after-verify-each-attribute">
              <span className="show-GST-details-after-verify-each-attribute-key">
                GSTIN
              </span>
              <span className="show-GST-details-after-verify-each-attribute-value">
                {finalData?.GST_DATA?.GSTIN}
              </span>
            </div>
            <div className="show-GST-details-after-verify-each-attribute">
              <span className="show-GST-details-after-verify-each-attribute-key">
                Legal Name of Business
              </span>
              <span className="show-GST-details-after-verify-each-attribute-value">
                {finalData?.GST_DATA?.legal_name_of_business}
              </span>
            </div>
            <div className="show-GST-details-after-verify-each-attribute">
              <span className="show-GST-details-after-verify-each-attribute-key">
                GST.Ref.ID
              </span>
              <span className="show-GST-details-after-verify-each-attribute-value">
                {finalData?.GST_DATA?.reference_id}
              </span>
            </div>
            <div className="show-GST-details-after-verify-each-attribute">
              <span className="show-GST-details-after-verify-each-attribute-key">
                Trade Name of Business
              </span>
              <span className="show-GST-details-after-verify-each-attribute-value">
                {finalData?.GST_DATA?.trade_name_of_business}
              </span>
            </div>
            <div className="show-GST-details-after-verify-each-attribute">
              <span className="show-GST-details-after-verify-each-attribute-key">
                Tax payment type
              </span>
              <span className="show-GST-details-after-verify-each-attribute-value">
                {finalData?.GST_DATA?.taxpayer_type}
              </span>
            </div>
            <div className="show-GST-details-after-verify-each-attribute">
              <span className="show-GST-details-after-verify-each-attribute-key">
                Date of Registration
              </span>
              <span className="show-GST-details-after-verify-each-attribute-value">
                {finalData?.GST_DATA?.date_of_registration}
              </span>
            </div>
            <div className="show-GST-details-after-verify-each-attribute">
              <span className="show-GST-details-after-verify-each-attribute-key">
                GST Status
              </span>
              <span className="show-GST-details-after-verify-each-attribute-value">
                {finalData?.GST_DATA?.gst_in_status}
              </span>
            </div>
            <div className="show-GST-details-after-verify-each-attribute">
              <span className="show-GST-details-after-verify-each-attribute-key">
                Place Address
              </span>
              <span className="show-GST-details-after-verify-each-attribute-value">
                {finalData?.GST_DATA?.principal_place_address}
              </span>
            </div>
          </div>
          {/* <span
            className="show-GST-details-after-verify-close-btn"
            onClick={handleVerifyGSTClose}
          >
            Close
          </span> */}
        </div>
      )} 
    </div>
  );
}

export default BusinessRegistration;
