import React, { useState, useEffect, useRef } from "react";
import "./OurBrands.css";
import brandVideo from "../../img/banners/brand-video.mp4";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchThroughBrandAsync,
  selectAllCategoryProducts,
} from "../../features/listingPage/listingPageSlice";
import { useNavigate } from "react-router";
import { setSubCategories } from "../../features/listingPage/listingPageSlice";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";
import { selectUser } from "../../features/auth/authSlice";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import bikeProLogo from "../../icons/parts/bike-pro-temp-logo.png";
import sovereignLogo from "../../img/banners/sovereign-logo.png";
import masterLogo from "../../img/banners/proride-logo-1.png";
import prorideLogo from "../../img/banners/proride-logo-2.png";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";


function OurBrands() {
  // const videoRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const listingProduct = useSelector(selectAllCategoryProducts);
  const videoContainerRef = useRef(null);
  const [hoverBrand, setHoverBrand] = useState("cratoni");
  const [playVideo, setPlayVideo] = useState(null);
  const [imgIdx, setImgIdx] = useState(0);

  const handleFetchThroughBrands = (brand) => {
    dispatch(fetchThroughBrandAsync(brand)).then(()=>{
      dispatch(setSubCategories([]));
      dispatch(setSelectedSubcategory(null));
      dispatch(
        setBreadcrumb({
          categoryID: null,
          categoryName: brand,
          subCategoryID: null,
          subCategoryName: "",
          productName: "",
          ProductID: null,
        })
      );
      navigate(`/listing-page/${brand}`);
    });
  };

  const handleFetchThroughBrandsImg = (idx) => {
    switch (idx) {
      case 0:
        navigate(`/listing-page/${"cratoni"}`);
        break;
      case 1:
        navigate(`/listing-page/${"moon sports"}`);
        break;
      case 2:
        navigate(`/listing-page/${"funn"}`);
        break;
      case 3:
        navigate(`/listing-page/${"granite"}`);
        break;
      default:
        break;
    }
  };

  const img = [
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_BRAND_LOGO_IMAGE/1711189761694-Brand%20Banner-Cratoni-1.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_BRAND_LOGO_IMAGE/1711189697427-Brand%20Banner-Moon-1.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_BRAND_LOGO_IMAGE/1711189728130-Brand%20Banner-Funn-3.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_BRAND_LOGO_IMAGE/1711189931064-Brand%20Banner-Granite-3.png",
  ];
  const imgHidden = [
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_BRAND_LOGO_IMAGE/1711189761694-Brand%20Banner-Cratoni-1.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_BRAND_LOGO_IMAGE/1711189697427-Brand%20Banner-Moon-1.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_BRAND_LOGO_IMAGE/1711189728130-Brand%20Banner-Funn-3.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_BRAND_LOGO_IMAGE/1711189931064-Brand%20Banner-Granite-3.png",
  ];
  const handlePlay = (value) => {
    setPlayVideo(value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const nextIdx = (imgIdx + 1) % img.length;
      setImgIdx(nextIdx);
      switch (nextIdx) {
        case 0:
          setHoverBrand("cratoni");
          break;
        case 1:
          setHoverBrand("moon");
          break;
        case 2:
          setHoverBrand("funn");
          break;
        case 3:
          setHoverBrand("granite");
          break;
        default:
          break;
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [imgIdx, img.length]);

  const handleHoverBrand = (brand, idx) => {
    setHoverBrand(brand);
    setImgIdx(idx);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        videoContainerRef.current &&
        !videoContainerRef.current.contains(event.target)
      ) {
        // Click is outside the video container
        // Close the video by setting its state to null
        setPlayVideo(null);
      }
    };

    // Attach event listener to detect clicks outside of the video container
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="our-brands-container">
      <div className="our-exclusive-brands-container">
        <span className="our-exclusive-brands-heading">
          Exclusively on Cyclecircle
        </span>
        <div className="our-exclusive-brands-top-img-container">
          <img
            src={
              Object.keys(userDetail).length > 0
                ? img[imgIdx]
                : imgHidden[imgIdx]
            }
            alt=""
            className="our-exclusive-brands-top-img"
            onClick={() => handleFetchThroughBrandsImg(imgIdx)}
          />
        </div>
        <div className="our-exclusive-brands-logo-navigator-container">
          <div className="our-exclusive-brands-logo-navigator">
            <div
              className={`our-exclusive-brands-each-brand-container ${
                hoverBrand === "cratoni" && "each-brand-selected"
              }`}
              onMouseEnter={() => handleHoverBrand("cratoni", 0)}
              onClick={() => handleFetchThroughBrands("cratoni")}
            >
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQohbUwx79PkQDbs4YO695jU0OXIAmfqRB-0jAxz05cag&s"
                alt=""
                className={`our-exclusive-brands-each-logo ${
                  hoverBrand === "cratoni"
                } && "img-active"`}
              />
              <span className="our-exclusive-brands-each-brand-what-sell">
                Helmets
              </span>
              <span
                className={`our-exclusive-brands-each-brand-offers ${
                  hoverBrand === "cratoni" && "offers-active"
                }`}
              >
                Upto{" "}
                {Object.keys(userDetail).length > 0 ? (
                  "35"
                ) : (
                  <VisibilityOffOutlinedIcon
                    style={{ margin: "0 0.2vw", fontSize: "1vw" }}
                  />
                )}
                % Dealer Margin
              </span>
              {/* {hoverBrand === "cratoni" && ( */}
              <hr
                className={`our-exclusive-brands-each-brand-line ${
                  hoverBrand === "cratoni" && "hr-active"
                }`}
              />
              {/* )} */}
            </div>
            <div
              className={`our-exclusive-brands-each-brand-container ${
                hoverBrand === "moon" && "each-brand-selected"
              }`}
              onMouseEnter={() => handleHoverBrand("moon", 1)}
              onClick={() => handleFetchThroughBrands("moon sports")}
            >
              <img
                src="https://www.handshake.fi/wp-content/uploads/2018/06/logo-moon.png"
                alt=""
                className={`our-exclusive-brands-each-logo ${
                  hoverBrand === "moon"
                } && "img-active"`}
              />
              <span className="our-exclusive-brands-each-brand-what-sell">
                Lights
              </span>
              <span
                className={`our-exclusive-brands-each-brand-offers ${
                  hoverBrand === "moon" && "offers-active"
                }`}
              >
                Upto{" "}
                {Object.keys(userDetail).length > 0 ? (
                  "30"
                ) : (
                  <VisibilityOffOutlinedIcon
                    style={{ margin: "0 0.2vw", fontSize: "1vw" }}
                  />
                )}
                % Dealer Margin
              </span>
              {/* {hoverBrand === "moon" && ( */}
              <hr
                className={`our-exclusive-brands-each-brand-line ${
                  hoverBrand === "moon" && "hr-active"
                }`}
              />
              {/* )} */}
            </div>
            <div
              className={`our-exclusive-brands-each-brand-container ${
                hoverBrand === "funn" && "each-brand-selected"
              }`}
              onMouseEnter={() => handleHoverBrand("funn", 2)}
              onClick={() => handleFetchThroughBrands("funn")}
            >
              <img
                src="https://images.bike24.com/i/mb/0c/67/20/logofunn-3903.jpg"
                alt=""
                className={`our-exclusive-brands-each-logo ${
                  hoverBrand === "funn"
                } && "img-active"`}
              />
              <span className="our-exclusive-brands-each-brand-what-sell">
                Cockpit
              </span>
              <span
                className={`our-exclusive-brands-each-brand-offers ${
                  hoverBrand === "funn" && "offers-active"
                }`}
              >
                Upto{" "}
                {Object.keys(userDetail).length > 0 ? (
                  "30"
                ) : (
                  <VisibilityOffOutlinedIcon
                    style={{ margin: "0 0.2vw", fontSize: "1vw" }}
                  />
                )}
                % Dealer Margin
              </span>
              {/* {hoverBrand === "funn" && ( */}
              <hr
                className={`our-exclusive-brands-each-brand-line ${
                  hoverBrand === "funn" && "hr-active"
                }`}
              />
              {/* )} */}
            </div>
            <div
              className={`our-exclusive-brands-each-brand-container ${
                hoverBrand === "granite" && "each-brand-selected"
              }`}
              onMouseEnter={() => handleHoverBrand("granite", 3)}
              onClick={() => handleFetchThroughBrands("granite")}
            >
              <img
                src="https://www.stifmtb.com/cdn/shop/collections/Granite_logo_WH_1200x1200_13e278a3-8114-419c-94c0-0f6c6a0a86cc_1200x1200.jpg?v=1646754378"
                alt=""
                className={`our-exclusive-brands-each-logo ${
                  hoverBrand === "granite"
                } && "img-active"`}
              />
              <span className="our-exclusive-brands-each-brand-what-sell">
                Tools
              </span>
              <span
                className={`our-exclusive-brands-each-brand-offers ${
                  hoverBrand === "granite" && "offers-active"
                }`}
              >
                Upto{" "}
                {Object.keys(userDetail).length > 0 ? (
                  "30"
                ) : (
                  <VisibilityOffOutlinedIcon
                    style={{ margin: "0 0.2vw", fontSize: "1vw" }}
                  />
                )}
                % Dealer Margin
              </span>
              {/* {hoverBrand === "granite" && ( */}
              <hr
                className={`our-exclusive-brands-each-brand-line ${
                  hoverBrand === "granite" && "hr-active"
                }`}
              />
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      <div className="our-inclusive-brands-container">
        <div className="our-inclusive-brands-heading">
          <span className="our-inclusive-brands-heading-span">
            Vocal4Local : Discover New Indian Brands
          </span>
        </div>
        <div className="our-inclusive-brands-main-brands-container">
          <div
            className="our-inclusive-brands-each-brand-container"
            onMouseEnter={() => handlePlay("proride")}
            onMouseLeave={() => handlePlay(null)}
            onClick={() => handleFetchThroughBrands("proride")}
            ref={videoContainerRef}
          >
            {playVideo === "proride" ? (
              <video
                src={brandVideo}
                autoPlay
                loop
                className="our-inclusive-brands-each-brand-banners"
              ></video>
            ) : (
              <img
                src="https://cdn.mos.cms.futurecdn.net/pUyW3aVBo9VDsVP5ycsDSj.jpg"
                alt=""
                className="our-inclusive-brands-each-brand-banners"
              />
            )}

            <div className="our-inclusive-brands-each-brand-details">
              <div className="our-inclusive-brands-each-brand-logo">
                <img
                  src={prorideLogo}
                  alt=""
                  className="our-inclusive-brands-each-brand-img"
                />
              </div>
              <span className="our-inclusive-brands-each-brand-details-what-sell">
                Grips,Fenders & Bottles
              </span>
              <div className="our-inclusive-brands-each-brand-offers">
                <span className="our-inclusive-brands-each-brand-offers-span">
                  {Object.keys(userDetail).length > 0 ? (
                    "50-85"
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{ margin: "0 0.2vw", fontSize: "1vw" }}
                    />
                  )}
                  % Dealer Margin
                </span>
              </div>
              <div className="our-inclusive-brands-each-brand-shop-now">
                <span
                  className="our-inclusive-brands-each-brand-shop-now-btn"
                  onClick={() => handleFetchThroughBrands("proride")}
                >
                  Shop Now
                </span>
              </div>
            </div>
          </div>
          <div
            className="our-inclusive-brands-each-brand-container"
            onMouseEnter={() => handlePlay("sovereign")}
            onMouseLeave={() => handlePlay(null)}
            onClick={() => handleFetchThroughBrands("sovereign")}
            ref={videoContainerRef}
          >
            {playVideo === "sovereign" ? (
              <video
                src={brandVideo}
                autoPlay
                loop
                className="our-inclusive-brands-each-brand-banners"
              ></video>
            ) : (
              <img
                src="https://w0.peakpx.com/wallpaper/926/811/HD-wallpaper-bike-cafe-cycle-downhill-hornet-motor-motors-mountain-racer.jpg"
                alt=""
                className="our-inclusive-brands-each-brand-banners"
              />
            )}

            <div className="our-inclusive-brands-each-brand-details">
              <div className="our-inclusive-brands-each-brand-logo">
                <img
                  src={sovereignLogo}
                  alt=""
                  className="our-inclusive-brands-each-brand-img"
                />
              </div>
              <span className="our-inclusive-brands-each-brand-details-what-sell">
                Premium Saddles
              </span>
              <div className="our-inclusive-brands-each-brand-offers">
                <span className="our-inclusive-brands-each-brand-offers-span">
                  {Object.keys(userDetail).length > 0 ? (
                    "50-85"
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{ margin: "0 0.2vw", fontSize: "1vw" }}
                    />
                  )}
                  % Dealer Margin
                </span>
              </div>
              <div className="our-inclusive-brands-each-brand-shop-now">
                <span
                  className="our-inclusive-brands-each-brand-shop-now-btn"
                  onClick={() => handleFetchThroughBrands("sovereign")}
                >
                  Shop Now
                </span>
              </div>
            </div>
          </div>
          <div
            className="our-inclusive-brands-each-brand-container"
            onMouseEnter={() => handlePlay("bikepro")}
            onMouseLeave={() => handlePlay(null)}
            onClick={() => handleFetchThroughBrands("bikepro")}
            ref={videoContainerRef}
          >
            {playVideo === "bikepro" ? (
              <video
                src={brandVideo}
                autoPlay
                loop
                className="our-inclusive-brands-each-brand-banners"
              ></video>
            ) : (
              <img
                src="https://c0.wallpaperflare.com/preview/500/768/1015/blue-bike-in-selective-focus-photography.jpg"
                alt=""
                className="our-inclusive-brands-each-brand-banners"
              />
            )}

            <div className="our-inclusive-brands-each-brand-details">
              <div className="our-inclusive-brands-each-brand-logo">
                <img
                  src={bikeProLogo}
                  alt=""
                  className="our-inclusive-brands-each-brand-img"
                />
              </div>
              <span className="our-inclusive-brands-each-brand-details-what-sell">
                Bottom Brackets & Alloy Hubs
              </span>
              <div className="our-inclusive-brands-each-brand-offers">
                <span className="our-inclusive-brands-each-brand-offers-span">
                  {Object.keys(userDetail).length > 0 ? (
                    "50-85"
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{ margin: "0 0.2vw", fontSize: "1vw" }}
                    />
                  )}
                  % Dealer Margin
                </span>
              </div>
              <div className="our-inclusive-brands-each-brand-shop-now">
                <span
                  className="our-inclusive-brands-each-brand-shop-now-btn"
                  onClick={() => handleFetchThroughBrands("bikepro")}
                >
                  Shop Now
                </span>
              </div>
            </div>
          </div>
          <div
            className="our-inclusive-brands-each-brand-container"
            onMouseEnter={() => handlePlay("master")}
            onMouseLeave={() => handlePlay(null)}
            onClick={() => handleFetchThroughBrands("master")}
            ref={videoContainerRef}
          >
            {playVideo === "master" ? (
              <video
                src={brandVideo}
                autoPlay
                loop
                className="our-inclusive-brands-each-brand-banners"
              ></video>
            ) : (
              <img
                src="https://preview.redd.it/nbd-commencal-meta-tr-v0-9d13nkt74aqb1.jpg?width=1080&crop=smart&auto=webp&s=831be7a350083dfee92feedc470a43892382867d"
                alt=""
                className="our-inclusive-brands-each-brand-banners"
              />
            )}

            <div className="our-inclusive-brands-each-brand-details">
              <div className="our-inclusive-brands-each-brand-logo">
                <img
                  src={masterLogo}
                  alt=""
                  className="our-inclusive-brands-each-brand-img"
                />
              </div>
              <span className="our-inclusive-brands-each-brand-details-what-sell">
                Bushless Chains
              </span>
              <div className="our-inclusive-brands-each-brand-offers">
                <span className="our-inclusive-brands-each-brand-offers-span">
                  {Object.keys(userDetail).length > 0 ? (
                    "50-85"
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{ margin: "0 0.2vw", fontSize: "1vw" }}
                    />
                  )}
                  % Dealer Margin
                </span>
              </div>
              <div className="our-inclusive-brands-each-brand-shop-now">
                <span
                  className="our-inclusive-brands-each-brand-shop-now-btn"
                  onClick={() => handleFetchThroughBrands("master")}
                >
                  Shop Now
                </span>
              </div>
            </div>
          </div>
          <div
            className="our-inclusive-brands-each-brand-container"
            onMouseEnter={() => handlePlay("dass")}
            onMouseLeave={() => handlePlay(null)}
            onClick={() => handleFetchThroughBrands("dass")}
            ref={videoContainerRef}
          >
            {playVideo === "dass" ? (
              <video
                src={brandVideo}
                autoPlay
                loop
                className="our-inclusive-brands-each-brand-banners"
              ></video>
            ) : (
              <img
                src="https://images.jdmagicbox.com/comp/def_content/bicycle_dealers/default-bicycle-dealers-1-250.jpg"
                alt=""
                className="our-inclusive-brands-each-brand-banners"
              />
            )}

            <div className="our-inclusive-brands-each-brand-details">
              <div className="our-inclusive-brands-each-brand-logo">
                <img
                  src="https://dassbike.com/assets/img/logo/logo.png"
                  alt=""
                  className="our-inclusive-brands-each-brand-img"
                />
              </div>
              <span className="our-inclusive-brands-each-brand-details-what-sell">
                Brake Parts & Hubs
              </span>
              <div className="our-inclusive-brands-each-brand-offers">
                <span className="our-inclusive-brands-each-brand-offers-span">
                  {Object.keys(userDetail).length > 0 ? (
                    "50-85"
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{ margin: "0 0.2vw", fontSize: "1vw" }}
                    />
                  )}
                  % Dealer Margin
                </span>
              </div>
              <div className="our-inclusive-brands-each-brand-shop-now">
                <span
                  className="our-inclusive-brands-each-brand-shop-now-btn"
                  onClick={() => handleFetchThroughBrands("dass")}
                >
                  Shop Now
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurBrands;
