import React, { useState,useEffect,useRef } from "react";
import "./Coupon.css";
import { services } from "../../data";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import ribbon from "../../img/banners/Ribbon.png";
import illustration from "../../img/banners/Illustration.png";

function Coupon() {
  const [showCoupon, setShowCoupon] = useState(false);
  const couponRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setShowCoupon(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (couponRef.current && !couponRef.current.contains(e.target)) {
        setShowCoupon(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleCoupon = () => {
    setShowCoupon(!showCoupon);
  };
  return (
    <div ref={couponRef} className={`coupon-container ${showCoupon && "coupon-show"}`} >
      <div className="coupon-main-container">
        <div className="coupon-side-container" onClick={handleCoupon}>
          <span className="coupon-side-span">FLAT ₹500 OFF </span>
          <ArrowDropDownOutlinedIcon style={{ fontSize: "2.5vw" }} />
        </div>
        <div className="coupon-inner-container">
          <div className="coupon-upper-container">
            <div className="coupon-avail-container">
              <span className="coupon-avail-heading">Avail Flat</span>
              <span className="coupon-avail-price">500 OFF</span>
            </div>
            <div className="coupon-price-off-banner-container">
              <img src={ribbon} alt="" className="coupon-price-off-banner" />
              <img
                src={illustration}
                alt=""
                className="coupon-illustration-banner"
              />
            </div>
            <div className="coupon-code-container">
              <span className="coupon-code-number">
                Coupon Code: <b>CC312305</b>
              </span>
              <span className="coupon-code-app">
                Applicable on your first order
              </span>
            </div>
            <div className="coupon-sign-up-container">
              <span className="coupon-sign-up-btn">
                Sign up now <KeyboardArrowRightOutlinedIcon />
              </span>
            </div>
          </div>
          <div className="coupon-bottom-container">
            <div className="coupon-bottom-promises-container">
              {services &&
                services.map((data, index) => (
                  <div className="coupon-each-promise-container" key={index}>
                    <img
                      src={data.icon}
                      alt=""
                      className="coupon-each-promises-img"
                    />
                    <span className="coupon-each-promise-span">
                      {data.heading}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Coupon;
