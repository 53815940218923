import React, { useState, useEffect, useRef } from "react";
import "./BasicDetails.css";
import upload_logo from "../../../img/upload_layer.svg";
import CropEasy from "./CropEasy"; // Import CropEasy component
import deleteUploadedImage from "../../../img/mdi-light_delete.svg";
import inspectUploadedImage from "../../../img/gg_search.svg";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { randomFemaleLogo } from "../../../data";
import { randomMaleLogo } from "../../../data";
import { API_BASE_URL } from "../../../config";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from "react-lazy-load-image-component";

function BasicDetails({ updateFinalData, finalData }) {
  const [originalSelectedImage, setOriginalSelectedImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [data, setData] = useState(0);
  const [inspect, setInspect] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedAddressType, setSelectedAddressType] = useState(
    (finalData && finalData?.save_address) || "no"
  );
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (
      finalData &&
      finalData?.USER_IMAGE &&
      finalData.USER_IMAGE.type.startsWith("image/")
    ) {
      const imageUrl = URL.createObjectURL(finalData.USER_IMAGE);
      setSelectedImage(imageUrl);
      setOriginalSelectedImage(imageUrl);
    }
  }, [finalData]);

  const inputRef = useRef(null);

  const importImageAsFile = async (importedImageUrl) => {
    try {
      const blob = await importedImageToBlob(importedImageUrl);
      const file = new File([blob], "image.png", { type: "image/png" });
      updateFinalData({ USER_IMAGE: file });
      updateFinalData({ Document_IMAGE: file });
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      // console.log(selectedImage);
      setOriginalSelectedImage(imageUrl);
    } catch (error) {
      console.error("Error importing image:", error);
    }
  };

  const importedImageToBlob = (importedImage) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", importedImage, true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(new Error("Failed to convert image to Blob."));
        }
      };
      xhr.onerror = () => {
        reject(new Error("Failed to convert image to Blob."));
      };
      xhr.send();
    });
  };

  const getStateId = (stateName) => {
    switch (stateName) {
      case "Andhra Pradesh":
        return "1";
      case "Arunachal Pradesh":
        return "2";
      case "Assam":
        return "3";
      case "Bihar":
        return "4";
      case "Chhattisgarh":
        return "5";
      case "Goa":
        return "6";
      case "Gujarat":
        return "7";
      case "Haryana":
        return "8";
      case "Himachal Pradesh":
        return "9";
      case "Jharkhand":
        return "10";
      case "Karnataka":
        return "11";
      case "Kerala":
        return "12";
      case "Madhya Pradesh":
        return "13";
      case "Maharashtra":
        return "14";
      case "Manipur":
        return "15";
      case "Meghalaya":
        return "16";
      case "Mizoram":
        return "17";
      case "Nagaland":
        return "18";
      case "Odisha":
        return "19";
      case "Punjab":
        return "20";
      case "Rajasthan":
        return "21";
      case "Sikkim":
        return "22";
      case "Tamil Nadu":
        return "23";
      case "Telangana":
        return "24";
      case "Tripura":
        return "25";
      case "Uttar Pradesh":
        return "26";
      case "Uttarakhand":
        return "27";
      case "West Bengal":
        return "28";
      case "Chandigarh":
        return "29";
      case "Delhi":
        return "30";
      case "Ladakh":
        return "31";
      case "Jammu and Kashmir":
        return "32";
      default:
        return "";
    }
  };
  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    // Check if the input is for 'Gender' and value is either 'Male' or 'Female'
    if (name === "Gender" && (value === "Male" || value === "Female")) {
      // Randomly select an image from userMaleLogo or userFemaleLogo based on the selected gender
      const randomIndex = Math.floor(Math.random() * 15); // Generates a random index from 0 to 54
      const randomImage =
        value === "Male"
          ? randomMaleLogo[randomIndex]
          : randomFemaleLogo[randomIndex];

      if (finalData.USER_IMAGE === null) {
        importImageAsFile(randomImage);
      }
    }
    if (name === "pin_code" && value.length === 6) {
      // Fetch data based on pin code
      try {
        handleOpen();
        const response = await fetch(
          `https://api.postalpincode.in/pincode/${value}`
        );
        const data = await response.json();
        if (response.ok && data.length > 0) {
          // Update state, district, location based on API response
          const { State, District, Division } = data[0].PostOffice[0];
          updateFinalData({
            state_id: getStateId(State),
            district: District,
            location: Division,
          });
          handleClose();
        } else {
          // Show error message if pin code is invalid
          handleClose();
          Swal.fire({
            title: "Invalid Pin Code",
            text: "Please enter a valid pin code.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        handleClose();
        console.error("Error fetching data:", error);
        // Show error message if API call fails
        Swal.fire({
          title: "Error",
          text: "An unexpected error occurred. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }

    updateFinalData({ [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    updateFinalData({ [e.target.name]: file });
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleInspect = () => {
    setInspect(!inspect);
  };

  const handleImageUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      updateFinalData({ [e.target.name]: selectedFile });
      setSelectedImage(imageUrl);
      setOriginalSelectedImage(imageUrl);
    }

    // console.log("Selected File:", selectedFile);
  };

  const handleRemoveImage = () => {
    updateFinalData({ USER_IMAGE: null });
    setSelectedImage(null);
    setOriginalSelectedImage(null);
    inputRef.current.value = null;
  };

  const handleZoomChange = (value) => {
    setData(value);
  };

  const handleEnterKey = (e, nextInputId) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const nextInput = document.getElementById(nextInputId);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  useEffect(() => {
    if (finalData && finalData?.pin_code && finalData?.pin_code.length < 6) {
      setPincodeAvailable(true);
      setPincodeError(null);
      setSelectedAddressType("no");
      updateFinalData({ ["save_address"]: "no" });
    }
  }, [finalData?.pin_code]);

  const [pincodeAvailable, setPincodeAvailable] = useState(true);
  const [pincodeError, setPincodeError] = useState(null);

  const handleSaveAddressType = async (addressType) => {
    if (addressType === "Office") {
      if (
        finalData &&
        finalData?.pin_code &&
        finalData?.pin_code.length === 6
      ) {
        try {
          handleOpen();
          const responseDelivery = await fetch(
            `${API_BASE_URL}pincode/${finalData?.pin_code}`
          );
          const dataDelivery = await responseDelivery.json();
          // console.log(dataDelivery[0]?.Pincode,parseInt(finalData?.pin_code));
          if (
            dataDelivery &&
            dataDelivery[0] &&
            dataDelivery[0]?.Pincode === parseInt(finalData?.pin_code)
          ) {
            setPincodeAvailable(true);
            setPincodeError(null);
            setSelectedAddressType(addressType);
            updateFinalData({ ["save_address"]: addressType });
            handleClose();
          } else {
            setPincodeAvailable(false);
            setPincodeError(
              "Please change pincode, the shipment is not available at this pincode"
            );
            handleClose();
          }
        } catch (error) {
          setPincodeAvailable(false);
          setPincodeError(
            "Please change pincode, the shipment is not available at this pincode"
          );
          handleClose();
        }
      } else {
        setPincodeAvailable(false);
        setPincodeError("Please fill the pincode first!");
      }
    } else {
      setSelectedAddressType(addressType);
      updateFinalData({ ["save_address"]: addressType });
    }
  };

  return (
    <div className="basic-detail-main-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        {inspect && (
          <div className="basic-details-overlay">
            <div className="basic-details-crop-easy-main-container">
              <CropEasy
                photoURL={originalSelectedImage}
                setPhotoURL={setSelectedImage}
                zoom={data}
                onZoomChange={handleZoomChange}
                setOpenCrop={setInspect}
              />
            </div>
          </div>
        )}
        <div className="basic-details-form-container-main-half-div">
          <h3 className="detail-page-name">Personal & Shipping Details</h3>
          <span className="detail-page-text">
            We require this information to have smooth shopping experience
          </span>
          <div className="basic-detail-basic-detail-form-2">
            <fieldset className="input-fieldset">
              <legend className="input-legend">
                First name&nbsp;<b>*</b>
              </legend>
              <input
                className="input-type-write"
                type="text"
                name="FirstName"
                value={finalData?.FirstName || ""}
                placeholder="Enter your first name"
                onKeyDown={(e) => handleEnterKey(e, "secondNameInput")}
                onChange={handleInputChange}
                required
              />
            </fieldset>
            <fieldset className="input-fieldset">
              <legend className="input-legend">
                Last name&nbsp;<b>*</b>
              </legend>
              <input
                className="input-type-write"
                type="text"
                name="LastName"
                value={finalData?.LastName || ""}
                placeholder="Enter your last name"
                id="secondNameInput"
                onKeyDown={(e) => handleEnterKey(e, "dobInput")}
                onChange={handleInputChange}
                required
              />
            </fieldset>
            <div className="basic-detail-form-2-gender">
              <fieldset className="input-fieldset">
                <legend
                  className="input-legend"
                  style={{ marginLeft: "-15px" }}
                >
                  Gender&nbsp;<b>*</b>
                </legend>
                <div className="type-radio-each-btn">
                  <input
                    type="radio"
                    name="Gender"
                    id="genderInput"
                    value="Male"
                    checked={finalData?.Gender === "Male"}
                    onChange={handleInputChange}
                  />
                  <span className="type-radio-span">Male</span>
                </div>
                <div className="type-radio-each-btn">
                  <input
                    type="radio"
                    name="Gender"
                    id="genderInput"
                    value="Female"
                    checked={finalData?.Gender === "Female"}
                    onChange={handleInputChange}
                  />
                  <span className="type-radio-span">Female</span>
                </div>
              </fieldset>
            </div>
          </div>
          <div className="basic-detail-basic-detail-form-3">
            <fieldset
              className="input-fieldset"
              style={{ marginBottom: "6vh" }}
            >
              <legend className="input-legend">
                Date of birth&nbsp;<b>*</b>
              </legend>
              <input
                className="input-type-write"
                type="date"
                id="dobInput"
                value={finalData?.DOB || ""}
                name="DOB"
                max={new Date().toISOString().split("T")[0]}
                onKeyDown={(e) => handleEnterKey(e, "pincode")}
                onChange={handleInputChange}
                required
              />
            </fieldset>
            <span className="business-address-heading">
              Please add your business address as per your GST address.
            </span>
            <fieldset className="input-fieldset" style={{ marginTop: "2vh" }}>
              <legend className="input-legend">
                Pincode&nbsp;<b>*</b>
              </legend>
              <input
                className="input-type-write"
                type="number"
                id="pincode"
                onChange={handleInputChange}
                value={finalData?.pin_code || ""}
                onKeyDown={(e) => handleEnterKey(e, "address1Input")}
                name="pin_code"
                placeholder="Enter your pincode"
                pattern="\d{6}"
                maxLength="6"
                onKeyPress={(e) => {
                  // Allow only numbers (0-9) and backspace (8)
                  if (!/[0-9\b]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                required
              />
            </fieldset>
          </div>
          <div className="basic-detail-basic-detail-form-2">
            <fieldset className="input-fieldset">
              <legend className="input-legend">
                Address 1&nbsp;<b>*</b>
              </legend>
              <input
                className="input-type-write"
                type="text"
                name="address_line_1"
                value={finalData?.address_line_1 || ""}
                placeholder="Address line 1"
                id="address1Input"
                onKeyDown={(e) => handleEnterKey(e, "address2Input")}
                onChange={handleInputChange}
                required
              />
            </fieldset>
            <fieldset className="input-fieldset">
              <legend className="input-legend">Address 2</legend>
              <input
                className="input-type-write"
                type="text"
                name="address_line_2"
                value={finalData?.address_line_2 || ""}
                placeholder="Address line 2"
                id="address2Input"
                onKeyDown={(e) => handleEnterKey(e, "cityInput")}
                onChange={handleInputChange}
              />
            </fieldset>
            <fieldset className="input-fieldset">
              <legend className="input-legend">
                City&nbsp;<b>*</b>
              </legend>
              <input
                className="input-type-write"
                type="text"
                name="location"
                value={finalData?.location || ""}
                placeholder="Enter your city name"
                id="cityInput"
                onKeyDown={(e) => handleEnterKey(e, "districtInput")}
                onChange={handleInputChange}
                required
              />
            </fieldset>
            <fieldset className="input-fieldset">
              <legend className="input-legend">
                District&nbsp;<b>*</b>
              </legend>
              <input
                className="input-type-write"
                type="text"
                name="district"
                value={finalData.district || ""}
                placeholder="Enter your district name"
                id="districtInput"
                onKeyDown={(e) => handleEnterKey(e, "state_id")}
                onChange={handleInputChange}
                required
              />
            </fieldset>
            <fieldset className="input-fieldset">
              <legend className="input-legend">
                State&nbsp;<b>*</b>
              </legend>
              <select
                className=" select-state-select"
                id="state_id"
                name="state_id"
                onChange={handleInputChange}
                value={finalData?.state_id || 0}
                required
              >
                <option unselectable="">select State</option>
                <option value="1">Andhra Pradesh</option>
                <option value="2">Arunachal Pradesh</option>
                <option value="3">Assam</option>
                <option value="4">Bihar</option>
                <option value="5">Chhattisgarh</option>
                <option value="29">Chandigarh</option>
                <option value="30">Delhi</option>
                <option value="6">Goa</option>
                <option value="7">Gujarat</option>
                <option value="8">Haryana</option>
                <option value="9">Himachal Pradesh</option>
                <option value="32">Jammu and Kashmir</option>
                <option value="10">Jharkhand</option>
                <option value="11">Karnataka</option>
                <option value="12">Kerala</option>
                <option value="31">Ladakh</option>
                <option value="13">Madhya Pradesh</option>
                <option value="14">Maharashtra</option>
                <option value="15">Manipur</option>
                <option value="16">Meghalaya</option>
                <option value="17">Mizoram</option>
                <option value="18">Nagaland</option>
                <option value="19">Odisha</option>
                <option value="20">Punjab</option>
                <option value="21">Rajasthan</option>
                <option value="22">Sikkim</option>
                <option value="23">Tamil Nadu</option>
                <option value="24">Telangana</option>
                <option value="25">Tripura</option>
                <option value="26">Uttar Pradesh</option>
                <option value="27">Uttarakhand</option>
                <option value="28">West Bengal</option>
              </select>
            </fieldset>
          </div>
          <div className="save-address-as">
            <h3 className="save-address-as-heading">
              My shipping address is same as my billing address:
            </h3>
            <span className="detail-page-text">
              This address will be saved and displayed at time of user profile
              creation.
            </span>
            <div className="save-address-option">
              <div className="save-detail-default-address-div">
                <input
                  type="radio"
                  name="default_address"
                  value="Office"
                  checked={selectedAddressType === "Office"}
                  onChange={() => handleSaveAddressType("Office")}
                />
                &nbsp; Yes
                {!pincodeAvailable && (
                  <span className="pincode-not-available">
                    <b className="verify-syl">!</b>
                    {pincodeError}
                  </span>
                )}
              </div>
              <div className="save-detail-default-address-div">
                <input
                  type="radio"
                  name="default_address"
                  value="no"
                  defaultChecked
                  checked={selectedAddressType === "no"}
                  onChange={() => handleSaveAddressType("no")}
                />
                &nbsp; No
              </div>
            </div>
          </div>
          <div className="basic-detail-photo-upload-container">
            {finalData &&
            finalData?.USER_IMAGE &&
            finalData.USER_IMAGE.type.startsWith("image/") ? (
              <>
                <LazyLoadImage
                  src={
                    finalData &&
                    finalData?.USER_IMAGE &&
                    finalData.USER_IMAGE.type.startsWith("image/") &&
                    URL.createObjectURL(finalData?.USER_IMAGE)
                  }
                  alt="selected"
                  className="uploaded-image-fix"
                  effect="blur"
                wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
                }}
                />
                <div className="uploaded-image-inspect-remove-container">
                  <div className="uploaded-image-inspect-div-container">
                    <LazyLoadImage
                      src={inspectUploadedImage}
                      alt=""
                      onClick={handleInspect}
                      effect="blur"
                wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
                }}
                    />
                    <span onClick={handleInspect}>Inspect</span>
                  </div>
                  <div className="uploaded-image-remove-div-container">
                    <LazyLoadImage
                      src={deleteUploadedImage}
                      alt=""
                      onClick={handleRemoveImage}
                      effect="blur"
                wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
                }}
                    />
                    <span
                      onClick={() => {
                        handleRemoveImage();
                        setInspect(false);
                      }}
                    >
                      Remove
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <div
                className="basic-detail-photo-upload-bordered-container"
                style={{ cursor: "pointer" }}
                onClick={handleImageClick}
              >
                <LazyLoadImage src={upload_logo} alt="upload_logo" effect="blur"
                wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
                }}/>
                <label>
                  <p>Upload profile photo</p>
                </label>
                <span>Drag & Drop</span>
                <span className="upload-browse">Browse</span>
              </div>
            )}
            <input
              type="file"
              id="userImage"
              name="USER_IMAGE"
              ref={inputRef}
              style={{ display: "none" }}
              onChange={(e) => {
                handleImageUpload(e);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BasicDetails;
