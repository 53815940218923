import React, { useState } from "react";
import "./MobilePagination.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

function MobilePagination({ totalItems, itemsPerPage, onPageChange ,currentPage,setCurrentPage}) {
  // const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    if (page <= 1) {
      setCurrentPage(1);
      onPageChange(1);
    } else if (page > totalPages) {
      setCurrentPage(totalPages);
      onPageChange(totalPages);
    } else {
      setCurrentPage(page);
      onPageChange(page);
    }
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="mobile-pagination-container">
      {currentPage > 1 && (
        <div
          className="mobile-pagination-prev-container"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <NavigateBeforeIcon />
          <span>Previous</span>
        </div>
      )}
      <div className="mobile-pagination-total-number-container">
        {Array.from({ length: totalPages }).map((_, i) => {
          if (
            i === 0 ||
            i === totalPages - 1 ||
            (i >= currentPage - 2 && i <= currentPage + 2)
          ) {
            return (
              <span
                key={i}
                onClick={() => handlePageChange(i + 1)}
                style={{
                  background:
                    currentPage === i + 1 ? "var(--color-blue)" : "#f3f3f3",
                  color: currentPage === i + 1 ? "white" : "black",
                }}
              >
                {i + 1}
              </span>
            );
          } else if (
            (i === currentPage - 3 && currentPage > 4) ||
            (i === currentPage + 3 && currentPage < totalPages - 3)
          ) {
            return <span key={i}>...</span>;
          }
          return null;
        })}
      </div>
      {currentPage < totalPages && (
        <div
          className="mobile-pagination-next-container"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <span>Next</span>
          <NavigateNextIcon />
        </div>
      )}
    </div>
  );
}

export default MobilePagination;
