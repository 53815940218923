import React, { useState } from "react";
import "./Carts_Breadcrum.css";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedAddress } from "../../features/cartPage/addressSlice";
import Swal from "sweetalert2";
import { selectAllCarts } from "../../features/cartPage/cartPageSlice";
import OrderConfirmed from "./OrderConfirmed";
import { calculateDealerPrice, calculateOfferPrice, calculateTotalAmount } from "../../utils/utils";

function Carts_Breadcrum({
  confirmOverlay,
  setConfirmOverlay,
  handleConfirmOverlay,
  onCheckout,
  currentStep,
  setCurrentStep,
  handleGeneratePaymentURL,
  paymentSessionId,
  transactionId,
  setGeneratePaymentUrl,
}) {
  const dispatch = useDispatch();
  const cart = useSelector(selectAllCarts);
  const navigate = useNavigate();
  const selectedAddress = useSelector(selectSelectedAddress);
  const steps = [
    { label: "Cart", icon: <ShoppingCartIcon /> },
    { label: "Address", icon: <LocationOnIcon /> },
    { label: "Payment", icon: <MonetizationOnIcon /> },
  ];
  // const [currentStep, setCurrentStep] = useState(1);
  const [complete, setComplete] = useState(false);

  const nextStepIndex =
    currentStep < steps.length ? currentStep + 1 : currentStep;
  const nextLabel = steps[nextStepIndex - 1]?.label;

  const handleStepClick = (stepIndex) => {
    if (stepIndex <= currentStep || complete) {
      setCurrentStep(stepIndex);
    }
  };
  const handleNavigate = () => {
    navigate("/");
  };

  // Function to calculate the shipping cost for the selected shipping mode
  const calculateShippingCost = () => {
    let totalAmount = 0;
    cart &&
      cart?.forEach((product) => {
        totalAmount +=
          !product?.skuDetails?.SKU_ID?.startsWith("D") &&
          (product.skuDetails?.Offer_price
            ? calculateOfferPrice(
                parseInt(product.skuDetails?.MRP_price),
                parseInt(product.skuDetails?.Offer_price),
                product?.skuDetails?.GST
              )
            : calculateDealerPrice(
                parseInt(product.skuDetails?.MRP_price),
                parseInt(product.skuDetails?.NDP_Price),
                product?.skuDetails?.GST
              )) * product?.buy_quantity;
      });

    if (totalAmount >= 3000) {
      return 0;
    } else {
      return 250;
    }
  };


  // Function to calculate the amount to pay (total amount + shipping cost)
  const calculateAmountToPay = () => {
    const totalAmount = calculateTotalAmount(cart);
    const shippingCost = calculateShippingCost();
    return (totalAmount + shippingCost).toFixed(2);
  };
  return (
    <div className="breadcrum-container">
      {currentStep > 1 && (
        <div className="breadcrum-back-to-shopping">
          <KeyboardArrowLeftIcon />
          <span
            className="breadcrum-back-btn"
            onClick={() => setCurrentStep(1)}
          >
            Back to cart
          </span>
        </div>
      )}
      <div className="breadcrum-step-container">
        {steps?.map((step, i) => (
          <div
            key={i}
            className={`step-item ${currentStep === i + 1 && "active"} ${
              (i + 1 < currentStep || complete) && "complete"
            } `}
            onClick={() => handleStepClick(i + 1)}
          >
            <div className="step">{step.icon}</div>
            <p className="text-gray">{step.label}</p>
          </div>
        ))}
      </div>
      <div
        className="breadcrum-next-step"
        onClick={() => {
          setCurrentStep((prev) => {
            let nextStep = prev;
            if (prev < 2) {
              if (cart && cart?.length > 0) {
                nextStep = prev + 1;
              } else {
                Swal.fire({
                  icon: "warning",
                  title: "Please add items in cart",
                  text: "You need to add an items before proceeding.",
                  confirmButtonText: "OK",
                });
              }
            } else if (prev === 2 && selectedAddress !== null) {
              nextStep = 3;
            } else if (prev === 2 && selectedAddress === null) {
              // Display SweetAlert if selectedAddress is null
              Swal.fire({
                icon: "warning",
                title: "Please add an address",
                text: "You need to add an address before proceeding.",
                confirmButtonText: "OK",
              });
            } else if (prev === 3) {
              handleConfirmOverlay();
              setGeneratePaymentUrl(true);
              // handleGeneratePaymentURL(calculateAmountToPay());
            }
            return nextStep;
          });
        }}
      >
        <span className="breadcrum-next-btn">Proceed to {nextLabel}</span>
        <EastIcon />
      </div>
    </div>
  );
}

export default Carts_Breadcrum;
