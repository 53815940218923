import React, { useEffect, useState } from "react";
import "./MobileProductCard.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  addToWishlistsAsync,
  fetchAllWishlistsAsync,
  removeFromWishlistAsync,
  selectAllWishlists,
} from "../../features/wishlist/wishlistSlice";
import {
  fetchAllCartsAsync,
  removeFromCartsAsync,
  selectAllCarts,
} from "../../features/cartPage/cartPageSlice";
import { selectUser } from "../../features/auth/authSlice";
import {
  selectIsOverlayVisible,
  toggleOverlay,
} from "../../store/slices/headerSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MobileSelectVarient from "../MobileEndpoints/MobileSelectVarient";
import AuthenticationPage from "../../components/Authentication/AuthenticationPage";
import cartOutlineLogo from "../../img/navigation/cart-bookmark-outline.png";
import cartFilledLogo from "../../img/navigation/cart-bookmark-filled.png";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import HoverSlider from "../../components/ListingPage/ListingProduct/HoverSlider";
import StarIcon from "@mui/icons-material/Star";
import { IMAGE_BASE } from "../../config";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import badgeIcon from "../../icons/badges/PriceDrop.png";
import { calculateDealerPrice, calculateDiscountPercentage, calculateOfferPrice, formatWithCommasWithDecimal } from "../../utils/utils";

function MobileProductCard({ toggleOverlay, isOverlayVisible }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wishlist = useSelector(selectAllWishlists);
  const products = wishlist.product;
  const [openMobileVarient, setOpenMobileVarient] = useState(null);
  // console.log(products);
  const qtys = wishlist.quantity;
  const cart = useSelector(selectAllCarts);
  const userDetail = useSelector(selectUser);
  const [openDesktopVariant, setOpenDesktopVariant] = useState(null);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail.UserID) {
      dispatch(fetchAllWishlistsAsync(userDetail.UserID));
      dispatch(fetchAllCartsAsync(userDetail.UserID));
    }
  }, [dispatch, userDetail.UserID]);

  const handleLoginOverlay = (e) => {
    toggleOverlay();
    e.preventDefault();
  };

  // TO ADD PRODUCT INTO WISHLIST
  const handleAddToWishlist = async (data, e) => {
    handleOpen();
    let wishlistData = {
      UserID: userDetail?.UserID,
      prodID: data?.prod_ID,
    };
    dispatch(addToWishlistsAsync(wishlistData)).then(() =>
      dispatch(fetchAllWishlistsAsync(userDetail.UserID)).then(() => {
        handleClose();
      })
    ); // Fetch updated wishlist
    e.preventDefault();
  };

  const handleRemoveFromWishlist = async (data, e) => {
    handleOpen();
    let wishlistData = {
      UserID: userDetail?.UserID,
      prodID: data?.prod_ID,
    };
    dispatch(removeFromWishlistAsync(wishlistData)).then(() => {
      dispatch(fetchAllWishlistsAsync(userDetail.UserID)).then(() => {
        handleClose();
      });
    });
    e.preventDefault();
  };

  // REMOVE FROM CART
  const removeFromCart = (data, e) => {
    e.preventDefault();
    let cartData = {
      userID: userDetail?.UserID,
      productId: data?.prod_ID,
    };
    dispatch(removeFromCartsAsync(cartData));
    dispatch(fetchAllCartsAsync(userDetail.UserID));
  };

  // Function to check if a product is in the wishlist
  const isInWishlist = (productId) => {
    return (
      Array.isArray(wishlist && wishlist?.product) &&
      wishlist?.product?.some((item) => item?.prod_ID === productId)
    );
  };
  // Function to check if a product is in the cart
  const isInCart = (productId) => {
    return;
    // return (
    //   Array.isArray(cart) &&
    //   cart.some((item) => parseInt(item?.skuDetails[0]?.prod_ID) === parseInt(productId))
    // );
  };

  const handleNavigate = (prod_id, event) => {
    event.preventDefault();
    navigate(`/product/${prod_id}`);
  };

  const handleOpenDesktopVariant = (id, e) => {
    setOpenDesktopVariant(id);
    e.preventDefault();
  };


 

  const handleOpenMobileVarient = (id, e) => {
    setOpenMobileVarient(id);
    e.preventDefault();
  };

  const handleMouseEnter = () => {
    return;
  };

  const handleMouseLeave = () => {
    return;
  };
  return (
    <div className="mobile-wishlist-product-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open} // Show backdrop when either open or filtering state is true
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mobile-wishlist-product-main-container">
        {(products || []).map((data) => (
          <div
            className="mobile-promotion-product-main-container"
            key={data?.prod_ID}
          >
            <div className="mobile-promotion-product-top-div">
              <div
                className="mobile-promotion-img-div"
                onClick={(event) => handleNavigate(data.prod_ID, event)}
              >
                {/* {data?.Badges && ( */}
                {/* <div
                  className="mobile-promotion-product-badges-container"
                  style={{ left: "-3vw" }}
                >
                  <img
                    // src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Logo/${
                    //   data?.Badges && data?.Badges
                    // }`}
                    src={badgeIcon}
                    alt=""
                    className="product-slider-card-product-badges"
                  />
                </div> */}
                {/* )} */}
                <HoverSlider images={data?.images && data.images} />
                <div className="mobile-promotion-product-details-rating">
                  <span className="mobile-promotion-product-ratings-rate">
                    {parseInt(data.net_rating).toFixed(1)}
                    <StarIcon />
                  </span>
                  <span className="mobile-promotion-product-ratings-divider">
                    |
                  </span>
                  <span className="mobile-promotion-product-ratings-number">
                    313
                  </span>
                </div>
              </div>
              <div className="mobile-promotion-product-details-div">
                <div className="mobile-promotion-product-favourite-container">
                  {isInWishlist(data.prod_ID) ? (
                    <img
                      src={cartFilledLogo}
                      alt=""
                      onClick={(e) => {
                        handleRemoveFromWishlist(data, e);
                      }}
                      id="mobile-promotion-product-slider-card-favourite-svg"
                    />
                  ) : (
                    <img
                      src={cartOutlineLogo}
                      alt=""
                      onClick={(e) => {
                        userDetail && userDetail?.UserID
                          ? handleAddToWishlist(data, e)
                          : handleLoginOverlay(e);
                      }}
                      id="mobile-promotion-product-slider-card-favourite-svg"
                    />
                  )}
                </div>
                <div className="mobile-promotion-product-brands-name-img">
                  {data && data?.manufacture_images ? (
                    <img
                      className="mobile-promotion-product-slider-card-product-brand-img"
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                        data && data?.manufacture_images
                      }`}
                      alt=""
                    />
                  ) : (
                    <span className="mobile-promotion-product-slider-card-product-brand-name">
                      {data.manufacture_name && data.manufacture_name}
                    </span>
                  )}
                </div>
                <div className="mobile-promotion-product-details-name">
                  <span className="mobile-promotion-product-name">
                    {data.prod_name &&
                      data.prod_name
                        .slice(0, 45)
                        .toLowerCase()
                        .charAt(0)
                        .toUpperCase() +
                        data.prod_name.slice(0, 45).toLowerCase().slice(1)}
                    {data.prod_name.length > 45 && "..."}
                  </span>
                </div>
                {data &&
                  data?.skuDetails &&
                  data?.skuDetails?.length > 0 &&
                  data?.skuDetails[0]?.NDP_Price && (
                    <div className="mobile-promotion-product-dealer-price-div">
                      <span className="mobile-promotion-product-dealer-price">
                        Dealer Price-{" "}
                        {Object.keys(userDetail).length > 0 ? (
                          <b
                            // className="price-font"
                            style={{
                              textDecoration:
                                data?.skuDetails[0].Offer_price !== null &&
                                "line-through",
                              fontSize:
                                data?.skuDetails[0].Offer_price !== null &&
                                "3vw",
                              marginLeft: "0.5vw",
                            }}
                          >
                            ₹
                            {formatWithCommasWithDecimal(
                              calculateDealerPrice(
                                parseInt(data.skuDetails[0].MRP_price),
                                parseInt(data.skuDetails[0].NDP_Price),
                                data?.GST
                              )
                            )}
                          </b>
                        ) : (
                          <b
                            className="mobile-login-to-see-price"
                            onClick={toggleOverlay}
                          >
                            Login to see Price
                          </b>
                        )}
                      </span>
                      {Object.keys(userDetail).length > 0 &&
                        data?.skuDetails[0].Offer_price !== null && (
                          <span className="mobile-promotion-product-offer-price">
                            ₹
                            {formatWithCommasWithDecimal(
                              calculateOfferPrice(
                                parseInt(data.skuDetails[0].MRP_price),
                                parseInt(data.skuDetails[0].Offer_price),
                                data?.GST
                              )
                            )}
                          </span>
                        )}
                    </div>
                  )}
                {data &&
                  data?.skuDetails &&
                  data?.skuDetails?.length > 0 &&
                  data?.skuDetails[0]?.MRP_price && (
                    <div className="mobile-promotion-product-mrp-price-div">
                      <span className="mobile-promotion-product-mrp-price">
                        Product MRP- ₹
                        {formatWithCommasWithDecimal(
                          parseInt(
                            data.skuDetails[0] && data.skuDetails[0].MRP_price
                          )
                        )}
                      </span>
                    </div>
                  )}
                <div className="mobile-promotion-product-dealer-margin-div">
                  {Object.keys(userDetail).length > 0 ? (
                    <div className="mobile-promotion-product-dealer-margin-login">
                      <LocalOfferOutlinedIcon />{" "}
                      <span
                        className="mobile-promotion-product-dealer-margin-span"
                        style={{
                          textDecoration:
                            data?.skuDetails[0].Offer_price !== null &&
                            "line-through",
                          fontSize:
                            data?.skuDetails[0].Offer_price !== null && "3.5vw",
                          margin: "0 0.8vw",
                        }}
                      >
                        {" "}
                        {calculateDiscountPercentage(
                          data?.skuDetails[0]?.MRP_price,
                          calculateDealerPrice(
                            parseInt(data.skuDetails[0].MRP_price),
                            parseInt(data.skuDetails[0].NDP_Price),
                            data?.GST
                          )
                        )}
                        %
                      </span>
                      {data?.skuDetails[0].Offer_price !== null && (
                        <span className="mobile-promotion-product-dealer-margin-offer-ribbon">
                          {calculateDiscountPercentage(
                            data?.skuDetails[0]?.MRP_price,
                            calculateOfferPrice(
                              parseInt(data.skuDetails[0].MRP_price),
                              parseInt(data.skuDetails[0].Offer_price),
                              data?.GST
                            )
                          )}
                          %
                        </span>
                      )}{" "}
                    </div>
                  ) : (
                    <div className="mobile-not-visible-container">
                      <VisibilityOffOutlinedIcon onClick={toggleOverlay} />{" "}
                      <span>%</span>
                    </div>
                  )}{" "}
                  Dealer Margin
                </div>
              </div>
            </div>
            <div className="mobile-promotion-product-bottom-div">
              <div
                className="mobile-promotion-product-cart-btn"
                onClick={(e) => {
                  userDetail && userDetail?.UserID
                    ? handleOpenMobileVarient(data.prod_ID, e)
                    : handleLoginOverlay(e);
                }}
              >
                <ShoppingCartOutlinedIcon
                  style={{ color: "var(--color-green)" }}
                />
                <span className="mobile-promotion-product-cart-span">
                  Add to cart
                </span>
              </div>
              <div
                className="mobile-promotion-product-buy-btn"
                onClick={(e) => {
                  userDetail && userDetail?.UserID
                    ? handleOpenMobileVarient(data.prod_ID, e)
                    : handleLoginOverlay(e);
                }}
              >
                <span className="mobile-promotion-product-buy-span">
                  Buy Now
                </span>
              </div>
            </div>
          </div>
        ))}
        {openMobileVarient !== null && (
          <MobileSelectVarient
            openMobileVarient={openMobileVarient}
            setOpenMobileVarient={setOpenMobileVarient}
            product={products?.find(
              (item) => item.prod_ID === openMobileVarient
            )}
            qtys={qtys}
          />
        )}
        {isOverlayVisible && (
          <div className="overlay-container">
            <AuthenticationPage />
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});

const mapDispatchToProps = {
  toggleOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileProductCard);
