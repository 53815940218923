import React, { useState, useRef } from "react";
import "./ViaEmail.css";
import backArrow from "../../../img/back-arrow.svg";
import { API_BASE_URL } from "../../../config";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PhoneAndEmailChange from "./PhoneAndEmailChange";
import { fetchUserAsync, selectUser } from "../../../features/auth/authSlice";
import { useDispatch,useSelector } from "react-redux";

function ViaEmail({ handleBackClick, email, verify, setVerify }) {
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const [otpInputs, setOtpInputs] = useState(["", "", ""]);
  const [open, setOpen] = useState(false);
  const inputRefs = useRef([...Array(2)].map(() => React.createRef()));

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleVerify = async () => {
    handleOpen();
    const enteredOTP = otpInputs.join("");
    if (!enteredOTP || enteredOTP.length < 6) {
      handleClose();
      Swal.fire({
        title: "Enter OTP correctly",
        text: "Please Enter OTP correctly",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}update_verify_otp/${userDetail?.UserID}/${enteredOTP}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          dispatch(fetchUserAsync(userDetail?.UserID));
          handleClose();
          // console.log(data);

          setVerify(true);
        } else {
          handleClose();
          console.error("API request failed:", response.statusText);
          Swal.fire({
            title: "Invalid OTP",
            text: "Please check and try again",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        handleClose();
        console.error(
          "An error occurred during the API request:",
          error.message
        );
      }
    }
  };

  const handleInputChange = (index, event) => {
    const value = event.target.value;
    if (value.length <= 3) {
      const newOtpInputs = [...otpInputs];
      newOtpInputs[index] = value;
      setOtpInputs(newOtpInputs);
      if (value.length === 3 && index === 0) {
        inputRefs.current[1].current.focus();
      }
    }
  };
  return (
    <div className="via-email-verification-via-email-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <img
        src={backArrow}
        alt=""
        onClick={handleBackClick}
        style={{ background: "#F5F8FE" }}
      />
      {verify ? (
        // <div className="via-email-verification-phone-email-verified">
        //   <CheckOutlinedIcon/>
        //   <span>Verified successfully</span>
        // </div>
        <div className="via-phone-phone-email-change-container">
          <PhoneAndEmailChange />
        </div>
      ) : (
        <>
          <h3>Verification via email</h3>
          <span>We have sent an OTP to your email</span>
          <span>Please enter it below</span>
          <div className="via-email-vefification-via-email-input-div">
            <input
              ref={inputRefs.current[0]}
              type="text"
              maxLength={3}
              placeholder="X X X"
              value={otpInputs[0]}
              onChange={(e) => handleInputChange(0, e)}
            />

            <span>-</span>
            <input
              ref={inputRefs.current[1]}
              type="text"
              maxLength={3}
              placeholder="X X X"
              value={otpInputs[1]}
              onChange={(e) => handleInputChange(1, e)}
            />
          </div>
          <span className="via-phone-verify-otp-btn" onClick={handleVerify}>
            Verify
          </span>
        </>
      )}
    </div>
  );
}

export default ViaEmail;
