// Home.js

import React, { useEffect, useState } from "react";
import Header from "../components/HomePage/Header";
import Footer from "../components/HomePage/Footer";
import Promises from "../components/HomePage/Promises";
import Banner from "../components/HomePage/Banner";
import "./HomeNew.css";
import styled from "styled-components";
import ScrollPromices from "../components/HomePage/ScrollPromices";
import PopularSearch from "../components/HomePage/PopularSearch";
import OurBrands from "../components/HomePage/OurBrands";
import ShopByCategories from "../components/HomePage/ShopByCategories";
import Promotions from "../components/HomePage/Promotions/Promotions";
import Parallex from "../components/HomePage/Parallex";
import BottomPromises from "../components/HomePage/BottomPromises";
import Coupon from "../components/HomePage/Coupon";
import { useMediaQuery } from "react-responsive";
import MobileScrollPromises from "../mobileComponent/MobileEndpoints/MobileScrollPromises";
import MobileHeroBanner from "../mobileComponent/MobileHomePage/MobileHeroBanner";
import MobileBrands from "../mobileComponent/MobileHomePage/MobileBrands";
import MobileHeader from "../mobileComponent/MobileEndpoints/MobileHeader";
import MobilePromotion from "../mobileComponent/MobileHomePage/MobilePromotion/MobilePromotion";
import MobileDetailedPromises from "../mobileComponent/MobileHomePage/MobileDetailedPromises";
import MobileShopByCtg from "../mobileComponent/MobileHomePage/MobileShopByCtg";
import MobilePopularSearch from "../mobileComponent/MobileEndpoints/MobilePopularSearch";
import MobileFooter from "../mobileComponent/MobileEndpoints/MobileFooter";
import MobileBottomNavigation from "../mobileComponent/MobileEndpoints/MobileBottomNavigation";
import PreOrder from "../components/HomePage/PreOrder";
import MobilePreOrder from "../mobileComponent/MobileHomePage/MobilePreOrder";
function Home() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 501px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  return (
    <div className="Home">
      {isDesktopOrLaptop && (
        <>
          {/* <Coupon /> */}
          <HeaderDiv>
            <ScrollPromices />
            <Header />
          </HeaderDiv>
          <Banner />
          <PreOrder/>
          <OurBrands />
          <Promises />
          <ShopByCategories />
          <Promotions />
          <Parallex />
          <BottomPromises />
          <PopularSearch />
          <Footer />
        </>
      )}
      {isTabletOrMobile && (
        <>
        <MobileHeaderDiv>
        {/* <MobileScrollPromises/> */}
        <MobileHeader/>
        </MobileHeaderDiv>
        <MobileBottomNavigation/>
        <MobileHeroBanner/>
        <MobilePreOrder/>
        <MobileBrands/>
        <MobilePromotion/>
        <MobileDetailedPromises/>
        <MobileShopByCtg/>
        <MobilePopularSearch/>
        <MobileFooter/>
        </>
      )}
    </div>
  );
}

export default Home;

const HeaderDiv = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const MobileHeaderDiv = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;
