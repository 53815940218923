import React, { useEffect, useState } from "react";
import "./MobileBigSearch.css";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { IMAGE_BASE } from "../../config";
import cyclecircleLogo from "../../img/parts/Cycle-Circle-Logo-White-1.png";
import { useNavigate, useParams } from "react-router";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchAllRecommendSearchAsync,
  fetchAllTopSearchAsync,
  selectAllRecommendSearch,
  selectAllTopSearch,
} from "../../features/search/searchSlice";
import {
  selectSearchTerm,
  setSearchTerm,
} from "../../store/slices/headerSlice";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";
import WhatshotOutlinedIcon from "@mui/icons-material/WhatshotOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";
import {
  fetchAllSubCategoriesAsync,
  fetchThroughSearchAsync,
  setSubCategories,
} from "../../features/listingPage/listingPageSlice";
import debounce from "lodash.debounce";
import Swal from "sweetalert2";
import { Slide } from "react-reveal";

function MobileBigSearch({
  searchTerm,
  setSearchTerm,
  setBigSearch,
  bigSearch,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recommendSearch = useSelector(selectAllRecommendSearch);
  // console.log(recommendSearch);
  const [recommendProduct, setRecommentProduct] = useState(
    (recommendSearch && recommendSearch?.product) || []
  );
  const [recommendCategories, setRecommentCategories] = useState(
    (recommendSearch && recommendSearch?.categories) || []
  );
  const [recommendSubCategories, setRecommentSubCategories] = useState(
    (recommendSearch && recommendSearch?.subcategories) || []
  );
  const [recommendManufacture, setRecommentManufacture] = useState(
    (recommendSearch && recommendSearch?.manufacture) || []
  );

  const [allAddTerms, setAllAddTerms] = useState([
    ...recommendCategories,
    ...recommendProduct,
    ...recommendSubCategories,
    ...recommendManufacture,
  ]);

  const [recentSearches, setRecentSearches] = useState([]);

  // Function to store recent searches in local storage
  const storeRecentSearches = (searches) => {
    const encryptedSearches = btoa(JSON.stringify(searches)); // Convert to non-readable form
    localStorage.setItem("recentSearches", encryptedSearches);
  };

  // Function to fetch recent searches from local storage
  const fetchRecentSearches = () => {
    const encryptedSearches = localStorage.getItem("recentSearches");
    if (encryptedSearches) {
      const decryptedSearches = JSON.parse(atob(encryptedSearches)); // Convert back to readable form
      setRecentSearches(decryptedSearches);
    }
  };

  useEffect(() => {
    fetchRecentSearches();
  }, []);

  const handleSearchDebounced = debounce(() => {
    if (searchTerm.trim().length > 0) {
      dispatch(fetchThroughSearchAsync(searchTerm.trim()));
      dispatch(setSubCategories(null));
      dispatch(setSelectedSubcategory(null));
      dispatch(
        setBreadcrumb({
          categoryID: 0,
          categoryName: "",
          subCategoryID: 0,
          subCategoryName: "",
          ProductID: 0,
          productName: "",
        })
      );
      navigate(`/search/${searchTerm}`);
      if (!recentSearches.includes(searchTerm)) {
        const updatedSearches = [searchTerm, ...recentSearches.slice(0, 4)]; // Limit to maximum of 4 items
        setRecentSearches(updatedSearches);
        storeRecentSearches(updatedSearches);
      }
      setSearchTerm("");
      setBigSearch(false);
    }
  }, 300); // Adjust the delay as needed

  const sTerm = useParams();
  useEffect(() => {
    if (sTerm.text && sTerm.text.trim().length > 0) {
      dispatch(fetchThroughSearchAsync(sTerm.text.trim()));
    }
  }, [dispatch, sTerm]);

  const handleSearch = () => {
    if (searchTerm.trim().length === 0) {
      // Show SweetAlert for empty search term
      Swal.fire({
        icon: "error",
        title: "Empty Search Term",
        text: "Please enter a search term before searching.",
      });
      return; // Exit the function early
    }
    handleSearchDebounced();
    document.getElementById("searchInput").blur();
  };
  const handleKeyInput = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
    // console.log(e.target.value.length);
    if (e.target.value === "") {
      dispatch(fetchAllRecommendSearchAsync("a"));
    } else {
      dispatch(fetchAllRecommendSearchAsync(e.target.value));
    }
  };
  useEffect(() => {
    dispatch(fetchAllRecommendSearchAsync("a"));
  }, [dispatch]);

  const handleSearchs = (searchTerm) => {
    dispatch(fetchThroughSearchAsync(searchTerm.trim()));
    dispatch(
      setBreadcrumb({
        categoryID: 0,
        categoryName: "",
        subCategoryID: 0,
        subCategoryName: "",
        ProductID: 0,
        productName: "",
      })
    );
    navigate(`/search/${searchTerm}`);
    setSearchTerm("");
    setBigSearch(false);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (e.target.value.trim().length === 0) {
        // Show SweetAlert for empty search term
        Swal.fire({
          icon: "error",
          title: "Empty Search Term",
          text: "Please enter a search term before searching.",
        });
        return; // Exit the function early
      }

      // Proceed with the search
      handleSearch();
      navigate(`/search/${e.target.value.trim()}`);
      e.target.value = "";
    }
  };

  // console.log(allAddTerms);
  useEffect(() => {
    if (recommendSearch) {
      setRecommentProduct(recommendSearch.product || []);
      setRecommentCategories(recommendSearch.categories || []);
      setRecommentSubCategories(recommendSearch.subcategories || []);
      setRecommentManufacture(recommendSearch.manufacture || []);

      setAllAddTerms([
        ...recommendCategories,
        ...recommendProduct,
        ...recommendSubCategories,
        ...recommendManufacture,
      ]);
    }
  }, [
    recommendSearch,
    recommendCategories,
    recommendManufacture,
    recommendProduct,
    recommendSubCategories,
  ]);

  const topSearch = useSelector(selectAllTopSearch);
  // console.log(topSearch);

  useEffect(() => {
    dispatch(fetchAllTopSearchAsync());
  }, [dispatch]);

  const handleTopSearch = (search) => {
    dispatch(fetchAllSubCategoriesAsync(search?.CategoryID)).then(() => {
      navigate(`/listing-page/${search?.CategoryID}/${search?.category_name}`);
      dispatch(setSelectedSubcategory(search?.sc_id));
      // dispatch(fetchAllSubCategoryProductsAsync(recommend.id));
      setBigSearch(false);
    });
  };

  const handleNavigate = (recommend) => {
    if (recommend?.type === "product") {
      navigate(`/product/${recommend?.id}`);
      setSearchTerm("");
      setBigSearch(false);
    } else if (recommend?.type === "category") {
      navigate(`/listing-page/${recommend?.id}/${recommend?.name}`);
      // dispatch(fetchAllCategoryProductsAsync(recommend.id));
      dispatch(setSelectedSubcategory(null));
      setSearchTerm("");
      setBigSearch(false);
    } else if (recommend?.type === "manufacturer") {
      navigate(`/listing-page/${recommend?.name}`);
      setSearchTerm("");
      setBigSearch(false);
    } else if (recommend?.type === "subcategory") {
      dispatch(fetchAllSubCategoriesAsync(recommend?.CategoryID)).then(() => {
        navigate(
          `/listing-page/${recommend?.CategoryID}/${recommend?.category_name}`
        );
        dispatch(setSelectedSubcategory(recommend?.id));
        // dispatch(fetchAllSubCategoryProductsAsync(recommend.id));
        setBigSearch(false);
      });
    }
  };

  const handleClearHistory = () => {
    localStorage.removeItem("recentSearches");
    setRecentSearches([]);
  };

  const handleDeleteTerm = (termToDelete) => {
    const updatedSearches = recentSearches.filter(
      (term) => term !== termToDelete
    );
    storeRecentSearches(updatedSearches);
    setRecentSearches(updatedSearches);
  };

  const renderHighlightedText = (name, type) => {
    if (!searchTerm || !name) {
      return name?.length > 10 ? name.slice(0, 22) + "..." : name;
    }

    let truncatedName = name;
    if (name?.length > 10) {
      truncatedName = name.slice(0, 25) + "...";
    }

    const parts = truncatedName.split(new RegExp(`(${searchTerm})`, "gi"));
    return parts.map((part, index) => (
      <span
        key={index}
        style={
          part.toLowerCase() === searchTerm.toLowerCase()
            ? { color: "black" }
            : { color: "gray" }
        }
      >
        {part}
      </span>
    ));
  };

  // Function to determine image link based on term category
  const getImageLink = (term) => {
    if (term.type === "product") {
      return `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${term.image}`;
    } else if (term.type === "category") {
      return `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_CATEGORY_IMAGE/${term.image}`;
    } else if (term.type === "subcategory") {
      return `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_SUBCATEGORY_IMAGE/${term.image}`;
    } else if (term.type === "manufacturer") {
      return `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${term.image}`;
    } else {
      return ""; // Default image link if category is not recognized
    }
  };

  const renderItemLimitedWithImage = (item, index) => {
    return (
      <div
        className="mobile-big-search-searched-each-item"
        key={index}
        onClick={() => handleNavigate(item)}
      >
        <img
          src={getImageLink(item)}
          alt=""
          className="mobile-big-search-searched-each-img"
        />
        <span className="mobile-big-search-searched-each-span">
          {renderHighlightedText(item?.name, item?.type)}
        </span>
      </div>
    );
  };

  return (
    <Slide right>
      <div className="mobile-big-search-container">
        <div className="mobile-big-search-logo-header">
          <img
            src={cyclecircleLogo}
            alt=""
            className="mobile-website-header-logo"
          />
          <CloseSharpIcon onClick={() => setBigSearch(false)} />
        </div>
        <div className="mobile-big-search-input-container">
          <input
            id="searchInput"
            // ref={inputRef}
            type="text"
            placeholder="Search over 3000+ products"
            className="mobile-headers-middle-search-input"
            value={searchTerm}
            onChange={(e) => handleKeyInput(e)}
            onKeyPress={handleKeyPress}
            onClick={(e) => e.stopPropagation()}
          />
          <SearchSharpIcon id="mobile-big-search-icon" onClick={handleSearch} />
        </div>
        <div className="mobile-big-search-auto-recommend-container">
          {allAddTerms &&
            allAddTerms
              .slice(0, 5)
              .map((term, index) => renderItemLimitedWithImage(term, index))}
        </div>
        {recommendManufacture.length > 0 && (
          <div className="mobile-big-search-auto-recommend-brand">
            {recommendManufacture &&
              recommendManufacture.map((brand, index) => (
                <img
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${brand.image}`}
                  alt=""
                  className="mobile-big-search-each-auto-recommend-brand"
                  onClick={() => handleNavigate(brand)}
                />
              ))}
          </div>
        )}
        <div className="mobile-big-search-trending-search-container">
          <div className="mobile-big-search-trending-search-heading">
            <WhatshotOutlinedIcon />
            <span className="mobile-big-search-trending-search-heading-span">
              Trending Searches
            </span>
          </div>
          <div className="mobile-big-search-trending-search-main-container">
            {topSearch &&
              topSearch.map((search, index) => (
                <span
                  className="mobile-big-search-each-trending-search"
                  onClick={() => handleTopSearch(search)}
                >
                  {search?.SubcategorieName && search?.SubcategorieName}
                </span>
              ))}
          </div>
        </div>
        {recentSearches.length > 0 && (
          <div className="mobile-big-search-recent-searches-container">
            <div className="mobile-big-search-recent-search-heading">
              <div className="mobile-big-search-recent-search-main-heading">
                <ScheduleOutlinedIcon />
                <span className="mobile-big-search-recent-search-heading-span">
                  Recent Searches
                </span>
              </div>
              <span
                style={{ color: "var(--color-peach)" }}
                id="mobile-big-search-recent-delete-icon"
                onClick={handleClearHistory}
              >
                Clear All
              </span>
            </div>
            <div className="mobile-big-search-recent-search-main-container">
              {recentSearches &&
                recentSearches.map((term, index) => (
                  <div
                    className="mobile-search-each-recent-search"
                    key={index}
                    onClick={() => handleSearchs(term)}
                  >
                    <span className="mobile-search-each-recent-search-span">
                      {term}
                    </span>
                    <CloseSharpIcon onClick={() => handleDeleteTerm(term)} />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </Slide>
  );
}

const mapStateToProps = (state) => ({
  searchTerm: selectSearchTerm(state),
});
const mapDispatchToProps = {
  setSearchTerm,
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileBigSearch);
