import React from "react";
import "./MobileFooter.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import cyclecircleLogo from "../../img/parts/Cycle-Circle-Logo-White-1.png";
import {
  FaCcMastercard,
  FaCcPaypal,
  FaCcAmex,
  FaCcVisa,
  FaCcDiscover,
} from "react-icons/fa";
import { setSelectedSupport } from "../../store/slices/supportSlice";

function MobileFooter({ setSelectedSupport }) {
  return (
    <div className="mobile-footer-container">
      <div className="mobile-footer-top-container">
        <div className="mobile-footer-about-us-container">
          <h3 className="mobile-footer-about-us-heading">About Us</h3>
          <Link to="/aboutUs">Know About Us</Link>
          <Link to="/aboutUs">Become a member</Link>
          <Link to="/refund">Exclusively on CC</Link>
          <Link to="/our_belief_system">Our Belief System</Link>
          <Link to="/privacypolicy">Privacy Policy</Link>
          <Link to="/copyright">CopyRight</Link>
        </div>
        <div className="mobile-footer-cc-support-container">
          <h3 className="mobile-footer-cc-support-heading">
            Cyclecircle Support
          </h3>
          <Link to="/support" onClick={() => setSelectedSupport(1)}>
            Track My Order
          </Link>
          <Link to="/support" onClick={() => setSelectedSupport(5)}>
            Returns and Refunds
          </Link>
          <Link to="/support" onClick={() => setSelectedSupport(4)}>
            User Agreement
          </Link>
          <Link to="/support" onClick={() => setSelectedSupport(3)}>
            Data Privacy Policy
          </Link>
          <Link to="/support" onClick={() => setSelectedSupport(2)}>
            Shipping Policy
          </Link>
        </div>
      </div>
      <div className="mobile-footer-bottom-container">
        <div className="mobile-footer-website-logo">
          <img
            src={cyclecircleLogo}
            alt=""
            className="mobile-footer-website-logo-img"
          />
        </div>
        <div className="mobile-footer-bottom-contact-container">
          <h3 className="mobile-footer-bottom-contact-heading">Contact</h3>
          <div className="mobile-footer-bottom-address-container">
            <LocationOnIcon />
            <p className="mobile-footer-bottom-address-parts">
              <b>RADKAAT VENTURES PRIVATE LIMITED</b>
              <br /> 1st Floor, Unit no.124, Paras Trade Centre,
              <br /> Gurgaon Faridabad Road, Gwal Pahari, Gurugram,
              <br /> District, Gurugram, Haryana 122003
              <br /> <b>GST: 02AAMCR8372H1Z4</b>
            </p>
          </div>
          <div className="mobile-footer-bottom-phone-container">
            <PhoneIcon />
            <p className="mobile-footer-bottom-phone-parts">+91-9429693000 </p>
          </div>
          <div className="mobile-footer-bottom-email-container">
            <MailIcon />
            <p className="mobile-footer-bottom-email-parts">
              support@cyclecircle.one{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="mobile-footer-stay-connected-container">
        <h3 className="mobile-footer-stay-connected-heading">Stay Connected</h3>
        <div className="mobile-footer-social-networking-container">
          <Link to="https://www.instagram.com/cyclecircle.one" target="_black">
            <img
              src="https://cdn-icons-png.flaticon.com/128/2111/2111463.png"
              alt=""
            />
          </Link>
          <Link
            to="https://www.facebook.com/profile.php?id=100083101911813&_rdr"
            target="_black"
          >
            <img
              src="https://cdn-icons-png.flaticon.com/128/733/733547.png"
              alt=""
            />
          </Link>
          <Link to="https://www.youtube.com/@cyclecircle5922" target="_black">
            {" "}
            <img
              src="https://cdn-icons-png.flaticon.com/128/3938/3938026.png"
              alt=""
            />
          </Link>
        </div>
        <div className="mobile-footer-secure-payment-container">
          <h3 className="mobile-footer-secure-payment-heading">
            Secure Online Payment
          </h3>
          <div className="mobile-footer-secure-payment-main-container">
            <FaCcMastercard />
            <FaCcPaypal />
            <FaCcAmex />
            <FaCcVisa />
            <FaCcDiscover />
          </div>
        </div>
        <div className="mobile-footer-right-container">
          <span className="mobile-footer-right-span">
            © Radkaat Ventures Private Limited @2024, All Rights Reserved
          </span>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  setSelectedSupport,
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileFooter);
